import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import FormBuilder, {
  FormState,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import { pushRoute, RouterState } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Toast from "../../../../../Layout/Common/Toast";
import Field from "../../../../../Tools/FormBuilder/Field";
import Label from "../../../../Util/Label";
import { StateType } from "../../../../../State/Store";
import PageNotFound from "../../../../Layout/Views/PageNotFound";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import HiddenField from "../../../../FormFields/HiddenField";
import InputField from "../../../../FormFields/InputField";
import UrlField from "../../../../FormFields/UrlField";
import ImageField from "../../../../FormFields/ImageField";
import OptionsField from "../../../../FormFields/OptionsField";
import { asset, transformDate } from "../../../../Util/Helper";
import ItemFormLayout from "../ItemForms/ItemFormLayout";
import VideoField from "../../../../FormFields/VideoField";
import DocumentField from "../../../../FormFields/DocumentField";
import { getItemListSignature } from "../../../../Layout/BodyOpenedWeb";
import AnswerField from "../../../../FormFields/AnswerField";
import DateTimeField from "../../../../FormFields/DateTimeField";
import WYSIWYGTextField from "../../../../FormFields/WYSIWYGTextField";
import ItemDraftFormLayout from "../ItemForms/ItemDraftFormLayout";
import ButtonsField from "../../../../FormFields/ButtonsField";
import serverValidation from "../ItemCreators/serverValidation";


type RouteParams = { uuid: string };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;

  return {
    authObject: state.app.authObject,
    params,
    record: Records.getRecord("Item", params.uuid),
  };
})(
  (props: {
    authObject: RecordObject;
    params: RouteParams;
    record: Record;
  }): React.ReactElement => {
    const [contentType, setContentType] = useState(trans(Label.ITEM__TAG_FACEBOOK));
    const [subType, setSubType] = useState(props.record.subType);

    const domainTypeMapping = {
      'instagram.com': { contentType: Label.ITEM__TAG_INSTAGRAM, subType: 'instagram' },
      'facebook.com': { contentType: Label.ITEM__TYPE_FACEBOOK_MEMBER_AREA, subType: 'facebook' },
      'twitter.com': { contentType: Label.ITEM__TAG_TWITTER, subType: 'twitter' },
      'x.com': { contentType: Label.ITEM__TAG_TWITTER, subType: 'twitter' },
      'linkedin.com': { contentType: Label.ITEM__TAG_LINKEDIN, subType: 'linkedin' }
    };

    const formBuilderRef = useRef(null);

    useEffect(() => {
      if (props.record && props.record.link) {
        checkContentType(props.record.link);
      }
    }, [props.record]);

    useEffect(() => {
      const formBuilder = formBuilderRef.current;
      if (formBuilder && subType) {
        formBuilder.updateSubTypeValue(subType);
      }
    }, [subType]);

    const checkContentType = (value: string) => {
      clearTimeout(checkContentType.timer);
      checkContentType.timer = setTimeout(() => {
        const lowerCaseValue = value.toLowerCase();
        const domains = new Set(Object.keys(domainTypeMapping));
        let found = false;

        const parts = lowerCaseValue.includes('/') ? lowerCaseValue.split('/') : [lowerCaseValue];
        for (const part of parts) {
          const matchingDomain = Array.from(domains).find(domain => part.includes(domain));
          if (matchingDomain) {
            const mapping = domainTypeMapping[matchingDomain];
            setContentType(trans(mapping.contentType));
            setSubType(mapping.subType);
            found = true;
            break;
          }
        }

        if (!found) {
          setContentType(trans(Label.ITEM__TAG_FACEBOOK));
          setSubType(props.record.subType);
        }
      }, 500);
    };
    checkContentType.timer = null;
    props.record || Records.retrieve("Item", props.params.uuid);

    if (!props.record) {
      return null; // aka loading
    }

    const isExclusive = !!props.record.exclusive;
    const displayArea = props.record.displayArea;

    let fields: { [key: string]: Field };
    let metas: { [key: string]: Field } = {};
    const draftFields =
      props.record.publishState === "published"
        ? {}
        : {
          publishState: new ButtonsField(
            "form-item-publishState-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
              options: [
                {
                  value: "published",
                  label: trans(Label.ITEM__PUBLISHED),
                  onSelect: (form) => {
                    form.setFieldValue("publishState", "published");
                    form.setFieldRequired("publishDate", true);
                  },
                  selected: true,
                },
                {
                  value: "draft",
                  label: trans(Label.ITEM__DRAFT),
                  onSelect: (form) => {
                    form.setFieldValue("publishState", "draft");
                    form.setFieldValue("pushNotification", false);
                    form.setFieldRequired("publishDate", false);
                    form.setFieldRequired("pushNotification", false);
                    form.setFieldRequired("pushTitle", false);
                    form.setFieldRequired("pushText", false);
                  },
                },
              ],
            },
            false,
            {
              padding: "8px 30px 7px",
              borderRadius: "4px",
              textTransform: "uppercase",
              marginLeft: "0px",
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL
          ),
          publishDate: new DateTimeField(
            "form-item-publishDate-id",
            Field.FLAG_REQUIRED,
            new Date(),
            { label: trans(Label.FORM__PUBLISH_DATE) }
          ),
          pushNotification: new OptionsField(
            "form-item-pushNotification-id",
            Field.FLAG_OPTIONAL,
            true,
            null,
            (value: any, form: FormState) => {
              form.setFieldRequired &&
                form.setFieldRequired("pushTitle", value);
              form.setFieldRequired &&
                form.setFieldRequired("pushText", value);
            }
          ),
          pushTitle: new InputField(
            "form-item-pnTitle-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__TITLE),
              validations: ["maxChar60"],
            }
          ),
          pushText: new InputField(
            "form-item-pnText-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__TEXT),
              validations: ["maxChar120"],
            }
          ),
        };

    switch (true) {
      case props.record.type === "message" && !props.record.subType:
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "message" && props.record.subType === "link":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "link"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "message" &&
        props.record.subType === "graphic":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "graphic"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "message" && props.record.subType === "video":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "video"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          video: new VideoField(
            "form-item-video-id",
            Field.FLAG_REQUIRED,
            asset(props.record.video),
            { label: trans(Label.FORM__VIDEO) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "message" && props.record.subType === "quote":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "quote"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;
      case props.record.type === "socialMedia" &&
        props.record.subType === "other":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            subType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_OPTIONAL,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) },
            checkContentType
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "socialMedia" &&
        props.record.subType === "facebook":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            subType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) },
            checkContentType
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_OPTIONAL,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "socialMedia" &&
        props.record.subType === "linkedin":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            subType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_OPTIONAL,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) },
            checkContentType
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;
      case props.record.type === "socialMedia" &&
        props.record.subType === "twitter":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            subType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_OPTIONAL,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) },
            checkContentType
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          ...draftFields,
        };
        break;
      case props.record.type === "socialMedia" &&
        props.record.subType === "instagram":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            subType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField('form-item-title-id', Field.FLAG_OPTIONAL, props.record.title, { label: trans(Label.FORM__TITLE), validations: ['maxChar200'] }),
          desc: new InputField('form-item-desc-id', Field.FLAG_OPTIONAL, props.record.desc, { label: trans(Label.FORM__DESC), validations: ['maxChar200'] }),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) },
            checkContentType
          ),
          cover: new ImageField('form-item-cover-id', Field.FLAG_REQUIRED, asset(props.record.cover), { label: trans(Label.FORM__COVER) }),
          ...draftFields,
        };
        break;

      case props.record.type === "message" &&
        props.record.subType === "mediaReport":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          subType: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "mediaReport"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          video: new VideoField(
            "form-item-video-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.video),
            { label: trans(Label.FORM__VIDEO) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "argument":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "argument"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "currentStatus":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "currentStatus"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "document":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "document"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "conclusion":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "conclusion"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "fixture":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "fixture"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "collectiveAgreement":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                // {label: trans(Label.ITEM__DISPLAY_MEMBER_AREA), value: 'memberArea'},
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "collectiveAgreement"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case props.record.type === "participation":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                // {label: trans(Label.ITEM__DISPLAY_MEMBER_AREA), value: 'memberArea'},
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "participation"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      case [
        "economy",
        "restructuring",
        "training",
        "securingEmployment",
        "workingHours",
        "pensions",
        "other",
        "industry",
        "corona",
        "wagePolicy",
        "digitalization",
        "employment",
        "socialPolicy",
        "education",
        "skilledEmployment",
        "laborPolicy",
        "legal",
        "international",
        "elections",
      ].indexOf(props.record.type) !== -1:
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.record.type
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          ...draftFields,
        };
        break;

      //

      case props.record.type === "poll":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.record.type
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          expiry: new DateTimeField(
            "form-item-expiry-id",
            Field.FLAG_REQUIRED,
            new Date(transformDate(props.record.expiry)),
            {
              label: trans(Label.FORM__EXPIRY),
              meta: "skipNow",
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),
          ...draftFields,
        };
        metas = {
          answer1: new AnswerField(
            "form-item-answer1-id",
            Field.FLAG_REQUIRED,
            props.record.answers[0],
            { label: trans(Label.FORM__ANSWER), validations: ["maxChar200"] }
          ),
          answer2: new AnswerField(
            "form-item-answer2-id",
            Field.FLAG_REQUIRED,
            props.record.answers[1],
            { label: trans(Label.FORM__ANSWER), validations: ["maxChar200"] }
          ),
          answer3: new AnswerField(
            "form-item-answer3-id",
            Field.FLAG_OPTIONAL,
            props.record.answers[2] || null,
            { label: trans(Label.FORM__ANSWER), validations: ["maxChar200"] }
          ),
        };
        break;

      case props.record.type === "event":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          // displayArea: new OptionsField('form-item-displayArea-id', Field.FLAG_REQUIRED, props.record.displayArea, {
          //     options: [
          //         {label: trans(Label.ITEM__DISPLAY_MEMBER_AREA), value: 'memberArea'},
          //         {label: trans(Label.ITEM__DISPLAY_TARIFF_AREA), value: 'tariffArea'},
          //     ]
          // }),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.record.type
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE), validations: ["maxChar200"] }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC), validations: ["maxChar200"] }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          address: new InputField(
            "form-item-address-id",
            Field.FLAG_OPTIONAL,
            props.record.address,
            { label: trans(Label.FORM__ADDRESS) }
          ),
          canJoin: new OptionsField(
            "form-item-canJoin-id",
            Field.FLAG_OPTIONAL,
            props.record.canJoin
          ),
          startTime: new DateTimeField(
            "form-item-startTime-id",
            Field.FLAG_REQUIRED,
            new Date(transformDate(props.record.startTime)),
            {
              label: trans(Label.FORM__START_TIME),
              meta: "skipNow",
            }
          ),
          endTime: new DateTimeField(
            "form-item-endTime-id",
            Field.FLAG_REQUIRED,
            new Date(transformDate(props.record.endTime)),
            {
              label: trans(Label.FORM__END_TIME),
              meta: "skipNow",
            }
          ),
          ...draftFields,
        };
        break;

      default:
        return <PageNotFound />;
    }


    return (
      <FormBuilder
        key={`form-item-${props.record.uuid}`}
        ref={formBuilderRef}
        action={(blueprint, done, data, callback) => {
          Records.edit(
            "Item",
            props.params.uuid,
            blueprint,
            false,
            serverValidation
          )
            .then(() => {
              Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });

              // reset exclusive list if exclusive field changes
              if (!!blueprint.fields.exclusive !== isExclusive) {
                Records.removeListFromCache(
                  getItemListSignature(
                    "adminExclusive",
                    null,
                    "tariffArea",
                    true
                  )
                );
              }
              // reset respective lists if displayArea field changes
              if (blueprint.fields.displayArea !== displayArea) {
                const tariffAreaList = getItemListSignature(
                  props.record.type,
                  props.record.subType,
                  "tariffArea"
                );
                const memberAreaList = getItemListSignature(
                  props.record.type,
                  props.record.subType,
                  "memberArea"
                );
                Records.removeListFromCache(tariffAreaList);
                Records.removeListFromCache(memberAreaList);
              }

              pushRoute(`/Admin/ItemLanding/${props.record.uuid}`);
            })
            .finally(() => done());
        }}
        data={{ record: props.record }}
        fields={fields}
        metas={metas}
        layout={
          props.record.publishState === "draft"
            ? ItemDraftFormLayout
            : ItemFormLayout
        }
        steps={[
          [
            "form-item-title-id",
            "form-item-link-id",
            "form-item-desc-id",
            "form-item-text-id",
            "form-item-cover-id",
            "form-item-address-id",
            "form-item-video-id",
            "form-item-documents-id",
            "form-item-subItems-id",
          ],
          [
            "form-item-publishDate-id",
            "form-item-pushNotification-id",
            "form-item-pnTitle-id",
            "form-item-pnText-id",
          ],
        ]}
      />
    );
  }
);
