import React from "react";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import View from "../../../../../Layout/View";
import Button from "../../../../../Layout/Components/Button";
import { Link } from "../../../../../State/Router";
import Form from "../../../../../Layout/Components/Form";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import Label from "../../../../Util/Label";
import { trans } from "../../../../../Tools/LocaleHandler";
// import MobileHandler from "../../../../../Layout/MobileHandler";
import Text from "../../../../../Layout/Components/Text";

const AuthFormLayout = (form: FormState): React.ReactNode => {
  const ScrollView: any = View; //getClientType() === "ctmobile" ? RnScrollView : View;
  const scrollProps = {
    style: {
      backgroundColor: "#115280",
      display: "flex",
      flexDirection: "column",
      flex: 1,
    },
  };

  return (
    <ScrollView {...scrollProps}>
      <View
        style={{
          alignItems: "center",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 180,
          borderBottomRightRadius: 180,
          flexDirection: "column",
          marginBottom: 80,
          paddingBottom: 64,
          paddingTop: 80,
          textAlign: "center",
        }}
      >
        <img alt={""} src={"/Logo.jpg"} width={166} height={100} />
      </View>

      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          form.buttons.submit.action();
          return false;
        }}
        style={{ paddingLeft: "8%", paddingRight: "8%" }}
      >
        <ErrorMsg error={form.getError("form-auth-email-id", true)} />
        {form.fields.email}

        <View style={{ margin: 5 }}>&nbsp;</View>

        <ErrorMsg error={form.getError("form-auth-password-id", true)} />
        {form.fields.password}

        <View style={{ margin: 5 }}>&nbsp;</View>

        <View style={{ alignItems: "center" }}>
          <Link textAlign={"right"} to={"/Gate/PasswordReset/User"}>
            <Text style={{ color: "#fff", fontSize: 15 }}>
              {trans(Label.FORM__FORGOT_PASSWORD)}
            </Text>
          </Link>
        </View>

        <View style={{ margin: 5 }}>&nbsp;</View>

        <Button
          color={"#207bba"}
          disabled={form.buttons.submit.disabled && form.touched}
          icon={"send"}
          large={true}
          onPress={() => form.buttons.submit.action()}
          title={trans(Label.FORM__AUTH)}
        />

        <Text style={{ marginTop: 50 }}>&nbsp;</Text>
      </Form>
    </ScrollView>
  );
};
export default AuthFormLayout;
