import Toast from "../../../../../../Layout/Common/Toast";
import Records, { Record } from "../../../../../../State/Records";
import { rerender } from "../../../../../../State/Router";
import { trans } from "../../../../../../Tools/LocaleHandler";
import Label from "../../../../../Util/Label";

export enum Tabs {
  "List",
  "Rejected",
}

export const moveRecord = function (
  record: Record,
  action: string,
  confirmMessage: string = null,
  moveTrigger: string = null,
  moveTo: string = null
) {
  const removedRecord = { ...record };

  const rm = function () {
    Records.remove(record.toRef("Item"), null, action)
      .then(() => {
        Toast.show({
          type: "info",
          text: trans(Label.UI__SUCCESS),
        });
      })
      .catch((ex) => {
        Toast.show({
          type: "warning",
          text: trans(ex.text || ex),
        });
      })
      .finally(() => {
        rerender();
        if (action === moveTrigger) {
          Records.addToList("Item:" + moveTo, "Item", removedRecord);
        }
      });
  };

  if (confirmMessage) {
    if (window.confirm(confirmMessage)) {
      rm();
    }
  } else {
    rm();
  }
};

export const styles = {
  td: {
    padding: 6,
    fontFamily: "Lato",
    fontSize: 14,
    color: "#0A3B60",
    borderBottom: "solid 3px #fff",
  },
  th: {
    borderTop: "solid 1px #ccc",
    padding: 12,
    fontFamily: "Lato",
    fontSize: 14,
    fontWeight: "bold" as const,
    color: "#0A3B60",
  },
  tr: {
    background: "#F5F7F8",
  },
  widget: {
    backgroundColor: "#0083c4",
    borderRadius: 4,
    cursor: "pointer",
    fontSize: 44,
    marginLeft: 4,
    marginRight: 4,
    fill: "#fff",
  },
  blueButton: {
    cursor: "pointer",
    backgroundColor: "#d3e5f4",
    borderColor: "#0083c4",
    color: "#0083c4",
    textTransform: "none",
  },
  redButton: {
    cursor: "pointer",
    backgroundColor: "#ffe8e8",
    borderColor: "#fc5d5f",
    color: "#fc5d5f",
    textTransform: "none",
  },
  actionButton: {
    cursor: "pointer",
    width: 30,
    margin: 2,
  },
};
