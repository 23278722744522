import Box from "@mui/material/Box";

const ItemLayoutHeader: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Box
      sx={{
        color: "#207BBA",
        fontSize: "20px",
        fontWeight: "bold",
        marginBottom: "24px",
        marginTop: "24px",
      }}
    >
      {title}
    </Box>
  );
};
export default ItemLayoutHeader;
