import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { trans } from "../../../../../Tools/LocaleHandler";
import Form from "../../../../../Layout/Components/Form";
import Label from "../../../../Util/Label";
import { ContentWizardHeader } from "./ContentWizardFormLayout";
import ItemLayoutHeader from "./ItemLayoutHeader";
import ContentPreview from "../ItemPreview/ContentPreview";
import { InfoBubble } from "../../../../../Layout/Components/InfoBubble";
import ItemFieldWrapper, { FieldLabelStyled } from "./ItemFieldWrapper";
import ItemNavButtons from "./ItemNavButtons";


const ItemCwFormLayout = (form: FormState): React.ReactNode => {

  return (
    <Form form={form} style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <ItemLayoutHeader title={trans(Label.ADMIN__CONTENT_WIZARD_TITLE)} />
      {/* step 0 */}
      {form.step.current === 0 && (
        <React.Fragment>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "50px 70px",
              marginBottom: "50px",
            }}
          >
            {/* wizard step header */}
            {<ContentWizardHeader step={form.step.current + 1} />}
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Box sx={{ width: "50%" }}>
                {form.fields.expiry && (
                  <ItemFieldWrapper field={form.fields.expiry}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FieldLabelStyled>
                        {trans(Label.FORM__EXPIRY)}
                      </FieldLabelStyled>
                      <Box sx={{ alignSelf: "center" }}>
                        <InfoBubble fieldId={"form-item-expiry-id"} />
                      </Box>
                    </Box>
                  </ItemFieldWrapper>
                )}

                {form.fields.title && (
                  <ItemFieldWrapper
                    field={form.fields.title}
                    error={form.getError("form-item-title-id", true)}
                  />
                )}

                {form.fields.link && (
                  <ItemFieldWrapper
                    field={form.fields.link}
                    error={form.getError("form-item-link-id", true)}
                  />
                )}

                {form.fields.desc && (
                  <ItemFieldWrapper
                    field={form.fields.desc}
                    error={form.getError("form-item-desc-id", true)}
                  />
                )}

                {form.fields.text && (
                  <ItemFieldWrapper
                    field={form.fields.text}
                    error={form.getError("form-item-text-id", true)}
                  />
                )}

                {form.fields.cover && (
                  <ItemFieldWrapper
                    field={form.fields.cover}
                    error={form.getError("form-item-cover-id", true)}
                  />
                )}

                {form.fields.address && (
                  <ItemFieldWrapper
                    field={form.fields.address}
                    error={form.getError("form-item-address-id", true)}
                  />
                )}

                {form.fields.canJoin && (
                  <ItemFieldWrapper field={form.fields.canJoin}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <FieldLabelStyled>
                        {trans(Label.FORM__EVENT_CAN_JOIN_FLAG)}
                      </FieldLabelStyled>
                      <Box>
                        <InfoBubble fieldId={"form-item-canJoin-id"} />
                      </Box>
                    </Box>
                  </ItemFieldWrapper>
                )}

                {form.fields.startTime && (
                  <ItemFieldWrapper field={form.fields.startTime}>
                    <FieldLabelStyled>
                      {trans(Label.FORM__START_TIME)}
                    </FieldLabelStyled>
                  </ItemFieldWrapper>
                )}

                {form.fields.endTime && (
                  <ItemFieldWrapper field={form.fields.endTime}>
                    <FieldLabelStyled>
                      {trans(Label.FORM__END_TIME)}
                    </FieldLabelStyled>
                  </ItemFieldWrapper>
                )}

                {form.fields.video && (
                  <ItemFieldWrapper
                    field={form.fields.video}
                    error={form.getError("form-item-video-id", true)}
                  />
                )}

                {form.fields.documents && (
                  <ItemFieldWrapper
                    field={form.fields.documents}
                    error={form.getError("form-item-documents-id", true)}
                  />
                )}

                {form.metas.answer1 &&
                  form.metas.answer2 &&
                  form.metas.answer3 && (
                    <React.Fragment>
                      <ItemFieldWrapper
                        field={form.metas.answer1}
                        error={form.getError("form-item-answer1-id", true)}
                      />
                      <ItemFieldWrapper
                        field={form.metas.answer2}
                        error={form.getError("form-item-answer2-id", true)}
                      />
                      <ItemFieldWrapper
                        field={form.metas.answer3}
                        error={form.getError("form-item-answer3-id", true)}
                      />
                    </React.Fragment>
                  )}
              </Box>
              <ContentPreview form={form} />
            </Stack>
          </Box>

          {form.metas.subItems && (
            <React.Fragment>{form.metas.subItems}</React.Fragment>
          )}
          <ItemNavButtons form={form} />
        </React.Fragment>
      )}

      {/* step 1 */}
      {form.step.current === 1 && (
        <React.Fragment>
          <Box
            sx={{
              backgroundColor: "#fff",
              padding: "50px 70px",
              marginBottom: "50px",
            }}
          >
            {/* wizard step header */}
            {<ContentWizardHeader step={form.step.current + 1} />}

            {form.getFieldValue("displayArea") === "tariffArea" &&
              !!form.fields.exclusive && (
                <ItemFieldWrapper field={form.fields.exclusive}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "",
                      alignItems: "center",
                    }}
                  >
                    <FieldLabelStyled>
                      {trans(Label.FORM__EXCLUSIVE)}
                    </FieldLabelStyled>
                    <Box>
                      <InfoBubble fieldId={"form-item-exclusive-id"} />
                    </Box>
                  </Box>
                </ItemFieldWrapper>
              )}
            <ItemFieldWrapper field={form.fields.publishState}>
              <FieldLabelStyled>
                {trans(Label.FORM__PUBLISH_STATE_LABEL)}
              </FieldLabelStyled>
            </ItemFieldWrapper>

            {form.getFieldValue("publishState") === "published" && (
              <>
                <ItemFieldWrapper field={form.fields.publishDate}>
                  <FieldLabelStyled>
                    {trans(Label.FORM__PUBLISH_DATE)}
                  </FieldLabelStyled>
                </ItemFieldWrapper>
                <ItemFieldWrapper field={form.fields.pushNotification}>
                  <FieldLabelStyled>
                    {trans(Label.FORM__PUSH_NOTIFICATION)}
                  </FieldLabelStyled>
                </ItemFieldWrapper>

                {form.getFieldValue("pushNotification") ? (
                  <React.Fragment>
                    <ItemFieldWrapper
                      field={form.fields.pushTitle}
                      error={form.getError("form-item-pnTitle-id", true)}
                    />
                    <ItemFieldWrapper
                      field={form.fields.pushText}
                      error={form.getError("form-item-pnText-id", true)}
                    />
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </>
            )}
          </Box>
          <ItemNavButtons form={form} />
        </React.Fragment>
      )}
    </Form>
  );
};
export default ItemCwFormLayout;
