import Typography from "@mui/material/Typography";
type LinkMoreProps = {
  label: string;
};
export const LinkMore: React.FC<LinkMoreProps> = ({ label }) => {
  return (
    <Typography
      variant="subtitle2"
      sx={{ color: "#207BBA", fontWeight: "bold" }}
    >
      {label}
    </Typography>
  );
};
