import Box from "@mui/material/Box";
import Button, { ButtonProps } from "../../../../../Layout/Components/Button";
import { pushRoute } from "../../../../../State/Router";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";

const ItemNavButton: React.FC<ButtonProps> = (props) => (
  <Button color="#4daff0" large={true} {...props} />
);

const ItemNavButtons: React.FC<{ form: FormState }> = ({ form }) => {
  const { submit, prev, next } = form.buttons;
  const { current } = form.step;
  const goBack =
    current === 0
      ? () => pushRoute("/Admin/ContentWizard")
      : () => prev.action();
  const goForward = current === 0 ? () => next.action() : () => submit.action();
  const disabled = current === 0 ? next.disabled : submit.disabled;
  const published = form.getFieldValue("publishState");
  const titleGoBack = trans(Label.UI__BACK);
  const titleGoForward =
    current === 0
      ? trans(Label.UI__NEXT)
      : published === "published"
      ? trans(Label.FORM__PUBLISH)
      : trans(Label.FORM__SAVE_DRAFT);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "50px",
      }}
    >
      <ItemNavButton
        disabled={prev.disabled}
        onPress={goBack}
        title={titleGoBack}
      />
      <ItemNavButton
        disabled={disabled}
        onPress={goForward}
        title={titleGoForward}
      />
    </Box>
  );
};
export default ItemNavButtons;
