import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import Box from "@mui/material/Box";
import { InfoBubble } from "../../Layout/Components/InfoBubble";

export function inputStyles(this: Field) {
  const borderColor = this.error
    ? "#ff3333"
    : this.isRequired && !this.isValid
    ? "#ff3333"
    : "transparent";

  const bgColor = !this.context.bgColor ? "#f2f5f7" : this.context.bgColor;

  const color = !this.context.color ? "#093b60" : this.context.color;

  return {
    borderColor: borderColor,
    boxShadow: `0px 0px 5px ${borderColor}`,
    borderWidth: 1,
    backgroundColor: bgColor,
    borderRadius: 16,
    color: color,
    fontSize: 18,
    outline: "none",
    padding: 18,
    paddingLeft: 32,
    paddingRight: 32,
    width: "100%",
  };
}

export type InputStylesDefinition = ReturnType<typeof inputStyles>;

export default class InputField extends Field {
  public display(signal: FieldSignal): React.ReactElement {
    const style: InputStylesDefinition = inputStyles.call(this);
    const fieldId =
      this.id.indexOf("subItemTitle") !== -1
        ? "form-item-subItemTitle-id"
        : this.id;
    const accentInfo = this.context.validations
      ? this.context.validations[0].substring(7)
      : null;
    const labelWithLimit = `${this.context.label}${
      accentInfo
        ? " " + accentInfo + " " + this.trans("trans.form.charachters")
        : ""
    }`;
    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <input
          autoFocus={this.context.focus}
          defaultValue={this.value}
          id={this.id}
          onBlur={() => {
            signal.blur();
          }}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
            this.change(ev.target.value)
          }
          onFocus={() => {
            signal.focus();
          }}
          onKeyPress={(ev) => {
            if (
              ev.key === "Enter" &&
              typeof this.context.onReturn === "function"
            ) {
              ev.preventDefault();
              this.context.onReturn();
              return false;
            }
          }}
          placeholder={labelWithLimit}
          ref={(ref) => !!ref && (Field.fieldRefs[this.id] = ref)}
          style={style}
          type={"text"}
        />
        <Box sx={{ alignSelf: "center", position: "absolute", right: ".8rem" }}>
          {!this.value ? (
            <InfoBubble fieldId={fieldId} accentInfo={accentInfo} />
          ) : null}
        </Box>
      </Box>
    );
  }
}
