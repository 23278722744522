import { connect } from "react-redux";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import List from "@mui/material/List";

import { pushRoute } from "../../../State/Router";
import { StateType } from "../../../State/Store";
import { RecordObject } from "../../../State/Records";
import { deauth, getAuthObject } from "../../Root";

import NavNestedButton from "./NavNestedButton";
import { getUserMenu } from "./config";
import { KNOWLEDGE_BASE_ICON } from "./config";
import { MenuSelectionProvider } from "./context";
import NavButton from "./NavButton";
import { kb } from "../../ActionDomains/Admin/KnowledgeBase/KnowledgeBaseViews/KnowledgeBaseLanding";

type NavWebProps = { authObject: RecordObject };

export default connect((state: StateType) => ({
  authObject: state.app.authObject,
}))((props: NavWebProps) => {
  const { authObject } = props;
  if (!authObject) return null;
  const navMenu = getUserMenu(authObject.record);
  return (
    <>
      <Box
        sx={{
          paddingTop: "20px",
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
        }}
        onClick={() => {
          pushRoute("/");
        }}
      >
        <img alt={""} src={"/Logo.jpg"} width={166} height={100} />
      </Box>
      <Box
        sx={{
          margin: "10px",
          display: "flex",
          cursor: "pointer",
          justifyContent: "center",
        }}
        onClick={() => pushRoute("/Web/MyProfile")}
      >
        {getAuthObject().record.firstName} {getAuthObject().record.lastName}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-around",
          padding: "10px",
        }}
      >
        <Box
          onClick={() => pushRoute("/Admin/Settings")}
          sx={{ cursor: "pointer" }}
        >
          <img alt={""} src={"/icons/Setup_gray.svg"} width={20} />
        </Box>
        <Box onClick={() => deauth()} sx={{ cursor: "pointer" }}>
          <img alt={""} src={"/icons/Power.svg"} width={20} />
        </Box>
      </Box>
      <Divider />
      <MenuSelectionProvider>
        <List component="div">
          {navMenu.map((menuItem, index) => (
            <NavNestedButton
              key={menuItem.buttonText}
              position={`${index}`}
              buttonText={menuItem.buttonText}
              icon={menuItem.icon}
              nestedList={menuItem.nestedList}
              route={menuItem.route}
            />
          ))}

          <NavButton
            buttonText={"Knowledge Base"}
            icon={KNOWLEDGE_BASE_ICON}
            action={() => {
              kb("https://knowledge.metallarbeitgeber.app/");

              //temporary fix, please dont touch!
              //   let cookie = null;

              //   const fd = new FormData();
              //   fd.append("username", Env.kbUser);
              //   fd.append("password", Env.kbPass);

              //   if (!cookie)
              //     fetch(Env.kbBaseUrl + "/api/auth/generate_auth_cookie", {
              //       method: "post",
              //       body: fd,
              //     })
              //       .then((res) => {
              //         res.json().then((d) => {
              //           let u =
              //             Env.kbBaseUrl +
              //             "?cookie=" +
              //             encodeURI(d.cookie) +
              //             "&cookie_name=" +
              //             encodeURI(d.cookie_name);
              //           window.open(u, "blank");
              //         });
              //       })
              //       .catch((ex) => {
              //         console.warn(ex);
              //       });
            }}
            selected={false}
          ></NavButton>
        </List>
      </MenuSelectionProvider>
    </>
  );
});
