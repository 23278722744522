import React from "react";
import Button from "../../../../../Layout/Components/Button";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import Storage from "../../../../../State/Storage";
import HttpClient from "../../../../../State/HttpClient";
import Field from "../../../../../Tools/FormBuilder/Field";
import { asset } from "../../../../Util/Helper";
import Delete from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
const SettingsLanding = () => {
  const settings = Storage.get<any>("settings");
  const [state, setState] = React.useState({
    settings,
    uploadImageErr: null,
  });

  let emptyFiles: FileList = null;
  let iRef: HTMLInputElement = null;

  const setNullImage = () => {
    iRef.files = emptyFiles;
  };

  const setImage = (file: File) => {
    setNullImage();
    HttpClient.action("uploadOnboardingImage", "User", { upload: file }).then(
      (settings) => {
        Storage.set("settings", settings);
        setState({
          ...state,
          settings,
        });
      }
    );
  };

  return (
    <div style={{ marginBottom: 100, padding: "2%" }}>
      <div>
        <Button
          onPress={() => {
            HttpClient.action("settingTariffMode", "User", {
              data: { tariffMode: true },
            }).then((settings) => {
              Storage.set("settings", settings);
              setState({
                ...state,
                settings,
              });
            });
          }}
          color={settings.tariffMode ? "#0083c4" : "#ccc"}
          title={trans(Label.SETTINGS__MODE_TARIFF)}
          style={{ marginRight: 15 }}
        />
        <Button
          onPress={() => {
            HttpClient.action("settingTariffMode", "User", {
              data: { tariffMode: false },
            }).then((settings) => {
              Storage.set("settings", settings);
              setState({
                ...state,
                settings,
              });
            });
          }}
          color={settings.tariffMode ? "#ccc" : "#0083c4"}
          title={trans(Label.SETTINGS__MODE_MEMBER)}
        />
      </div>

      <div
        style={{
          backgroundColor: "#ccc",
          height: 1,
          marginBottom: 25,
          marginTop: 25,
          width: "100%",
        }}
      ></div>

      <div>
        {/*<Button*/}
        {/*    color={'#0083c4'}*/}
        {/*    onPress={() => {*/}
        {/*        if (!confirm(trans(Label.UI__CONFIRM))) {*/}
        {/*            return;*/}
        {/*        }*/}
        {/*        HttpClient.action('resetOnboardingForAllUsers', 'User').then(res => {*/}
        {/*            if (!res) {*/}
        {/*                return;*/}
        {/*            }*/}
        {/*            Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)});*/}
        {/*        });*/}
        {/*    }}*/}
        {/*    style={{marginBottom: 25}}*/}
        {/*    title={trans(Label.SETTINGS__RESET_ONBOARDING_FOR_ALL_USERS)}*/}
        {/*/>*/}
        {/*<br/>*/}
        <Button
          color={state.settings.enableOnboarding ? "#0083c4" : "#ccc"}
          onPress={() => {
            HttpClient.action("toggleOnboarding", "User").then((settings) => {
              Storage.set("settings", settings);
              setState({
                ...state,
                settings,
              });
            });
          }}
          style={{ marginBottom: 25 }}
          title={trans(Label.SETTINGS__ENABLE_ONBOARDING)}
        />
        <br />
        <label htmlFor={"onboarding-picture-mode-select"}>
          {trans(Label.SETTINGS__ONBOARDING_PICTURE_MODE)}
        </label>
        &nbsp;&nbsp;&nbsp;
        <Select
          id={"onboarding-picture-mode-select"}
          onChange={(ev) => {
            HttpClient.action("onboardingPictureMode", "User", {
              data: { onboardingPictureMode: ev.target.value },
            });
            const settings = {
              ...state.settings,
              onboardingPictureMode: ev.target.value,
            };
            setState({
              ...state,
              settings,
            });
            Storage.set("settings", settings);
          }}
          style={{ marginBottom: 25 }}
          value={state.settings.onboardingPictureMode}
        >
          {[
            {
              label: trans(Label.SETTINGS__ONBOARDING_PICTURE_MODE_COVER),
              value: "cover",
            },
            {
              label: trans(Label.SETTINGS__ONBOARDING_PICTURE_MODE_CONTAIN),
              value: "contain",
            },
            {
              label: trans(Label.SETTINGS__ONBOARDING_PICTURE_MODE_STRETCH),
              value: "stretch",
            },
            {
              label: trans(Label.SETTINGS__ONBOARDING_PICTURE_MODE_CENTER),
              value: "center",
            },
          ].map((item) => (
            <MenuItem key={`${item.value}:${item.label}`} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        <br />
        <div style={{ marginBottom: 35, marginTop: 10 }}>
          {state.uploadImageErr && (
            <div style={{ color: "#ff3333", marginBottom: 20 }}>
              {state.uploadImageErr}
            </div>
          )}
          <label
            htmlFor={"onboarding-upload-field"}
            style={{
              background: "#0083c4",
              borderRadius: 5,
              color: "#fff",
              fontWeight: "bold",
              padding: 10,
              paddingBottom: 8,
              paddingTop: 8,
            }}
          >
            {trans(Label.FORM__UPLOAD)}
          </label>
          <input
            id={"onboarding-upload-field"}
            onChange={(ev) => {
              setState({
                ...state,
                uploadImageErr: null,
              });

              if (!ev.target.files.length) {
                setNullImage();
                return;
              }

              const file = ev.target.files[0];
              if (file.type.substr(0, 5) !== "image") {
                setNullImage();
                setState({
                  ...state,
                  uploadImageErr: trans(Field.VALIDATION_ERROR__IMAGE),
                });
                return;
              }

              setImage(ev.target.files[0]);
            }}
            ref={(ref) => {
              if (!ref) {
                return;
              }
              iRef = ref;
              emptyFiles === null && (emptyFiles = ref.files);
            }}
            type={"file"}
            style={{ display: "none" }}
          />
        </div>
        {state.settings.onboardingImages?.length > 0 && (
          <DragDropContext
            onDragEnd={(result) => {
              const from = result.source.index;
              const to = result.destination.index;
              if (from === to) return;
              let newList = [...state.settings.onboardingImages];

              const image = state.settings.onboardingImages[from];
              newList.splice(from, 1);
              newList.splice(to, 0, image);

              const settings = { ...state.settings, onboardingImages: newList };
              setState({
                ...state,
                settings,
              });

              HttpClient.action("reorderOnboardingImages", "User", {
                data: { onboardingImages: newList },
              }).then((settings) => {
                Storage.set("settings", settings);
                setState({
                  ...state,
                  settings,
                });
              });
            }}
          >
            <Droppable droppableId={"droppable"}>
              {(provided, snapshot) => (
                <div ref={provided.innerRef}>
                  {state.settings.onboardingImages.map(
                    (image: string, index: number) => (
                      <Draggable key={image} draggableId={image} index={index}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                marginBottom: 25,
                              }}
                            >
                              <img
                                alt={""}
                                src={asset(`Onboarding/${image}`)}
                                style={{ maxHeight: 250, maxWidth: 250 }}
                              />
                              <Delete
                                onClick={() => {
                                  HttpClient.action(
                                    "deleteOnboardingImage",
                                    "User",
                                    { data: { image } }
                                  ).then((settings) => {
                                    Storage.set("settings", settings);
                                    setState({
                                      ...state,
                                      settings,
                                    });
                                  });
                                }}
                                style={{
                                  cursor: "pointer",
                                  margin: 15,
                                  marginTop: 0,
                                }}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  )}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </div>
    </div>
  );
};
export default SettingsLanding;
