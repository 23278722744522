import { useState, useContext, createContext } from "react";

type SelectionState = { selectedItem: string };
type Action = { setSelectedItem: React.Dispatch<string> };
type SelectionProviderProps = { children: React.ReactNode };
const SelectionContext = createContext<SelectionState | undefined>(undefined);
const SetSelectionContext = createContext<Action | undefined>(undefined);

const MenuSelectionProvider: React.FC<SelectionProviderProps> = ({
  children,
}) => {
  const [selected, setSelected] = useState("0");
  return (
    <SelectionContext.Provider value={{ selectedItem: selected }}>
      <SetSelectionContext.Provider value={{ setSelectedItem: setSelected }}>
        {children}
      </SetSelectionContext.Provider>
    </SelectionContext.Provider>
  );
};
function useSelectionContext() {
  const context = useContext(SelectionContext);
  if (context === undefined) {
    throw new Error(
      "useSelectionContext must be used within a MenuSelectionProvider"
    );
  }
  return context;
}
function useSetSelectionContext() {
  const context = useContext(SetSelectionContext);
  if (context === undefined) {
    throw new Error(
      "useSetSelectionContext must be used within a MenuSelectionProvider"
    );
  }
  return context;
}

export { MenuSelectionProvider, useSelectionContext, useSetSelectionContext };
