import React from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../../State/Store";
import Records, { Record, RecordRef } from "../../../../../../State/Records";
import { trans } from "../../../../../../Tools/LocaleHandler";
import Label from "../../../../../Util/Label";
import Button from "../../../../../../Layout/Components/Button";
import HttpClient from "../../../../../../State/HttpClient";
import Toast from "../../../../../../Layout/Common/Toast";

type ReleaseCenterProps = { mySubscriptions: Record[]; publishers: Record[] };
export default connect((state: StateType): ReleaseCenterProps => {
  return {
    mySubscriptions: Records.mapRefsToRecords(
      state.lists["AssociationSubscription:mySubscriptions"]
    ),
    publishers: Records.mapRefsToRecords(state.lists["Association:publishers"]),
  };
})((props: ReleaseCenterProps) => {
  props.mySubscriptions ||
    Records.list("AssociationSubscription", "mySubscriptions");
  props.publishers || Records.list("Association", "publishers");

  if (!props.mySubscriptions || !props.publishers) {
    return null;
  }

  const styles = {
    header: {
      fontFamily: "Lato",
      color: "#0A3B60",
      display: "flex",
      flexDirection: "row" as const,
      fontSize: 16,
      fontWeight: "bold" as const,
      justifyContent: "space-between",
      marginBottom: 24,
      marginTop: 24,
    },
    box: {
      backgroundColor: "#fff",
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginBottom: 30,
      marginLeft: 30,
      marginRight: 30,
    },
    subheader: {
      margin: 25,
    },
    td: {
      padding: 6,
      fontFamily: "Lato",
      fontSize: 14,
      color: "#0A3B60",
      borderBottom: "solid 3px #fff",
    },
    th: {
      borderTop: "solid 1px #ccc",
      borderBottom: "solid 1px #ccc",
      padding: 12,
      width: "16.6%",
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: "bold" as const,
      color: "#0A3B60",
    },
    widget: {
      backgroundColor: "#0083c4",
      borderRadius: 4,
      cursor: "pointer",
      fontSize: 44,
      marginLeft: 4,
      marginRight: 4,
      fill: "#fff",
    },
  };

  const mySubscriptions: { [key: string]: Record } = {};
  props.mySubscriptions.map(
    (subscription) =>
      (mySubscriptions[subscription.publisher.uuid] = subscription)
  );

  const records: {
    uuid: string;
    name: string;
    isSubscribed: boolean;
    autoAdopt: boolean;
  }[] = props.publishers.map((publisher) => {
    const isSubscribed = !!mySubscriptions[publisher.uuid];
    const autoAdopt = !isSubscribed
      ? false
      : mySubscriptions[publisher.uuid].autoAdopt;
    return {
      uuid: publisher.uuid,
      name: publisher.name,
      isSubscribed,
      autoAdopt,
    };
  });

  return (
    <React.Fragment>
      <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
        <h2 style={styles.header}>
          {trans(Label.ADMIN__RELEASE_CENTER_TITLE)}
        </h2>

        <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10 }}>
          <div
            style={{
              fontFamily: "Lato",
              color: "#0A3B60",
              display: "flex",
              flexDirection: "row",
              fontSize: 16,
              fontWeight: "bold",
              justifyContent: "space-between",
              marginBottom: 24,
              marginTop: 24,
            }}
          >
            {trans(Label.ADMIN__RELEASE_CENTER_SUBTITLE)}
          </div>

          <table
            cellSpacing={0}
            cellPadding={0}
            style={{ marginLeft: 25, width: "calc(100% - 48px)" }}
          >
            <thead>
              <tr>
                <td style={styles.th}>&nbsp;</td>
                <td style={styles.th}>
                  {trans(Label.ADMIN__NAV_RELEASE_CENTER_SUBSCRIBE)}
                </td>
                <td style={styles.th}>
                  {trans(Label.ADMIN__NAV_RELEASE_CENTER_AUTO_ADOPT)}
                </td>
              </tr>
            </thead>
            <tbody>
              {!records.length && (
                <tr>
                  <td colSpan={4} style={styles.th}>
                    {trans("trans.ui.emptyList")}
                  </td>
                </tr>
              )}
              {records.map((record) => {
                return (
                  <tr key={record.uuid}>
                    <td style={styles.td}>{record.name}</td>
                    <td style={styles.td}>
                      {record.isSubscribed ? (
                        <Button
                          color={"#0083c4"}
                          onPress={() => {
                            Records.remove(
                              new RecordRef(
                                "AssociationSubscription",
                                mySubscriptions[record.uuid].uuid
                              )
                            )
                              .then(() => {
                                // Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)});
                              })
                              .catch((ex) => {
                                console.warn(ex);
                                Toast.show({ type: "warning", text: ex });
                              });
                          }}
                          small={true}
                          style={{ border: "solid 1px #707070" }}
                          title={trans(Label.UI__ACTIVATED)}
                        />
                      ) : (
                        <Button
                          color={"#eef3f6"}
                          onPress={() => {
                            HttpClient.action(
                              "subscribe",
                              "AssociationSubscription",
                              {
                                data: {
                                  publisherUuid: record.uuid,
                                },
                              }
                            )
                              .then((associationSubscription) => {
                                Records.addToList(
                                  "AssociationSubscription:mySubscriptions",
                                  "AssociationSubscription",
                                  associationSubscription
                                );
                                // Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)})
                              })
                              .catch((ex) => {
                                console.warn(ex);
                                Toast.show({ type: "warning", text: ex });
                              });
                          }}
                          small={true}
                          style={{ border: "solid 1px #707070" }}
                          textColor={"#646f75"}
                          title={trans(Label.UI__DEACTIVATED)}
                        />
                      )}
                    </td>
                    <td style={styles.td}>
                      {record.autoAdopt ? (
                        <Button
                          color={"#0083c4"}
                          disabled={!record.isSubscribed}
                          onPress={() => {
                            Records.edit(
                              "AssociationSubscription",
                              mySubscriptions[record.uuid].uuid,
                              {
                                fields: {
                                  uuid: mySubscriptions[record.uuid].uuid,
                                  autoAdopt: false,
                                },
                              }
                            )
                              .then(() => {
                                // Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)});
                              })
                              .catch((ex) => {
                                console.warn(ex);
                                Toast.show({ type: "warning", text: ex });
                              });
                          }}
                          small={true}
                          style={{ border: "solid 1px #707070" }}
                          title={trans(Label.UI__ACTIVATED)}
                        />
                      ) : (
                        <Button
                          color={"#eef3f6"}
                          disabled={!record.isSubscribed}
                          onPress={() => {
                            Records.edit(
                              "AssociationSubscription",
                              mySubscriptions[record.uuid].uuid,
                              {
                                fields: {
                                  uuid: mySubscriptions[record.uuid].uuid,
                                  autoAdopt: true,
                                },
                              }
                            )
                              .then(() => {
                                // Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)});
                              })
                              .catch((ex) => {
                                console.warn(ex);
                                Toast.show({ type: "warning", text: ex });
                              });
                          }}
                          small={true}
                          style={{ border: "solid 1px #707070" }}
                          textColor={"#646f75"}
                          title={trans(Label.UI__DEACTIVATED)}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
});
