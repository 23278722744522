import React, { HTMLAttributes, ReactNode, SyntheticEvent } from "react";
import { PressHandler } from "./LayoutHelper";

interface WebViewProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactNode;
  getSize?: () => { height: number; width: number };
  onPress?: PressHandler;
  space?: number;
  style?: { [key: string]: any };
}
export default class View extends React.Component<WebViewProps> {
  public render(): ReactNode {
    let {
      children,
      getSize,
      onPress,
      space,
      style = {},
      ...elemProps
    } = this.props;
    !style.display && (style.display = "flex");
    !style.flexDirection && (style.flexDirection = "column");
    space && (style.margin = space / 2);

    const textStyle = { textAlign: "none", color: "none" };
    if (style.textAlign) textStyle.textAlign = style.textAlign;
    if (style.color) textStyle.color = style.color;
    if (!onPress) {
      return (
        <div {...(elemProps as WebViewProps)} style={style}>
          {children}
        </div>
      );
    }
    return (
      <div
        style={style}
        onClick={(ev: SyntheticEvent) =>
          onPress({
            synthetic: ev,
          })
        }
        {...(elemProps as WebViewProps)}
      >
        {children}
      </div>
    );
  }
}
