import React from "react";
import View from "../../../../../Layout/View";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";

const DraftEditHeader = (props: { step: number }): React.ReactElement => {
  const activeStepStyle = { backgroundColor: "#67ADF4", color: "#fff" };
  const baseStyle = {
    fontSize: 16,
    fontWeight: "bold",
  };
  return (
    <View
      style={{
        backgroundColor: "#F5F7F8",
        flexDirection: "row",
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      <View
        style={{
          ...baseStyle,
          ...(props.step === 0 ? activeStepStyle : { color: "#0A3B60" }),
          flex: 1,
          padding: 16,
        }}
      >
        {trans(Label.ADMIN__CONTENT_WIZARD_STEP_2_TITLE).replace("2.", "")}
      </View>
      <View
        style={{
          ...baseStyle,
          ...(props.step === 1 ? activeStepStyle : { color: "#0A3B60" }),
          flex: 1,
          padding: 16,
        }}
      >
        {trans(Label.ADMIN__CONTENT_WIZARD_STEP_3_TITLE).replace("3.", "")}
      </View>
    </View>
  );
};
export default DraftEditHeader;
