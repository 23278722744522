import React from "react";
import { FormState } from "../../../../../../../Tools/FormBuilder/FormBuilder";
import ErrorMsg from "../../../../../../../Layout/Components/ErrorMsg";
import View from "../../../../../../../Layout/View";
import Delete from "@mui/icons-material/Delete";
import { trans } from "../../../../../../../Tools/LocaleHandler";
import Label from "../../../../../../Util/Label";
import Button from "../../../../../../../Layout/Components/Button";

const SubItemFormLayout = (form: FormState): React.ReactNode => {
  const inlineSuffix = form.data.inline ? "-inline" : "";

  return (
    <View
      style={{
        paddingBottom: 48,
        paddingLeft: 72,
        paddingRight: 72,
        paddingTop: 16,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginRight: -36,
        }}
      >
        {
          <Delete
            onClick={() => {
              if (!form.remove) return;
              form.remove();
            }}
            style={
              form.remove ? { cursor: "pointer", opacity: 1 } : { opacity: 0 }
            }
          />
        }
      </View>

      <ErrorMsg error={form.getError(`form-subItem${inlineSuffix}-title-id`)} />
      {form.fields.title}
      <View style={{ margin: 4 }}>&nbsp;</View>

      {/*<ErrorMsg error={form.getError(`form-subItem${inlineSuffix}-icon-id`, true)}/>*/}
      {/*{form.fields.icon}*/}
      {/*<View style={{margin: 4}}>&nbsp;</View>*/}

      <ErrorMsg
        error={form.getError(`form-subItem${inlineSuffix}-text-id`, true)}
      />
      {form.fields.text}

      <View style={{ margin: 4 }}>&nbsp;</View>
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <Button
          color={"#207bba"}
          disabled={form.buttons.submit.disabled && form.touched}
          onPress={() => form.buttons.submit.action()}
          title={trans(Label.FORM__SUBMIT)}
        />
      </View>
    </View>
  );
};
export default SubItemFormLayout;
