import Chip from "@mui/material/Chip";
type ContentTypeProps = {
  contentType: string;
};
export const ContentType: React.FC<ContentTypeProps> = ({ contentType }) => {
  return (
    <Chip
      label={contentType}
      color="info"
      sx={{
        position: "absolute",
        top: "12px",
        right: "12px",
        fontSize: "10px",
        textTransform: "uppercase",
      }}
    />
  );
};
