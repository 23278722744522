import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import FormBuilder, {
  FormState,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import Toast from "../../../../../Layout/Common/Toast";
import Records, { RecordObject } from "../../../../../State/Records";
import { pushRoute, RouterState } from "../../../../../State/Router";
import HiddenField from "../../../../FormFields/HiddenField";
import ItemCwFormLayout from "../ItemForms/ItemCwFormLayout";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import Field from "../../../../../Tools/FormBuilder/Field";
import InputField from "../../../../FormFields/InputField";
import UrlField from "../../../../FormFields/UrlField";
import ImageField from "../../../../FormFields/ImageField";
import DateTimeField from "../../../../FormFields/DateTimeField";
import OptionsField from "../../../../FormFields/OptionsField";
import { getItemListSignature } from "../../../../Layout/BodyOpenedWeb";
import { DisplayArea } from "../../../../Root";
import ButtonsField from "../../../../FormFields/ButtonsField";
import serverValidation from "./serverValidation";
import { Dialog, DialogTitle, DialogContent, Button } from "@mui/material";
// import DynamicHiddenField from "../../../../FormFields/DynamicHiddenField";

type RouteParams = { displayArea: DisplayArea };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;
  return {
    authObject: state.app.authObject,
    params,
  };
})((props: { authObject: RecordObject; params: RouteParams }) => {

  const [contentType, setContentType] = useState(trans(Label.ITEM__TAG_FACEBOOK));
  const [subType, setSubType] = useState('other');
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");


  const domainTypeMapping = {
    'instagram.com': { contentType: Label.ITEM__TAG_INSTAGRAM, subType: 'instagram' },
    'facebook.com': { contentType: Label.ITEM__TYPE_FACEBOOK_MEMBER_AREA, subType: 'facebook' },
    'twitter.com': { contentType: Label.ITEM__TAG_TWITTER, subType: 'twitter' },
    'x.com': { contentType: Label.ITEM__TAG_TWITTER, subType: 'twitter' },
    'linkedin.com': { contentType: Label.ITEM__TAG_LINKEDIN, subType: 'linkedin' }
  };

  const formBuilderRef = useRef(null);

  useEffect(() => {
    const formBuilder = formBuilderRef.current;
    if (formBuilder && subType) {
      formBuilder.updateSubTypeValue(subType);
    }
  }, [subType]);

  const checkContentType = (value: string) => {
    clearTimeout(checkContentType.timer);
    checkContentType.timer = setTimeout(() => {
      const lowerCaseValue = value.toLowerCase();
      const domains = new Set(Object.keys(domainTypeMapping));
      let found = false;

      const parts = lowerCaseValue.includes('/') ? lowerCaseValue.split('/') : [lowerCaseValue];
      for (const part of parts) {
        const matchingDomain = Array.from(domains).find(domain => part.includes(domain));
        if (matchingDomain) {
          const mapping = domainTypeMapping[matchingDomain];
          setContentType(trans(mapping.contentType));
          setSubType(mapping.subType);
          found = true;
          break;
        }
      }

      if (!found) {
        setContentType(trans(Label.ITEM__TAG_FACEBOOK));
        setSubType('other');
      }
    }, 500);
  };


  checkContentType.timer = null;


  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        style={{
          textAlign: 'center',
        }}
      >
        <DialogTitle
          style={{
            color: 'red',
            fontSize: '2rem'
          }}
        >Fehler</DialogTitle>
        <DialogContent
          style={{
            padding: '0 100px 40px'
          }}
        >{error}</DialogContent>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Dialog>
      <FormBuilder
        key={"form-itemFacebook"}
        ref={formBuilderRef}
        action={(blueprint, done) => {
          HttpClient.action("create", "Item", { blueprint }, false, serverValidation)
            .then((item) => {
              Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
              Records.addToList(
                getItemListSignature(
                  blueprint.fields.type,
                  blueprint.fields.subType || null,
                  blueprint.fields.displayArea
                ),
                "Item",
                item
              );
              if (blueprint.fields.exclusive) {
                Records.addToList(
                  getItemListSignature(
                    "adminExclusive",
                    null,
                    "tariffArea",
                    true
                  ),
                  "Item",
                  item
                );
              }
              pushRoute(`/Admin/ItemLanding/${item.uuid}`);
            })
            .catch((error) => {
              const errorMsg = error.errors.link[0] || "Error";
              setError(errorMsg);
              setOpen(true);
            })
            .finally(() => done());
        }}
        fields={{
          // step 0
          displayArea: new HiddenField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.params.displayArea
          ),
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            subType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField("form-item-title-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__TITLE),
            validations: ["maxChar200"],
          }),
          desc: new InputField("form-item-desc-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__DESC),
            validations: ["maxChar200"],
          }),
          link: new UrlField("form-item-link-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__LINK),
          },
            checkContentType
          ),
          cover: new ImageField("form-item-cover-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__COVER),
          }),
          // step 1
          publishState: new ButtonsField(
            "form-item-publishState-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
              options: [
                {
                  value: "published",
                  label: trans(Label.ITEM__PUBLISHED),
                  onSelect: (form) => {
                    form.setFieldValue("publishState", "published");
                    form.setFieldRequired("publishDate", true);
                  },
                  selected: true,
                },
                {
                  value: "draft",
                  label: trans(Label.ITEM__DRAFT),
                  onSelect: (form) => {
                    form.setFieldValue("publishState", "draft");
                    form.setFieldValue("pushNotification", false);
                    form.setFieldRequired("publishDate", false);
                    form.setFieldRequired("pushNotification", false);
                    form.setFieldRequired("pushTitle", false);
                    form.setFieldRequired("pushText", false);
                  },
                },
              ],
            },
            false,
            {
              padding: "8px 30px 7px",
              borderRadius: "4px",
              textTransform: "uppercase",
              marginLeft: "0px",
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL
          ),
          publishDate: new DateTimeField(
            "form-item-publishDate-id",
            Field.FLAG_REQUIRED,
            new Date(),
            { label: trans(Label.FORM__PUBLISH_DATE) }
          ),
          pushNotification: new OptionsField(
            "form-item-pushNotification-id",
            Field.FLAG_OPTIONAL,
            true,
            null,
            (value: any, form: FormState) => {
              form.setFieldRequired && form.setFieldRequired("pushTitle", value);
              form.setFieldRequired && form.setFieldRequired("pushText", value);
            }
          ),
          pushTitle: new InputField(
            "form-item-pnTitle-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__TITLE),
              validations: ["maxChar60"],
            }
          ),
          pushText: new InputField(
            "form-item-pnText-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__TEXT),
              validations: ["maxChar120"],
            }
          ),
        }}
        layout={ItemCwFormLayout}
        steps={[
          ["form-item-title-id", "form-item-link-id", "form-item-cover-id"],
          [
            "form-item-publishDate-id",
            "form-item-pushNotification-id",
            "form-item-pnTitle-id",
            "form-item-pnText-id",
          ],
        ]}
        data={{ contentType: contentType }}
      />
    </>
  );
});
