import React from "react";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
// import {
//   Image,
//   NativeScrollEvent,
//   NativeSyntheticEvent,
//   ScrollView as RnScrollView,
// } from "react-native";
// import { getClientType } from "../../../../Util/Helper";
import View from "../../../../../Layout/View";
import Button from "../../../../../Layout/Components/Button";
import { Link } from "../../../../../State/Router";
import Form from "../../../../../Layout/Components/Form";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
// import MobileHandler from '../../../../../Layout/MobileHandler';

const GatePasswordResetLayout = (form: FormState): React.ReactElement => {
  const ScrollView: any = View; //getClientType() === "ctmobile" ? RnScrollView : View;
  //   const scrollProps =
  //     getClientType() === "ctmobile"
  //       ? {
  //           ref: (ref: RnScrollView) => MobileHandler.setActiveScroll(ref),
  //           onScroll: (ev: NativeSyntheticEvent<NativeScrollEvent>) =>
  //             MobileHandler.setActiveScrollPos(ev.nativeEvent.contentOffset.y),
  //           scrollEventThrottle: 8,
  //         }
  //       :
  //       {};

  return (
    <ScrollView
      //   {...(getClientType() === "ctmobile" ? { bounces: false } : {})}
      //   {...scrollProps}
      style={{
        backgroundColor: "#115280",
        display: "flex",
        flexDirection: "column",
        flex: 1,
      }}
    >
      <View
        style={{
          alignItems: "center",
          backgroundColor: "#fff",
          borderBottomLeftRadius: 180,
          borderBottomRightRadius: 180,
          flexDirection: "column",
          marginBottom: 80,
          paddingBottom: 64,
          paddingTop: 80,
          textAlign: "center",
        }}
      >
        <img alt={""} src={"/Logo.jpg"} width={166} height={100} />
        {/* {getClientType() === "ctweb" ? (
          <img alt={""} src={"/Logo.jpg"} width={166} height={100} />
        ) : (
          <Image
            source={require("../../../../Assets/Forms/Logo.jpg")}
            style={{ width: 166, height: 100 }}
          />
        )} */}
      </View>

      <Form
        onSubmit={(ev) => {
          ev.preventDefault();
          form.buttons.submit.action();
          return false;
        }}
        style={{ paddingLeft: "8%", paddingRight: "8%" }}
      >
        {form.context.isExecution ? (
          <React.Fragment>
            <ErrorMsg error={form.getError(`form-pr-password-id`, true)} />
            {form.fields.password}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <ErrorMsg error={form.getError(`form-pr-email-id`, true)} />
            {form.fields.email}
          </React.Fragment>
        )}

        <View style={{ margin: 4 }}>&nbsp;</View>

        <Button
          color={"#207bba"}
          disabled={form.buttons.submit.disabled && form.touched}
          large={true}
          onPress={() => form.buttons.submit.action()}
          title={
            form.context.isExecution
              ? trans(Label.FORM__RESET_PASSWORD_EXECUTE)
              : trans(Label.FORM__RESET_PASSWORD_REQUEST)
          }
        />

        <View style={{ margin: 4 }}>&nbsp;</View>

        <View style={{ alignItems: "center" }}>
          <Link to={"/Gate/Auth/User"}>
            <Button
              color={"#fff"}
              simple={true}
              title={trans(Label.FORM__AUTH)}
            />
          </Link>
        </View>

        <View style={{ margin: 8 }}>&nbsp;</View>
      </Form>
    </ScrollView>
  );
};
export default GatePasswordResetLayout;
