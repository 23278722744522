import React from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import Toast from "../../../../../Layout/Common/Toast";
import Records from "../../../../../State/Records";
import { pushRoute } from "../../../../../State/Router";
import Label from "../../../../Util/Label";
import { trans } from "../../../../../Tools/LocaleHandler";
import Field from "../../../../../Tools/FormBuilder/Field";
import InputField from "../../../../FormFields/InputField";
import SvgField from "../../../../FormFields/SvgField";
import OptionsField from "../../../../FormFields/OptionsField";
import AssociationFormLayout from "../AssociationForms/AssociationFormLayout";
import TextField from "../../../../FormFields/TextField";
import serverValidation from "../../Item/ItemCreators/serverValidation";

export default connect((state: StateType) => {
  return {};
})(() => {
  return (
    <FormBuilder
      key={"form-createAssociation"}
      action={(blueprint, done, data, callback) => {
        HttpClient.action(
          "create",
          "Association",
          {
            blueprint,
            data,
          },
          false,
          serverValidation
        )
          .then((association) => {
            Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
            Records.addToList("Association:all", "Association", association);
            pushRoute(`/Admin/LandAssociation/${association.uuid}`);
          })
          .catch((ex) => {
            console.warn(ex);
            Toast.show({ type: "warning", text: trans(ex.text || ex) });
          });
        done();
      }}
      data={{
        questionEmails: [],
        contentEmails: [],
        membershipRequestEmails: [],
      }}
      fields={{
        name: new InputField(
          "form-association-name-id",
          Field.FLAG_REQUIRED,
          null,
          { label: trans(Label.FORM__NAME) }
        ),
        logo: new SvgField(
          "form-association-logo-id",
          Field.FLAG_OPTIONAL,
          null,
          { label: trans(Label.FORM__LOGO) }
        ),
        contactEmail: new InputField(
          "form-association-contactEmail-id",
          Field.FLAG_REQUIRED,
          null,
          { label: trans(Label.FORM__CONTACT_EMAIL) }
        ),
        contactAddress: new TextField(
          "form-association-contactAddress-id",
          Field.FLAG_REQUIRED,
          null,
          { label: trans(Label.FORM__CONTACT_ADDRESS) }
        ),
        contactPhone: new InputField(
          "form-association-contactPhone-id",
          Field.FLAG_REQUIRED,
          null,
          { label: trans(Label.FORM__CONTACT_PHONE) }
        ),
        publisher: new OptionsField(
          "form-association-publisher-id",
          Field.FLAG_OPTIONAL
        ),
        hidden: new OptionsField(
          "form-association-hidden-id",
          Field.FLAG_OPTIONAL,
          null
        ),
        twitterAccount: new InputField(
          "form-association-twitter-id",
          Field.FLAG_OPTIONAL,
          null,
          { label: trans(Label.FORM__TWITTER_ACCOUNT) }
        ),
        hasTariffArea: new OptionsField(
          "form-association-hasTariffArea-id",
          Field.FLAG_OPTIONAL,
          { label: trans(Label.FORM__HAS_TARIFF_AREA) }
        ),
        exclusiveForTariffArea: new OptionsField(
          "form-association-hasExclusiveTariffArea-id",
          Field.FLAG_OPTIONAL,
          { label: trans(Label.FORM__HAS_EXCLUSIVE_TARIFF_AREA) }
        ),
        // twitterToken: new InputField('form-association-twitter-token-id', Field.FLAG_OPTIONAL, null, {label: trans(Label.FORM__TWITTER_ACCOUNT_TOKEN)}),
        facebookAccount: new InputField(
          "form-association-facebook-id",
          Field.FLAG_OPTIONAL,
          null,
          { label: trans(Label.FORM__FACEBOOK_ACCOUNT) }
        ),
        facebookToken: new InputField(
          "form-association-facebook-token-id",
          Field.FLAG_OPTIONAL,
          null,
          { label: trans(Label.FORM__FACEBOOK_ACCOUNT_TOKEN) }
        ),
      }}
      layout={AssociationFormLayout}
    />
  );
});
