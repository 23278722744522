export default new (class Validator {
  uuid(value) {
    return value.length === 24 && value.match(/^[0-9a-zA-Z]+$/);
  }
  email(value) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(value).toLowerCase()
    );
  }
  maxChar200(value) {
    return value.length <= 200;
  }
  maxChar60(value) {
    return value.length <= 60;
  }
  maxChar120(value) {
    return value.length <= 120;
  }
  maxChar110(value) {
    return value.length <= 110;
  }
  phoneNumber(value) {
    if (value[0] === "+") return value.match(/^\+?\d{12}$/);

    if (value[0] === "0") return value.match(/^[0-9]{10}$/);

    return false;
  }
})();
