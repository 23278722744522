import React from "react";
import SwitchWeb from "react-switch";

type SwtchProps = {
  onValueChange?: Function;
  value?: boolean;
};
export default class SwitchButton extends React.Component<SwtchProps> {
  public state: SwtchProps;
  protected onValueChange: Function;

  public constructor(props: SwtchProps) {
    super(props);
    this.onValueChange = props.onValueChange
      ? props.onValueChange
      : (): void => null;
    this.state = { value: !!props.value };
  }

  public render(): React.ReactElement {
    return (
      <SwitchWeb
        onChange={(value) => {
          this.onValueChange(value);
          this.setState({ value });
        }}
        checked={this.state.value}
      />
    );
  }
}
