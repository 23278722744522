import React from "react";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import View from "../../../../../Layout/View";
import Form from "../../../../../Layout/Components/Form";
import { trans } from "../../../../../Tools/LocaleHandler";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import Button from "../../../../../Layout/Components/Button";
import Label from "../../../../Util/Label";
import DraftEditHeader from "./DraftEditHeader";

const ItemDraftFormLayout = (form: FormState): React.ReactNode => {
  return (
    <Form
      onSubmit={(ev) => {
        ev.preventDefault();
        form.buttons.submit.action();
        return false;
      }}
      style={{ paddingLeft: "4%", paddingRight: "4%" }}
    >
      <View
        style={{
          fontSize: 22,
          fontWeight: "bold",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.ADMIN__ITEM_EDIT)}
      </View>

      {form.step.current === 0 && (
        <View>
          <View
            style={{
              backgroundColor: "#fff",
              padding: 48,
              paddingLeft: 72,
              paddingRight: 72,
            }}
          >
            {/* wizard step header */}
            {<DraftEditHeader step={form.step.current} />}
            <View style={{ margin: 12 }}>&nbsp;</View>

            {/* controls */}
            {form.fields.displayArea && (
              <React.Fragment>
                <View style={{ fontSize: 18, marginBottom: 24 }}>
                  {trans(Label.FORM__DISPLAY_AREA_QUESTION)}
                </View>
                {form.fields.displayArea}
                <View style={{ margin: 16 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.expiry && (
              <React.Fragment>
                <View style={{ fontSize: 18, marginBottom: 25 }}>
                  {trans(Label.FORM__EXPIRY)}
                </View>
                {form.fields.expiry}
                <View style={{ margin: 15 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.getFieldValue("displayArea") === "tariffArea" &&
              !!form.fields.exclusive && (
                <React.Fragment>
                  <View style={{ fontSize: 18, marginBottom: 24 }}>
                    {trans(Label.FORM__EXCLUSIVE)}
                  </View>
                  {form.fields.exclusive}
                  <View style={{ margin: 16 }}>&nbsp;</View>
                </React.Fragment>
              )}

            {/* fields */}

            {form.fields.title && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-title-id", true)} />
                {form.fields.title}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.link && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-link-id", true)} />
                {form.fields.link}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.desc && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-desc-id", true)} />
                {form.fields.desc}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.text && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-text-id", true)} />
                {form.fields.text}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.body && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-body-id", true)} />
                {form.fields.body}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.cover && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-cover-id", true)} />
                {form.fields.cover}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.address && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-address-id", true)} />
                {form.fields.address}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.canJoin && (
              <React.Fragment>
                <View style={{ fontSize: 18, marginBottom: 25, marginTop: 20 }}>
                  {trans(Label.FORM__EVENT_CAN_JOIN_FLAG)}
                </View>
                {form.fields.canJoin}
                <View style={{ margin: 16 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.startTime && (
              <React.Fragment>
                <View style={{ fontSize: 18, marginBottom: 25, marginTop: 20 }}>
                  {trans(Label.FORM__START_TIME)}
                </View>
                {form.fields.startTime}
                <View style={{ margin: 16 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.endTime && (
              <React.Fragment>
                <View style={{ fontSize: 18, marginBottom: 25 }}>
                  {trans(Label.FORM__END_TIME)}
                </View>
                {form.fields.endTime}
                <View style={{ margin: 16 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.video && (
              <React.Fragment>
                <ErrorMsg error={form.getError("form-item-video-id", true)} />
                {form.fields.video}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.fields.documents && (
              <React.Fragment>
                <ErrorMsg
                  error={form.getError("form-item-documents-id", true)}
                />
                {form.fields.documents}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}

            {form.metas.answer1 && form.metas.answer2 && form.metas.answer3 && (
              <React.Fragment>
                <View space={30} />

                <ErrorMsg error={form.getError("form-item-answer1-id", true)} />
                {form.metas.answer1}
                <View style={{ margin: 5 }}>&nbsp;</View>

                <ErrorMsg error={form.getError("form-item-answer2-id", true)} />
                {form.metas.answer2}
                <View style={{ margin: 5 }}>&nbsp;</View>

                <ErrorMsg error={form.getError("form-item-answer3-id", true)} />
                {form.metas.answer3}
                <View style={{ margin: 5 }}>&nbsp;</View>
              </React.Fragment>
            )}
          </View>

          <View style={{ margin: 25 }}></View>

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              color={"#4daff0"}
              large={true}
              title={trans(Label.UI__BACK)}
            />
            <Button
              color={"#4daff0"}
              disabled={form.buttons.next.disabled}
              large={true}
              onPress={() => form.buttons.next.action()}
              title={trans(Label.UI__NEXT)}
            />
          </View>
        </View>
      )}

      {form.step.current === 1 && (
        <View
          style={{
            backgroundColor: "#fff",
            padding: 50,
            paddingLeft: 70,
            paddingRight: 70,
          }}
        >
          {/* wizard step header */}
          {<DraftEditHeader step={form.step.current} />}
          <View style={{ margin: 12 }}>&nbsp;</View>

          <React.Fragment>
            <View style={{ fontSize: 18, marginBottom: 17 }}>
              {trans(Label.FORM__PUBLISH_STATE_LABEL)}
            </View>
            {form.fields.publishState}
            <View style={{ margin: 16 }}>&nbsp;</View>
          </React.Fragment>

          {form.getFieldValue("publishState") === "published" && (
            <View>
              <View style={{ fontSize: 18, marginBottom: 25 }}>
                {trans(Label.FORM__PUBLISH_DATE)}
              </View>
              {form.fields.publishDate}
              <View style={{ margin: 16 }}>&nbsp;</View>

              <View style={{ fontSize: 18, marginBottom: 25 }}>
                {trans(Label.FORM__PUSH_NOTIFICATION)}
              </View>
              {form.fields.pushNotification}
              <View style={{ margin: 25 }}>
                {form.getFieldValue("pushNotification") ? (
                  <React.Fragment>
                    <ErrorMsg
                      error={form.getError("form-item-pnTitle-id", true)}
                    />
                    {form.fields.pushTitle}
                    <View style={{ margin: 5 }}>&nbsp;</View>
                    <ErrorMsg
                      error={form.getError("form-item-pnText-id", true)}
                    />
                    {form.fields.pushText}
                    <View style={{ margin: 5 }}>&nbsp;</View>
                  </React.Fragment>
                ) : (
                  " "
                )}
              </View>
            </View>
          )}

          <View
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            <Button
              color={"#4daff0"}
              disabled={form.buttons.prev.disabled}
              large={true}
              onPress={() => form.buttons.prev.action()}
              title={trans(Label.UI__BACK)}
            />
            <Button
              color={"#4daff0"}
              disabled={form.buttons.submit.disabled}
              large={true}
              onPress={() => form.buttons.submit.action()}
              title={
                form.getFieldValue("publishState") === "published"
                  ? trans(Label.FORM__PUBLISH)
                  : trans(Label.FORM__SAVE_DRAFT)
              }
            />
          </View>

          <View style={{ margin: 8 }}>&nbsp;</View>
        </View>
      )}

      <View style={{ margin: 24 }}></View>
    </Form>
  );
};
export default ItemDraftFormLayout;
