import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import { Title } from "./components/Title";
import { Description } from "./components/Description";
import { ContentType } from "./components/ContentType";
import { LinkMore } from "./components/LinkMore";
import { Video } from "./components/Video";
import { ViewTitle } from "./components/ViewTitle";
import InstagramPost from "./components/InstagramPost";

const previewWidth = 292;

const PreviewBreif: React.FC<{ form: FormState; switchView: () => void }> = ({
  form,
  switchView,
}) => {
  const videoField = form.getField("video");
  const videoUrl = videoField && videoField["videoSrc"];

  const coverUrl = form.getField("cover")
    ? form.getField("cover")["imgSrc"]
    : null;

  const title = form.getFieldValue("title");

  const description = form.getFieldValue("desc");

  const contentType = form.data.contentType
    ? form.data.contentType
    : "content type";

  const link = form.getFieldValue("link");

  // const validInstaLink =
  //   (typeof link === "string" ? link.indexOf("instagram.com") > -1 : null) &&
  //   contentType.indexOf("Instagram") !== -1;

  const showMore =
    (form.stepFields.length > 3 || form.stepMetas) &&
    contentType.indexOf("externer Link") === -1 &&
    contentType.indexOf("Termine") === -1;

  return (
    <>
      <ViewTitle titleText={trans("trans.ui.feed")} />
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            backgroundImage: 'url("/icons/mobile.png")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            backgroundSize: "contain",
            marginLeft: "35px",
            minHeight: "692px",
            padding: "70px 30px 0px",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              minHeight: "356px",
              borderRadius: "7px",
              width: `${previewWidth}px`,
              position: "relative",
            }}
          >
            <ContentType contentType={contentType} />
            {coverUrl && (
              <CardMedia
                component="img"
                src={coverUrl}
                sx={{ maxHeight: "175px" }}
              />
            )}
            {videoUrl && !coverUrl ? <Video videoUrl={videoUrl} /> : null}
            <CardContent>
              <Title title={title} color="#0A3B60" />
              <Description description={description} />
              {/* {validInstaLink ? (
                <InstagramPost
                  src={link}
                  fallback="/icons/Instagram.Logo.jpg"
                />
              ) : null} */}
              {showMore && <LinkMore label={trans(Label.UI__MORE)} />}
            </CardContent>
          </Card>
        </Box>
        {showMore ? (
          <Box
            sx={{
              backgroundImage: 'url("/icons/mobile.part.png")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center top",
              backgroundSize: "contain",
              marginLeft: "35px",
              padding: "70px 30px",
            }}
          >
            <div
              style={{
                width: "33px",
                height: "356px",
                transform: "rotate(0deg)",
              }}
            >
              <div
                style={{
                  padding: "15px",
                  color: "#053C60",
                  fontSize: "48px",
                  position: "absolute",
                  top: "190px",
                  cursor: "pointer",
                }}
                onClick={switchView}
              >
                <ArrowForwardIcon fontSize="inherit" color="inherit" />
              </div>
            </div>
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default PreviewBreif;
