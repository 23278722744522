import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";

export default class PhoneField extends Field {
  public display(signal: FieldSignal): React.ReactNode {
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
      ? "#ff3333"
      : "transparent";
    return (
      <input
        autoFocus={this.context.focus}
        defaultValue={this.value}
        id={this.id}
        onBlur={() => {
          signal.blur();
        }}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          this.change(ev.target.value)
        }
        onFocus={() => {
          signal.focus();
        }}
        onKeyPress={(ev) => {
          if (
            ev.key === "Enter" &&
            typeof this.context.onReturn === "function"
          ) {
            ev.preventDefault();
            this.context.onReturn();
            return false;
          }
        }}
        placeholder={this.context.label}
        ref={(ref) => (Field.fieldRefs[this.id] = ref)}
        style={{
          borderColor: colorForError,
          boxShadow: `0px 0px 5px ${colorForError}`,
          borderWidth: 1,
          //
          backgroundColor: !this.context.bgColor
            ? "#f2f5f7"
            : this.context.bgColor,
          borderRadius: 16,
          color: !this.context.color ? "#093b60" : this.context.color,
          fontSize: 18,
          outline: "none",
          padding: 18,
          paddingLeft: 32,
          paddingRight: 32,
        }}
        type={"text"}
      />
    );
  }
}
