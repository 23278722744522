import React from "react";
import { connect } from "react-redux";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Pageview from "@mui/icons-material/Pageview";
import { StateType } from "../../../../../State/Store";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import HttpClient from "../../../../../State/HttpClient";
import { pushRoute } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import Button from "../../../../../Layout/Components/Button";

export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
    records: Records.mapRefsToRecords(state.lists["Question:all"]),
  };
})((props: { authObject: RecordObject; records: Record[] }) => {
  props.records || Records.list("Question", "all");

  if (!props.records) {
    return null;
  }

  const styles = {
    td: {
      padding: 6,
      fontFamily: "Lato",
      fontSize: 14,
      color: "#0A3B60",
      borderBottom: "solid 3px #fff",
    },
    th: {
      borderTop: "solid 1px #ccc",
      padding: 12,
      width: "16.6%",
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: "bold" as const,
      color: "#0A3B60",
    },
    tr: {
      background: "#F5F7F8",
    },
    widget: {
      borderRadius: 4,
      cursor: "pointer",
      fontSize: 44,
      marginLeft: 4,
      marginRight: 4,
      fill: "#fff",
    },
  };

  return (
    <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          fontFamily: "Lato",
          color: "#0A3B60",
          display: "flex",
          flexDirection: "row",
          fontSize: 16,
          fontWeight: "bold",
          justifyContent: "space-between",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.QUESTIONS__NAV_BUTTON)}
      </div>
      <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10 }}>
        <div>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{ margin: 24, width: "calc(100% - 48px)" }}
          >
            <thead>
              <tr>
                <td style={styles.th}>Association</td>
                <td style={styles.th}>Timestamp</td>
                <td style={styles.th}>First name</td>
                <td style={styles.th}>Last name</td>
                <td style={{ ...styles.th, width: "10.6%" }}>
                  {trans(Label.FORM__ASSOCIATION_NAME)}
                </td>
                <td style={{ ...styles.th, width: "29.2%" }}>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {props.records.map((record) => {
                return (
                  <tr
                    key={record.uuid}
                    style={{
                      backgroundColor:
                        record.state === "approved"
                          ? "#b5ffea"
                          : record.state === "rejected"
                          ? "#ffdcdc"
                          : "#E8ECF0",
                    }}
                  >
                    <td style={styles.td}>{record.association.name}</td>
                    <td style={styles.td}>{record.created_at}</td>
                    <td style={styles.td}>{record.user.firstName}</td>
                    <td style={styles.td}>{record.user.lastName}</td>
                    <td style={styles.td}>{record.user.organisationName}</td>
                    <td style={styles.td}>
                      {record.state === "pending" ? (
                        <div
                          id={`widgets-${record.uuid}`}
                          style={{ display: "inline-block" }}
                        >
                          <Check
                            onClick={() => {
                              document.getElementById(
                                `widgets-${record.uuid}`
                              ).innerHTML = "Loading...";
                              HttpClient.action(
                                "approve",
                                "AssociationMembership",
                                { data: { uuid: record.uuid } }
                              ).then((recordBlueprint: Record) => {
                                if (!recordBlueprint) return;
                                Records.edit(
                                  "AssociationMembership",
                                  record.uuid,
                                  recordBlueprint,
                                  true
                                );
                              });
                            }}
                            style={{
                              ...styles.widget,
                              backgroundColor: "#00b96c",
                            }}
                          />
                          <Close
                            onClick={() => {
                              document.getElementById(
                                `widgets-${record.uuid}`
                              ).innerHTML = "Loading...";
                              HttpClient.action(
                                "reject",
                                "AssociationMembership",
                                { data: { uuid: record.uuid } }
                              ).then((recordBlueprint: Record) => {
                                if (!recordBlueprint) return;
                                Records.edit(
                                  "AssociationMembership",
                                  record.uuid,
                                  recordBlueprint,
                                  true
                                );
                              });
                            }}
                            style={{
                              ...styles.widget,
                              backgroundColor: "#ff3333",
                            }}
                          />
                          <Pageview
                            onClick={() =>
                              pushRoute(`/Admin/LandQuestion/${record.uuid}`)
                            }
                            style={{
                              ...styles.widget,
                              backgroundColor: "#0083c4",
                            }}
                          />
                        </div>
                      ) : (
                        <Button
                          style={{
                            textTransform: "initial",
                            border: "solid 1px #6eccff",
                          }}
                          color={"#c1e4f7"}
                          textColor={"#0A3B60"}
                          title={trans(Label.UI__VIEW_DETAILS)}
                          onPress={() =>
                            pushRoute(`/Admin/LandQuestion/${record.uuid}`)
                          }
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
