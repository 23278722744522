import React from "react";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";
import { InfoBubble } from "../../Layout/Components/InfoBubble";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";

const maxFileSize = 209715200;

export default class VideoField extends Field {
  public defaultFilename: string = null;
  public defaultValue: string = null;
  public emptyFiles: FileList = null;
  public filename: string = null;
  public files: FileList = null;
  public videoSrc: any = "";
  public signal: FieldSignal = null;

  public reset(): void {
    this.resetVideo();
    super.reset();
  }

  public display(signal: FieldSignal): React.ReactNode {
    this.signal = signal;
    this.skipFieldIfValueIsInitial();
    if (!this.defaultValue && this.value && typeof this.value === "string") {
      const lastIndexOfSlash = this.value.lastIndexOf("/");
      this.filename =
        lastIndexOfSlash === -1
          ? this.value
          : this.value.substr(lastIndexOfSlash + 1);
      this.videoSrc = this.value + "?rand=" + Math.random(); // chrome handles video caches very poorly. reimplement app version and invalidate caches per version maybe?
      this.defaultFilename = this.filename;
      this.defaultValue = this.value;
    }
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
      ? "#ff3333"
      : "transparent";
    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <div
          style={{
            borderColor: colorForError,
            boxShadow: `0px 0px 5px ${colorForError}`,
            borderWidth: 1,
            borderStyle: "solid",
            flex: 1,
            //
            backgroundColor: !this.context.bgColor
              ? "#f2f5f7"
              : this.context.bgColor,
            borderRadius: 16,
            padding: 18,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <input
            id={this.id}
            name={this.context.label}
            onBlur={() => signal.blur()}
            onChange={(ev) => {
              signal.focus();

              if (!ev.target.files.length) {
                this.setNullVideo();
                return;
              }
              const file = ev.target.files[0];
              if (
                file.type.substring(0, 5) !== "video" ||
                file.size > maxFileSize
              ) {
                this.setNullVideo();
                this.setError(this.trans("trans.validationError.maxSize"));
                this.isValid = !this.isRequired;
                return;
              }

              this.setVideo(ev.target.files);
            }}
            ref={(ref) => {
              if (!ref) return;
              Field.fieldRefs[this.id] = ref;
              if (!this.emptyFiles) this.emptyFiles = ref.files; // hold ref empty files to clear on reset / remove
              if (!this.files) return;
              ref.files = this.files;
            }}
            style={{ display: "none" }}
            type={"file"}
            accept="video/mp4"
          />

          <div style={{ display: "flex" }}>
            <button type={"button"}>
              <label
                style={{
                  display: "block",
                  maxWidth: 120,
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                htmlFor={this.id}
              >
                {this.filename || this.context.label}
              </label>
            </button>
            {this.defaultValue && (
              <IconButton
                color="primary"
                onClick={() => this.resetVideo()}
                sx={{ padding: 0 }}
              >
                <RestoreIcon />
              </IconButton>
            )}
            {this.filename && (
              <IconButton
                color="error"
                onClick={() => this.setNullVideo()}
                sx={{ padding: 0 }}
              >
                <CancelIcon />
              </IconButton>
            )}
          </div>

          {this.videoSrc && (
            <div
              style={{ display: "flex", justifyContent: "center", padding: 30 }}
            >
              <video
                key={this.videoSrc}
                controls={true}
                style={{ maxHeight: 400, maxWidth: "100%" }}
              >
                <source
                  id={`video-field-preview-${this.id}`}
                  src={this.videoSrc}
                />
              </video>
            </div>
          )}
        </div>
        <Box sx={{ paddingTop: "10px", position: "absolute", right: ".8rem" }}>
          <InfoBubble fieldId={this.id} />
        </Box>
      </Box>
    );
  }

  protected resetVideo(): void {
    if (!this.defaultValue) {
      this.setNullVideo();
      return;
    }

    this.signal.focus();
    this.files = null;
    this.filename = this.defaultFilename;
    this.videoSrc = this.defaultValue;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    this.change(this.defaultValue);
  }

  protected setNullVideo(): void {
    this.signal.focus();
    this.files = null;
    this.filename = null;
    this.videoSrc = null;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    this.change(null);
  }

  protected setVideo(filelist: FileList): void {
    this.signal.focus();
    this.files = filelist;
    this.filename = filelist[0].name;
    this.videoSrc = URL.createObjectURL(filelist[0]);
    this.change(filelist[0]);
  }
}
