import LoginIcon from "@mui/icons-material/Login";
import Box from "@mui/material/Box";
type LinkDocumentsProps = {
  fileName: string;
};
export const LinkDocuments: React.FC<LinkDocumentsProps> = ({ fileName }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <LoginIcon
        sx={{
          transform: "rotate(90deg)",
          marginRight: "6px",
        }}
      />
      <span>{fileName}</span>
    </Box>
  );
};
