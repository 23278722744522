import React from "react";
import Button from "../../../../../Layout/Components/Button";
import View from "../../../../../Layout/View";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";

const PushFormLayout = (form: FormState): React.ReactNode => {
  return (
    <View
      style={{
        paddingBottom: 48,
        paddingLeft: 72,
        paddingRight: 72,
        paddingTop: 16,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginRight: -36,
        }}
      ></View>

      {form.fields.title}
      <View style={{ margin: 4 }}>&nbsp;</View>

      {form.fields.body}

      <View style={{ margin: 4 }}>&nbsp;</View>
      <View style={{ flexDirection: "row", justifyContent: "right" }}>
        <Button
          color={"#207bba"}
          disabled={form.buttons.submit.disabled && form.touched}
          onPress={() => form.buttons.submit.action()}
          title={trans(Label.FORM__SUBMIT)}
        />
      </View>
    </View>
  );
};
export default PushFormLayout;
