import React from "react";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Pagination from "@mui/material/Pagination";
import { DisplayArea, getTransForItemSubType } from "../../../../Root";
import { trans } from "../../../../../Tools/LocaleHandler";
import { Link, pushRoute, rerender } from "../../../../../State/Router";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import Toast from "../../../../../Layout/Common/Toast";
import Label from "../../../../Util/Label";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import HttpClient from "../../../../../State/HttpClient";
import { StateType } from "../../../../../State/Store";
import {
  getItemListContext,
  getItemListSignature,
} from "../../../../Layout/BodyOpenedWeb";
import Storage from "../../../../../State/Storage";
import Button from "../../../../../Layout/Components/Button";
import { PushButton } from "../Common/MultiPushes";

const perPage = 10;
let tmr: any = null;

const styles = {
  td: {
    padding: 6,
    fontFamily: "Lato",
    fontSize: 14,
    color: "#0A3B60",
  },
  th: {
    borderBottom: "solid 1px #ccc",
    borderTop: "solid 1px #ccc",
    padding: 12,
    width: "16.6%",
    fontFamily: "Lato",
    fontSize: 14,
    fontWeight: "bold" as const,
    color: "#0A3B60",
  },
  widget: {
    backgroundColor: "#0083c4",
    borderRadius: 4,
    cursor: "pointer",
    fontSize: 44,
    marginLeft: 4,
    marginRight: 4,
    fill: "#fff",
  },
  actionButton: {
    cursor: "pointer",
    width: 30,
    margin: 2,
  },
  labelSuccess: {
    backgroundColor: "#d2e5f5",
    color: "#12689e",
    padding: "4px 8px",
    borderRadius: "10px",
  },
  labelWarning: {
    backgroundColor: "#f5f2d2",
    color: "#605a0b",
    padding: "4px 8px",
    borderRadius: "10px",
  },
};

const PollExportButton = (props: { displayArea: string }) => {
  let method: string = null;
  switch (true) {
    case props.displayArea === "tariffArea":
      method = "csvExportPollTariff";
      break;
    case props.displayArea === "memberArea":
      method = "csvExportPollMember";
      break;
    default:
      return null;
  }

  const apiUrl = HttpClient.getApiUrl();
  const authorization = Storage.get("authorization");
  return (
    <a
      href={`${apiUrl}/action/${authorization}/Item/${method}`}
      style={{ margin: 25, marginBottom: 0, textDecoration: "none" }}
      target={"_blank"}
      rel="noreferrer"
    >
      <Button color={"#0083c4"} title={trans(Label.UI__CSV_EXPORT)} />
    </a>
  );
};

const EventExportButton = () => {
  const apiUrl = HttpClient.getApiUrl();
  const authorization = Storage.get("authorization");
  return (
    <a
      href={`${apiUrl}/action/${authorization}/Item/csvExportEvent`}
      style={{ margin: 25, marginBottom: 0, textDecoration: "none" }}
      target={"_blank"}
      rel="noreferrer"
    >
      <Button color={"#0083c4"} title={trans(Label.UI__CSV_EXPORT)} />
    </a>
  );
};

const RemoveTagsFromString = (originalString: string): string => {
  return originalString.replace(/(<([^>]+)>)|(&.+;)/gi, "").slice(0, 50);
};

const List = connect(
  (
    state: StateType,
    props: { displayArea: DisplayArea; list: string; subType?: string }
  ) => ({
    authObject: state.app.authObject,
    list: props.list,
    records: Records.mapRefsToRecords(
      state.lists[
        getItemListSignature(
          props.list,
          props.subType || null,
          props.displayArea,
          true
        )
      ]
    ),
    subType: props.subType || null,
  })
)(
  (props: {
    authObject: RecordObject;
    displayArea: DisplayArea;
    list: string;
    records: Record[];
    subType: string;
  }) => {
    props.records ||
      Records.list(
        "Item",
        getItemListContext(props.list, props.subType, props.displayArea, true)
      );

    const [state, setState] = React.useState({
      page: 1,
      filters: {
        publishState: "",
      },
    });

    if (!props.records) {
      return null;
    }

    const records = props.records.filter(
      (record) =>
        !(
          state.filters.publishState &&
          record.publishState !== state.filters.publishState
        )
    );
    const pageCount = Math.ceil(records.length / perPage);
    if (!!props.authObject.record.supergod) {
      return (
        <React.Fragment>
          {props.list === "adminPolls" && (
            <PollExportButton displayArea={props.displayArea} />
          )}
          {props.list === "adminEvents" && <EventExportButton />}
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{ margin: 25, width: "calc(100% - 48px)" }}
          >
            <thead>
              <tr>
                <td style={styles.th}>{trans(Label.FORM__TITLE)}</td>
                <td style={styles.th}>{trans(Label.FORM__CATEGORY)}</td>
                <td style={styles.th}>{trans(Label.FORM__PUBLISH_DATE)}</td>
                <td style={styles.th}>{trans(Label.FORM__PUBLISH_STATE)}</td>
                <td style={styles.th}>&nbsp;</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <Select
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || "";
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          filters: {
                            ...state.filters,
                            publishState: value,
                          },
                        });
                      }, 250);
                    }}
                    style={{ width: "120px", height: "36px" }}
                    value={state.filters.publishState}
                  >
                    {[
                      { label: trans(Label.UI__ALL), value: "" },
                      {
                        label: trans(Label.ITEM__PUBLISHED),
                        value: "published",
                      },
                      {
                        label: trans(Label.ITEM__DRAFT),
                        value: "draft",
                      },
                    ].map((item) => (
                      <MenuItem
                        key={`${item.value}:${item.label}`}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {!records.length && (
                <tr>
                  <td colSpan={5} style={styles.th}>
                    {trans("trans.ui.emptyList")}
                  </td>
                </tr>
              )}
              {records
                .slice(
                  (state.page - 1) * perPage,
                  (state.page - 1) * perPage + perPage
                )
                .map((record, index) => {
                  return (
                    <tr
                      key={record.uuid}
                      style={{
                        background: index % 2 === 0 ? "#F5F7F8" : "#FFFFFF",
                      }}
                    >
                      <td style={styles.td}>
                        {record.title
                          ? record.title
                          : record.desc
                          ? record.desc
                          : record.text
                          ? RemoveTagsFromString(record.text)
                          : "N/A"}
                      </td>
                      <td style={styles.td}>
                        {getTransForItemSubType(record.subType || record.type)}
                      </td>
                      <td style={styles.td}>{record.publishDate}</td>

                      <td style={styles.td}>
                        <span
                          style={
                            record.publishState === "draft"
                              ? styles.labelWarning
                              : styles.labelSuccess
                          }
                        >
                          {record.publishState === "draft"
                            ? trans(Label.ITEM__DRAFT)
                            : trans(Label.ITEM__PUBLISHED)}
                        </span>
                      </td>
                      <td style={{ ...styles.td, textAlign: "right" }}>
                        {record.publishState === "published" && (
                          <PushButton item={record}></PushButton>
                        )}

                        <Link to={`/Admin/ItemLanding/${record.uuid}`}>
                          <img
                            alt={"View"}
                            src={"/icons/eye_box.svg"}
                            style={styles.actionButton}
                          />
                        </Link>
                        <img
                          alt={"Edit"}
                          onClick={() =>
                            pushRoute(`/Admin/EditItem/${record.uuid}`)
                          }
                          src={"/icons/pencil_box.svg"}
                          style={styles.actionButton}
                        />
                        <img
                          alt={"Delete"}
                          onClick={() => {
                            if (window.confirm(trans(Label.UI__CONFIRM))) {
                              Records.remove(record.toRef("Item"))
                                .then(() => {
                                  Toast.show({
                                    type: "info",
                                    text: trans(Label.UI__SUCCESS),
                                  });
                                })
                                .catch((ex) => {
                                  Toast.show({
                                    type: "warning",
                                    text: trans(ex.text || ex),
                                  });
                                })
                                .finally(() => {
                                  rerender();
                                });
                            }
                          }}
                          src={"/icons/delete_box.svg"}
                          style={styles.actionButton}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {records.length > perPage ? (
            <Pagination
              count={pageCount}
              page={state.page}
              shape="rounded"
              color="primary"
              defaultPage={1}
              boundaryCount={2}
              hideNextButton
              hidePrevButton
              sx={{ display: "flex", justifyContent: "center" }}
              onChange={(ev, page) => setState({ ...state, page })}
            />
          ) : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        {props.list === "adminPolls" && (
          <PollExportButton displayArea={props.displayArea} />
        )}
        {props.list === "adminEvents" && <EventExportButton />}
        <DragDropContext
          onDragEnd={(result, provided) => {
            const from = result.source.index;
            const to = result.destination.index;
            if (from === to) return;
            let newList = [...props.records];

            const record = props.records[from];
            newList.splice(from, 1);
            newList.splice(to, 0, record);

            HttpClient.action(
              props.list === "adminExclusive" ? "reorderExclusive" : "reorder",
              "Item",
              {
                data: { itemUuids: newList.map((item) => item.uuid) },
              }
            );

            Records.setList(
              getItemListSignature(
                props.list,
                props.subType || null,
                props.displayArea,
                true
              ),
              newList.map((record) => record.toRef("Item"))
            );
          }}
        >
          <Droppable droppableId={"droppable"}>
            {(provided, snapshot) => (
              <table
                ref={provided.innerRef}
                cellSpacing={0}
                cellPadding={0}
                style={{ margin: 25, width: "calc(100% - 48px)" }}
              >
                <thead>
                  <tr>
                    <td style={styles.th}>{trans(Label.FORM__TITLE)}</td>
                    <td style={styles.th}>{trans(Label.FORM__CATEGORY)}</td>
                    <td style={styles.th}>{trans(Label.FORM__PUBLISH_DATE)}</td>
                    <td style={styles.th}>
                      {trans(Label.FORM__PUBLISH_STATE)}
                    </td>
                    <td style={styles.th}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <Select
                        onChange={(ev) => {
                          clearTimeout(tmr);
                          const value = ev.target.value || "";
                          tmr = setTimeout(() => {
                            setState({
                              ...state,
                              filters: {
                                ...state.filters,
                                publishState: value,
                              },
                            });
                          }, 250);
                        }}
                        style={{ width: "120px", height: "36px" }}
                        value={state.filters.publishState}
                      >
                        {[
                          { label: trans(Label.UI__ALL), value: "" },
                          {
                            label: trans(Label.ITEM__PUBLISHED),
                            value: "published",
                          },
                          {
                            label: trans(Label.ITEM__DRAFT),
                            value: "draft",
                          },
                        ].map((item) => (
                          <MenuItem
                            key={`${item.value}:${item.label}`}
                            value={item.value}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {!records.length && (
                    <tr>
                      <td colSpan={5} style={styles.th}>
                        {trans("trans.ui.emptyList")}
                      </td>
                    </tr>
                  )}
                  {records
                    .slice(
                      (state.page - 1) * perPage,
                      (state.page - 1) * perPage + perPage
                    )
                    .map((record, index) => (
                      <Draggable
                        key={record.uuid}
                        draggableId={record.uuid}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td
                              style={{
                                ...styles.td,
                                background:
                                  index % 2 === 0 ? "#F5F7F8" : "#FFFFFF",
                              }}
                            >
                              <img
                                alt={"Drag"}
                                src={"/icons/drag.svg"}
                                style={{
                                  width: 7,
                                  marginLeft: 5,
                                  marginRight: 10,
                                }}
                              />
                              &nbsp;&nbsp;
                              {record.title
                                ? record.title
                                : record.desc
                                ? record.desc
                                : record.text
                                ? RemoveTagsFromString(record.text)
                                : "N/A"}
                            </td>
                            <td
                              style={{
                                ...styles.td,
                                background:
                                  index % 2 === 0 ? "#F5F7F8" : "#FFFFFF",
                              }}
                            >
                              {getTransForItemSubType(
                                record.subType || record.type
                              )}
                            </td>
                            <td
                              style={{
                                ...styles.td,
                                background:
                                  index % 2 === 0 ? "#F5F7F8" : "#FFFFFF",
                              }}
                            >
                              {record.publishDate}
                            </td>
                            <td
                              style={{
                                ...styles.td,
                                background:
                                  index % 2 === 0 ? "#F5F7F8" : "#FFFFFF",
                              }}
                            >
                              <span
                                style={
                                  record.publishState === "draft"
                                    ? styles.labelWarning
                                    : styles.labelSuccess
                                }
                              >
                                {record.publishState === "draft"
                                  ? trans(Label.ITEM__DRAFT)
                                  : trans(Label.ITEM__PUBLISHED)}
                              </span>
                            </td>
                            <td
                              style={{
                                ...styles.td,
                                textAlign: "right",
                                background:
                                  index % 2 === 0 ? "#F5F7F8" : "#FFFFFF",
                              }}
                            >
                              {record.publishState === "published" && (
                                <PushButton item={record}></PushButton>
                              )}

                              <Link to={`/Admin/ItemLanding/${record.uuid}`}>
                                <img
                                  alt={"View"}
                                  src={"/icons/eye_box.svg"}
                                  style={styles.actionButton}
                                />
                              </Link>
                              <img
                                alt={"Edit"}
                                onClick={() =>
                                  pushRoute(`/Admin/EditItem/${record.uuid}`)
                                }
                                src={"/icons/pencil_box.svg"}
                                style={styles.actionButton}
                              />
                              <img
                                alt={"Delete"}
                                onClick={() => {
                                  if (
                                    window.confirm(trans(Label.UI__CONFIRM))
                                  ) {
                                    Records.remove(record.toRef("Item"))
                                      .then(() => {
                                        Toast.show({
                                          type: "info",
                                          text: trans(Label.UI__SUCCESS),
                                        });
                                      })
                                      .catch((ex) => {
                                        Toast.show({
                                          type: "warning",
                                          text: trans(ex.text || ex),
                                        });
                                      })
                                      .finally(() => {
                                        rerender();
                                      });
                                  }
                                }}
                                src={"/icons/delete_box.svg"}
                                style={styles.actionButton}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                </tbody>
              </table>
            )}
          </Droppable>
          {records.length > perPage ? (
            <Pagination
              count={pageCount}
              page={state.page}
              shape="rounded"
              color="primary"
              defaultPage={1}
              boundaryCount={2}
              hideNextButton
              hidePrevButton
              sx={{ display: "flex", justifyContent: "center" }}
              onChange={(ev, page) => setState({ ...state, page })}
            />
          ) : null}
        </DragDropContext>
      </React.Fragment>
    );
  }
);
List.displayName = "List";
export default List;
