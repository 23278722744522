import React, { SyntheticEvent } from "react";
import { BackButton } from "../../../State/Router";
import ArrowLeft from "@mui/icons-material/ArrowLeft";

const BackButtonWeb = (props: {
  backgroundColor?: string;
  color?: string;
  onPress?: (ev: SyntheticEvent) => void;
  to?: string;
}): React.ReactElement => {
  return <BackButton component={<ArrowLeft fontSize={"large"} />} {...props} />;
};
export default BackButtonWeb;
