import React from "react";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import { pushRoute, RouterState } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Toast from "../../../../../Layout/Common/Toast";
import EmailField from "../../../../FormFields/EmailField";
import Field from "../../../../../Tools/FormBuilder/Field";
import PasswordField from "../../../../FormFields/PasswordField";
import PageNotFound from "../../../../Layout/Views/PageNotFound";
import PasswordResetFormLayout from "../GateForms/GatePasswordResetFormLayout";
import Label from "../../../../Util/Label";

const GatePasswordReset = (props: { rstate: RouterState }) => {
  const [component, setComponent] = React.useState<React.ReactElement>(null);

  // user requests password reset
  if (!props.rstate.params.email || !props.rstate.params.token) {
    return (
      <FormBuilder
        key={"form-passwordReset"}
        action={(blueprint, done) => {
          HttpClient.action("passwordReset", "User", { blueprint })
            .then((res) => {
              if (!res) return;
              Toast.show({
                type: "info",
                text: trans(Label.UI__PASSWORD_RESET_REQUEST_SUCCESS),
              });
              pushRoute(`/Gate/Auth/User`);
            })
            .catch((ex) => {
              console.warn(ex);
              Toast.show({ type: "warning", text: trans(ex.text || ex) });
            })
            .finally(() => done());
        }}
        fields={{
          email: new EmailField("form-pr-email-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__RESET_PASSWORD_EMAIL_LABEL),
            focus: true,
            validations: ["email"],
          }),
        }}
        layout={PasswordResetFormLayout}
      />
    );
  }

  if (component) {
    return component;
  }

  // user has received the password reset instructions
  HttpClient.action("passwordResetVerifyToken", "User", {
    blueprint: { fields: props.rstate.params, metas: {} },
  }).then((res) => {
    // email and token are invalid
    if (!res) {
      setComponent(<PageNotFound />);
    }

    // execute password reset
    setComponent(
      <FormBuilder
        key={"form-passwordResetExecute"}
        action={(blueprint, done) => {
          HttpClient.action("passwordResetExecute", "User", {
            blueprint: {
              fields: {
                email: props.rstate.params.email,
                password: blueprint.fields.password,
                token: props.rstate.params.token,
              },
              metas: {},
            },
          })
            .then((res) => {
              if (!res) return;
              Toast.show({
                type: "info",
                text: trans(Label.UI__PASSWORD_RESET_SUCCESS),
              });
              pushRoute(`/Gate/Auth/User`);
            })
            .catch((ex) => {
              console.warn(ex);
              Toast.show({ type: "warning", text: trans(ex.text || ex) });
            })
            .finally(() => done());
        }}
        context={{ isExecution: true }}
        fields={{
          password: new PasswordField(
            "form-pr-password-id",
            Field.FLAG_REQUIRED,
            null,
            {
              focus: true,
              label: trans(Label.FORM__RESET_PASSWORD_LABEL),
            }
          ),
        }}
        layout={PasswordResetFormLayout}
      />
    );
  });

  return null;
};
export default GatePasswordReset;
