import React from "react";
import { connect } from "react-redux";
import FormBuilder, { FormState } from "../../../../../../Tools/FormBuilder/FormBuilder";
import { pushRoute, RouterState } from "../../../../../../State/Router";
import { trans } from "../../../../../../Tools/LocaleHandler";
import Toast from "../../../../../../Layout/Common/Toast";
import Field from "../../../../../../Tools/FormBuilder/Field";
import Label from "../../../../../Util/Label";
import { StateType } from "../../../../../../State/Store";
import PageNotFound from "../../../../../Layout/Views/PageNotFound";
import Records, {
  Record,
  RecordRef,
  RecordObject,
} from "../../../../../../State/Records";
import HiddenField from "../../../../../FormFields/HiddenField";
import InputField from "../../../../../FormFields/InputField";
import UrlField from "../../../../../FormFields/UrlField";
// import TextField from '../../../../../FormFields/TextField';
import ImageField from "../../../../../FormFields/ImageField";
import OptionsField from "../../../../../FormFields/OptionsField";
import { asset, transformDate } from "../../../../../Util/Helper";
import ItemFormLayout from "../../ItemForms/ItemFormLayout";
import VideoField from "../../../../../FormFields/VideoField";
import DocumentField from "../../../../../FormFields/DocumentField";
import { getItemListSignature } from "../../../../../Layout/BodyOpenedWeb";
import DateTimeField from "../../../../../FormFields/DateTimeField";
import AnswerField from "../../../../../FormFields/AnswerField";
import WYSIWYGTextField from "../../../../../FormFields/WYSIWYGTextField";

type RouteParams = { uuid: string };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;

  return {
    authObject: state.app.authObject,
    params,
    record: Records.getRecord("Item", params.uuid),
  };
})(
  (props: {
    authObject: RecordObject;
    params: RouteParams;
    record: Record;
  }): React.ReactElement => {
    props.record || Records.retrieve("Item", props.params.uuid);

    if (!props.record) {
      return null; // aka loading
    }

    let fields: { [key: string]: Field };
    let metas: { [key: string]: Field } = {};

    switch (true) {
      case props.record.type === "message" && !props.record.subType:
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "message" && props.record.subType === "link":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "link"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
        };
        break;

      case props.record.type === "message" &&
        props.record.subType === "graphic":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "graphic"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
        };
        break;

      case props.record.type === "message" && props.record.subType === "video":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "video"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          video: new VideoField(
            "form-item-video-id",
            Field.FLAG_REQUIRED,
            asset(props.record.video),
            { label: trans(Label.FORM__VIDEO) }
          ),
        };
        break;

      case props.record.type === "message" && props.record.subType === "quote":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "message"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "quote"
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
        };
        break;

      case props.record.type === "socialMedia" &&
        props.record.subType === "facebook":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "facebook"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
        };
        break;

      case props.record.type === "socialMedia" &&
        props.record.subType === "instagram":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "instagram"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
        };
        break;

      case props.record.type === "socialMedia" &&
        props.record.subType === "twitter":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "socialMedia"
          ),
          subType: new HiddenField(
            "form-item-subType-id",
            Field.FLAG_REQUIRED,
            "twitter"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_OPTIONAL,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
        };
        break;

      case props.record.type === "message" &&
        props.record.subType === "mediaReport":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "mediaReport"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_REQUIRED,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          video: new VideoField(
            "form-item-video-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.video),
            { label: trans(Label.FORM__VIDEO) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "argument":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "argument"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "currentStatus":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "currentStatus"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "document":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "document"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_REQUIRED,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "conclusion":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "conclusion"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-item-id",
            Field.FLAG_REQUIRED,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "fixture":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            "fixture"
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case [
        "economy",
        "restructuring",
        "training",
        "securingEmployment",
        "workingHours",
        "pensions",
        "other",
        "industry",
        "corona",
        "wagePolicy",
        "digitalization",
        "employment",
        "socialPolicy",
        "education",
        "skilledEmployment",
        "laborPolicy",
        "legal",
        "international",
        "elections",
      ].indexOf(props.record.type) !== -1:
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.record.type
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          desc: new InputField(
            "form-item-desc-id",
            Field.FLAG_REQUIRED,
            props.record.desc,
            { label: trans(Label.FORM__DESC) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_REQUIRED,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.documents),
            { label: trans(Label.FORM__DOCUMENT) }
          ),
        };
        break;

      case props.record.type === "poll":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          displayArea: new OptionsField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.record.displayArea,
            {
              options: [
                {
                  label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                  value: "memberArea",
                },
                {
                  label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                  value: "tariffArea",
                },
              ],
            }
          ),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.record.type
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          expiry: new DateTimeField(
            "form-item-expiry-id",
            Field.FLAG_REQUIRED,
            new Date(transformDate(props.record.expiry)),
            {
              label: trans(Label.FORM__EXPIRY),
              meta: "skipNow",
            }
          ),
          exclusive: new OptionsField(
            "form-item-exclusive-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusive
          ),
        };
        metas = {
          answer1: new AnswerField(
            "form-item-answer1-id",
            Field.FLAG_REQUIRED,
            props.record.answers[0],
            { label: trans(Label.FORM__ANSWER) }
          ),
          answer2: new AnswerField(
            "form-item-answer2-id",
            Field.FLAG_REQUIRED,
            props.record.answers[1],
            { label: trans(Label.FORM__ANSWER) }
          ),
          answer3: new AnswerField(
            "form-item-answer3-id",
            Field.FLAG_OPTIONAL,
            props.record.answers[2] || null,
            { label: trans(Label.FORM__ANSWER) }
          ),
        };
        break;

      case props.record.type === "event":
        fields = {
          uuid: new HiddenField(
            "form-item-uuid-id",
            Field.FLAG_REQUIRED,
            props.params.uuid
          ),
          // controls
          // displayArea: new OptionsField('form-item-displayArea-id', Field.FLAG_REQUIRED, props.record.displayArea, {
          //     options: [
          //         {label: trans(Label.ITEM__DISPLAY_MEMBER_AREA), value: 'memberArea'},
          //         {label: trans(Label.ITEM__DISPLAY_TARIFF_AREA), value: 'tariffArea'},
          //     ]
          // }),

          // fields
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.record.type
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField(
            "form-item-title-id",
            Field.FLAG_REQUIRED,
            props.record.title,
            { label: trans(Label.FORM__TITLE) }
          ),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            props.record.text,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField(
            "form-item-cover-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.cover),
            { label: trans(Label.FORM__COVER) }
          ),
          link: new UrlField(
            "form-item-link-id",
            Field.FLAG_OPTIONAL,
            props.record.link,
            { label: trans(Label.FORM__LINK) }
          ),
          address: new InputField(
            "form-item-address-id",
            Field.FLAG_REQUIRED,
            props.record.address,
            { label: trans(Label.FORM__ADDRESS) }
          ),
          canJoin: new OptionsField(
            "form-item-canJoin-id",
            Field.FLAG_OPTIONAL,
            props.record.canJoin
          ),
          startTime: new DateTimeField(
            "form-item-startTime-id",
            Field.FLAG_REQUIRED,
            new Date(transformDate(props.record.startTime)),
            {
              label: trans(Label.FORM__START_TIME),
              meta: "skipNow",
            }
          ),
          endTime: new DateTimeField(
            "form-item-endTime-id",
            Field.FLAG_REQUIRED,
            new Date(transformDate(props.record.endTime)),
            {
              label: trans(Label.FORM__END_TIME),
              meta: "skipNow",
            }
          ),
        };
        break;

      default:
        return <PageNotFound />;
    }

    fields.reviewState = new HiddenField(
      "form-item-reviewState-id",
      Field.FLAG_REQUIRED,
      "approved"
    );
    fields.publishDate = new DateTimeField(
      "form-item-publishDate-id",
      Field.FLAG_REQUIRED,
      new Date(transformDate(props.record.publishDate)),
      { label: trans(Label.FORM__PUBLISH_DATE) }
    );
    fields.pushNotification = new OptionsField(
      "form-item-pushNotification-id",
      Field.FLAG_OPTIONAL,
      props.record.pushNotification,
      null,
      (value: any, form: FormState) => {
        form.setFieldRequired &&
          form.setFieldRequired("pushTitle", value);
        form.setFieldRequired &&
          form.setFieldRequired("pushText", value);
      }
    );
    fields.pushTitle = new InputField(
      "form-item-pnTitle-id",
      Field.FLAG_REQUIRED,
      props.record.pushTitle,
      {
        label: trans(Label.FORM__TITLE),
        validations: ["maxChar60"],
      }
    );
    fields.pushText = new InputField(
      "form-item-pnText-id",
      Field.FLAG_REQUIRED,
      props.record.pushText,
      {
        label: trans(Label.FORM__TEXT),
        validations: ["maxChar120"],
      }
    );
    return (
      <FormBuilder
        key={`form-item-${props.record.uuid}`}
        action={(blueprint, done) => {
          Records.edit("Item", props.params.uuid, blueprint)
            .then((item) => {
              if (!item) return;
              Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
              Records.removeFromList(
                "Item:reviewable",
                new RecordRef("Item", props.record.uuid)
              );
              const signature = getItemListSignature(
                props.record.type,
                props.record.subType,
                props.record.displayArea
              );
              Records.addToList(signature, "Item", item);
              if (item.exclusive) {
                Records.addToList(
                  getItemListSignature(
                    "adminExclusive",
                    null,
                    "tariffArea",
                    true
                  ),
                  "Item",
                  item
                );
              }
              pushRoute(`/Admin/ItemLanding/${props.record.uuid}`);
            })
            .finally(() => done());
        }}
        data={{ record: props.record }}
        fields={fields}
        metas={metas}
        layout={ItemFormLayout}
      />
    );
  }
);
