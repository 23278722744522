//
import Label from "../../Util/Label";
const INHALT_ASSISTENT_ICON = "/icons/Inhaltsassistent.svg";
const FRAGE_ICON = "/icons/Fragen.svg";
const MEINVERBAND_ICON = "/icons/meinverband.svg";
const TARIF_ICON = "/icons/tarif.svg";
const USERS_ICON = "/icons/Nutzer.svg";
const SETTINGS_ICON = "/icons/Setup_darkblue.svg";
const ABO_SYSTEM_ICON = "/icons/Abo-Inhalt.svg";
export const KNOWLEDGE_BASE_ICON = "/icons/openbook.svg";

export function getUserMenu(user) {
  let mainMenu = [
    {
      buttonText: Label.ADMIN__NAV_CONTENT_WIZARD,
      route: "/Admin/ContentWizard",
      icon: INHALT_ASSISTENT_ICON,
    },
    {
      buttonText: Label.ITEM__DISPLAY_MEMBER_AREA,
      icon: MEINVERBAND_ICON,
      nestedList: [
        {
          buttonText: Label.ADMIN__NAV_NEWS_ITEMS,
          nestedList: [
            {
              buttonText: Label.ITEM__TYPE_NEWS_MEMBER_AREA,
              route: "/Items/NewsMessagesMemberArea/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_SOCIAL_MEDIA,
              route: "/Items/NewsSocialMedia/memberArea/socialMedia",
            },
            {
              buttonText: Label.ITEM__TYPE_POLL,
              route: "/Items/Polls/memberArea",
            },
          ],
        },
        {
          buttonText: Label.ADMIN__NAV_TOPIC_ITEMS,
          nestedList: [
            {
              buttonText: Label.ITEM__TYPE_INDUSTRY,
              route: "/Items/TopicsIndustry/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_CORONA,
              route: "/Items/TopicsCorona/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_WAGE_POLICY,
              route: "/Items/TopicsWagePolicy/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_DIGITALIZATION,
              route: "/Items/TopicsDigitalization/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_EMPLOYMENT,
              route: "/Items/TopicsEmployment/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_SOCIAL_POLICY,
              route: "/Items/TopicsSocialPolicy/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_EDUCATION,
              route: "/Items/TopicsEducation/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_SKILLED_EMPLOYMENT,
              route: "/Items/TopicsSkilledEmployment/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_LABOR_POLICY,
              route: "/Items/TopicsLaborPolicy/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_LEGAL,
              route: "/Items/TopicsLegal/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_INTERNATIONAL,
              route: "/Items/TopicsInternational/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_ELECTIONS,
              route: "/Items/TopicsElections/memberArea",
            },
            {
              buttonText: Label.ITEM__TYPE_OTHER,
              route: "/Items/TopicsOther/memberArea",
            },
          ],
        },
        {
          buttonText: Label.ITEM__TYPE_EVENT,
          route: "/Items/Events/memberArea",
        },
      ],
    },
    {
      buttonText: Label.ITEM__DISPLAY_TARIFF_AREA,
      icon: TARIF_ICON,
      nestedList: [
        { buttonText: Label.ITEM__TYPE_EXCLUSIVE, route: "/Items/Exclusive" },
        {
          buttonText: Label.ADMIN__NAV_NEWS_ITEMS,
          nestedList: [
            {
              buttonText: Label.ITEM__TYPE_NEWS,
              route: "/Items/NewsMessages/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_SOCIAL_MEDIA,
              route: "/Items/NewsSocialMedia/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_MEDIA_REPORT,
              route: "/Items/NewsMediaReports/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_POLL,
              route: "/Items/Polls/tariffArea",
            },
          ],
        },
        {
          buttonText: Label.ADMIN__NAV_NEGOTIATION_ITEMS,
          nestedList: [
            {
              buttonText: Label.ITEM__TYPE_ARGUMENT,
              route: "/Items/NegotiationsArguments/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_CURRENT_STATUS,
              route: "/Items/NegotiationsCurrentStatus/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_DOCUMENT,
              route: "/Items/NegotiationsDocuments/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_CONCLUSION,
              route: "/Items/NegotiationsConclusions/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_FIXTURE,
              route: "/Items/NegotiationsFixtures/tariffArea",
            },
          ],
        },
        {
          buttonText: Label.ADMIN__NAV_TOPIC_ITEMS,
          nestedList: [
            {
              buttonText: Label.ITEM__TYPE_ECONOMY,
              route: "/Items/TopicsEconomy/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_RESTRUCTURING,
              route: "/Items/TopicsRestructuring/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_TRAINING,
              route: "/Items/TopicsTraining/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_SECURING_EMPLOYMENT,
              route: "/Items/TopicsSecuringEmployment/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_WORKING_HOURS,
              route: "/Items/TopicsWorkingHours/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_PENSIONS,
              route: "/Items/TopicsPensions/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_COLLECTIVE_AGREEMENT,
              route: "/Items/TopicsCollectiveAgreements/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_PARTICIPATION,
              route: "/Items/TopicsParticipation/tariffArea",
            },
            {
              buttonText: Label.ITEM__TYPE_OTHER,
              route: "/Items/TopicsOther/tariffArea",
            },
          ],
        },
      ],
    },
    {
      buttonText: Label.ADMIN__NAV_USERS_MEMBERS,
      icon: USERS_ICON,
      nestedList: [
        {
          buttonText: Label.ADMIN__NAV_ASSOCIATION_MEMBERSHIP,
          route: "/Admin/AssociationMembership",
        },
        { buttonText: Label.ADMIN__NAV_USER, route: "/Admin/Users" },
      ],
    },
    {
      buttonText: Label.ADMIN__NAV_QUESTIONS,
      route: "/Admin/QuestionList",
      icon: FRAGE_ICON,
    },
    {
      buttonText: Label.ADMIN__NAV_SETTINGS,
      icon: SETTINGS_ICON,
      nestedList: [
        {
          buttonText: Label.ASSOCIATION__JOIN,
          route: "/Association/Join",
        },
      ],
    },
  ];
  if (user.supergod === 1) {
    mainMenu[5].nestedList.splice(0, 0, {
      buttonText: Label.ADMIN__NAV_ASSOCIATION,
      route: "/Admin/Associations",
    });
  }
  if (user.associationAdmin) {
    mainMenu.splice(1, 0, {
      buttonText: Label.ADMIN__NAV_RELEASE_CENTER_MANUAL,
      route: "/Admin/ReleaseCenterManual",
      icon: ABO_SYSTEM_ICON,
    });
    mainMenu[6].nestedList.splice(
      0,
      0,
      {
        buttonText: Label.ADMIN__NAV_RELEASE_CENTER,
        route: "/Admin/ReleaseCenter",
      },
      {
        buttonText: Label.ADMIN__NAV_MY_ASSOCIATION,
        route: `/Admin/LandAssociation/${user.associationAdmin.uuid}`,
      }
    );
  }
  return mainMenu;
}
