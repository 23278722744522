import Box from "@mui/material/Box";

type EventDateTimeProps = {
  start: Date;
  end: Date;
};

const color = "#1880C4";
const calendarIcon = 'url("/icons/calendar_clock.svg")';

export const EventDateTime: React.FC<EventDateTimeProps> = (
  props: EventDateTimeProps
) => {
  const { start, end } = props;
  const startDate =
    start.getDate() + "." + (start.getMonth() + 1) + "." + start.getFullYear();
  const endDate =
    end.getDate() + "." + (end.getMonth() + 1) + "." + end.getFullYear();
  const startTime =
    start.getHours() +
    ":" +
    (start.getMinutes() < 10 ? "0" + start.getMinutes() : start.getMinutes());
  const endTime =
    end.getHours() +
    ":" +
    (end.getMinutes() < 10 ? "0" + end.getMinutes() : end.getMinutes());

  const datesEquals =
    start.getFullYear() === end.getFullYear() &&
    start.getMonth() === end.getMonth() &&
    start.getDay() === end.getDay();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        fontSize: "14px",
        fontWeight: "bold",
        color: color,
        marginBottom: "10px",
      }}
    >
      <Box
        className="calendar-icon"
        sx={{
          width: 20,
          height: 20,
          WebkitMaskImage: calendarIcon,
          maskImage: calendarIcon,
          backgroundColor: color,
        }}
      />
      {datesEquals ? (
        <span>
          {startDate}
          {", "}
          {startTime}
          {"-"}
          {endTime}
        </span>
      ) : (
        <span>
          {startDate}
          {", "}
          {startTime}
          {" - "}
          {endDate}
          {", "}
          {endTime}
        </span>
      )}
    </Box>
  );
};
