export type TranslationsSet = { [key: string]: any };

class LocaleHandler {
  protected defaultLang: string = "";
  protected languages: { [key: string]: string } = {};
  protected translations: any = {};

  protected lang: string = null;

  // public handlers(): void {
  //     Action.registerHandler(ActionType.UI__AUTH_CHANGE, action => {
  //         if (!action.blueprint) {
  //             return;
  //         }
  //         this.sendLang();
  //     });
  // }

  public init(
    locale: { defaultLang: string; languages: { [key: string]: string } },
    translations: TranslationsSet = {}
  ): void {
    this.defaultLang = locale.defaultLang;
    this.languages = locale.languages;
    this.translations = translations;

    const lang = this.getLang();
    this.setLang(lang, true);
  }

  //

  public trans(prop: string): string {
    if (!prop || typeof prop !== "string") return "";
    prop = prop.replace(/:/g, ".");
    const lang = this.getLang();
    return this.translations[lang] && this.translations[lang][prop]
      ? this.translations[lang][prop]
      : prop;
  }

  public getAvailableLangs(exceptCurrent = false): any {
    const langs = { ...this.languages };
    if (exceptCurrent) {
      delete langs[this.getLang()];
    }
    return langs;
  }

  public getLang(): string {
    // let userLang = window.app.storageGet<string>('userLang');
    // if (!window.app.pidExists(this.languages, userLang)) {
    //     userLang = this.defaultLang;
    // }
    // return userLang;
    return "de";
  }

  public setLang(lang: string, noAction: boolean = false): string {
    this.lang = lang;

    // window.app.storageSet('userLang', lang);

    if (typeof document !== "undefined") {
      document.getElementsByTagName("html")[0].setAttribute("lang", lang);
    }

    // if (!window.app.isAuth()) {
    //     return lang;
    // }

    if (!noAction) {
      this.sendLang();
    }

    return lang;
  }

  protected sendLang(): void {
    // const lang = window.app.storageGet<string>('userLang');
    // new Action(ActionType.REQUEST__LANG_SEND, null, null, {lang: lang}).send();
  }
}

const localeHandler = new LocaleHandler();
const trans = (prop: any): string =>
  typeof prop !== "string" ? "" : localeHandler.trans(String(prop));

export default localeHandler;
export {
  trans,
  // TranslationsSet
};
