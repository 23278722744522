type TextProps = {
  text: string;
};

export const Text: React.FC<TextProps> = ({ text }) => {
  return (
    <div
      className="text-main"
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    />
  );
};
