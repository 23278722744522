import React from "react";
import Box from "@mui/material/Box";
import { InfoBubble } from "../../Layout/Components/InfoBubble";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";

export default class InputField extends Field {
  public display(signal: FieldSignal): React.ReactNode {
    const backgroundColor = !this.context.bgColor
      ? "#f2f5f7"
      : this.context.bgColor;
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
      ? "#ff3333"
      : "transparent";

    return (
      <Box sx={{ position: "relative", display: "flex" }}>
        <div
          style={{
            borderColor: colorForError,
            borderRadius: 16,
            borderWidth: 1,
            borderStyle: "solid",
            boxShadow: `0px 0px 5px ${colorForError}`,
            //
            display: "flex",
            flexDirection: "row",
            width: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              backgroundColor,
              borderBottomLeftRadius: 16,
              borderTopLeftRadius: 16,
              display: "flex",
              fontSize: 24,
              justifyContent: "center",
              width: 45,
            }}
          >
            {(this.context && this.context.index) || (
              <React.Fragment>&bull;</React.Fragment>
            )}
          </div>
          <input
            autoFocus={this.context.focus}
            defaultValue={this.value}
            id={this.id}
            onBlur={() => {
              signal.blur();
            }}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
              this.change(ev.target.value)
            }
            onFocus={() => {
              signal.focus();
            }}
            onKeyPress={(ev) => {
              if (
                ev.key === "Enter" &&
                typeof this.context.onReturn === "function"
              ) {
                ev.preventDefault();
                this.context.onReturn();
                return false;
              }
            }}
            placeholder={this.context.label}
            ref={(ref) => !!ref && (Field.fieldRefs[this.id] = ref)}
            style={{
              backgroundColor,
              border: "none",
              borderBottomRightRadius: 16,
              borderTopRightRadius: 16,
              color: !this.context.color ? "#093b60" : this.context.color,
              fontSize: 18,
              outline: "none",
              padding: 18,
              paddingLeft: 32,
              paddingRight: 32,

              //
              flex: 1,
            }}
            type={"text"}
          />
        </div>
        <Box sx={{ alignSelf: "center", position: "absolute", right: ".8rem" }}>
          {!this.value ? <InfoBubble fieldId={this.id} /> : null}
        </Box>
      </Box>
    );
  }
}
