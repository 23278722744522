import React, { CSSProperties } from "react";
import styled from 'styled-components';
import View from "../../Layout/View";
import Toast from "../../Layout/Common/Toast";
import { RouterState } from "../../State/Router";
import { DisplayArea } from "../Root";
import { Context } from "../../State/HttpClient";
import NavWebNew from "./Nav/NavWebNew";

const Styles: { [key: string]: CSSProperties } = {
  searchInput: {
    borderColor: "#ccc",
    borderRadius: 5,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: "none",
    color: "#093b60",
    fontSize: 14,
    outline: "none",
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    width: 120,
  },
};

const DrawerWrapper = styled(View)`
  background-color: #fff;
  flex: 3;
  max-width: 300px;
  min-width: 200px;
  box-shadow: -3px 0px 15px 0px;
  height: 100vh;
  overflow: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const ContentWrapper = styled(View)`
  align-items: stretch;
  flex: 9;
  max-height: 100vh;
  overflow: auto;
`;

const getItemListContext = (
  itemType: string,
  subType: string,
  displayArea: DisplayArea,
  itemTypeIsListType: boolean = false
): Context => {
  let listType: string;

  switch (true) {
    case displayArea === "memberArea" &&
      ["message", "mediaReport", "material"].indexOf(itemType) !== -1:
      listType = "adminNewsMessagesMemberArea";
      break;
    case displayArea === "memberArea" && subType === "facebook":
      listType = "adminFacebook";
      break;
    case displayArea === "memberArea" && subType === "twitter":
      listType = "adminTwitter";
      break;
    case listType === "poll":
      listType = "adminPolls";
      break;
    case listType === "event":
      listType = "adminEvents";
      break;

    //

    case itemTypeIsListType:
      listType = itemType;
      break;

    //

    case itemType === "message":
      listType = "adminNewsMessages";
      break;
    case itemType === "socialMedia":
      listType = "adminNewsSocialMedia";
      break;
    case itemType === "mediaReport":
      listType = "adminNewsMediaReports";
      break;
    case itemType === "argument":
      listType = "adminNegotiationsArguments";
      break;
    case itemType === "conclusion":
      listType = "adminNegotiationsConclusions";
      break;
    case itemType === "currentStatus":
      listType = "adminNegotiationsCurrentStatus";
      break;
    case itemType === "document":
      listType = "adminNegotiationsDocuments";
      break;
    case itemType === "fixture":
      listType = "adminNegotiationsFixtures";
      break;
    case itemType === "economy":
      listType = "adminTopicsEconomy";
      break;
    case itemType === "other":
      listType = "adminTopicsOther";
      break;
    case itemType === "pensions":
      listType = "adminTopicsPensions";
      break;
    case itemType === "restructuring":
      listType = "adminTopicsRestructuring";
      break;
    case itemType === "securingEmployment":
      listType = "adminTopicsSecuringEmployment";
      break;
    case itemType === "training":
      listType = "adminTopicsTraining";
      break;

    case itemType === "industry":
      listType = "adminTopicsIndustry";
      break;
    case itemType === "corona":
      listType = "adminTopicsCorona";
      break;
    case itemType === "wagePolicy":
      listType = "adminTopicsWagePolicy";
      break;
    case itemType === "digitalization":
      listType = "adminTopicDigitalization";
      break;
    case itemType === "employment":
      listType = "adminTopicsEmployment";
      break;
    case itemType === "socialPolicy":
      listType = "adminTopicsSocialPolicy";
      break;
    case itemType === "education":
      listType = "adminTopicsEducation";
      break;
    case itemType === "skilledEmployment":
      listType = "adminTopicsSkilledEmployment";
      break;
    case itemType === "laborPolicy":
      listType = "adminTopicsLaborPolicy";
      break;
    case itemType === "legal":
      listType = "adminTopicsLegal";
      break;
    case itemType === "international":
      listType = "adminTopicsInternational";
      break;
    case itemType === "elections":
      listType = "adminTopicsElections";
      break;
    case itemType === "collectiveAgreement":
      listType = "adminTopicsCollectiveAgreements";
      break;
    case itemType === "participation":
      listType = "adminTopicsParticipation";
      break;
    default:
      return null;
  }

  return {
    list: {
      type: listType,
      where: [["displayArea", "=", displayArea]],
    },
  };
};
const getItemListSignature = (
  itemType: string,
  subType: string,
  displayArea: DisplayArea,
  itemTypeIsListType: boolean = false
): string => {
  return [
    "Item",
    JSON.stringify(
      getItemListContext(itemType, subType, displayArea, itemTypeIsListType)
    ),
  ].join(":");
};

const BodyOpenedWeb = (props: {
  rstate: RouterState;
  children?: React.ReactNode;
}): React.ReactElement => {
  const component = props.children || null;

  return (
    <View style={{ backgroundColor: "#e7ecf0", flexDirection: "row", flex: 1 }}>
      <DrawerWrapper>
        <NavWebNew />
      </DrawerWrapper>
      <ContentWrapper>
        {component}
      </ContentWrapper>
      <Toast />
    </View>
  );
};

export { getItemListContext, getItemListSignature, Styles };
export default BodyOpenedWeb;
