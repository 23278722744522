import React from "react";
import Toast from "../../Layout/Common/Toast";
import { RouterState } from "../../State/Router";

const BodyGated = (props: {
  rstate: RouterState;
  children?: React.ReactNode;
}): React.ReactElement => {
  return (
    <React.Fragment>
      {props.children || null}
      <Toast />
    </React.Fragment>
  );
};
export default BodyGated;
