import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import { Editor } from "@tinymce/tinymce-react";


export default class WYSIWYGTextField extends Field {
  public display(signal: FieldSignal): React.ReactNode {
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
        ? "#ff3333"
        : "transparent";

    const boxShadow = this.error
      ? 'rgb(255, 51, 51) 0px 0px 5px'
      : this.isRequired && !this.isValid
        ? "rgb(255, 51, 51) 0px 0px 5px"
        : "none";

    return (
      <div
        style={{
          borderColor: colorForError,
          borderWidth: 1,
          borderStyle: "solid",
          boxShadow: boxShadow
        }}
      >
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          value={this.value}
          onBlur={() => {
            signal.blur();
          }}
          onEditorChange={(content) => this.change(content)}
          init={{
            placeholder: this.context.label,
            height: 500,
            menubar: false,
            plugins: [
              "autolink lists link image charmap preview anchor",
              "searchreplace visualblocks fullscreen",
              "insertdatetime media table paste wordcount",
            ],
            toolbar: "bold italic underline | bullist numlist | link",
            branding: false,
            statusbar: false,
            formats: {
              underline: [{ inline: "u" }, { inline: "span", remove: "all" }],
            },
            content_style: `ol {counter-reset: list;} ol > li {list-style: none;}ol > li:before {content: counter(list) ") ";counter-increment: list;}`,
            license_key: 'gpl'
          }}
        />
      </div>
    );
  }
}
