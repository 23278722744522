import Box from "@mui/material/Box";
type AnswerProps = {
  answerText: string;
  answerNumber: number;
};
export const Answer: React.FC<AnswerProps> = ({ answerNumber, answerText }) => {
  return (
    <Box
      sx={{
        padding: "22px 15px 23px",
        display: "flex",
        color: "#207BBA",
        fontSize: "14px",
        fontWeight: "bold",
        backgroundColor: "#E7EFF4",
        marginBottom: "10px",
        borderRadius: "16px",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
        <span>
          {answerNumber}
          {"."}
        </span>
        <span style={{ marginLeft: "5px" }}>{answerText}</span>
      </Box>
      <Box>
        <span>{"0%"}</span>
      </Box>
    </Box>
  );
};
