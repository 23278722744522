import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import AddCircleOutlined from "@mui/icons-material/AddCircleOutlineOutlined";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { InfoBubble } from "../../Layout/Components/InfoBubble";

export default class CollectionField extends Field {
  public display(signal: FieldSignal): React.ReactElement {
    return (
      <>
        {this.getCollectionForms().map((form) => (
          <Box key={form.index} sx={{ marginBottom: "24px" }}>
            {form.node}
          </Box>
        ))}
        <Box
          sx={{
            backgroundColor: "#fff",
            marginBottom: "24px",
            padding: "16px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box>
            <IconButton
              sx={{ color: "#4daff0" }}
              onClick={() => {
                this.addCollectionItem();
              }}
            >
              <AddCircleOutlined fontSize={"large"} />
            </IconButton>
          </Box>
          <Box>
            <InfoBubble fieldId={this.id} />
          </Box>
        </Box>
      </>
    );
  }
}
