import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import Close from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { DialogContent, Link } from "@mui/material";
import config from "./InfoBubble.config.json";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { trans } from "../../Tools/LocaleHandler";
import { kb } from "../../app/ActionDomains/Admin/KnowledgeBase/KnowledgeBaseViews/KnowledgeBaseLanding";

type InfoBubbleProps = {
  fieldId?: string;
  accentInfo?: string;
};
export const InfoBubble: React.FC<InfoBubbleProps> = ({
  fieldId,
  accentInfo,
}) => {
  const [open, setOpen] = useState(false);
  const [timerId, setTimerId] = useState(undefined);
  const [start, setStart] = useState(undefined);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    text = null,
    linkToKB = null,
    title = null,
  } = config[fieldId] || { text: null, linkToKB: null, title: null };
  const textWithAccent = trans(text).replace("XXX", accentInfo);
  const showInfoBubble = config[fieldId] ? true : false;
  return (
    showInfoBubble && (
      <>
        <IconButton
          onClick={handleOpen}
          onMouseEnter={() => {
            setStart(Date.now());
            setTimerId(setTimeout(() => setOpen(true), 4000));
          }}
          onMouseLeave={() => {
            const onLeave = Date.now() - start;
            if (onLeave < 4000) {
              clearTimeout(timerId);
            }
          }}
        >
          <FeedbackOutlinedIcon />
        </IconButton>
        <Dialog open={open}>
          <DialogTitle
            sx={{ display: "flex", justifyContent: "flex-end", padding: "0px" }}
          >
            <IconButton onClick={handleClose}>
              <Close sx={{ color: "#0A3B60", fontSize: "30px" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: "400px",
            }}
          >
            <CardMedia
              component="img"
              src="/icons/Info.Title.png"
              sx={{ height: "95px", width: "95px" }}
            />
            <Typography
              sx={{
                marginBottom: "20px",
                fontSize: "24px",
                fontWeight: "bold",
                color: "#0A3B60",
              }}
            >
              {trans(title) ? trans(title) : fieldId}
            </Typography>
            <Typography component="p" sx={{ fontSize: "14px", color: "" }}>
              {textWithAccent}
            </Typography>
            {linkToKB ? (
              <Link
                href={linkToKB}
                underline="hover"
                target="_blank"
                onClick={(e: any) => {
                  e.preventDefault();
                  kb(linkToKB);
                }}
              >
                {trans("trans.infobubble.moreInfo")}
              </Link>
            ) : null}
          </DialogContent>
        </Dialog>
      </>
    )
  );
};
