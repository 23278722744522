import { useState } from "react";
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import { trans } from "../../../Tools/LocaleHandler";
import { pushRoute } from "../../../State/Router";
import NavButton from "./NavButton";
import { useSelectionContext, useSetSelectionContext } from "./context";

export type NavNestedButtonProps = {
  buttonText: string;
  position: string;
  icon?: string;
  route?: string;
  nestedList?: {
    buttonText: string;
    route?: string;
    nestedList?: { buttonText: string; route: string }[];
  }[];
};

const NavNestedButton: React.FC<NavNestedButtonProps> = (props) => {
  const { icon, route, buttonText, nestedList, position } = props;
  const [openCollapse, setOpenCollapse] = useState(false);
  const { selectedItem } = useSelectionContext();
  const { setSelectedItem } = useSetSelectionContext();
  const handleClick = () => {
    setOpenCollapse(!openCollapse);
    setSelectedItem(position);
  };
  const goToRoute = () => {
    pushRoute(route);
    setSelectedItem(position);
  };
  return (
    <>
      {route ? (
        <NavButton
          buttonText={trans(buttonText)}
          icon={icon}
          action={goToRoute}
          selected={selectedItem === position}
        />
      ) : (
        <>
          <NavButton
            buttonText={trans(buttonText)}
            icon={icon}
            action={handleClick}
            selected={selectedItem === position}
          >
            {openCollapse ? (
              <KeyboardArrowDownIcon />
            ) : (
              <KeyboardArrowRightIcon />
            )}
          </NavButton>
          <Collapse in={openCollapse} timeout="auto" unmountOnExit>
            <List component="div">
              {nestedList &&
                nestedList.map((listItem, index) => {
                  return listItem.nestedList ? (
                    <NavNestedButton
                      key={trans(listItem.buttonText)}
                      buttonText={trans(listItem.buttonText)}
                      nestedList={listItem.nestedList}
                      position={`${position}${index}`}
                    />
                  ) : (
                    // Redundant?
                    <NavButton
                      buttonText={trans(listItem.buttonText)}
                      key={listItem.buttonText}
                      selected={selectedItem === `${position}${index}`}
                      action={() => {
                        pushRoute(listItem.route);
                        setSelectedItem(`${position}${index}`);
                      }}
                    />
                  );
                })}
            </List>
          </Collapse>
        </>
      )}
    </>
  );
};
export default NavNestedButton;
