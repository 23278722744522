import React from "react";
import Box from "@mui/material/Box";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import View from "../../../../../Layout/View";
import Button from "../../../../../Layout/Components/Button";
import Form from "../../../../../Layout/Components/Form";
import { trans } from "../../../../../Tools/LocaleHandler";
import Text from "../../../../../Layout/Components/Text";
import Label from "../../../../Util/Label";
import ItemLayoutHeader from "./ItemLayoutHeader";
import { InfoBubble } from "../../../../../Layout/Components/InfoBubble";

const ContentWizardFormLayout = (form: FormState): React.ReactNode => {
  const selectedFields = () => {
    return Object.keys(form.fields)
      .map((item) => form.getFieldValue(item))
      .filter((item) => item !== null);
  };
  return (
    <Form
      onSubmit={(ev) => {
        ev.preventDefault();
        form.buttons.submit.action();
        return false;
      }}
      style={{ paddingLeft: "4%", paddingRight: "4%" }}
    >
      <ItemLayoutHeader title={trans(Label.ADMIN__CONTENT_WIZARD_TITLE)} />
      <View
        style={{
          backgroundColor: "#fff",
          padding: 48,
          paddingLeft: 72,
          paddingRight: 72,
        }}
      >
        {/* wizard step header */}
        {<ContentWizardHeader step={0} />}
        <View style={{ margin: 12 }}>&nbsp;</View>

        <Text
          style={{
            fontSize: 16,
            color: "#0A3B60",
            fontWeight: "bold",
            margin: 8,
            marginBottom: 5,
          }}
        >
          {trans(Label.FORM__DISPLAY_AREA_QUESTION)}
        </Text>
        {form.fields.displayArea}

        <View style={{ margin: 16 }}>&nbsp;</View>

        <Text
          style={{
            fontSize: 16,
            color: "#0A3B60",
            fontWeight: "bold",
            margin: 8,
            marginBottom: 5,
          }}
        >
          {trans(Label.FORM__CONTENT_TYPE_QUESTION)}
        </Text>

        {/* for tariffArea display items in wizard */}
        {form.getFieldValue("displayArea") === "tariffArea" && (
          <View>
            <Text
              style={{
                fontSize: 16,
                color: "#0198E1",
                fontWeight: "bold",
                margin: 8,
                marginBottom: 5,
              }}
            >
              {trans(Label.ADMIN__NAV_NEWS_ITEMS)}
            </Text>
            {form.fields.itemTypeNewsForTariffArea}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#0198E1",
                  fontWeight: "bold",
                  margin: "8px 20px 8px 8px",
                }}
              >
                {trans(Label.UI__NAV_NEGOTIATIONS)}
              </Text>
              <Box sx={{ alignSelf: "center" }}>
                <InfoBubble fieldId={"contentWizard-multipleLocations"} />
              </Box>
            </Box>
            {form.fields.itemTypeNegotiationsForTariffArea}
            {form.fields.itemTypeNegotiationsGroupForTariffArea}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#0198E1",
                  fontWeight: "bold",
                  margin: "8px 20px 8px 8px",
                }}
              >
                {trans(Label.UI__NAV_TOPICS)}
              </Text>
              <Box sx={{ alignSelf: "center" }}>
                <InfoBubble fieldId={"contentWizard-multipleLocations"} />
              </Box>
            </Box>
            {form.fields.itemTypeTopicsForTariffArea}
          </View>
        )}

        {/* for memberArea display items in wizard */}
        {form.getFieldValue("displayArea") === "memberArea" && (
          <View>
            <Text
              style={{
                fontSize: 16,
                color: "#0198E1",
                fontWeight: "bold",
                margin: 8,
                marginBottom: 5,
              }}
            >
              {trans(Label.ADMIN__NAV_NEWS_ITEMS)}
            </Text>
            {form.fields.itemTypeNewsForMemberArea}
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "5px",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#0198E1",
                  fontWeight: "bold",
                  margin: "8px 20px 8px 8px",
                }}
              >
                {trans(Label.UI__NAV_TOPICS)}
              </Text>
              <Box sx={{ alignSelf: "center" }}>
                <InfoBubble fieldId={"contentWizard-multipleLocations"} />
              </Box>
            </Box>
            {form.fields.itemTypeTopicsForMemberArea}

            <Text
              style={{
                fontSize: 16,
                color: "#0198E1",
                fontWeight: "bold",
                margin: 8,
                marginBottom: 5,
              }}
            >
              {trans(Label.ITEM__TYPE_EVENT)}
            </Text>
            {form.fields.itemTypeEventsForMemberArea}
          </View>
        )}

        <View style={{ margin: 16 }}>&nbsp;</View>

        <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
          <Button
            color={"#67ADF4"}
            disabled={selectedFields().length < 2}
            large={true}
            onPress={() => form.buttons.submit.action()}
            title={trans(Label.UI__NEXT)}
          />
        </View>

        <View style={{ margin: 4 }}>&nbsp;</View>
      </View>
    </Form>
  );
};

const ContentWizardHeader = (props: { step: number }): React.ReactElement => {
  const activeStepStyle = { backgroundColor: "#67ADF4", color: "#fff" };
  const baseStyle = {
    fontSize: 16,
    fontWeight: "bold",
  };
  return (
    <Box
      sx={{
        backgroundColor: "#F5F7F8",
        display: "flex",
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        marginBottom: "40px",
      }}
    >
      <View
        style={{
          ...baseStyle,
          ...(props.step === 0 ? activeStepStyle : { color: "#0A3B60" }),
          flex: 1,
          padding: 16,
        }}
      >
        {trans(Label.ADMIN__CONTENT_WIZARD_STEP_1_TITLE)}
      </View>
      <View
        style={{
          ...baseStyle,
          ...(props.step === 1 ? activeStepStyle : { color: "#0A3B60" }),
          flex: 1,
          padding: 16,
        }}
      >
        {trans(Label.ADMIN__CONTENT_WIZARD_STEP_2_TITLE)}
      </View>
      <View
        style={{
          ...baseStyle,
          ...(props.step === 2 ? activeStepStyle : { color: "#0A3B60" }),
          flex: 1,
          padding: 16,
        }}
      >
        {trans(Label.ADMIN__CONTENT_WIZARD_STEP_3_TITLE)}
      </View>
    </Box>
  );
};
export default ContentWizardFormLayout;
export { ContentWizardHeader };
