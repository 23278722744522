import React from "react";
import { getHomeUrl, Link } from "../../../State/Router";
import View from "../../../Layout/View";
import Button from "../../../Layout/Components/Button";

const PageNotFound = () => {
  const homeUrl = getHomeUrl();
  return (
    <View style={{ flexDirection: "row", marginLeft: 40, marginTop: 40 }}>
      Page not found.{" "}
      <Link to={homeUrl}>
        &nbsp;
        <Button simple={true} title={`go home?`} />
      </Link>
    </View>
  );
};
export default PageNotFound;
