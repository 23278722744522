import { Blueprint } from "../Tools/FormBuilder/FormBuilder";

export type Context = {
  blueprint?: Blueprint;
  collect?: {
    foreignRepository: string;
    foreignKey: string;
    uuid: string;
    orderBy?: [string, "asc" | "desc"];
    where?: [string, string, any][];
  };
  data?: {
    [key: string]: any;
  };
  list?: {
    orderBy?: [string, "asc" | "desc"];
    type: string;
    where?: [string, string, any][];
  };
  remove?: UuidFlag;
  retrieve?: UuidFlag;
  upload?: File;
};

type RejectedResponse = { res: Response; status: number; text: string };

type UuidFlag = { flag: string; uuid: string };

export default new (class HttpClient {
  protected static url: string = null;

  protected config: RequestInit = {
    credentials: "include",
    headers: {},
  };

  public getApiUrl() {
    return HttpClient.url;
  }

  public setApiUrl(apiUrl: string) {
    HttpClient.url = apiUrl;
  }

  public action(
    method: string,
    repository: string = null,
    context: Context = {},
    skipProcRes: boolean = false,
    errCallback?: CallableFunction
  ): Promise<{ [key: string]: any }> {
    return new Promise((resolve, reject) => {
      const files: FileList[] = [];

      if (context.blueprint) {
        let i = -1;

        const getFileRef = (
          file: File,
          index: number,
          multiple: boolean = false
        ) => ({
          name: file.name || true,
          type: file.type || true,
          index,
          multiple,
        });

        const getBlueprint = (blueprint: Blueprint) => {
          const result: Blueprint = { fields: {}, metas: {} };
          Object.entries(blueprint.fields || {}).forEach(([prop, item]) => {
            switch (true) {
              case item instanceof File:
                i++;
                files.push(item);
                result.fields[prop] = getFileRef(item, i);
                break;
              case item instanceof FileList:
                i++;
                files.push(item);
                result.fields[prop] = getFileRef(item, i, true);
                break;
              case item &&
                Array.isArray(item) &&
                item.length &&
                item[0].fields &&
                item[0].metas:
                result.fields[prop] = [];
                item.forEach((item: Blueprint) =>
                  result.fields[prop].push(getBlueprint(item))
                );
                break;
              default:
                result.fields[prop] = item;
            }
          });

          Object.entries(blueprint.metas || {}).forEach(([prop, item]) => {
            switch (true) {
              case item instanceof File:
                i++;
                files.push(item);
                result.metas[prop] = getFileRef(item, i, true);
                break;
              case item instanceof FileList:
                i++;
                files.push(item);
                result.metas[prop] = getFileRef(item, i);
                break;
              case !!(
                item &&
                Array.isArray(item) &&
                item.length &&
                item[0].fields &&
                item[0].metas
              ):
                result.metas[prop] = [];
                item.forEach((subBlueprint: Blueprint) =>
                  result.metas[prop].push(getBlueprint(subBlueprint))
                );

                break;
              default:
                result.metas[prop] = item;
            }
          });

          return result;
        };

        context.blueprint = getBlueprint(context.blueprint);
      }

      const formData = new FormData();

      formData.append("method", method);
      formData.append("repository", repository);
      formData.append("context", JSON.stringify(context));
      formData.append(
        "timezoneOffset",
        new Date().getTimezoneOffset().toString()
      );
      context.upload && formData.append("upload", context.upload);
      files.forEach((file, index) => {
        if (file instanceof FileList) {
          Object.values(file).forEach((file, i2) =>
            formData.append(`mfiles:${index}:${i2}`, file)
          );

          return;
        }

        formData.append(`file:${index}`, file);
      });

      fetch(`${HttpClient.url}/action/${method}`, {
        ...this.config,
        body: formData,
        method: "post",
      })
        .then((res) => {
          if (skipProcRes) {
            return res;
          }

          const ok = res.status >= 200 && res.status < 300;
          const clone = res.clone();

          res
            .json()
            .then((res) => {
              if (ok) {
                resolve(res);
                return;
              }
              reject(res);
            })
            .catch((ex) => {
              clone.text().then((res: string) => {
                console.warn(ex);
                reject(this.reject(clone, { text: res }));
              });
            });
        })
        .catch((ex) => {
          console.warn(ex);
          reject(this.reject(ex, typeof ex === "string" ? { text: ex } : {}));
        });
    });
  }

  public setHeader(name: string, value: string): void {
    this.config.headers[name] = value;
  }

  public removeHeader(name: string): void {
    delete this.config.headers[name];
  }

  protected reject(
    res: Response,
    data: { [key: string]: any } = {}
  ): RejectedResponse {
    const result: RejectedResponse = {
      res,
      status: res.status,
      text: "rejected",
    };
    if (data.text) {
      result.text = data.text;
    }
    return result;
  }
})();
