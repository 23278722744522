import React from "react";
import { Component, ReactNode } from "react";
import Field, { FieldSignal } from "./Field";

import ImageField from "../../app/FormFields/ImageField";
import SvgField from "../../app/FormFields/SvgField";
import VideoField from "../../app/FormFields/VideoField";
import { CircularProgress, Dialog, DialogTitle, } from "@mui/material";
import { trans } from "../LocaleHandler";
import Label from "../../app/Util/Label";

type MediaField = ImageField | SvgField | VideoField | Field;
type ActionFn = (
  blueprint: Blueprint,
  done: (reset?: boolean) => void,
  data: { [key: string]: any },
  callback?: any
) => void;
export type Blueprint = {
  fields: { [key: string]: any };
  metas: { [key: string]: any };
};
type ButtonState = {
  action: (force?: boolean) => boolean;
  disabled: boolean;
};
type FieldSet = { [key: string]: Field };
type Value = string | any;
export type FormState = {
  activeElem: string;
  buttons: {
    next: ButtonState;
    prev: ButtonState;
    submit: ButtonState;
  };
  context: { [key: string]: any };
  data: { [key: string]: any };
  formIndex: number;
  errors: { [key: string]: string };
  fields: { [key: string]: ReactNode };
  getField: (field: string) => MediaField;
  getFieldValue: (field: string) => Value;
  getMetaValue: (meta: string) => Value;
  getError: (id: string, activeFlag?: boolean) => string;
  metas: { [key: string]: ReactNode };
  rerender: () => void;
  remove: () => void;
  reset: () => void;
  setFieldValue: (field: string, value: any) => void;
  setFieldRequired?: (field: string, required: boolean) => void;
  setMetaValue: (meta: string, value: any) => void;
  setDataValue: (key: string, value: any) => void;
  step: StepState;
  touched: boolean;
  //TODO It seems that 'fields' should contain only 'step' fields
  stepFields: Field[];
  stepMetas: Field[];
};
export type LayoutFn = (form: FormState) => ReactNode;
type OnScreenValidatables = { [key: string]: true };
export type SignalEvent = { target: Field; type: "focus" | "blur" };
type SignalEventListener = (ev: SignalEvent) => void;
type StepData = {
  sets: string[][];
  state: StepState;
};
type StepState = {
  current: number;
  isFirst: boolean;
  isLast: boolean;
};

type FormBuilderProps = {
  action?: ActionFn;
  context?: { [key: string]: any };
  data?: { [key: string]: any };
  fields: FieldSet;
  key?: string;
  layout: LayoutFn;
  metas?: FieldSet;
  steps?: string[][];
  dependant?: string[];
};
export default class FormBuilder extends Component<FormBuilderProps> {
  public isValid: boolean = false;
  public state = { canRemoveFlag: false };

  // parent form case {
  public formIndex: number = null;
  protected form: FormState = {
    activeElem: null,
    buttons: null,
    context: null,
    data: null,
    errors: null,
    fields: null,
    formIndex: null,
    getField: null,
    getFieldValue: null,
    getMetaValue: null,
    getError: null,
    metas: null,
    remove: null,
    reset: null,
    rerender: null,
    setFieldValue: null,
    setMetaValue: null,
    setDataValue: null,
    step: null,
    touched: null,
    stepFields: null,
    stepMetas: null,
  };
  protected parentFormChangeNotify = (): void => null;
  protected removeFn: () => void = null;
  //

  protected action: () => void;
  protected data: { [key: string]: any };
  protected fcontext: { [key: string]: any };
  protected fields: FieldSet;
  protected isAsyncOp: boolean = false;
  protected isSending: boolean = false;
  protected isValidStep: boolean = false;
  protected layout: LayoutFn;
  protected mapFieldsToStepIndex: { [key: string]: Field[] } = {};
  protected mapMetasToStepIndex: { [key: string]: Field[] } = {};
  protected mapStepIndexToId: { [key: string]: string } = {};
  protected metas: FieldSet; // metas are just like fields, but they aren't usually processed on the backend, more like metadata
  protected mounted = false;
  protected onScreenValidatables: OnScreenValidatables = {};
  protected static signalBlurEventListener: SignalEventListener = (): void =>
    null;
  protected static signalFocusEventListener: SignalEventListener = (): void =>
    null;
  protected steps: StepData;
  protected touched: { [key: string]: boolean } = {};

  public constructor(props: FormBuilderProps) {
    super(props);
    Field.fieldRefs = {};

    this.action = () => {
      this.isSending = true;
      props.action(
        this.getBlueprint(),

        (reset: boolean = false) => {
          this.isSending = false;
          if (reset) {
            this.reset();
          }
          this.rerender();
        },

        { data: this.data },
        (res: any) => {
          res.json().then((a: any) => {
            const errors = a.errors ?? null;
            if (errors) {
              Object.entries(errors).forEach((v: any) => {
                const msg = v[1].join(" ");
                const key = v[0];
                this.fields[key].setError(msg);
              });
            }
          });
        }
      );
    };

    this.fcontext = props.context || {};
    this.data = props.data || {};
    this.layout = props.layout || null;
    this.fields = props.fields || {};
    this.metas = props.metas || {};

    let sets: string[][] = [];
    let state: StepState;
    if (!props.steps || props.steps.length < 2) {
      const stepSet: string[] = [];
      state = null;
      Object.values(this.fields).map((field) => stepSet.push(field.id));
      Object.values(this.metas).map((meta) => stepSet.push(meta.id));
      sets.push(stepSet);
    } else {
      sets = props.steps;
      state = {
        current: 0,
        isFirst: true,
        isLast: false,
      };
    }
    this.steps = { sets, state };

    Object.values(this.fields).forEach((field) => {
      field.setFormBuilder(this);
      // map
      for (let [index, set] of Object.entries(this.steps.sets)) {
        if (set.indexOf(field.id) !== -1) {
          this.mapStepIndexToId[field.id] = index;
          if (!this.mapFieldsToStepIndex[index.toString()]) {
            this.mapFieldsToStepIndex[index.toString()] = [];
          }
          this.mapFieldsToStepIndex[index.toString()].push(field);
          break;
        }
      }
      // set change notify
      field.setChangeNotify((noTouch: boolean = false) => {
        // map it like this because there might be hanging async ops to update touched state for wrong step index
        if (!noTouch) this.touched[this.mapStepIndexToId[field.id]] = true;
        this.rerender();
      });
    });
    Object.values(this.metas).forEach((meta) => {
      meta.setFormBuilder(this);
      // map
      for (let [index, set] of Object.entries(this.steps.sets)) {
        if (set.indexOf(meta.id) !== -1) {
          this.mapStepIndexToId[meta.id] = index;
          if (!this.mapMetasToStepIndex[index.toString()]) {
            this.mapMetasToStepIndex[index.toString()] = [];
          }
          this.mapMetasToStepIndex[index.toString()].push(meta);
          break;
        }
      }
      // set change notify
      meta.setChangeNotify((noTouch: boolean = false) => {
        // map it like this because there might be hanging async ops to update touched state for wrong step index
        if (!noTouch) this.touched[this.mapStepIndexToId[meta.id]] = true;
        this.rerender();
      });
    });
  }

  public componentDidMount(): void {
    this.mounted = true;
  }

  public componentDidUpdate(prevProps: FormBuilderProps): void {
    if (prevProps.data && this.props.data && prevProps.data.subType !== this.props.data.subType) {
      if (this.fields['subType']) {
        this.fields['subType'].updateValue(this.props.data.subType);
      }
    }
  }



  public componentWillUnmount(): void {
    this.mounted = false;
  }

  public shouldComponentUpdate(
    nextProps: Readonly<FormBuilderProps>,
    nextState: Readonly<{}>,
    nextContext: any
  ): boolean {
    this.data = nextProps.data || {};
    return true;
  }

  public forceUpdate(): void {
    // do nothing
  }

  public remove(): void {
    this.removeFn();
  }

  public setFieldValue(field: string, value: any): void {
    for (let key of Object.keys(this.fields)) {
      if (key === field) {
        this.fields[key].setValue(value);
        this.rerender();
        break;
      }
    }
  }

  public setFieldRequired(field: string, required: boolean): void {
    for (let key of Object.keys(this.fields)) {
      if (key === field) {
        this.fields[key].setRequired(required);
        this.rerender();
        break;
      }
    }
  }

  public setMetaValue(meta: string, value: any): void {
    for (let key of Object.keys(this.metas)) {
      if (key === meta) {
        this.metas[key].setValue(value);
        this.rerender();
        break;
      }
    }
  }

  public setDataValue(key: string, value: any): void {
    this.data[key] = value;
    this.rerender();
  }

  public getFormState(): FormState {
    return this.form;
  }

  public reset(): void {
    Object.values(this.fields).map((field) => field.reset());
    Object.values(this.metas).map((field) => field.reset());
    this.touched = {};
  }

  public rerender(): void {
    if (this.formIndex !== null) {
      this.getValidations(this.onScreenValidatables);
      this.parentFormChangeNotify();
      return;
    }
    this.mounted && super.forceUpdate();
  }

  public getBlueprint(): Blueprint {
    const blueprint: Blueprint = { fields: {}, metas: {} };
    Object.entries(this.fields).forEach(([name, field]) => {
      if (
        field.skipFieldIfValueIsInitialFlag &&
        field.value === field.initialValue
      ) {
        return;
      }
      blueprint.fields[name] = field.value;
    });
    Object.entries(this.metas).forEach(([name, field]) => {
      if (
        field.skipFieldIfValueIsInitialFlag &&
        field.value === field.initialValue
      ) {
        return;
      }
      blueprint.metas[name] = field.value;
    });
    return blueprint;
  }

  public setFormIndex(formIndex: number) {
    this.formIndex = formIndex;
  }

  public setParentFormChangeNotify(handler: () => void) {
    this.parentFormChangeNotify = handler;
  }

  public setRemove(handler: () => void) {
    this.removeFn = handler;
  }

  public static setBlurEventListener(handler: SignalEventListener): void {
    this.signalBlurEventListener = handler;
  }

  public static setFocusEventListener(handler: SignalEventListener): void {
    this.signalFocusEventListener = handler;
  }

  // meta state sequences {
  protected getCurrentStep(): number {
    return !this.steps.state ? 0 : this.steps.state.current;
  }

  protected getOnScreenValidatables(): OnScreenValidatables {
    const onScreenValidatables = {};
    const currentStep = this.getCurrentStep().toString();
    const fields = this.mapFieldsToStepIndex[currentStep] || [];
    const metas = this.mapMetasToStepIndex[currentStep] || [];
    fields.map((field) => (onScreenValidatables[field.id] = true));
    metas.map((meta) => (onScreenValidatables[meta.id] = true));
    return onScreenValidatables;
  }

  protected getValidations(validatables: OnScreenValidatables): void {
    let isValid = true;
    let isValidStep = true;
    Object.values(this.fields).forEach((field) => {
      if (!field.isValid) isValid = false;
      if (validatables[field.id] && !field.isValid) isValidStep = false;
    });
    Object.values(this.metas).forEach((meta) => {
      if (!meta.isValid) isValid = false;
      if (validatables[meta.id] && !meta.isValid) isValidStep = false;
    });
    this.isValid = isValid;
    this.isValidStep = isValidStep;
  }

  protected getAllValidations(): void {
    // revalidate all fields and metas
    Object.values(this.fields).map((field) => field.validate(field.value));
    Object.values(this.metas).map((meta) => meta.validate(meta.value));
    this.rerender();
  }

  // button and other getters {
  protected getTouchedCurrentStep(): boolean {
    const currentStep = this.getCurrentStep().toString();
    return !!this.touched[currentStep];
  }

  protected getNext(): ButtonState {
    if (!this.steps.state) return null;
    if (this.steps.state.isLast) return null;
    // todo here: handle errors from backend (e.g. validation is ok, but it turns bad on the way to the server)
    // add re-validation after receiving error and markers for which steps have erros
    const disabled = this.isAsyncOp || !this.isValidStep;
    return {
      action: (force = false): boolean => {
        this.getValidations(this.onScreenValidatables);
        this.touched[this.getCurrentStep().toString()] = true;
        if (!this.isValidStep) {
          this.rerender();
          return;
        }

        let flag: boolean;
        switch (true) {
          case !force && disabled:
            flag = false;
            break;
          case force:
            flag = true;
            this.next();
            break;
          default:
            if (disabled) return false;
            this.next();
            flag = true;
            break;
        }
        this.rerender();
        return flag;
      },
      disabled,
    };
  }

  protected getPrev(): ButtonState {
    if (!this.steps.state) return null;
    if (!this.steps.state.isFirst) return null;
    return {
      action: (force = false): boolean => {
        this.prev();
        return true;
      },
      disabled: false,
    };
  }

  protected getSubmit(): ButtonState {
    const disabled = this.isAsyncOp || this.isSending || !this.isValid;
    return {
      action: (force = false): boolean => {
        this.getAllValidations();
        this.touched[this.getCurrentStep().toString()] = true;
        if (!this.isValid) {
          this.rerender();
          return;
        }

        let flag: boolean;
        switch (true) {
          case !force && disabled:
            flag = false;
            break;
          case force:
            this.isSending = true;
            this.action();
            flag = true;
            break;
          default:
            if (disabled) return false;
            // this.isSending = true;
            this.action();
            flag = true;
            break;
        }
        this.rerender();
        return flag;
      },
      disabled,
    };
  }

  // wizard steps navigation {
  protected next(): void {
    this.steps.state.current++;
    this.rerender();
    // getClientType() === 'ctweb' &&
    window.scrollTo({ top: 0 });
  }

  protected prev(): void {
    this.steps.state.current--;
    this.rerender();
    // getClientType() === 'ctweb' &&
    window.scrollTo({ top: 0 });
  }

  protected signalBlur(field: Field): void {
    Field.activeElem = null;
    FormBuilder.signalBlurEventListener({ target: field, type: "blur" });
    this.rerender();
  }

  protected signalFocus(field: Field): void {
    Field.activeElem = field.id;
    FormBuilder.signalFocusEventListener({ target: field, type: "focus" });
    this.rerender();
  }

  updateSubTypeValue(newSubType: any) {
    const subTypeField = this.fields['subType'];
    if (subTypeField) {
      subTypeField.updateValue(newSubType);
    }
  }

  protected provideSignal(field: Field): FieldSignal {
    return {
      blur: (): void => this.signalBlur(field),
      focus: (): void => this.signalFocus(field),
    };
  }

  public render(): ReactNode {
    this.onScreenValidatables = this.getOnScreenValidatables();
    this.getValidations(this.onScreenValidatables);
    const touched = this.getTouchedCurrentStep();
    const errors: { [key: string]: string } = {};
    const fields: { [key: string]: ReactNode } = {};
    const metas: { [key: string]: ReactNode } = {};

    const { dependant: dependantFields = [] } = this.props;
    const emptyFields = dependantFields.filter(
      (fieldName) => !this.fields[fieldName].value
    );
    if (emptyFields.length < dependantFields.length) {
      emptyFields.forEach((fieldName) => {
        if (this.fields[fieldName].flag === Field.FLAG_REQUIRED) {
          this.fields[fieldName].flag = Field.FLAG_OPTIONAL;
          this.fields[fieldName].isRequired =
            !this.fields[fieldName].isRequired;
          this.fields[fieldName].isValid = true;
        }
      });
    }

    Object.entries(this.fields).forEach(([prop, item]) => {
      errors[item.id] = touched ? item.getError() : null;
      fields[prop] = item.display(
        this.provideSignal(item),
        (key: string, value: any, rerender: boolean = false) => {
          this.data[key] = value;
          rerender && this.rerender();
        }
      );
    });
    Object.entries(this.metas).forEach(([prop, item]) => {
      errors[item.id] = touched ? item.getError() : null;
      metas[prop] = item.display(
        this.provideSignal(item),
        (key: string, value: any, rerender: boolean = false) => {
          this.data[key] = value;
          rerender && this.rerender();
        }
      );
    });

    this.form.activeElem = Field.activeElem;
    this.form.buttons = {
      next: this.getNext(),
      prev: this.getPrev(),
      submit: this.getSubmit(),
    };
    this.form.context = this.fcontext;
    this.form.data = this.data;
    this.form.errors = errors;
    this.form.fields = fields;
    this.form.formIndex = this.formIndex;
    this.form.getField = (field) =>
      this.fields[field] ? this.fields[field] : null;
    this.form.getFieldValue = (field) =>
      this.fields[field] ? this.fields[field].value : null;
    this.form.getMetaValue = (meta) =>
      this.metas[meta] ? this.metas[meta].value : null;
    this.form.getError = (id, activeFlag = false) =>
      !activeFlag || (Field.activeElem === id && errors[id])
        ? errors[id]
        : null;
    this.form.metas = metas;
    this.form.remove = this.state.canRemoveFlag ? () => this.remove() : null;
    this.form.reset = () => this.reset();
    this.form.rerender = () => this.rerender();
    this.form.setFieldValue = (field: string, value: any) =>
      this.setFieldValue(field, value);
    this.form.setFieldRequired = (field: string, required: boolean) =>
      this.setFieldRequired(field, required);
    this.form.setMetaValue = (meta: string, value: any) =>
      this.setMetaValue(meta, value);
    this.form.setDataValue = (key: string, value: any) =>
      this.setDataValue(key, value);
    this.form.step = this.steps.state;

    if (this.form.step) {
      const currentStep = this.form.step.current.toString();
      this.form.stepFields = this.mapFieldsToStepIndex[currentStep];
      this.form.stepMetas = this.mapMetasToStepIndex[currentStep];
    }

    this.form.touched = touched;
    return (
      <React.Fragment key={this.formIndex}>
        {this.layout(this.form)}

        <Dialog
          style={{ maxHeight: "820px" }}
          open={this.isSending}
          maxWidth={"xs"}
          fullWidth={true}
        >
          <DialogTitle
            color={"#12689e"}
            style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <CircularProgress />
            {trans(Label.UI__UPLOADING_CONTENT_PLEASE_WAIT)}
          </DialogTitle>
        </Dialog>

      </React.Fragment>
    );
  }
}
