import React from 'react';
import {connect} from 'react-redux';
import {StateType} from '../../../../../../../State/Store';
import Records, {Record, RecordObject} from '../../../../../../../State/Records';
import View from '../../../../../../../Layout/View';
import {trans} from '../../../../../../../Tools/LocaleHandler';
import Label from '../../../../../../Util/Label';
import Button from '../../../../../../../Layout/Components/Button';
import HttpClient from '../../../../../../../State/HttpClient';
import {Link, RouterState} from '../../../../../../../State/Router';

type RouteParams = {uuid: string};
export default connect((state: StateType, props: {rstate: RouterState}) => {
    const params = props.rstate.params;

    return {
        authObject: state.app.authObject,
        params,
        record: Records.getRecord('AssociationMembership', params.uuid),
    };
})((props: {authObject: RecordObject; record: Record; params: RouteParams}) => {
    props.record || Records.retrieve('AssociationMembership', props.params.uuid);

    if (!props.record) {
        return null;
    }

    return <div style={{paddingLeft: '4%', paddingRight: '4%'}}>
        <View style={{flexDirection: 'row', fontSize: 22, fontWeight: 'bold', justifyContent: 'space-between', marginBottom: 24, marginTop: 24}}>
            {trans(Label.ADMIN__USER_VIEW)}
            {/*<Edit onClick={() => pushRoute(`/Admin/EditUser/${props.record.uuid}`)} style={{cursor: 'pointer'}}/>*/}
        </View>

        <View style={{backgroundColor: '#fff', padding: 48, paddingLeft: 72, paddingRight: 72}}>

            <h2 style={{marginBottom: 48, textAlign: 'center'}}>{trans(Label.ADMIN__ASSOCIATION_MEMBERSHIP_VIEW_TITLE)}</h2>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ID)}</span>&nbsp;&nbsp;&nbsp;{props.record.id} : {props.record.uuid}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__FIRST_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.firstName}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__LAST_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.lastName}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ORGANISATION_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.organisationName}
            </div>

            <div style={{fontSize: 20, marginBottom: 48}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ORGANISATION_POSITION)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.organisationPosition}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                {(!!props.authObject.record.supergod || !!props.authObject.record.associationAdmin)
                    ? <React.Fragment>
                        <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__EMAIL)}</span>
                        &nbsp;&nbsp;&nbsp;
                        <Link to={`/Web/LandUser/${props.record.user.uuid}`}><span style={{color: '#0083c4', fontSize: 20}}>{props.record.user.email}</span></Link>
                    </React.Fragment>
                    : <React.Fragment>
                        <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__EMAIL)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.email}
                    </React.Fragment>
                }
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__PHONE_NUMBER)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.phoneNumber}
            </div>

            <div style={{fontSize: 20, marginBottom: 48}}>
                {(!!props.authObject.record.supergod || !!props.authObject.record.associationAdmin)
                    ? <React.Fragment>
                        <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ORGANISATION_NAME)}</span>
                        &nbsp;&nbsp;&nbsp;
                        <Link to={`/Admin/LandAssociation/${props.record.association.uuid}`}>
                            <span style={{color: '#0083c4', fontSize: 20}}>{props.record.association.name}</span>
                        </Link>
                    </React.Fragment>
                    : <React.Fragment>
                        <span style={{
                            color: '#aaa',
                            fontSize: 18
                        }}>{trans(Label.FORM__ORGANISATION_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.association.name}
                    </React.Fragment>
                }
            </div>

            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>

                {props.record.state === 'pending' && (!!props.authObject.record.supergod || (!!props.authObject.record.associationAdmin && props.authObject.record.associationAdmin.uuid === props.record.association.uuid))

                    ? <React.Fragment>
                        <Button color={'#0083c4'} onPress={() => {
                            HttpClient.action('approve', 'AssociationMembership', {data: {uuid: props.record.uuid}})
                                .then((recordBlueprint: Record) => {
                                    if (!recordBlueprint) return;
                                    Records.edit('AssociationMembership', props.record.uuid, recordBlueprint, true);
                                });
                        }} style={{marginBottom: 12}} title={trans(Label.ADMIN__ASSOCIATION_MEMBERSHIP_APPROVE)}
                        />

                        <Button color={'#0083c4'} onPress={() => {
                            HttpClient.action('reject', 'AssociationMembership', {data: {uuid: props.record.uuid}})
                                .then((recordBlueprint: Record) => {
                                    if (!recordBlueprint) return;
                                    Records.edit('AssociationMembership', props.record.uuid, recordBlueprint, true);
                                });
                        }} title={trans(Label.ADMIN__ASSOCIATION_MEMBERSHIP_REJECT)}
                        />
                    </React.Fragment>

                    : <React.Fragment>
                        <Button disabled={true} title={props.record.state}/>
                    </React.Fragment>

                }
            </div>

        </View>
    </div>;
});