import React from "react";
import { FormState } from "../../../../../../../Tools/FormBuilder/FormBuilder";
import ErrorMsg from "../../../../../../../Layout/Components/ErrorMsg";
import View from "../../../../../../../Layout/View";
import Delete from "@mui/icons-material/Delete";

const SubItemCwFormLayout = (form: FormState): React.ReactNode => {
  return (
    <View
      style={{
        backgroundColor: "#fff",
        paddingBottom: 48,
        paddingLeft: 72,
        paddingRight: 72,
        paddingTop: 16,
      }}
    >
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          marginBottom: 16,
          marginRight: -36,
        }}
      >
        {
          <Delete
            onClick={() => {
              if (!form.remove) return;
              form.remove();
            }}
            style={
              form.remove ? { cursor: "pointer", opacity: 1 } : { opacity: 0 }
            }
          />
        }
      </View>

      <ErrorMsg
        error={form.getError(
          `form-item-subItemTitle-${form.formIndex}-id`,
          true
        )}
      />
      {form.fields.title}
      <View style={{ margin: 4 }}>&nbsp;</View>

      <ErrorMsg
        error={form.getError(
          `form-item-subItemText-${form.formIndex}-id`,
          true
        )}
      />
      {form.fields.text}
    </View>
  );
};
export default SubItemCwFormLayout;
