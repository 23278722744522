import React, { CSSProperties } from "react";
import List from "./List";
import { RouterState } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";

let style: CSSProperties = {
  fontFamily: "Lato",
  color: "#0A3B60",
  display: "flex",
  flexDirection: "row",
  fontSize: 16,
  fontWeight: "bold",
  justifyContent: "space-between",
  marginBottom: 24,
  marginTop: 24,
};
const TopicsTraining = (props: { rstate: RouterState }) => (
  <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
    <div style={style}>{trans(Label.ITEM__TYPE_TRAINING)}</div>
    <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10 }}>
      <div style={{ fontSize: 14, marginLeft: 25, marginTop: 24, ...style }}>
        {trans(Label.UI__EXISTING_TOPICS)}
      </div>
      <List
        displayArea={props.rstate.params.displayArea}
        list={"adminTopicsTraining"}
      />
    </div>
  </div>
);
export default TopicsTraining;
