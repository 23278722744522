import React from 'react';
import {connect} from 'react-redux';
import {StateType} from '../../../../../State/Store';
import Records, {Record, RecordObject} from '../../../../../State/Records';
import View from '../../../../../Layout/View';
import {trans} from '../../../../../Tools/LocaleHandler';
import Label from '../../../../Util/Label';
import {Link, RouterState} from '../../../../../State/Router';

type RouteParams = {uuid: string};
export default connect((state: StateType, props: {rstate: RouterState}) => {
    const params = props.rstate.params;

    return {
        authObject: state.app.authObject,
        params,
        record: Records.getRecord('Question', params.uuid),
    };
})((props: {authObject: RecordObject; record: Record; params: RouteParams}) => {
    props.record || Records.retrieve('Question', props.params.uuid);

    if (!props.record) {
        return null;
    }

    return <div style={{paddingLeft: '4%', paddingRight: '4%'}}>
        <View style={{flexDirection: 'row', fontSize: 22, fontWeight: 'bold', justifyContent: 'space-between', marginBottom: 24, marginTop: 24}}>
            {trans(Label.ADMIN__QUESTION_VIEW)}
        </View>

        <View style={{backgroundColor: '#fff', padding: 48, paddingLeft: 72, paddingRight: 72}}>

            {/*<h2 style={{marginBottom: 48, textAlign: 'center'}}>{trans(Label.ADMIN__QUESTION_VIEW_TITLE)}</h2>*/}

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ID)}</span>&nbsp;&nbsp;&nbsp;{props.record.id} : {props.record.uuid}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__FIRST_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.firstName}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__LAST_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.lastName}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ORGANISATION_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.organisationName}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ORGANISATION_POSITION)}</span>&nbsp;&nbsp;&nbsp;{props.record.user.organisationPosition}
            </div>

            <div style={{fontSize: 20, marginBottom: 48}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__ASSOCIATION_NAME)}</span>&nbsp;&nbsp;&nbsp;{props.record.association.name}
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__EMAIL)}</span>
                &nbsp;&nbsp;&nbsp;
                <Link to={`/Web/LandUser/${props.record.user.uuid}`}>
                    <span style={{fontSize: 18, lineHeight: '22px'}}>{props.record.user.email}</span>
                </Link>
            </div>

            <div style={{fontSize: 20, marginBottom: 24}}>
                <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.FORM__QUESTION)}</span>
                <br/><br/>
                {props.record.question}
            </div>

        </View>
    </div>;
});