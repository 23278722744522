import React from "react";
import List from "./List";
import { RouterState } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";

const TopicsSecuringEmployments = (props: { rstate: RouterState }) => (
  <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
    <div
      style={{
        fontFamily: "Lato",
        color: "#0A3B60",
        display: "flex",
        flexDirection: "row",
        fontSize: 16,
        fontWeight: "bold",
        justifyContent: "space-between",
        marginBottom: 24,
        marginTop: 24,
      }}
    >
      {trans(Label.ITEM__TYPE_SECURING_EMPLOYMENT)}
    </div>
    <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10 }}>
      <div
        style={{
          fontFamily: "Lato",
          color: "#0A3B60",
          display: "flex",
          flexDirection: "row",
          fontSize: 14,
          fontWeight: "bold",
          justifyContent: "space-between",
          marginBottom: 24,
          marginTop: 24,
          marginLeft: 25,
        }}
      >
        {trans(Label.UI__EXISTING_TOPICS)}
      </div>
      <List
        displayArea={props.rstate.params.displayArea}
        list={"adminTopicsSecuringEmployment"}
      />
    </div>
  </div>
);
export default TopicsSecuringEmployments;
