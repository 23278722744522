import React from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import FormBuilder, {
  FormState,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import Toast from "../../../../../Layout/Common/Toast";
import Records, { RecordObject } from "../../../../../State/Records";
import { pushRoute, RouterState } from "../../../../../State/Router";
import HiddenField from "../../../../FormFields/HiddenField";
import ItemCwFormLayout from "../ItemForms/ItemCwFormLayout";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import Field from "../../../../../Tools/FormBuilder/Field";
import UrlField from "../../../../FormFields/UrlField";
import DateTimeField from "../../../../FormFields/DateTimeField";
import OptionsField from "../../../../FormFields/OptionsField";
import InputField from "../../../../FormFields/InputField";
import { getItemListSignature } from "../../../../Layout/BodyOpenedWeb";
import { DisplayArea } from "../../../../Root";
import ButtonsField from "../../../../FormFields/ButtonsField";
import serverValidation from "./serverValidation";
import ImageField from "../../../../FormFields/ImageField";

type RouteParams = { displayArea: DisplayArea };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;
  return {
    authObject: state.app.authObject,
    params,
  };
})((props: { authObject: RecordObject; params: RouteParams }) => {
  return (
    <FormBuilder
      key={"form-itemInstagram"}
      action={(blueprint, done) => {
        HttpClient.action("create", "Item", { blueprint }, false, serverValidation)
          .then((item) => {
            Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
            Records.addToList(
              getItemListSignature(
                blueprint.fields.type,
                blueprint.fields.subType || null,
                blueprint.fields.displayArea
              ),
              "Item",
              item
            );
            if (blueprint.fields.exclusive) {
              Records.addToList(
                getItemListSignature(
                  "adminExclusive",
                  null,
                  "tariffArea",
                  true
                ),
                "Item",
                item
              );
            }
            pushRoute(`/Admin/ItemLanding/${item.uuid}`);
          })
          .catch((ex) => {
            console.warn(ex);
          })
          .finally(() => done());
      }}
      fields={{
        // step 0
        displayArea: new HiddenField(
          "form-item-displayArea-id",
          Field.FLAG_REQUIRED,
          "memberArea"
        ),
        type: new HiddenField(
          "form-item-type-id",
          Field.FLAG_REQUIRED,
          "socialMedia"
        ),
        subType: new HiddenField(
          "form-item-subType-id",
          Field.FLAG_REQUIRED,
          "instagram"
        ),
        association: new HiddenField(
          "form-item-association-id",
          Field.FLAG_OPTIONAL,
          props.authObject.record.associationAdmin
            ? props.authObject.record.associationAdmin.uuid
            : null
        ),
        link: new UrlField("form-item-link-id", Field.FLAG_REQUIRED, null, {
          label: trans(Label.FORM__LINK),
        }),
        title: new InputField("form-item-title-id", Field.FLAG_REQUIRED, null, {
          label: trans(Label.FORM__TITLE),
          validations: ["maxChar200"],
        }),
        desc: new InputField("form-item-desc-id", Field.FLAG_REQUIRED, null, {
          label: trans(Label.FORM__DESC),
          validations: ["maxChar200"],
        }),
        cover: new ImageField("form-item-cover-id", Field.FLAG_REQUIRED, null, {
          label: trans(Label.FORM__COVER),
        }),
        // step 1
        publishState: new ButtonsField(
          "form-item-publishState-id",
          Field.FLAG_REQUIRED,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              {
                value: "published",
                label: trans(Label.ITEM__PUBLISHED),
                onSelect: (form) => {
                  form.setFieldValue("publishState", "published");
                  form.setFieldRequired("publishDate", true);
                },
                selected: true,
              },
              {
                value: "draft",
                label: trans(Label.ITEM__DRAFT),
                onSelect: (form) => {
                  form.setFieldValue("publishState", "draft");
                  form.setFieldValue("pushNotification", false);
                  form.setFieldRequired("publishDate", false);
                  form.setFieldRequired("pushNotification", false);
                  form.setFieldRequired("pushTitle", false);
                  form.setFieldRequired("pushText", false);
                },
              },
            ],
          },
          false,
          {
            padding: "8px 30px 7px",
            borderRadius: "4px",
            textTransform: "uppercase",
            marginLeft: "0px",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          }
        ),
        exclusive: new OptionsField(
          "form-item-exclusive-id",
          Field.FLAG_OPTIONAL
        ),
        publishDate: new DateTimeField(
          "form-item-publishDate-id",
          Field.FLAG_REQUIRED,
          new Date(),
          { label: trans(Label.FORM__PUBLISH_DATE) }
        ),
        pushNotification: new OptionsField(
          "form-item-pushNotification-id",
          Field.FLAG_OPTIONAL,
          true,
          null,
          (value: any, form: FormState) => {
            form.setFieldRequired && form.setFieldRequired("pushTitle", value);
            form.setFieldRequired && form.setFieldRequired("pushText", value);
          }
        ),
        pushTitle: new InputField(
          "form-item-pnTitle-id",
          Field.FLAG_REQUIRED,
          null,
          {
            label: trans(Label.FORM__TITLE),
            validations: ["maxChar60"],
          }
        ),
        pushText: new InputField(
          "form-item-pnText-id",
          Field.FLAG_REQUIRED,
          null,
          {
            label: trans(Label.FORM__TEXT),
            validations: ["maxChar120"],
          }
        ),
      }}
      layout={ItemCwFormLayout}
      steps={[
        ["form-item-title-id", "form-item-link-id", "form-item-cover-id"],
        [
          "form-item-publishDate-id",
          "form-item-pushNotification-id",
          "form-item-pnTitle-id",
          "form-item-pnText-id",
        ],
      ]}
      data={{ contentType: trans(Label.ITEM__TAG_INSTAGRAM) }}
    />
  );
});
