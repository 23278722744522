import React from 'react';
import {connect} from 'react-redux';
import FormBuilder from '../../../../../Tools/FormBuilder/FormBuilder';
import {pushRoute, RouterState} from '../../../../../State/Router';
import {trans} from '../../../../../Tools/LocaleHandler';
import Toast from '../../../../../Layout/Common/Toast';
import Field from '../../../../../Tools/FormBuilder/Field';
import Label from '../../../../Util/Label';
import {StateType} from '../../../../../State/Store';
import Records, {Record, RecordObject} from '../../../../../State/Records';
import HiddenField from '../../../../FormFields/HiddenField';
import InputField from '../../../../FormFields/InputField';
import UserFormLayout from '../../../Main/User/UserForms/UserFormLayout';
import serverValidation from '../../Item/ItemCreators/serverValidation';

type RouteParams = {uuid: string};
export default connect((state: StateType, props: {rstate: RouterState}) => {
    const params = props.rstate.params;

    return {
        authObject: state.app.authObject,
        params,
        record: Records.getRecord('User', params.uuid),
    }
})((props: {authObject: RecordObject, params: RouteParams, record: Record}): React.ReactElement => {
    props.record || Records.retrieve('User', props.params.uuid);

    if (!props.record) {
        return null; // aka loading
    }

    return <FormBuilder key={`form-user-${props.record.updated_at}`}
        action={(blueprint, done) => {
            Records.edit('User', props.record.uuid, blueprint, false, serverValidation).then(() => {
                done(true);
                Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)});
                pushRoute(`/Web/LandUser/${props.record.uuid}`);
            }).catch (ex => {
                Toast.show({type: 'warning', text: trans(ex.text || ex)});
                done();
            });
        }}
        data={{record: props.record}}
        fields={{
            uuid: new HiddenField('form-user-uuid-id', Field.FLAG_REQUIRED, props.record.uuid),
            firstName: new InputField('form-user-firstName-id', Field.FLAG_REQUIRED, props.record.firstName, {label: trans(Label.FORM__FIRST_NAME)}),
            lastName: new InputField('form-user-lastName-id', Field.FLAG_REQUIRED, props.record.lastName, {label: trans(Label.FORM__LAST_NAME)}),
            phoneNumber: new InputField('form-user-phoneNumber-id', Field.FLAG_REQUIRED, props.record.phoneNumber, {label: trans(Label.FORM__PHONE_NUMBER)}),
            organisationName: new InputField('form-user-organisationName-id', Field.FLAG_REQUIRED, props.record.organisationName, {label: trans(Label.FORM__ORGANISATION_NAME)}),
            organisationPosition: new InputField('form-user-organisationPosition-id', Field.FLAG_REQUIRED, props.record.organisationPosition, {label: trans(Label.FORM__ORGANISATION_POSITION)}),
        }}
        layout={UserFormLayout}
    />;
})