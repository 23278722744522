import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";

export default class SvgField extends Field {
  public defaultFilename: string = null;
  public defaultValue: string = null;
  public emptyFiles: FileList = null;
  public filename: string = null;
  public files: FileList = null;
  public fileReader: FileReader = new FileReader();
  public imgSrc: string = "";
  public signal: FieldSignal = null;

  public reset(): void {
    this.resetSvg();
    super.reset();
  }

  public display(signal: FieldSignal): React.ReactNode {
    this.signal = signal;
    this.skipFieldIfValueIsInitial();
    if (!this.defaultValue && this.value && typeof this.value === "string") {
      const lastIndexOfSlash = this.value.lastIndexOf("/");
      this.filename =
        lastIndexOfSlash === -1
          ? this.value
          : this.value.substr(lastIndexOfSlash + 1);
      this.imgSrc = this.value;
      this.defaultFilename = this.filename;
      this.defaultValue = this.value;
    }
    this.fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      this.imgSrc = ev.target.result.toString();
      if (document.getElementById(`svg-field-preview-${this.id}`)) {
        document.getElementById(
          `svg-field-preview-${this.id}`
        ).style.backgroundImage = `url(${this.imgSrc})`;
      }
    };
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
      ? "#ff3333"
      : "transparent";
    return (
      <div
        style={{
          borderColor: colorForError,
          boxShadow: `0px 0px 5px ${colorForError}`,
          borderWidth: 1,
          //
          backgroundColor: !this.context.bgColor
            ? "#f2f5f7"
            : this.context.bgColor,
          borderRadius: 16,
          padding: 18,
          paddingLeft: 32,
          paddingRight: 32,
        }}
      >
        <input
          id={this.id}
          name={this.context.label}
          onBlur={() => signal.blur()}
          onChange={(ev) => {
            signal.focus();

            if (!ev.target.files.length) {
              this.setNullSvg();
              return;
            }
            const file = ev.target.files[0];
            if (file.type.substr(0, 9) !== "image/svg") {
              this.setNullSvg();
              this.setError(this.trans(Field.VALIDATION_ERROR__SVG));
              this.isValid = !this.isRequired;
              return;
            }

            this.setSvg(ev.target.files);
          }}
          ref={(ref) => {
            if (!ref) return;
            Field.fieldRefs[this.id] = ref;
            if (!this.emptyFiles) this.emptyFiles = ref.files; // hold ref empty files to clear on reset / remove
            if (!this.files) return;
            ref.files = this.files;
          }}
          style={{ display: "none" }}
          type={"file"}
          accept={"image/svg+xml"}
        />

        <div style={{ display: "flex" }}>
          <button type={"button"}>
            <label htmlFor={this.id}>
              {this.filename || this.context.label}
            </label>
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          {this.defaultValue && (
            <button
              onClick={() => this.resetSvg()}
              type={"button"}
              style={{
                backgroundColor: "blue",
                borderRadius: 10,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              O
            </button>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;
          {this.filename && (
            <button
              onClick={() => this.setNullSvg()}
              type={"button"}
              style={{
                backgroundColor: "#ff3333",
                borderRadius: 10,
                color: "#fff",
                fontWeight: "bold",
              }}
            >
              X
            </button>
          )}
        </div>

        <div
          id={`svg-field-preview-${this.id}`}
          style={{
            backgroundImage: `url(${this.imgSrc})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            margin: "0 auto",
            marginTop: 16,
            paddingTop: "50%",
            width: "50%",
          }}
        >
          &nbsp;
        </div>
      </div>
    );
  }

  protected resetSvg(): void {
    if (!this.defaultValue) {
      this.setNullSvg();
      return;
    }

    this.signal.focus();
    this.files = null;
    this.filename = this.defaultFilename;
    this.imgSrc = this.defaultValue;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    this.change(this.defaultValue);
  }

  protected setNullSvg(): void {
    this.signal.focus();
    this.files = null;
    this.filename = null;
    this.imgSrc = null;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    if (document.getElementById(`svg-field-preview-${this.id}`)) {
      document.getElementById(
        `svg-field-preview-${this.id}`
      ).style.backgroundImage = "none";
    }
    this.change(null);
  }

  protected setSvg(filelist: FileList): void {
    this.signal.focus();
    this.files = filelist;
    this.filename = filelist[0].name;
    this.imgSrc = null;
    if (document.getElementById(`svg-field-preview-${this.id}`)) {
      document.getElementById(
        `svg-field-preview-${this.id}`
      ).style.backgroundImage = "none";
    }
    this.fileReader.readAsDataURL(filelist[0]);
    this.change(filelist[0]);
  }
}
