import React from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { getTransForItemType } from "../../../../../Root";
import { trans } from "../../../../../../Tools/LocaleHandler";
import { Link } from "../../../../../../State/Router";
import Records, { Record, RecordObject } from "../../../../../../State/Records";
import Label from "../../../../../Util/Label";
import Button from "../../../../../../Layout/Components/Button";
import { StateType } from "../../../../../../State/Store";
import TopPanel from "./TopPanel";
import { moveRecord, styles, Tabs } from "./Utils";
const perPage = 10;
export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
    records: Records.mapRefsToRecords(state.lists["Item:reviewable"]),
  };
})((props: { authObject: RecordObject; records: Record[] }) => {
  const [state, setState] = React.useState({
    page: 1,
  });
  const { records } = props;
  Records.list("Item", "reviewable");

  if (!props.records) {
    return null;
  }
  const pageCount = Math.ceil(records.length / perPage);
  return (
    <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          fontFamily: "Lato",
          color: "#0A3B60",
          display: "flex",
          flexDirection: "row",
          fontSize: 16,
          fontWeight: "bold",
          justifyContent: "space-between",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.ADMIN__NAV_RELEASE_CENTER_MANUAL)}
      </div>

      <TopPanel activeTab={Tabs.List}></TopPanel>

      <div style={{ backgroundColor: "#fff", padding: 15 }}>
        <div>
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{ margin: 24, width: "calc(100% - 48px)" }}
          >
            <thead>
              <tr>
                <td style={styles.th}>{trans(Label.FORM__TITLE)}</td>
                <td style={styles.th}>{trans(Label.FORM__CATEGORY)}</td>
                <td style={styles.th}>{trans(Label.FORM__PUBLISH_DATE)}</td>
                <td style={styles.th}>{trans(Label.FORM__PUBLISHER_LABEL)}</td>
                <td style={styles.th} colSpan={3}></td>
              </tr>
            </thead>
            <tbody>
              {!props.records.length && (
                <tr>
                  <td colSpan={7} style={styles.th}>
                    {trans("trans.ui.emptyList")}
                  </td>
                </tr>
              )}
              {records.length > 0 &&
                records
                  .slice(
                    (state.page - 1) * perPage,
                    (state.page - 1) * perPage + perPage
                  )
                  .map((record) => {
                    return (
                      <tr style={styles.tr} key={record.uuid}>
                        <td style={styles.td}>
                          {record.title
                            ? record.title
                            : record.desc
                            ? record.desc
                            : record.text
                            ? record.text
                            : "N/A"}
                        </td>
                        <td style={styles.td}>
                          {getTransForItemType(record.type)}
                        </td>
                        <td style={styles.td}>{record.publishDate}</td>
                        <td style={styles.td}>
                          {record.publisher ? record.publisher.name : "N/A"}
                        </td>
                        <td style={{ ...styles.td, textAlign: "right" }}>
                          <Link
                            to={`/Admin/ReviewItem/${record.uuid}`}
                            style={{ textDecoration: "none" }}
                          >
                            <Button
                              color={styles.blueButton.backgroundColor}
                              textColor={styles.blueButton.color}
                              title={trans(
                                Label.ADMIN__RELEASE_CENTER_MANUAL_REVIEW
                              )}
                            />
                          </Link>
                        </td>
                        <td style={{ ...styles.td, textAlign: "right" }}>
                          <Button
                            title={trans(Label.ADMIN__RELEASE_CENTER_REJECT)}
                            color={styles.blueButton.backgroundColor}
                            textColor={styles.blueButton.color}
                            onPress={() =>
                              moveRecord(
                                record,
                                "reject",
                                null,
                                "reject",
                                "rejected"
                              )
                            }
                          />
                        </td>
                        <td style={{ ...styles.td, textAlign: "right" }}>
                          <Button
                            title={trans(Label.ADMIN__RELEASE_CENTER_REMOVE)}
                            color={styles.redButton.backgroundColor}
                            textColor={styles.redButton.color}
                            onPress={() =>
                              moveRecord(
                                record,
                                "remove",
                                trans(Label.UI__CONFIRM_ABO_REMOVE)
                              )
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {records.length > perPage ? (
            <Pagination
              count={pageCount}
              page={state.page}
              shape="rounded"
              color="primary"
              defaultPage={1}
              boundaryCount={2}
              hideNextButton
              hidePrevButton
              sx={{ display: "flex", justifyContent: "center" }}
              onChange={(ev, page) => setState({ ...state, page })}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});
