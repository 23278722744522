import React from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { getTransForItemType } from "../../../../../Root";
import { trans } from "../../../../../../Tools/LocaleHandler";
import { Link } from "../../../../../../State/Router";
import Records, { Record, RecordObject } from "../../../../../../State/Records";
import Label from "../../../../../Util/Label";
import { StateType } from "../../../../../../State/Store";
import TopPanel from "./TopPanel";
import { moveRecord, styles, Tabs } from "./Utils";

const perPage = 10;
export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
    records: Records.mapRefsToRecords(state.lists["Item:rejected"]),
  };
})((props: { authObject: RecordObject; records: Record[] }) => {
  const [state, setState] = React.useState({
    page: 1,
  });
  const { records } = props;
  Records.list("Item", "rejected");

  if (!props.records) {
    return null;
  }
  const pageCount = Math.ceil(records.length / perPage);
  return (
    <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          fontFamily: "Lato",
          color: "#0A3B60",
          display: "flex",
          flexDirection: "row",
          fontSize: 16,
          fontWeight: "bold",
          justifyContent: "space-between",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.ADMIN__NAV_RELEASE_CENTER_MANUAL)}
      </div>

      <TopPanel activeTab={Tabs.Rejected}></TopPanel>

      <div style={{ backgroundColor: "#fff", padding: 15 }}>
        <div>
          <table
            cellSpacing={0}
            cellPadding={0}
            style={{ margin: 24, width: "calc(100% - 48px)" }}
          >
            <thead>
              <tr>
                <td style={styles.th}>{trans(Label.FORM__TITLE)}</td>
                <td style={styles.th}>{trans(Label.FORM__CATEGORY)}</td>
                <td style={styles.th}>{trans(Label.FORM__PUBLISH_DATE)}</td>
                <td style={styles.th}>{trans(Label.FORM__REJECTED_DATE)}</td>
                <td style={styles.th}></td>
              </tr>
            </thead>
            <tbody>
              {!props.records.length && (
                <tr>
                  <td colSpan={5} style={styles.th}>
                    {trans("trans.ui.emptyList")}
                  </td>
                </tr>
              )}
              {records.length > 0 &&
                records
                  .slice(
                    (state.page - 1) * perPage,
                    (state.page - 1) * perPage + perPage
                  )
                  .map((record) => {
                    return (
                      <tr style={styles.tr} key={record.uuid}>
                        <td style={styles.td}>
                          {record.title
                            ? record.title
                            : record.desc
                            ? record.desc
                            : record.text
                            ? record.text
                            : "N/A"}
                        </td>
                        <td style={styles.td}>
                          {getTransForItemType(record.type)}
                        </td>
                        <td style={styles.td}>{record.publishDate}</td>
                        <td style={styles.td}>{record.rejectedAt}</td>
                        <td style={{ ...styles.td, textAlign: "right" }}>
                          <Link to={`/Admin/ItemLanding/${record.uuid}`}>
                            <img
                              alt={"View"}
                              src={"/icons/eye_box.svg"}
                              style={styles.actionButton}
                            />
                          </Link>
                          <img
                            alt={"Edit"}
                            onClick={() =>
                              moveRecord(
                                record,
                                "restore",
                                null,
                                "restore",
                                "reviewable"
                              )
                            }
                            src={"/icons/cloud.svg"}
                            style={{
                              ...styles.actionButton,
                              border: "1.5px solid #7db1d5",
                              borderRadius: "5px",
                              padding: "4px",
                              backgroundColor: "#e1edf5",
                            }}
                          />
                          <img
                            alt={"Delete"}
                            onClick={() =>
                              moveRecord(
                                record,
                                "remove",
                                trans(Label.UI__CONFIRM)
                              )
                            }
                            src={"/icons/delete_box.svg"}
                            style={styles.actionButton}
                          />
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {records.length > perPage ? (
            <Pagination
              count={pageCount}
              page={state.page}
              shape="rounded"
              color="primary"
              defaultPage={1}
              boundaryCount={2}
              hideNextButton
              hidePrevButton
              sx={{ display: "flex", justifyContent: "center" }}
              onChange={(ev, page) => setState({ ...state, page })}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});
