import React, { useState } from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import FormBuilder, {
  FormState,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import Toast from "../../../../../Layout/Common/Toast";
import Records, { RecordObject } from "../../../../../State/Records";
import { pushRoute, RouterState } from "../../../../../State/Router";
import HiddenField from "../../../../FormFields/HiddenField";
import ItemCwFormLayout from "../ItemForms/ItemCwFormLayout";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label, { getLabelField } from "../../../../Util/Label";
import Field from "../../../../../Tools/FormBuilder/Field";
import InputField from "../../../../FormFields/InputField";
import UrlField from "../../../../FormFields/UrlField";
import ImageField from "../../../../FormFields/ImageField";
import DateTimeField from "../../../../FormFields/DateTimeField";
import OptionsField from "../../../../FormFields/OptionsField";
import SubItemCwFormLayout from "../ItemCollections/SubItem/SubItemForms/SubItemCwFormLayout";
import CollectionField from "../../../../FormFields/CollectionField";
import DocumentField from "../../../../FormFields/DocumentField";
import { DisplayArea } from "../../../../Root";
import { getItemListSignature } from "../../../../Layout/BodyOpenedWeb";
import WYSIWYGTextField from "../../../../FormFields/WYSIWYGTextField";
import ButtonsField from "../../../../FormFields/ButtonsField";
import serverValidation from "./serverValidation";
import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

type RouteParams = { displayArea: DisplayArea; itemType: string };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;
  return {
    authObject: state.app.authObject,
    params,
  };
})((props: { authObject: RecordObject; params: RouteParams }) => {
  const { itemType } = props.params;
  let translation = "";

  if (itemType.indexOf(",") !== -1) {
    translation = itemType.split(",").length + " Themen";
  } else {
    translation = getLabelField(itemType);
  }

  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        style={{
          textAlign: 'center',
        }}
      >
        <DialogTitle
          style={{
            color: 'red',
            fontSize: '2rem'
          }}
        >Fehler</DialogTitle>
        <DialogContent
          style={{
            padding: '0 100px 40px'
          }}
        >{error}</DialogContent>
        <Button onClick={() => setOpen(false)}>Close</Button>
      </Dialog>
      <FormBuilder
        key={"form-itemTopic"}
        action={(blueprint, done, data) => {
          HttpClient.action("create", "Item", { blueprint }, false)
            .then((item) => {
              Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
              Records.addToList(
                getItemListSignature(
                  blueprint.fields.type,
                  blueprint.fields.subType || null,
                  blueprint.fields.displayArea
                ),
                "Item",
                item
              );
              pushRoute(`/Admin/ItemLanding/${item.uuid}`);
            })
            .catch((error) => {
              const errorMsg = error.errors.link[0] || "Error";
              setError(errorMsg);
              setOpen(true);
            })
            .finally(() => done());
        }}
        fields={{
          // step 0
          displayArea: new HiddenField(
            "form-item-displayArea-id",
            Field.FLAG_REQUIRED,
            props.params.displayArea
          ),
          type: new HiddenField(
            "form-item-type-id",
            Field.FLAG_REQUIRED,
            props.params.itemType
          ),
          association: new HiddenField(
            "form-item-association-id",
            Field.FLAG_OPTIONAL,
            props.authObject.record.associationAdmin
              ? props.authObject.record.associationAdmin.uuid
              : null
          ),
          title: new InputField("form-item-title-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__TITLE),
            validations: ["maxChar200"],
          }),
          link: new UrlField("form-item-link-id", Field.FLAG_OPTIONAL, null, {
            label: trans(Label.FORM__LINK),
          }),
          desc: new InputField("form-item-desc-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__DESC),
            validations: ["maxChar200"],
          }),
          text: new WYSIWYGTextField(
            "form-item-text-id",
            Field.FLAG_OPTIONAL,
            null,
            { label: trans(Label.FORM__TEXT) }
          ),
          cover: new ImageField("form-item-cover-id", Field.FLAG_REQUIRED, null, {
            label: trans(Label.FORM__COVER),
          }),
          documents: new DocumentField(
            "form-item-documents-id",
            Field.FLAG_OPTIONAL,
            null,
            { label: trans(Label.FORM__DOCUMENT) }
          ),
          // step 1
          publishState: new ButtonsField(
            "form-item-publishState-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
              options: [
                {
                  value: "published",
                  label: trans(Label.ITEM__PUBLISHED),
                  onSelect: (form) => {
                    form.setFieldValue("publishState", "published");
                    form.setFieldRequired("publishDate", true);
                  },
                  selected: true,
                },
                {
                  value: "draft",
                  label: trans(Label.ITEM__DRAFT),
                  onSelect: (form) => {
                    form.setFieldValue("publishState", "draft");
                    form.setFieldValue("pushNotification", false);
                    form.setFieldRequired("publishDate", false);
                    form.setFieldRequired("pushNotification", false);
                    form.setFieldRequired("pushTitle", false);
                    form.setFieldRequired("pushText", false);
                  },
                },
              ],
            },
            false,
            {
              padding: "8px 30px 7px",
              borderRadius: "4px",
              textTransform: "uppercase",
              marginLeft: "0px",
              fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            }
          ),
          publishDate: new DateTimeField(
            "form-item-publishDate-id",
            Field.FLAG_REQUIRED,
            new Date(),
            { label: trans(Label.FORM__PUBLISH_DATE) }
          ),
          pushNotification: new OptionsField(
            "form-item-pushNotification-id",
            Field.FLAG_OPTIONAL,
            true,
            null,
            (value: any, form: FormState) => {
              form.setFieldRequired("pushTitle", value);
              form.setFieldRequired("pushText", value);
            }
          ),
          pushTitle: new InputField(
            "form-item-pnTitle-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__TITLE),
              validations: ["maxChar60"],
            }
          ),
          pushText: new InputField(
            "form-item-pnText-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__TEXT),
              validations: ["maxChar120"],
            }
          ),
        }}
        metas={{
          subItems: new CollectionField(
            "form-item-subItems-id",
            Field.FLAG_REQUIRED,
            null,
            {
              collectionFormBuilder: (
                formIndex: number,
                refFn: (ref: FormBuilder) => void
              ) => (
                <FormBuilder
                  ref={refFn}
                  key={"form-item-subItem"}
                  fields={{
                    title: new InputField(
                      `form-item-subItemTitle-${formIndex}-id`,
                      Field.FLAG_OPTIONAL,
                      null,
                      {
                        label: trans(Label.FORM__TITLE),
                        validations: ["maxChar200"],
                      }
                    ),
                    // icon: new ImageField(`form-item-subItemIcon-${formIndex}-id`, Field.FLAG_OPTIONAL, null, {label: trans(Label.FORM__ICON)}),
                    text: new WYSIWYGTextField(
                      `form-item-subItemText-${formIndex}-id`,
                      Field.FLAG_OPTIONAL,
                      null,
                      { label: trans(Label.FORM__TEXT) }
                    ),
                  }}
                  layout={SubItemCwFormLayout}
                />
              ),
            }
          ),
        }}
        layout={ItemCwFormLayout}
        steps={[
          [
            "form-item-title-id",
            "form-item-link-id",
            "form-item-desc-id",
            "form-item-text-id",
            "form-item-cover-id",
            "form-item-documents-id",
            "form-item-subItems-id",
          ],
          [
            "form-item-publishDate-id",
            "form-item-pushNotification-id",
            "form-item-pnTitle-id",
            "form-item-pnText-id",
          ],
        ]}
        data={{ contentType: trans(translation) }}
      />
    </>
  );
});
