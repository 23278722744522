import React from "react";
import ReactDOM from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import "@fontsource/lato";
import "@fontsource/roboto";
import Root from "./app/Root";
import * as serviceWorker from "./serviceWorker";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <CssBaseline />
    <Root />
  </React.StrictMode>
);
serviceWorker.unregister();
