import Typography from "@mui/material/Typography";
type DescriptionProps = {
  description: string;
};
export const Description: React.FC<DescriptionProps> = ({ description }) => {
  return (
    <Typography
      variant="body2"
      sx={{ color: "#6B7F8D", marginBottom: "0.5rem" }}
    >
      {description}
    </Typography>
  );
};
