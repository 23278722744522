import { connect } from "react-redux";
import { StateType } from "../../../../../../../State/Store";
import { asset } from "../../../../../../Util/Helper";
import FormBuilder from "../../../../../../../Tools/FormBuilder/FormBuilder";
import Toast from "../../../../../../../Layout/Common/Toast";
import { trans } from "../../../../../../../Tools/LocaleHandler";
import Field from "../../../../../../../Tools/FormBuilder/Field";
import Label from "../../../../../../Util/Label";
import GridField from "../../../../../../FormFields/GridField";
import Records, {
  Record,
  RecordObject,
} from "../../../../../../../State/Records";
import AssociationJoinFormLayout from "../JoinAssociationForms/AssociationJoinFormLayout";
import HttpClient from "../../../../../../../State/HttpClient";
import { pushRoute } from "../../../../../../../State/Router";
import OptionsField from "../../../../../../FormFields/OptionsField";

type JoinProps = {
  associationMemberships: Record[];
  associations: Record[];
  authObject: RecordObject;
};
export default connect((state: StateType) => {
  return {
    associationMemberships: Records.mapRefsToRecords(
      state.lists["AssociationMembership:myMemberships"]
    ),
    associations: Records.mapRefsToRecords(state.lists["Association:all"]),
    authObject: state.app.authObject,
  };
})((props: JoinProps) => {
  props.associationMemberships ||
    Records.list("AssociationMembership", "myMemberships");
  props.associations || Records.list("Association", "all");

  if (props.associations === null || props.associationMemberships === null) {
    return null; // aka loading
  }

  const removeAssociationUuids = props.associationMemberships.map(
    (associationMembership) => associationMembership.association.uuid
  );
  const associations = props.associations.filter(
    (association) => removeAssociationUuids.indexOf(association.uuid) > -1
  );

  return (
    <FormBuilder
      key={"form-register"}
      action={(blueprint, done) => {
        HttpClient.action("create", "AssociationMembership", { blueprint })
          .then((record: Record) => {
            const signature = [
              "User",
              "AssociationMembership",
              "user",
              props.authObject.ref.uuid,
            ].join(":");
            Records.addToCollection(signature, "AssociationMembership", record);
            Records.addToList(
              "AssociationMembership:myMemberships",
              "AssociationMembership",
              record
            );
            if (props.authObject.record.supergod) {
              Records.addToList(
                "AssociationMembership:all",
                "AssociationMembership",
                record
              );
            } else if (
              props.authObject.record.associationAdmin &&
              props.authObject.record.associationAdmin.uuid ===
                record.association.uuid
            ) {
              Records.addToList(
                "AssociationMembership:all",
                "AssociationMembership",
                record
              );
            }
            // Toast.show({type: 'info', text: trans(Label.UI__SUCCESS)});
            pushRoute(`/Web/LandUser/${props.authObject.ref.uuid}`);
            done();
          })
          .catch((ex) => {
            console.warn(ex);
            Toast.show({ type: "warning", text: trans(ex.text || ex) });
          })
          .finally(() => done());
      }}
      data={{
        associations: associations,
      }}
      fields={{
        hasTariffArea: new OptionsField(
          "form-request-tariffArea-area-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.FORM__HAS_TARIFF_AREA),
            validations: [],
          }
        ),
        association: new GridField(
          "form-register-associations-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.FORM__SELECT_ASSOCIATION),
            meta: {
              associations: associations.filter(
                (association) => association.exclusiveForTariffArea === 0
              ),
              gridImg: { uri: asset(`/Assets/Layout/MapGermany.png`) },
              gridSize: 6,
              labelAssociationSub: trans(Label.FORM__SELECT_ASSOCIATION_SUB),
            },
          }
        ),
        associationWithTariffArea: new GridField(
          "form-register-associations-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.FORM__SELECT_ASSOCIATION),
            meta: {
              associations: associations.filter(
                (association) => association.hasTariffArea === 1
              ),
              gridImg: { uri: asset(`/Assets/Layout/MapGermany.png`) },
              gridSize: 6,
              labelAssociationSub: trans(Label.FORM__SELECT_ASSOCIATION_SUB),
            },
          }
        ),
      }}
      layout={AssociationJoinFormLayout}
      steps={[
        ["form-request-tariffArea-area-id"],

        ["form-register-associations-id"],
      ]}
    />
  );
});
