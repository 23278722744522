import React from "react";
import { FormState } from "../../../../../../../Tools/FormBuilder/FormBuilder";
// import {
//   NativeScrollEvent,
//   NativeSyntheticEvent,
//   ScrollView as RnScrollView,
// } from "react-native";
// import { getClientType } from "../../../../../../Util/Helper";
import View from "../../../../../../../Layout/View";
import Button from "../../../../../../../Layout/Components/Button";
import Text from "../../../../../../../Layout/Components/Text";
import { trans } from "../../../../../../../Tools/LocaleHandler";
import Label from "../../../../../../Util/Label";
import { Record } from "../../../../../../../State/Records";
import BackButton from "../../../../../../Layout/Common/BackButton";
// import MobileHandler from "../../../../../../../Layout/MobileHandler";
import { canGoBack } from "../../../../../../../State/Router";

const AssociationJoinFormLayout = (form: FormState): React.ReactNode => {
  const associations: { [key: string]: Record } = {};
  form.data.associations.map(
    (association: Record) => (associations[association.uuid] = association)
  );
  const ScrollView: any = View; //getClientType() === "ctmobile" ? RnScrollView : View;
  const scrollProps =
    // getClientType() === "ctmobile"
    //   ? {
    //       nestedScrollEnabled: true,
    //       ref: (ref: RnScrollView) => MobileHandler.setActiveScroll(ref),
    //       onScroll: (ev: NativeSyntheticEvent<NativeScrollEvent>) =>
    //         MobileHandler.setActiveScrollPos(ev.nativeEvent.contentOffset.y),
    //       scrollEnabled: !form.data.scrollDisabled,
    //       scrollEventThrottle: 8,
    //     }
    //   :
    {};

  return (
    <ScrollView
      {...scrollProps}
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      {/* first step  */}
      {form.step.current === 0 && (
        <React.Fragment>
          <View
            style={{
              alignItems: "center",
              marginBottom: 25,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 25,
                marginTop: 25,
                width: "84%",
              }}
            >
              <View>
                {canGoBack() ? (
                  <BackButton />
                ) : (
                  <BackButton to={"/Gate/Auth/User"} />
                )}
                {(() => {
                  const message = trans(
                    Label.USER__WIZARD_STEP_FOR_TARIFF_AREA
                  );
                  if (!message) return;
                  const messageSplit = message.split(" ");
                  if (messageSplit.length === 1)
                    return (
                      <Text style={{ fontSize: 22, textAlign: "center" }}>
                        {message}
                      </Text>
                    );
                  const messageP2 = messageSplit.pop();
                  return (
                    <React.Fragment>
                      <Text style={{ fontSize: 22, textAlign: "left" }}>
                        {messageSplit.join(" ")}
                      </Text>
                      <Text
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {messageP2}
                      </Text>
                    </React.Fragment>
                  );
                })()}
              </View>
            </View>
            {form.fields.hasTariffArea}
          </View>
          <Button
            color={"#207bba"}
            large={true}
            onPress={() => form.buttons.next.action()}
            title={trans(Label.UI__NEXT)}
          />
        </React.Fragment>
      )}

      {/* second step // final */}
      {form.step.current === 1 && (
        <React.Fragment>
          <View
            style={{
              alignItems: "center",
              marginBottom: 25,
              paddingTop: 10,
            }}
          >
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
                marginBottom: 25,
                marginTop: 25,
                width: "84%",
              }}
            >
              <BackButton onPress={() => form.buttons.prev.action()} />

              <View>
                {(() => {
                  const message = trans(Label.USER__WIZARD_STEP_0_TITLE);
                  if (!message) return;
                  const messageSplit = message.split(" ");
                  if (messageSplit.length === 1)
                    return (
                      <Text style={{ fontSize: 22, textAlign: "center" }}>
                        {message}
                      </Text>
                    );
                  const messageP2 = messageSplit.pop();
                  return (
                    <React.Fragment>
                      <Text style={{ fontSize: 22, textAlign: "center" }}>
                        {messageSplit.join(" ")}
                      </Text>
                      <Text
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {messageP2}
                      </Text>
                    </React.Fragment>
                  );
                })()}
              </View>
            </View>
          </View>

          {form.getFieldValue("hasTariffArea")
            ? form.fields.associationWithTariffArea
            : form.fields.association}

          <Button
            color={"#207bba"}
            disabled={
              !(
                form.getFieldValue("association") &&
                !form.getFieldValue("hasTariffArea")
              ) &&
              !(
                form.getFieldValue("associationWithTariffArea") &&
                form.getFieldValue("hasTariffArea")
              )
            }
            large={true}
            onPress={() => form.buttons.submit.action()}
            title={trans(Label.ASSOCIATION__JOIN_SUBMIT)}
          />
        </React.Fragment>
      )}
    </ScrollView>
  );
};
export default AssociationJoinFormLayout;
