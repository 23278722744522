import React from "react";
import Field, {
  FieldContext,
  FieldFlagType,
  FieldSignal,
  Option,
} from "../../Tools/FormBuilder/Field";
import Button from "../../Layout/Components/Button";
import View from "../../Layout/View";

export default class ButtonsField extends Field {
  private multiselect: boolean;
  private additionalStyles: object;

  isSelected = (value: string) => {
    if (this.multiselect) {
      const values = this.value ? this.value.split(",") : [];

      return values.indexOf(value) !== -1;
    } else {
      return value === this.value;
    }
  };

  changeValue = (value: string, add: boolean) => {
    let values = this.value ? this.value.split(",") : [];
    const index = values.indexOf(value);

    if (index === -1 && add) {
      values.push(value);
    } else if (index !== -1 && !add) {
      values.splice(index, 1);
    }

    this.value = values.join(",");
    this.change(this.value);
  };

  public constructor(
    id: string,
    flag: FieldFlagType = Field.FLAG_OPTIONAL,
    value: any = null,
    context: FieldContext = null,
    multiselect: boolean = false,
    additionalStyles: object = {}
  ) {
    super(id, flag, value, context);
    this.multiselect = multiselect;
    this.additionalStyles = additionalStyles;

    this.context.options.forEach((o) => {
      if (o.selected) {
        this.setValue(o.value);
      }
    });
  }

  public display(signal: FieldSignal): React.ReactNode {
    let data: Option[] = this.context.options || [];

    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "left",
        }}
        className={this.multiselect ? "multiselect" : ""}
      >
        {data.map((item) => (
          <Button
            key={item.label}
            onPress={() => {
              if (this.multiselect) {
                if (this.isSelected(item.value)) {
                  this.changeValue(item.value, false);
                } else {
                  this.changeValue(item.value, true);
                }
              } else {
                this.change(item.value);
              }
            }}
            textColor={
              this.value && this.value.indexOf(item.value) !== -1
                ? "#FFFFFF"
                : "#0A3B60"
            }
            color={this.isSelected(item.value) ? "#67ADF4" : "#F5F7F8"}
            style={{
              fontFamily: "Lato",
              margin: 8,
              padding: 5,
              paddingLeft: 30,
              paddingRight: 30,
              fontSize: 14,
              fontWeight: "bold",
              textTransform: "initial",
              borderRadius: 10,
              ...this.additionalStyles,
            }}
            title={item.label}
            indicator={this.multiselect}
          ></Button>
        ))}
      </View>
    );
  }
}
