import React from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import { pushRoute, RouterState } from "../../../../../State/Router";
import View from "../../../../../Layout/View";
import { trans } from "../../../../../Tools/LocaleHandler";
import Edit from "@mui/icons-material/Edit";
import Label from "../../../../Util/Label";
import { asset } from "../../../../Util/Helper";

type RouteParams = { uuid: string };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;

  return {
    authObject: state.app.authObject,
    params,
    record: Records.getRecord("Association", params.uuid),
  };
})(
  (props: {
    authObject: RecordObject;
    record: Record;
    params: RouteParams;
  }) => {
    props.record || Records.retrieve("Association", props.params.uuid);

    if (!props.record) {
      return null;
    }
    return (
      <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
        <View
          style={{
            flexDirection: "row",
            fontSize: 22,
            fontWeight: "bold",
            justifyContent: "space-between",
            marginBottom: 24,
            marginTop: 24,
          }}
        >
          {trans(Label.ADMIN__ASSOCIATION_VIEW)}
          {(!!props.authObject.record.supergod ||
            (props.authObject.record.associationAdmin &&
              props.authObject.record.associationAdmin.uuid ===
                props.record.uuid)) && (
            <Edit
              onClick={() =>
                pushRoute(`/Admin/EditAssociation/${props.record.uuid}`)
              }
              style={{ cursor: "pointer" }}
            />
          )}
        </View>

        <View
          style={{
            backgroundColor: "#fff",
            padding: 48,
            paddingLeft: 72,
            paddingRight: 72,
          }}
        >
          <div style={{ textAlign: "center", marginBottom: 48 }}>
            <img
              alt={""}
              src={asset(props.record.logo)}
              style={{ maxWidth: 300 }}
            />
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__ID)}
            </span>
            &nbsp;&nbsp;&nbsp;{`${props.record.id} : ${props.record.uuid}`}
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__NAME)}
            </span>
            &nbsp;&nbsp;&nbsp;{props.record.name}
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__CONTACT_EMAIL)}
            </span>
            &nbsp;&nbsp;&nbsp;{props.record.contactEmail}
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__CONTACT_ADDRESS)}
            </span>
            &nbsp;&nbsp;&nbsp;{props.record.contactAddress}
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__CONTACT_PHONE)}
            </span>
            &nbsp;&nbsp;&nbsp;{props.record.contactPhone}
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__HAS_TARIFF_AREA)}
            </span>
            &nbsp;&nbsp;&nbsp;
            {!!props.record.hasTariffArea === true
              ? trans(Label.UI__YES)
              : trans(Label.UI__NO)}
          </div>

          <div style={{ fontSize: 20, marginBottom: 24 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__HAS_EXCLUSIVE_TARIFF_AREA)}
            </span>
            &nbsp;&nbsp;&nbsp;
            {!!props.record.exclusiveForTariffArea === true
              ? trans(Label.UI__YES)
              : trans(Label.UI__NO)}
          </div>

          {props.record.parentAssociation && (
            <div style={{ fontSize: 20, marginBottom: 24 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.ASSOCIATION__PARENT)}
              </span>
              &nbsp;&nbsp;&nbsp;{props.record.parentAssociation.name}
            </div>
          )}
        </View>
      </div>
    );
  }
);
