import React, { CSSProperties, SyntheticEvent } from "react";
import { PressHandler } from "../LayoutHelper";
import { Button as RmButton } from "@mui/material";
import * as RmIcons from "@mui/icons-material";

export type ButtonProps = {
  color?: string;
  disabled?: boolean;
  icon?: string; // pick from material icons name
  large?: boolean;
  onPress?: PressHandler;
  simple?: boolean;
  small?: boolean;
  style?: CSSProperties;
  textAlign?: "left" | "center" | "right";
  textColor?: string;
  textWeight?: "bold" | "normal";
  title: string;
  indicator?: boolean;
};
export default class Button extends React.Component<ButtonProps> {
  public render(): React.ReactNode {
    let {
      color,
      large,
      onPress,
      simple,
      small,
      style,
      textAlign,
      textColor,
      textWeight,
      indicator,
      ...props
    } = this.props;
    style = style || {};
    textAlign = textAlign || "center";
    textColor = textColor || "#fff";
    textWeight = textWeight || "bold";

    if (indicator) {
      style.paddingRight = "14px";
    }

    const pressQuirk: {
      web: {
        onClick?: (ev: SyntheticEvent) => void;
        onAuxClick?: (ev: SyntheticEvent) => void;
      };
    } = {
      web: {},
    };
    if (onPress) {
      pressQuirk.web.onClick = (ev: SyntheticEvent) => {
        if (!onPress || props.disabled) {
          return;
        }
        onPress({
          synthetic: ev,
        });
      };
      pressQuirk.web.onAuxClick = (ev: SyntheticEvent) => {
        if (!onPress || props.disabled) {
          return;
        }
        onPress({
          synthetic: ev,
        });
      };
    }
    if (simple) {
      return (
        <span
          {...pressQuirk.web}
          style={{
            ...style,
            color: color ? color : "#003b5d",
            cursor: "pointer",
            fontWeight: textWeight,
          }}
        >
          {props.title}
        </span>
      );
    }

    const RmIcon = props.icon
      ? (() => {
          let name = props.icon;
          name = name.charAt(0).toUpperCase() + name.slice(1);
          name = name.replace(/-([0-9a-z])/g, (g) => g[1].toUpperCase());
          const RmIcon = RmIcons[name];
          return <RmIcon style={{ color: textColor }} />;
        })()
      : null;
    return (
      <RmButton
        {...props}
        {...pressQuirk.web}
        disabled={props.disabled}
        size={large ? "large" : !small ? "medium" : "small"}
        startIcon={props.icon ? RmIcon : null}
        style={{
          ...style,
          backgroundColor: props.disabled ? "#ccc" : color,
          textAlign,
        }}
      >
        <span style={{ color: textColor, fontWeight: textWeight, textAlign }}>
          {props.title}
          {indicator && (
            <span
              style={{
                height: "8px",
                width: "8px",
                backgroundColor: "lightblue",
                display: "inline-flex",
                borderRadius: "50%",
                marginLeft: "10px",
              }}
            ></span>
          )}
        </span>
      </RmButton>
    );
  }
}
