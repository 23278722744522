import React from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import { StateType } from "../../../../../State/Store";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import { Link, pushRoute } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import Toast from "../../../../../Layout/Common/Toast";
import Button from "../../../../../Layout/Components/Button";
import { Styles } from "../../../../Layout/BodyOpenedWeb";

const perPage = 10;

let tmr: any = null;

export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
    records: Records.mapRefsToRecords(state.lists["Association:all"]),
  };
})((props: { authObject: RecordObject; records: Record[] }) => {
  props.records || Records.list("Association", "all");

  const [state, setState] = React.useState({
    page: 1,
    filters: {
      name: null,
    },
  });

  if (!props.records) {
    return null;
  }

  const styles = {
    td: {
      padding: 6,
      fontFamily: "Lato",
      fontSize: 14,
      color: "#0A3B60",
      borderBottom: "solid 3px #fff",
    },
    th: {
      borderTop: "solid 1px #ccc",
      padding: 12,
      width: "16.6%",
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: "bold" as const,
      color: "#0A3B60",
    },
    tr: {
      background: "#F5F7F8",
    },
    widget: {
      backgroundColor: "#0083c4",
      borderRadius: 4,
      cursor: "pointer",
      fontSize: 44,
      marginLeft: 4,
      marginRight: 4,
      fill: "#fff",
    },
    actionButton: {
      cursor: "pointer",
      width: 30,
      margin: 2,
    },
  };

  const records = props.records.filter((record) => {
    if (state.filters.name) {
      if (
        record.name.toLowerCase().indexOf(state.filters.name.toLowerCase()) ===
        -1
      ) {
        return false;
      }
    }
    return true;
  });
  const pageCount = Math.ceil(records.length / perPage);
  return (
    <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          fontFamily: "Lato",
          color: "#0A3B60",
          display: "flex",
          flexDirection: "row",
          fontSize: 16,
          fontWeight: "bold",
          justifyContent: "space-between",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.ADMIN__NAV_ASSOCIATION)}
      </div>
      <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10 }}>
        <Button
          color={"#0083c4"}
          title={trans(Label.FORM__CREATE)}
          style={{ marginBottom: 20 }}
          onPress={() => pushRoute("/Admin/CreateAssociation")}
        />

        <table cellSpacing={0} cellPadding={0} style={{ width: "100%" }}>
          <thead>
            <tr>
              <td style={styles.th}>{trans(Label.FORM__NAME)}</td>
              <td style={styles.th}>{trans(Label.FORM__CREATED_AT)}</td>
              <td style={styles.th}>&nbsp;</td>
            </tr>
            <tr>
              <td style={styles.th}>
                <input
                  type={"text"}
                  style={Styles.searchInput}
                  onChange={(event) => {
                    clearTimeout(tmr);
                    const value = event.target.value || null;
                    tmr = setTimeout(() => {
                      setState({
                        ...state,
                        page: 1,
                        filters: {
                          ...state.filters,
                          name: value,
                        },
                      });
                    }, 250);
                  }}
                />
              </td>
              <td style={styles.th}>&nbsp;</td>
              <td style={styles.th}>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            {!records.length && (
              <tr>
                <td colSpan={4} style={styles.th}>
                  {trans("trans.ui.emptyList")}
                </td>
              </tr>
            )}
            {records.length > 0 &&
              records
                .slice(
                  (state.page - 1) * perPage,
                  (state.page - 1) * perPage + perPage
                )
                .map((record) => {
                  return (
                    <tr style={styles.tr} key={record.uuid}>
                      <td style={styles.td}>{record.name}</td>
                      <td style={styles.td}>{record.created_at}</td>
                      <td style={{ ...styles.td, textAlign: "right" }}>
                        <Link to={`/Admin/LandAssociation/${record.uuid}`}>
                          <img
                            alt={"View"}
                            src={"/icons/eye_box.svg"}
                            style={styles.actionButton}
                          />
                        </Link>
                        <img
                          alt={"Edit"}
                          onClick={() =>
                            pushRoute(`/Admin/EditAssociation/${record.uuid}`)
                          }
                          src={"/icons/pencil_box.svg"}
                          style={styles.actionButton}
                        />
                        {!!props.authObject.record.supergod ? (
                          <img
                            alt={"Delete"}
                            onClick={() => {
                              if (window.confirm(trans(Label.UI__CONFIRM))) {
                                Records.remove(record.toRef("Association"))
                                  .then(() => {
                                    Toast.show({
                                      type: "info",
                                      text: trans(Label.UI__SUCCESS),
                                    });
                                  })
                                  .catch((ex) => {
                                    Toast.show({
                                      type: "warning",
                                      text: trans(ex.text || ex),
                                    });
                                  });
                              }
                            }}
                            src={"/icons/delete_box.svg"}
                            style={styles.actionButton}
                          />
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
        {records.length > perPage ? (
          <Pagination
            count={pageCount}
            page={state.page}
            shape="rounded"
            color="primary"
            defaultPage={1}
            boundaryCount={2}
            hideNextButton
            hidePrevButton
            sx={{ display: "flex", justifyContent: "center" }}
            onChange={(ev, page) => setState({ ...state, page })}
          />
        ) : null}
      </div>
    </div>
  );
});
