import React from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import Records, { Record } from "../../../../../State/Records";
import { pushRoute, RouterState } from "../../../../../State/Router";
import Toast from "../../../../../Layout/Common/Toast";
import HiddenField from "../../../../FormFields/HiddenField";
import InputField from "../../../../FormFields/InputField";
import SvgField from "../../../../FormFields/SvgField";
import OptionsField from "../../../../FormFields/OptionsField";
import AssociationFormLayout from "../AssociationForms/AssociationFormLayout";
import Field from "../../../../../Tools/FormBuilder/Field";
import { trans } from "../../../../../Tools/LocaleHandler";
import { asset } from "../../../../Util/Helper";
import Label from "../../../../Util/Label";
import TextField from "../../../../FormFields/TextField";
import serverValidation from "../../Item/ItemCreators/serverValidation";

type RouteParams = { uuid: string };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;
  const associationQuestionEmailsSignature = [
    "Association",
    "AssociationQuestionEmails",
    "association",
    params.uuid,
  ].join(":");
  const associationMembershipRequestEmailsSignature = [
    "Association",
    "AssociationMembershipRequestEmails",
    "association",
    params.uuid,
  ].join(":");
  const associationContentEmailsSignature = [
    "Association",
    "AssociationContentEmails",
    "association",
    params.uuid,
  ].join(":");
  return {
    params,
    questionEmails: Records.mapRefsToRecords(
      state.collections[associationQuestionEmailsSignature]
    ),
    membershipRequestEmails: Records.mapRefsToRecords(
      state.collections[associationMembershipRequestEmailsSignature]
    ),
    contentEmails: Records.mapRefsToRecords(
      state.collections[associationContentEmailsSignature]
    ),
    record: Records.getRecord("Association", params.uuid),
  };
})(
  (props: {
    params: RouteParams;
    questionEmails: Record[];
    membershipRequestEmails: Record[];
    contentEmails: Record[];
    record: Record;
  }) => {
    props.questionEmails ||
      Records.collect(
        "Association",
        "AssociationQuestionEmails",
        "association",
        props.params.uuid
      );
    props.membershipRequestEmails ||
      Records.collect(
        "Association",
        "AssociationMembershipRequestEmails",
        "association",
        props.params.uuid
      );
    props.contentEmails ||
      Records.collect(
        "Association",
        "AssociationContentEmails",
        "association",
        props.params.uuid
      );
    props.record || Records.retrieve("Association", props.params.uuid);

    if (
      !props.record ||
      !props.questionEmails ||
      !props.membershipRequestEmails ||
      !props.contentEmails
    ) {
      return null;
    }

    return (
      <FormBuilder
        action={(blueprint, done, data, callback) => {
          Records.edit(
            "Association",
            props.record.uuid,
            blueprint,
            false,
            serverValidation
          )
            .then(() => {
              done();
              pushRoute(`/Admin/LandAssociation/${props.record.uuid}`);
            })
            .catch((ex) => {
              console.warn(ex);
              Toast.show({ type: "warning", text: trans(ex.text || ex) });
              done();
            });
        }}
        data={{
          questionEmails: props.questionEmails,
          contentEmails: props.contentEmails,
          membershipRequestEmails: props.membershipRequestEmails,
          record: props.record,
        }}
        fields={{
          uuid: new HiddenField(
            "form-association-uuid-id",
            Field.FLAG_REQUIRED,
            props.record.uuid
          ),
          name: new InputField(
            "form-association-name-id",
            Field.FLAG_REQUIRED,
            props.record.name
          ),
          logo: new SvgField(
            "form-association-logo-id",
            Field.FLAG_OPTIONAL,
            asset(props.record.logo),
            { label: trans(Label.FORM__LOGO) }
          ),
          contactEmail: new InputField(
            "form-association-contactEmail-id",
            Field.FLAG_REQUIRED,
            props.record.contactEmail,
            { label: trans(Label.FORM__CONTACT_EMAIL) }
          ),
          contactAddress: new TextField(
            "form-association-contactAddress-id",
            Field.FLAG_REQUIRED,
            props.record.contactAddress,
            { label: trans(Label.FORM__CONTACT_ADDRESS) }
          ),
          contactPhone: new InputField(
            "form-association-contactPhone-id",
            Field.FLAG_REQUIRED,
            props.record.contactPhone,
            { label: trans(Label.FORM__CONTACT_PHONE) }
          ),
          publisher: new OptionsField(
            "form-association-publisher-id",
            Field.FLAG_OPTIONAL,
            props.record.publisher
          ),
          hidden: new OptionsField(
            "form-association-hidden-id",
            Field.FLAG_OPTIONAL,
            props.record.hidden
          ),
          hasTariffArea: new OptionsField(
            "form-association-hasTariffArea-id",
            Field.FLAG_OPTIONAL,
            props.record.hasTariffArea,
            { label: trans(Label.FORM__HAS_TARIFF_AREA) }
          ),
          exclusiveForTariffArea: new OptionsField(
            "form-association-hasExclusiveTariffArea-id",
            Field.FLAG_OPTIONAL,
            props.record.exclusiveForTariffArea,
            { label: trans(Label.FORM__HAS_EXCLUSIVE_TARIFF_AREA) }
          ),
          twitterAccount: new HiddenField(
            "form-association-twitter-id",
            Field.FLAG_OPTIONAL,
            props.record.twitterAccount,
            { label: trans(Label.FORM__TWITTER_ACCOUNT) }
          ),
          // twitterToken: new InputField('form-association-twitter-token-id', Field.FLAG_OPTIONAL, props.record.twitterToken, {label: trans(Label.FORM__TWITTER_ACCOUNT_TOKEN)}),
          facebookAccount: new HiddenField(
            "form-association-facebook-id",
            Field.FLAG_OPTIONAL,
            props.record.facebookAccount,
            { label: trans(Label.FORM__FACEBOOK_ACCOUNT) }
          ),
          facebookToken: new HiddenField(
            "form-association-facebook-token-id",
            Field.FLAG_OPTIONAL,
            props.record.facebookToken,
            { label: trans(Label.FORM__FACEBOOK_ACCOUNT_TOKEN) }
          ),
        }}
        layout={AssociationFormLayout}
      />
    );
  }
);
