import React from "react";
import { Provider } from "react-redux";

import Env from "../env.json";
import HttpClient from "../State/HttpClient";
import Router, { getHomeUrl, pushRoute, RouterState } from "../State/Router";
import Records, { RecordObject } from "../State/Records";
import Storage from "../State/Storage";
import { mergeDomain, store } from "../State/Store";
import Field from "../Tools/FormBuilder/Field";
import Validator from "../Tools/Validator";
import LocaleHandler, { trans, TranslationsSet } from "../Tools/LocaleHandler";
import FormBuilder, { SignalEvent } from "../Tools/FormBuilder/FormBuilder";
import BodyOpened from "./Layout/BodyOpened";
import BodyGated from "./Layout/BodyGated";
import { btoa, getClientType, setAssetDomain } from "./Util/Helper";
import Label from "./Util/Label";
import PageNotFound from "./Layout/Views/PageNotFound";
import CreateQuestion from "./ActionDomains/Main/Question/QuestionCreators/CreateQuestion";
import QuestionList from "./ActionDomains/Admin/Question/QuestionLists/QuestionList";
import LandQuestion from "./ActionDomains/Admin/Question/QuestionViews/QuestionLanding";
import JoinAssociation from "./ActionDomains/Main/Association/AssociationActionCenter/JoinAssociation/JoinAssociationViews/JoinAssociation";
import SettingsLanding from "./ActionDomains/Admin/Settings/SettingsViews/SettingsLanding";
import * as Controllers from "./index";
import KnowledgeBaseLanding from "./ActionDomains/Admin/KnowledgeBase/KnowledgeBaseViews/KnowledgeBaseLanding";

let interval: any = null;
let started = false;

export type DisplayArea = "tariffArea" | "memberArea";

const auth = (
  authObject: RecordObject,
  replaceAuthorization = false,
  callback: () => void = null
): void => {
  const authorization = btoa(JSON.stringify(authObject.ref));
  Storage.set("authorization", authorization);
  HttpClient.setHeader("authorization", authorization);

  if (replaceAuthorization) {
    mergeDomain("app", { authObject });
    return;
  }

  Records.reset();
  mergeDomain("app", { authObject });

  if (authObject && !authObject.ref.active) {
    interval = setInterval(() => {
      HttpClient.action("authGet", "User").then((authObject: RecordObject) => {
        if (authObject && authObject.ref.active) {
          clearInterval(interval);
          auth(
            new RecordObject(authObject.ref, authObject.record),
            false,
            () => {
              if (getClientType() === "ctmobile") {
                pushRoute(getHomeUrl());
              } else {
                pushRoute(`/Admin/ContentWizard`);
              }
            }
          );
        }
      });
    }, 6400);
    if (callback) {
      callback();
    }
  } else {
    HttpClient.action("countItemsMemberArea", "Item").then(
      (itemCountMemberArea) => mergeDomain("ui", { itemCountMemberArea })
    );
    HttpClient.action("countItemsTariffArea", "Item")
      .then((itemCountTariffArea) => {
        mergeDomain("ui", { itemCountTariffArea });
      })
      .catch((ex) => {
        console.warn(ex);
      })
      .finally(() => {
        if (callback) {
          callback();
        }
      });
  }
};

const deauth = (): void => {
  clearInterval(interval);
  Storage.set("authorization", null);
  HttpClient.removeHeader("authorization");
  Records.reset();
  pushRoute("/Gate/Auth/User");
};

const isAuth = (
  andActive: boolean = false,
  repository: string = null
): boolean => {
  const authObject = store.getState().app.authObject;
  if (andActive) {
    return (
      authObject &&
      authObject.ref.active &&
      (!repository || authObject.ref.repository === repository)
    );
  }
  return (
    !!authObject && (!repository || authObject.ref.repository === repository)
  );
};

const isAuthInactive = (repository: string = null): boolean => {
  const authObject = store.getState().app.authObject;
  return (
    authObject &&
    !authObject.ref.active &&
    (!repository || authObject.ref.repository === repository)
  );
};

const getAuthObject = (): RecordObject => {
  return store.getState().app.authObject;
};

const getTransForItemType = (
  itemType: string,
  displayArea: DisplayArea = "tariffArea"
) => {
  if (displayArea === "memberArea") {
    switch (itemType) {
      case "message":
        return trans(Label.ITEM__TYPE_NEWS_MEMBER_AREA);
      case "facebook":
        return trans(Label.ITEM__TYPE_FACEBOOK_MEMBER_AREA);
      case "twitter":
        return trans(Label.ITEM__TYPE_TWITTER_MEMBER_AREA);
      case "instagram":
        return trans(Label.ITEM__TYPE_INSTAGRAM_MEMBER_AREA);
      case "poll":
        return trans(Label.ITEM__TYPE_POLL);
      // topics
      case "industry":
        return trans(Label.ITEM__TYPE_INDUSTRY);
      case "corona":
        return trans(Label.ITEM__TYPE_CORONA);
      case "wagePolicy":
        return trans(Label.ITEM__TYPE_WAGE_POLICY);
      case "digitalization":
        return trans(Label.ITEM__TYPE_DIGITALIZATION);
      case "employment":
        return trans(Label.ITEM__TYPE_EMPLOYMENT);
      case "socialPolicy":
        return trans(Label.ITEM__TYPE_SOCIAL_POLICY);
      case "education":
        return trans(Label.ITEM__TYPE_EDUCATION);
      case "skilledEmployment":
        return trans(Label.ITEM__TYPE_SKILLED_EMPLOYMENT);
      case "laborPolicy":
        return trans(Label.ITEM__TYPE_LABOR_POLICY);
      case "legal":
        return trans(Label.ITEM__TYPE_LEGAL);
      case "international":
        return trans(Label.ITEM__TYPE_INTERNATIONAL);
      case "elections":
        return trans(Label.ITEM__TYPE_ELECTIONS);
      case "other":
        return trans(Label.ITEM__TYPE_OTHER);
      // events
      case "currentEvents":
        return trans(Label.ITEM__TYPE_EVENT_CURRENT);
      case "interestedEvents":
        return trans(Label.ITEM__TYPE_EVENT_INTERESTED);
      case "joinedEvents":
        return trans(Label.ITEM__TYPE_EVENT_JOINED);
      case "pastEvents":
        return trans(Label.ITEM__TYPE_EVENT_PAST);
      default:
        return trans(Label.ITEM__TYPE_ALL);
    }
  }

  switch (itemType) {
    // news
    case "exclusive":
      return trans(Label.ITEM__TYPE_EXCLUSIVE);
    case "message":
      return trans(Label.ITEM__TYPE_NEWS);
    case "socialMedia":
      return trans(Label.ITEM__TYPE_SOCIAL_MEDIA);
    case "mediaReport":
      return trans(Label.ITEM__TYPE_MEDIA_REPORT);
    case "poll":
      return trans(Label.ITEM__TYPE_POLL);
    // negotiations
    case "argument":
      return trans(Label.ITEM__TYPE_ARGUMENT);
    case "currentStatus":
      return trans(Label.ITEM__TYPE_CURRENT_STATUS);
    case "document":
      return trans(Label.ITEM__TYPE_DOCUMENT);
    case "conclusion":
      return trans(Label.ITEM__TYPE_CONCLUSION);
    case "fixture":
      return trans(Label.ITEM__TYPE_FIXTURE);
    // topics
    case "economy":
      return trans(Label.ITEM__TYPE_ECONOMY);
    case "restructuring":
      return trans(Label.ITEM__TYPE_RESTRUCTURING);
    case "training":
      return trans(Label.ITEM__TYPE_TRAINING);
    case "securingEmployment":
      return trans(Label.ITEM__TYPE_SECURING_EMPLOYMENT);
    case "workingHours":
      return trans(Label.ITEM__TYPE_WORKING_HOURS);
    case "pensions":
      return trans(Label.ITEM__TYPE_PENSIONS);
    case "participation":
      return trans(Label.ITEM__TYPE_PARTICIPATION);
    case "collectiveAgreement":
      return trans(Label.ITEM__TYPE_COLLECTIVE_AGREEMENT);
    case "other":
      return trans(Label.ITEM__TYPE_OTHER);
    default:
      return trans(Label.ITEM__TYPE_ALL);
  }
};

const getTransForItemSubType = (itemSubType: string) => {
  switch (itemSubType) {
    // news
    case "message":
      return trans(Label.ITEM__TAG_MESSAGE);
    case "link":
      return trans(Label.ITEM__TAG_LINK);
    case "graphic":
      return trans(Label.ITEM__TAG_GRAPHIC);
    case "video":
      return trans(Label.ITEM__TAG_VIDEO);
    case "quote":
      return trans(Label.ITEM__TAG_QUOTE);
    case "socialMedia":
      return trans(Label.ITEM__TYPE_SOCIAL_MEDIA);
    case "facebook":
      return trans(Label.ITEM__TYPE_FACEBOOK_MEMBER_AREA);
    case "twitter":
      return trans(Label.ITEM__TAG_TWITTER);
    case "instagram":
      return trans(Label.ITEM__TAG_INSTAGRAM);
    case "mediaReport":
      return trans(Label.ITEM__TYPE_MEDIA_REPORT);
    // negotiations
    case "argument":
      return trans(Label.ITEM__TYPE_ARGUMENT);
    case "currentStatus":
      return trans(Label.ITEM__TYPE_CURRENT_STATUS);
    case "document":
      return trans(Label.ITEM__TYPE_DOCUMENT);
    case "conclusion":
      return trans(Label.ITEM__TYPE_CONCLUSION);
    case "fixture":
      return trans(Label.ITEM__TYPE_FIXTURE);
    // topics
    case "economy":
      return trans(Label.ITEM__TYPE_ECONOMY);
    case "restructuring":
      return trans(Label.ITEM__TYPE_RESTRUCTURING);
    case "training":
      return trans(Label.ITEM__TYPE_TRAINING);
    case "securingEmployment":
      return trans(Label.ITEM__TYPE_SECURING_EMPLOYMENT);
    case "workingHours":
      return trans(Label.ITEM__TYPE_WORKING_HOURS);
    case "pensions":
      return trans(Label.ITEM__TYPE_PENSIONS);
    case "participation":
      return trans(Label.ITEM__TYPE_PARTICIPATION);
    case "collectiveAgreement":
      return trans(Label.ITEM__TYPE_COLLECTIVE_AGREEMENT);
    case "industry":
      return trans(Label.ITEM__TYPE_INDUSTRY);
    case "corona":
      return trans(Label.ITEM__TYPE_CORONA);
    case "wagePolicy":
      return trans(Label.ITEM__TYPE_WAGE_POLICY);
    case "digitalization":
      return trans(Label.ITEM__TYPE_DIGITALIZATION);
    case "employment":
      return trans(Label.ITEM__TYPE_EMPLOYMENT);
    case "socialPolicy":
      return trans(Label.ITEM__TYPE_SOCIAL_POLICY);
    case "education":
      return trans(Label.ITEM__TYPE_EDUCATION);
    case "skilledEmployment":
      return trans(Label.ITEM__TYPE_SKILLED_EMPLOYMENT);
    case "laborPolicy":
      return trans(Label.ITEM__TYPE_LABOR_POLICY);
    case "legal":
      return trans(Label.ITEM__TYPE_LEGAL);
    case "international":
      return trans(Label.ITEM__TYPE_INTERNATIONAL);
    case "elections":
      return trans(Label.ITEM__TYPE_ELECTIONS);
    case "other":
      return trans(Label.ITEM__TYPE_OTHER);
    case "linkedin":
      return trans(Label.ITEM__TAG_LINKEDIN);
    default:
      return trans(Label.ITEM__TYPE_ALL);
  }
};

class Root extends React.Component {
  public mounted = false;
  public ready = false;
  public start = () => {
    this.ready = true;
    this.forceUpdate();
  };
  //
  public onTokenCancel: any = (): void => null;
  public onNotificationCancel: any = (): void => null;

  constructor(props: {}) {
    super(props);

    if (started) {
      this.start();
      return;
    }

    started = true;

    setAssetDomain(Env.apiUrl);

    Storage.start().then(() => {
      FormBuilder.setFocusEventListener((ev: SignalEvent) => { });

      Field.setValidatorFn((method: string, value: string): boolean => {
        if (!Validator[method]) {
          console.warn(
            `Validator does not have method '${method}'. (Input value: '${value}')`
          );
          return false;
        }
        return Validator[method](value);
      });
      Field.setTransFn((label: string) => trans(label));
      Field.setValidationErrorTransFn((label: string) =>
        trans(`trans.validationError.${label}`)
      );

      const authorization = Storage.get<string>("authorization");
      authorization && HttpClient.setHeader("authorization", authorization);
      HttpClient.setHeader("x-client-type", getClientType());
      HttpClient.setApiUrl(`${Env.apiUrl}/api`);

      Promise.all([
        HttpClient.action("seed"),
        fetch(`${Env.translationsUrl}`),
      ]).then(([seed, translationsRes]) => {
        mergeDomain("app", { authObject: seed.authObject });

        Storage.set("settings", seed.settings);

        if (translationsRes.status.toString().charAt(0) === "2") {
          const init = (translations: { [key: string]: string }) => {
            LocaleHandler.init(seed.locale, translations);
            if (seed.authObject) {
              auth(
                new RecordObject(seed.authObject.ref, seed.authObject.record)
              );
            }
            this.start();
          };

          translationsRes
            .json()
            .then((translations: TranslationsSet) => {
              init(translations);
            })
            .catch(() => {
              init({});
            });
        } else {
          LocaleHandler.init(seed.locale);
          if (seed.authObject) {
            auth(new RecordObject(seed.authObject.ref, seed.authObject.record));
          }
          this.start();
        }
      });
    });
  }

  public componentDidMount(): void {
    this.mounted = true;
  }

  public componentWillUnmount(): void {
    this.mounted = false;
  }

  public forceUpdate(callback?: () => void): void {
    this.mounted && super.forceUpdate(callback);
  }

  public render(): React.ReactNode {
    if (!this.ready) {
      return null;
    }

    const rstate =
      getClientType() === "ctweb"
        ? { uri: window.location.pathname + window.location.hash }
        : Storage.get("rstate", { uri: "/" });

    const router = (
      <Router
        callback={(rstate: RouterState) => Storage.set("rstate", rstate)}
        controllers={Controllers}
        getHomeUrl={(rstate: RouterState) => {
          const authObject = store.getState().app.authObject;
          switch (true) {
            case !authObject:
              return `/Gate/Auth/User`;
            case isAuthInactive():
              return `/Gate/Auth/Opening`;
            default:
              return `/Admin/ContentWizard`;
          }
        }}
        layout={(
          component: React.ReactNode = null,
          rstate: RouterState
        ): React.ReactNode => {
          if (
            getClientType() === "ctmobile" &&
            (rstate.uri.substr(0, 6) === "/Admin" ||
              rstate.uri.substr(0, 4) === "/Web")
          ) {
            pushRoute(getHomeUrl());
            return null;
          }

          if (
            getClientType() === "ctmobile" &&
            isAuthInactive() &&
            rstate.uri !== `/Gate/Auth/Opening`
          ) {
            pushRoute(`/Gate/Auth/Opening`);
            return null;
          }

          switch (true) {
            case isAuth():
              if (rstate.uri === "/Association/Join") {
                return component;
              }
              return <BodyOpened rstate={rstate}>{component}</BodyOpened>;
            default:
              return <BodyGated rstate={rstate}>{component}</BodyGated>;
          }
        }}
        notFound={() => () => {
          if (isAuth(true)) {
            return <PageNotFound />;
          }
          pushRoute(getHomeUrl());
          return null;
        }}
        routes={{
          "/": {
            component: isAuth()
              ? Controllers.Web.LandUser
              : Controllers.Gate.Auth,
          },
          "/Admin/KnowledgeBase": {
            component: KnowledgeBaseLanding,
            condition: () => isAuth(true),
          },
          "/Admin/Settings": {
            component: SettingsLanding,
            condition: () => isAuth(true),
          },
          "/Admin/Associations": {
            component: "Admin:Associations",
            condition: () => isAuth(true),
          },
          "/Admin/AssociationMembership": {
            component: "Admin:AssociationMembership",
            condition: () => {
              // user is allwed to fetch his own association memberships
              // but in this case return 404 if he tries to browse the web app (like an admin does).
              // note: regardless of these frontend filters, permissions in backend will prevent unauthorized resource fetching.

              const authObject = getAuthObject();

              if (!authObject) {
                return false;
              }

              return (
                !!authObject.record.supergod ||
                !!authObject.record.associationAdmin
              );
            },
          },
          "/Admin/ContentWizard": {
            component: "Admin:ContentWizard",
            condition: () => {
              const authObject = getAuthObject();
              return (
                !!authObject &&
                (!!authObject.record.supergod ||
                  !!authObject.record.associationAdmin)
              );
            },
          },
          "/Admin/CreateAssociation": {
            component: "Admin:CreateAssociation",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemNegotationGroup/{displayArea}/{itemType}": {
            component: "Admin:CreateItemNegotationGroup",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemDocument/{displayArea}": {
            component: "Admin:CreateItemDocument",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemFacebook/{displayArea}": {
            component: "Admin:CreateItemFacebook",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemFixture/{displayArea}": {
            component: "Admin:CreateItemFixture",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemGraphic/{displayArea}": {
            component: "Admin:CreateItemGraphic",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemLink/{displayArea}": {
            component: "Admin:CreateItemLink",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemMediaReport/{displayArea}": {
            component: "Admin:CreateItemMediaReport",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemMessage/{displayArea}": {
            component: "Admin:CreateItemMessage",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemTopic/{displayArea}/{itemType}": {
            component: "Admin:CreateItemTopic",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemQuote/{displayArea}": {
            component: "Admin:CreateItemQuote",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemTwitter/{displayArea}": {
            component: "Admin:CreateItemTwitter",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemInstagram/{displayArea}": {
            component: "Admin:CreateItemInstagram",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemVideo/{displayArea}": {
            component: "Admin:CreateItemVideo",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemPoll/{displayArea}": {
            component: "Admin:CreateItemPoll",
            condition: () => isAuth(true),
          },
          "/Admin/CreateItemEvent/memberArea": {
            component: "Admin:CreateItemEvent",
            condition: () => isAuth(true),
          },
          "/Admin/EditAssociation/{uuid}": {
            component: "Admin:EditAssociation",
            condition: () => isAuth(true),
          },
          "/Admin/EditItem/{uuid}": {
            component: "Admin:EditItem",
            condition: () => isAuth(true),
          },
          "/Admin/EditUser/{uuid}": {
            component: "Admin:EditUser",
            condition: () => isAuth(true),
          },
          "/Admin/LandAssociation/{uuid}": {
            component: "Admin:LandAssociation",
            condition: () => isAuth(true),
          },
          "/Admin/LandAssociationMembership/{uuid}": {
            component: "Admin:LandAssociationMembership",
            condition: () => isAuth(true),
          },
          "/Admin/ItemLanding/{uuid}": {
            component: "Admin:ItemLanding",
            condition: () => isAuth(true),
          },
          "/Admin/QuestionList": {
            component: QuestionList,
            condition: () => isAuth(true),
          },
          "/Admin/LandQuestion/{uuid}": {
            component: LandQuestion,
            condition: () => isAuth(true),
          },
          "/Admin/ReleaseCenter": {
            component: "Admin:ReleaseCenter",
            condition: () => isAuth(true),
          },
          "/Admin/ReleaseCenterManual": {
            component: "Admin:ReleaseCenterManual",
            condition: () => isAuth(true),
          },
          "/Admin/ReleaseCenterRejected": {
            component: "Admin:ReleaseCenterRejected",
            condition: () => isAuth(true),
          },
          "/Admin/ReviewItem/{uuid}": {
            component: "Admin:ReviewItem",
            condition: () => isAuth(true),
          },
          "/Admin/Users": {
            component: "Admin:Users",
            condition: () => isAuth(true),
          },
          "/Items/Exclusive": {
            component: "ItemLists:Exclusive",
            condition: () => isAuth(true),
            fill: {
              displayArea: "tariffArea",
            },
          },
          "/Items/List/{displayArea}": {
            component: "ItemLists:List",
            condition: () => isAuth(true),
          },
          "/Items/NegotiationsArguments/{displayArea}": {
            component: "ItemLists:NegotiationsArguments",
            condition: () => isAuth(true),
          },
          "/Items/NegotiationsConclusions/{displayArea}": {
            component: "ItemLists:NegotiationsConclusions",
            condition: () => isAuth(true),
          },
          "/Items/NegotiationsCurrentStatus/{displayArea}": {
            component: "ItemLists:NegotiationsCurrentStatus",
            condition: () => isAuth(true),
          },
          "/Items/NegotiationsDocuments/{displayArea}": {
            component: "ItemLists:NegotiationsDocuments",
            condition: () => isAuth(true),
          },
          "/Items/NegotiationsFixtures/{displayArea}": {
            component: "ItemLists:NegotiationsFixtures",
            condition: () => isAuth(true),
          },
          "/Items/Negotiations/{displayArea}": {
            component: "ItemLists:NegotiationsArguments",
            condition: () => isAuth(true),
          },
          "/Items/NewsMediaReports/{displayArea}": {
            component: "ItemLists:NewsMediaReports",
            condition: () => isAuth(true),
          },
          "/Items/NewsMessages(/{displayArea})?": {
            component: "ItemLists:NewsMessages",
            condition: () => isAuth(true),
            fill: {
              displayArea: "tariffArea",
            },
            where: {
              displayArea: (param) =>
                ["tariffArea", "memberArea"].indexOf(param) !== -1,
            },
          },
          "/Items/NewsMessagesMemberArea(/{displayArea})?": {
            component: "ItemLists:NewsMessagesMemberArea",
            condition: () => isAuth(true),
            fill: {
              displayArea: "tariffArea",
            },
            where: {
              displayArea: (param) =>
                ["tariffArea", "memberArea"].indexOf(param) !== -1,
            },
          },
          "/Items/NewsSocialMedia/{displayArea}(/{subType})?": {
            component: "ItemLists:NewsSocialMedia",
            condition: () => isAuth(true),
          },
          "/Items/Polls/{displayArea}": {
            component: "ItemLists:Polls",
            condition: () => isAuth(true),
          },
          "/Items/Events/memberArea": {
            component: "ItemLists:Events",
            condition: () => isAuth(true),
            fill: {
              displayArea: "memberArea",
            },
          },
          "/Items/TopicsEconomy/{displayArea}": {
            component: "ItemLists:TopicsEconomy",
            condition: () => isAuth(true),
            where: {
              displayArea: (param) =>
                ["tariffArea", "memberArea"].indexOf(param) !== -1,
            },
          },
          "/Items/TopicsCollectiveAgreements/tariffArea": {
            component: "ItemLists:TopicsCollectiveAgreements",
            condition: () => isAuth(true),
            fill: {
              displayArea: "tariffArea",
            },
          },
          "/Items/TopicsParticipation/tariffArea": {
            component: "ItemLists:TopicsParticipation",
            condition: () => isAuth(true),
            fill: {
              displayArea: "tariffArea",
            },
          },
          "/Items/TopicsOther/{displayArea}": {
            component: "ItemLists:TopicsOther",
            condition: () => isAuth(true),
          },
          "/Items/TopicsPensions/{displayArea}": {
            component: "ItemLists:TopicsPensions",
            condition: () => isAuth(true),
          },
          "/Items/TopicsRestructuring/{displayArea}": {
            component: "ItemLists:TopicsRestructuring",
            condition: () => isAuth(true),
          },
          "/Items/TopicsSecuringEmployment/{displayArea}": {
            component: "ItemLists:TopicsSecuringEmployment",
            condition: () => isAuth(true),
          },
          "/Items/TopicsWorkingHours/{displayArea}": {
            component: "ItemLists:TopicsWorkingHours",
            condition: () => isAuth(true),
          },
          "/Items/TopicsTraining/{displayArea}": {
            component: "ItemLists:TopicsTraining",
            condition: () => isAuth(true),
          },
          "/Items/TopicsIndustry/{displayArea}": {
            component: "ItemLists:TopicsIndustry",
            condition: () => isAuth(true),
          },
          "/Items/TopicsCorona/{displayArea}": {
            component: "ItemLists:TopicsCorona",
            condition: () => isAuth(true),
          },
          "/Items/TopicsWagePolicy/{displayArea}": {
            component: "ItemLists:TopicsWagePolicy",
            condition: () => isAuth(true),
          },
          "/Items/TopicsDigitalization/{displayArea}": {
            component: "ItemLists:TopicsDigitalization",
            condition: () => isAuth(true),
          },
          "/Items/TopicsEmployment/{displayArea}": {
            component: "ItemLists:TopicsEmployment",
            condition: () => isAuth(true),
          },
          "/Items/TopicsSocialPolicy/{displayArea}": {
            component: "ItemLists:TopicsSocialPolicy",
            condition: () => isAuth(true),
          },
          "/Items/TopicsEducation/{displayArea}": {
            component: "ItemLists:TopicsEducation",
            condition: () => isAuth(true),
          },
          "/Items/TopicsSkilledEmployment/{displayArea}": {
            component: "ItemLists:TopicsSkilledEmployment",
            condition: () => isAuth(true),
          },
          "/Items/TopicsLaborPolicy/{displayArea}": {
            component: "ItemLists:TopicsLaborPolicy",
            condition: () => isAuth(true),
          },
          "/Items/TopicsLegal/{displayArea}": {
            component: "ItemLists:TopicsLegal",
            condition: () => isAuth(true),
          },
          "/Items/TopicsInternational/{displayArea}": {
            component: "ItemLists:TopicsInternational",
            condition: () => isAuth(true),
          },
          "/Items/TopicsElections/{displayArea}": {
            component: "ItemLists:TopicsElections",
            condition: () => isAuth(true),
          },
          "/Association/Join": {
            component: JoinAssociation,
            condition: () => isAuth(true),
          },
          "/Gate/Auth/User": {
            component: "Gate:Auth",
            condition: () => !isAuth(),
          },
          "/Gate/PasswordReset/User(/{email}/{token})?": {
            component: "Gate:PasswordReset",
            condition: () => !isAuth(),
          },
          "/Gate/Register/User": {
            component: "Gate:Register",
            condition: () => !isAuth(),
          },
          "/Item/Landing/{uuid}(/{displayArea})?": {
            component: "Item:Landing",
            condition: () => isAuth(true),
          },
          "/Item/List(/{itemType}/{displayArea}?)": {
            component: "Item:List",
          },
          "/Questions/Create": {
            component: CreateQuestion,
            condition: () => isAuth(true),
          },
          "/Web/LandUser/{uuid}": {
            component: "Web:LandUser",
            condition: (rstate) => {
              const authObject = getAuthObject();
              return (
                !!authObject &&
                (!!authObject.record.supergod ||
                  !!authObject.record.associationAdmin ||
                  authObject.ref.uuid === rstate.params.uuid)
              );
            },
          },
          "/Web/MyProfile": {
            component: "Web:LandUser",
            condition: () => isAuth(true),
            fill: {
              uuid: isAuth(true) && getAuthObject().ref.uuid,
            },
          },
        }}
        state={rstate}
      />
    );

    return <Provider store={store}>{router}</Provider>;
  }
}
export {
  auth,
  deauth,
  getAuthObject,
  getTransForItemType,
  getTransForItemSubType,
  isAuth,
  isAuthInactive,
};

export default Root;
