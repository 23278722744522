import Typography from "@mui/material/Typography/Typography";

type ViewTitleProps = {
  titleText: string;
};
export const ViewTitle: React.FC<ViewTitleProps> = ({ titleText }) => (
  <Typography
    component="h6"
    sx={{
      marginBottom: "40px",
      marginLeft: "165px",
      color: "#0A3B60",
      fontWeight: "bold",
    }}
  >
    {titleText}
  </Typography>
);
