import React from "react";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import View from "../../../../../Layout/View";
import Form from "../../../../../Layout/Components/Form";
import { trans } from "../../../../../Tools/LocaleHandler";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import Button from "../../../../../Layout/Components/Button";
import Label from "../../../../Util/Label";

const UserFormLayout = (form: FormState): React.ReactNode => {
  return (
    <Form
      onSubmit={(ev) => {
        ev.preventDefault();
        form.buttons.submit.action();
        return false;
      }}
      style={{ paddingLeft: "4%", paddingRight: "4%" }}
    >
      <View
        style={{
          fontSize: 22,
          fontWeight: "bold",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.ADMIN__USER_EDIT)}
      </View>

      <View
        style={{
          backgroundColor: "#fff",
          padding: 48,
          paddingLeft: 72,
          paddingRight: 72,
        }}
      >
        <div style={{ fontSize: 20, marginBottom: 40 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__ID)}
          </span>
          <br />
          <br />
          {form.data.record.id} : {form.data.record.uuid}
        </div>

        <div style={{ fontSize: 20, marginBottom: 40 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__EMAIL)}
          </span>
          <br />
          <br />
          {form.data.record.email}
        </div>

        <div style={{ fontSize: 20, marginBottom: 24 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__FIRST_NAME)}
          </span>
        </div>
        <ErrorMsg error={form.getError("form-user-firstName-id", true)} />
        {form.fields.firstName}
        <View style={{ margin: 16 }}>&nbsp;</View>

        <div style={{ fontSize: 20, marginBottom: 24 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__LAST_NAME)}
          </span>
        </div>
        <ErrorMsg error={form.getError("form-user-lastName-id", true)} />
        {form.fields.lastName}
        <View style={{ margin: 16 }}>&nbsp;</View>

        <div style={{ fontSize: 20, marginBottom: 24 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__PHONE_NUMBER)}
          </span>
        </div>
        <ErrorMsg error={form.getError("form-user-phoneNumber-id", true)} />
        {form.fields.phoneNumber}
        <View style={{ margin: 16 }}>&nbsp;</View>

        <div style={{ fontSize: 20, marginBottom: 24 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__ORGANISATION_NAME)}
          </span>
        </div>
        <ErrorMsg
          error={form.getError("form-user-organisationName-id", true)}
        />
        {form.fields.organisationName}
        <View style={{ margin: 16 }}>&nbsp;</View>

        <div style={{ fontSize: 20, marginBottom: 24 }}>
          <span style={{ color: "#aaa", fontSize: 18 }}>
            {trans(Label.FORM__ORGANISATION_POSITION)}
          </span>
        </div>
        <ErrorMsg
          error={form.getError("form-user-organisationPosition-id", true)}
        />
        {form.fields.organisationPosition}
        <View style={{ margin: 16 }}>&nbsp;</View>
      </View>

      <View style={{ margin: 24 }}></View>

      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Button
          color={"#4daff0"}
          disabled={form.buttons.submit.disabled && form.touched}
          large={true}
          onPress={() => form.buttons.submit.action()}
          title={trans(Label.FORM__SUBMIT)}
        />
      </View>

      <View style={{ margin: 24 }}></View>
    </Form>
  );
};
UserFormLayout.displayName = "UserFormLayout";
export default UserFormLayout;
