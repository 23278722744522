import React from "react";
import BodyOpenedWeb from "./BodyOpenedWeb";
import { RouterState } from "../../State/Router";

const BodyOpened = (props: {
  rstate: RouterState;
  children?: React.ReactNode;
}): React.ReactElement => {
  return (
    <BodyOpenedWeb rstate={props.rstate}>
      {props.children || null}
    </BodyOpenedWeb>
  );
};
export default BodyOpened;
