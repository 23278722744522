import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import Box from "@mui/material/Box/Box";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";
import { InfoBubble } from "../../Layout/Components/InfoBubble";

const imageMaxSize = 10485760;

export default class ImageField extends Field {
  public defaultFilename: string = null;
  public defaultValue: string = null;
  public emptyFiles: FileList = null;
  public filename: string = null;
  public files: FileList = null;
  public fileReader: FileReader = new FileReader();
  public imgSrc: string = "";
  public signal: FieldSignal = null;

  public reset(): void {
    this.resetImage();
    super.reset();
  }

  public display(signal: FieldSignal): React.ReactNode {
    this.signal = signal;
    this.skipFieldIfValueIsInitial();
    if (!this.defaultValue && this.value && typeof this.value === "string") {
      const lastIndexOfSlash = this.value.lastIndexOf("/");
      this.filename =
        lastIndexOfSlash === -1
          ? this.value
          : this.value.substr(lastIndexOfSlash + 1);
      this.imgSrc = this.value;
      this.defaultFilename = this.filename;
      this.defaultValue = this.value;
    }

    this.fileReader.onload = (ev: ProgressEvent<FileReader>) => {
      this.imgSrc = ev.target.result.toString();
      if (document.getElementById(`image-field-preview-${this.id}`)) {
        document.getElementById(
          `image-field-preview-${this.id}`
        ).style.backgroundImage = `url(${this.imgSrc})`;
      }
      signal.blur(); //!!!!!
    };
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
      ? "#ff3333"
      : "transparent";
    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <div
          style={{
            borderColor: colorForError,
            borderWidth: 1,
            borderStyle: "solid",
            boxShadow: `0px 0px 5px ${colorForError}`,
            flex: 1,
            //
            backgroundColor: !this.context.bgColor
              ? "#f2f5f7"
              : this.context.bgColor,
            borderRadius: 16,
            padding: 18,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <input
            id={this.id}
            name={this.context.label}
            onBlur={() => signal.blur()}
            onChange={(ev) => {
              signal.focus();

              if (!ev.target.files.length) {
                this.setNullImage();
                return;
              }
              const file = ev.target.files[0];
              if (
                file.type.substr(0, 5) !== "image" ||
                file.size > imageMaxSize
              ) {
                this.setNullImage();
                this.setError(this.trans("trans.validationError.imageMaxSize"));
                this.isValid = !this.isRequired;
                return;
              }

              this.setImage(ev.target.files);
            }}
            ref={(ref) => {
              if (!ref) return;
              Field.fieldRefs[this.id] = ref;
              if (!this.emptyFiles) this.emptyFiles = ref.files; // hold ref empty files to clear on reset / remove
              if (!this.files) return;
              ref.files = this.files;
            }}
            style={{ display: "none" }}
            type={"file"}
            accept="image/jpeg, image/png, image/bmp"
          />

          <Box sx={{ display: "flex", flex: 1 }}>
            <button type={"button"}>
              <label
                htmlFor={this.id}
                style={{
                  display: "block",
                  maxWidth: 120,
                  overflowX: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {this.filename || this.context.label}
              </label>
            </button>
            {this.defaultValue && (
              <IconButton
                color="primary"
                onClick={() => this.resetImage()}
                sx={{ padding: 0 }}
              >
                <RestoreIcon />
              </IconButton>
            )}
            {this.filename && (
              <IconButton
                color="error"
                onClick={() => this.setNullImage()}
                sx={{ padding: 0 }}
              >
                <CancelIcon />
              </IconButton>
            )}
          </Box>

          <div
            id={`image-field-preview-${this.id}`}
            style={{
              backgroundImage: `url(${this.imgSrc})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              margin: "0 auto",
              marginTop: 16,
              paddingTop: "50%",
              width: "50%",
            }}
          ></div>
        </div>{" "}
        <Box sx={{ paddingTop: "10px", position: "absolute", right: ".8rem" }}>
          <InfoBubble fieldId={this.id} />
        </Box>
      </Box>
    );
  }

  protected resetImage(): void {
    if (!this.defaultValue) {
      this.setNullImage();
      return;
    }

    this.signal.focus();
    this.files = null;
    this.filename = this.defaultFilename;
    this.imgSrc = this.defaultValue;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    this.change(this.defaultValue);
  }

  protected setNullImage(): void {
    this.signal.focus();
    this.files = null;
    this.filename = null;
    this.imgSrc = null;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    if (document.getElementById(`image-field-preview-${this.id}`)) {
      document.getElementById(
        `image-field-preview-${this.id}`
      ).style.backgroundImage = "none";
    }
    this.change(null);
  }

  protected setImage(filelist: FileList): void {
    this.signal.focus();
    this.files = filelist;
    this.filename = filelist[0].name;
    this.imgSrc = null;
    if (document.getElementById(`image-field-preview-${this.id}`)) {
      document.getElementById(
        `image-field-preview-${this.id}`
      ).style.backgroundImage = "none";
    }
    this.fileReader.readAsDataURL(filelist[0]);
    this.change(filelist[0]);
  }
}
