import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";

type AddressProps = {
  address: string;
};
export const Address: React.FC<AddressProps> = ({ address }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#1880C4",
        marginBottom: "10px",
      }}
    >
      <LocationOnIcon sx={{ fontSize: "24px", marginRight: "6px" }} />
      <span>{address}</span>
    </Box>
  );
};
