import React, { ReactNode, FormHTMLAttributes } from "react";

interface FormProps extends FormHTMLAttributes<HTMLFormElement> {
  form?: { [key: string]: any };
}
export default class Form extends React.Component<FormProps> {
  public render(): ReactNode {
    let { form, onSubmit, style, ...props } = this.props;
    if (!style) style = {};
    if (!style.display) style.display = "flex";
    if (!style.flexDirection) style.flexDirection = "column";
    return (
      <form
        onSubmit={(ev) => {
          if (onSubmit) {
            return onSubmit(ev);
          }
          if (
            form &&
            form.buttons &&
            form.buttons.submit &&
            form.buttons.submit.action &&
            typeof form.buttons.submit.action === "function"
          ) {
            ev.preventDefault();
            form.buttons.submit.action();
            return false;
          }
        }}
        style={style}
        {...props}
      >
        {this.props.children}
        <input type={"submit"} style={{ display: "none" }} />
      </form>
    );
  }
}
