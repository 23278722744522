import React from "react";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import { trans } from "../../Tools/LocaleHandler";
import Button from "../../Layout/Components/Button";
import View from "../../Layout/View";
import DatePicker from "react-datepicker";
import EventNote from "@mui/icons-material/EventNote";
import Label from "../Util/Label";
import "react-datepicker/dist/react-datepicker.css";
import { InputStylesDefinition, inputStyles } from "./InputField";
import zIndex from "@mui/material/styles/zIndex";

type InputProps = Pick<
  React.HTMLProps<HTMLInputElement>,
  "onChange" | "value" | "onClick"
>;

export default class DateTimeField extends Field {
  public display(signal: FieldSignal): React.ReactElement {
    const startDate = this.value ? this.value : new Date();
    let minDate = new Date();

    if (this.value === null) {
      this.setValue(startDate);
    }

    if ("minDate" in this.context) {
      minDate = this.context["minDate"];
    }

    const style: InputStylesDefinition = inputStyles.call(this);

    const CustomInput = React.forwardRef<HTMLInputElement, InputProps>(
      (props: InputProps, ref) => (
        <input
          type="text"
          style={style}
          onChange={props.onChange}
          onClick={props.onClick}
          ref={ref}
          value={props.value}
        />
      )
    );

    return (
      <View style={{ flexDirection: "row" }} id={this.id}>
        {!(this.context.meta === "skipNow") && (
          <React.Fragment>
            <Button
              color={"#4daff0"}
              onPress={() => this.change(new Date())}
              small={true}
              title={trans(Label.FORM__NOW)}
            />
            <View style={{ margin: 12 }}></View>
          </React.Fragment>
        )}
        <View
          style={{
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            zIndex: 10
          }}
        >
          <DatePicker
            dateFormat={"d.MM.yyyy HH:mm"}
            onChange={(ev: Date) => {
              this.change(ev);
            }}
            selected={startDate}
            showTimeSelect={true}
            timeFormat={"HH:mm"}
            minDate={minDate}
            ref={(ref) => (Field.fieldRefs[this.id] = ref)}
            customInput={<CustomInput />}

          />
          <View
            onPress={() => {
              Field.fieldRefs[this.id].setOpen(true);
            }}
            style={{
              alignItems: "center",
              border: "none",
              height: 38,
              justifyContent: "center",
              marginLeft: "-2em",
              zIndex: 1
            }}
          >
            <EventNote />
          </View>
        </View>
      </View>
    );
  }
}
