import React from "react";
import { connect } from "react-redux";
import View from "../../../../../../Layout/View";
import { trans } from "../../../../../../Tools/LocaleHandler";
import { RecordObject } from "../../../../../../State/Records";
import { StateType } from "../../../../../../State/Store";
import Label from "../../../../../Util/Label";
import { Tabs } from "./Utils";
import { Link } from "../../../../../../State/Router";

export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
  };
})((props: { authObject: RecordObject; activeTab: Tabs }) => {
  const baseStyle = {
    fontFamily: "Lato",
    fontSize: 16,
    fontWeight: "bold",
    color: "#0a3b60",
    cursor: "pointer",
  };

  const activeStyle = { backgroundColor: "#67adf4", color: "#ffffff" };

  return (
    <View
      style={{
        backgroundColor: "#F5F7F8",
        flexDirection: "row",
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
      }}
    >
      <Link
        to={"/Admin/ReleaseCenterManual"}
        style={{ textDecoration: "none", display: "flex", flex: 1 }}
      >
        <View
          style={{
            ...baseStyle,
            ...(props.activeTab === Tabs.List ? activeStyle : {}),
            flex: 1,
            padding: 16,
          }}
        >
          {trans(Label.ADMIN__RELEASE_CENTER_LIST)}
        </View>
      </Link>

      <Link
        to={"/Admin/ReleaseCenterRejected"}
        style={{ textDecoration: "none", display: "flex", flex: 1 }}
      >
        <View
          style={{
            ...baseStyle,
            ...(props.activeTab === Tabs.Rejected ? activeStyle : {}),
            flex: 1,
            padding: 16,
          }}
        >
          {trans(Label.ADMIN__RELEASE_CENTER_REJECTED_LIST)}
        </View>
      </Link>
    </View>
  );
});
