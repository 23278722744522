import React, { CSSProperties, SyntheticEvent } from "react";

// todo here: update - create a redirect method that's similar to push route, but instead of pushing a new state, replace the existing one <-- dafuq did i mean by this?

type ComponentSet = { [key: string]: { [key: string]: any } };
type GetHomeUrlFn = (rstate: RouterState) => string;
type GetNotFoundFn = () => ReactComponent;
type LayoutFn = (
  display: React.ReactNode,
  rstate: RouterState
) => React.ReactNode;
export type PushFn = (rstate: RouterState, resetScroll?: boolean) => void;
type ReactComponent = React.FunctionComponent | React.ComponentClass;

const defaultResetScrollValue = false;

const state: {
  goBack: Function;
  goForward: Function;
  getHomeUrl: GetHomeUrlFn;
  push: PushFn;
  rerender: Function;
  rstate: () => RouterState;
} = {
  goBack: (): void => null,
  goForward: (): void => null,
  getHomeUrl: (rstate: RouterState) => null,
  push: (): void => null,
  rerender: (): void => null,
  rstate: () => null,
};
let navCanGoBack: boolean = false;
let navCanGoForward: boolean = false;

// let's join history and router, and make it common for both react for web and react-native for mobile :/

type RouteSet = {
  [key: string]: {
    component: any;
    condition?: (rstate: RouterState) => boolean;
    fill?: {
      [key: string]: string;
    };
    where?: {
      [key: string]: (param: string) => boolean;
    };
  };
};
type RouterProps = {
  callback: Function;
  controllers: ComponentSet;
  notFound: GetNotFoundFn;
  state: RouterStateProps;
  getHomeUrl?: GetHomeUrlFn;
  routes?: RouteSet;
  layout?: LayoutFn;
};

class Router extends React.Component<RouterProps> {
  public callback: Function = (rstate: RouterState): void => null;
  public controllers: ComponentSet = {};
  public display: ReactComponent = null;
  public getHomeUrl: GetHomeUrlFn;
  public index: number;
  public layoutFn: LayoutFn;
  public mounted = false;
  public next: RouterState[] = [];
  public notFound: ReactComponent;
  public prev: RouterState[] = [];
  public routeSet: RouteSet;
  public rstate: RouterState;

  public constructor(props: RouterProps) {
    super(props);

    this.callback = props.callback;
    this.controllers = props.controllers;
    this.display = null;
    this.getHomeUrl = props.getHomeUrl
      ? props.getHomeUrl
      : (rstate: RouterState) => null;
    this.index = window.history.state ? window.history.state.index : 0;
    this.layoutFn = props.layout
      ? props.layout
      : (
        display: React.ReactNode = null,
        rstate: RouterState
      ): React.ReactNode => display;
    this.notFound = props.notFound();
    this.routeSet = props.routes || {};

    window.addEventListener("popstate", (ev: PopStateEvent): void => {
      if (!ev.state) return;
      ev.state.index < this.index
        ? this.jumpBack(ev.state.index, new RouterState(ev.state.rstate))
        : this.jumpForward(ev.state.index, new RouterState(ev.state.rstate));
    });

    this.rstate = new RouterState(props.state);

    state.goBack = () => this.jumpBack();
    state.goForward = () => this.jumpForward();
    state.getHomeUrl = () => this.getHomeUrl(this.rstate);
    state.push = (rstate: RouterState, resetScroll = defaultResetScrollValue) =>
      this.push(rstate, resetScroll);
    state.rerender = () => this.rerender();
    state.rstate = () => this.rstate;

    this.procNavigateAttributes();

    !window.history.state &&
      window.history.replaceState(
        {
          index: this.index,
          rstate: { uri: this.rstate.uri },
          scrollTop:
            document.body.scrollTop || document.documentElement.scrollTop || 0,
        },
        null,
        this.rstate.uri
      );
  }

  public componentDidMount(): void {
    this.mounted = true;
    this.renderRouteDisplay(this.rstate);
  }

  public componentWillUnmount(): void {
    this.mounted = false;
  }

  public jumpBack(index: number = null, rstate: RouterState = null): void {
    try {
      if (index === null) {
        switch (true) {
          case !navCanGoBack:
            return;
          case this.index > 0:
            window.history.back();
            return;
          default:
            index = this.index - 1;
        }
      }
      rstate = rstate ? rstate : this.prev[index];
      if (!rstate) {
        if (this.rstate["params"]["displayArea"] === "tariffArea") {
          state.push(
            new RouterState({ uri: `/Item/List/allNews/tariffArea` }),
            true
          );
        } else {
          state.push(
            new RouterState({ uri: `/Item/List/allNews/memberArea` }),
            true
          );
        }
        return;
      }
      this.next.unshift(this.rstate);
      this.prev = [...this.prev.slice(0, index), ...this.prev.slice(index + 1)];
      this.index = index;
      this.procNavigateAttributes();
      this.renderRouteDisplay(rstate);
    } catch (ex) {
      console.warn(ex);
    }
  }

  public jumpForward(index: number = null, rstate: RouterState = null): void {
    try {
      if (index === null) {
        switch (true) {
          case !navCanGoForward:
            return;
          case true:
            window.history.forward();
            return;
          default:
            index = this.index + 1;
        }
      }
      const indexOfNext = index - this.prev.length - 1;
      rstate = rstate ? rstate : this.next[indexOfNext];
      if (!rstate) {
        return;
      }
      this.prev.push(this.rstate);
      this.next = [
        ...this.next.slice(0, indexOfNext),
        ...this.next.slice(indexOfNext + 1),
      ];
      this.index = index;
      this.procNavigateAttributes();
      this.renderRouteDisplay(rstate);
    } catch (ex) {
      console.warn(ex);
    }
  }

  public push(
    rstate: RouterState,
    resetScroll: boolean = defaultResetScrollValue
  ): void {
    if (this.rstate && this.rstate.uri === rstate.uri) {
      !window.history.state &&
        window.history.replaceState(
          {
            index: this.index,
            rstate: { uri: this.rstate.uri },
            scrollTop:
              document.body.scrollTop ||
              document.documentElement.scrollTop ||
              0,
          },
          null,
          this.rstate.uri
        );
      return;
    }

    this.next = [];
    this.prev.push(this.rstate);
    this.index++;

    window.history.pushState(
      {
        index: this.index,
        rstate: { uri: rstate.uri },
        scrollTop:
          document.body.scrollTop || document.documentElement.scrollTop || 0,
      },
      null,
      rstate.uri
    );
    this.procNavigateAttributes();

    this.renderRouteDisplay(rstate);

    resetScroll && window.scrollTo({ top: 0 });
  }

  public rerender(): void {
    this.mounted && this.forceUpdate();
  }

  protected renderRouteDisplay(rstate: RouterState): void {
    this.display = null;
    this.rstate = rstate;

    for (let [route, data] of Object.entries(this.routeSet)) {
      if (!this.rstate.isMatch(route)) {
        continue;
      }
      this.rstate.params = this.rstate.getParams(route) || {};

      Object.entries(data.fill || {}).forEach(([key, value]) => {
        if (typeof this.rstate.params[key] !== "string") {
          this.rstate.params[key] = value;
        }
      });

      let fail = false;
      for (let [key, value] of Object.entries(data.where || {})) {
        const param = this.rstate.params[key];
        if (
          typeof param !== "undefined" &&
          param !== null &&
          typeof value === "function" &&
          value(param) === false
        ) {
          this.display = this.notFound;
          fail = true;
          break;
        }
      }
      if (
        fail ||
        (typeof data.condition === "function" && !data.condition(rstate))
      ) {
        this.display = this.notFound;
        break;
      }
      if (typeof data.component !== "string") {
        this.display = data.component;
        break;
      }

      try {
        const split = data.component.split(":");
        this.display = this.controllers[split[0]][split[1]] || this.notFound;
      } catch (ex) {
        console.warn(ex);
        this.display = this.notFound;
      }

      break;
    }

    this.display = this.display || this.notFound;
    this.rerender();
    this.callback(rstate);
  }

  protected procNavigateAttributes(): void {
    navCanGoBack = true;
    navCanGoForward = !!this.next.length;
  }

  public render(): React.ReactNode {
    if (!this.display) {
      return null;
    }
    return this.layoutFn(
      <this.display {...({ rstate: this.rstate } as any)} />,
      this.rstate
    );
  }
}

//

type LinkProps = {
  children?: any;
  style?: CSSProperties;
  textAlign?: "left" | "center" | "right";
  to: string;
};
const Link = (props: LinkProps): React.ReactElement => {
  const to = props.to.charAt(0) === "/" ? props.to : `/${props.to}`;

  let i = -1;
  const children = React.Children.toArray(props.children).map((child) =>
    typeof child === "string" ? <span key={i++}>{child}</span> : child
  );
  return (
    <a
      onClick={(ev) => {
        ev.preventDefault();
        state.push(new RouterState({ uri: to }), true);
        return false;
      }}
      href={to}
      style={{ ...props.style, cursor: "pointer" }}
    >
      {children}
    </a>
  );
};

export type RouterStateProps = { uri: string; params?: any };

class RouterState {
  public params: any;
  public uri: string;

  public constructor(rstate: RouterStateProps) {
    this.uri = rstate.uri;
    this.params = rstate.params || null;
  }

  public isMatch(uriScheme: string): boolean {
    let argMatches: RegExpMatchArray;
    try {
      argMatches = uriScheme.match(/{[^({}).]+}/g);
    } catch (ex) {
      return false;
    }
    const args = argMatches
      ? argMatches.map((uriScheme) => uriScheme.replace(/[{}]/g, ""))
      : [];

    let regex = `^${uriScheme}$`;
    args.forEach((arg) => {
      // this below is cool. route example: "/Items/NewsMessages(/{displayArea})?(/{remainingUri})??"
      // this will result in params like: {displayArea: "tariffAreaOrWhatever", remainingUri: "lang/en/exclusive/true/any/other/params"}
      // so "??" in the end can put everything else in a param
      // similar to the "exact=true/false" strategy on classic react routers
      regex = regex.replace(`(/{${arg}})??`, "(/(.+))?");
      regex = regex.replace(`(/{${arg}})?`, "(/([^/]+))?");
      regex = regex.replace(`{${arg}}`, "([^/]+)");
    });

    let match: RegExpMatchArray;
    try {
      match = this.uri.match(regex);
    } catch (ex) {
      return false;
    }
    return !!match;
  }

  public getParams<R>(uriScheme: string): R {
    let argMatches: RegExpMatchArray;
    try {
      argMatches = uriScheme.match(/{[^({}).]+}/g);
    } catch (ex) {
      return {} as R;
    }
    const args = argMatches
      ? argMatches.map((uriScheme) => uriScheme.replace(/[{}]/g, ""))
      : [];

    let regex = `^${uriScheme}$`;
    args.forEach((arg) => {
      regex = regex.replace(`(/{${arg}})??`, "(/(.+))?");
      regex = regex.replace(`(/{${arg}})?`, "(/([^/]+))?");
      regex = regex.replace(`{${arg}}`, "([^/]+)");
    });

    const params = {};

    let index = -1;
    const result: string[] = [];
    let match: RegExpMatchArray;
    try {
      match = this.uri.match(regex);
    } catch (ex) {
      return {} as R;
    }
    match &&
      match.forEach((match) => {
        index++;
        if (index > 0 && match && match[0] !== "/") {
          result.push(match);
        }
      });

    index = -1;
    for (let item of args) {
      index++;
      params[item] = result && result[index] ? result[index] : null;
    }

    return params as R;
  }

  public goBack(): void {
    state.goBack();
  }

  public goForward(): void {
    state.goForward();
  }

  public rerender(): void {
    state.rerender();
  }
}

const BackButton = (props: {
  backgroundColor?: string;
  color?: string;
  component: React.ReactNode;
  onPress?: (ev: SyntheticEvent) => void;
  to?: string;
}): React.ReactElement => {
  const to = props.to
    ? props.to.charAt(0) === "/"
      ? props.to
      : `/${props.to}`
    : null;

  return (
    <div
      style={{
        alignItems: "center",
        backgroundColor: props.backgroundColor
          ? props.backgroundColor
          : "transparent",
        cursor: "pointer",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: 6,
        width: 40,
      }}
      onClick={(ev: SyntheticEvent) => {
        to
          ? state.push(new RouterState({ uri: to }), true)
          : props.onPress
            ? props.onPress(ev)
            : state.goBack();
      }}
    >
      {props.component}
    </div>
  );
};

const canGoBack = () => navCanGoBack;
const canGoForward = () => navCanGoForward;
const getHomeUrl = () => state.getHomeUrl(state.rstate());
const getRouteParams = () => state.rstate().params;
const pushRoute = (uri: string) => state.push(new RouterState({ uri }), true);
const rerender = () => state.rerender();

export default Router;
export {
  BackButton,
  canGoBack,
  canGoForward,
  getHomeUrl,
  getRouteParams,
  Link,
  pushRoute,
  rerender,
  RouterState,
};
