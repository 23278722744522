import Box from "@mui/material/Box";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
export const BluePlayButton: React.ReactElement = (
  <Box
    sx={{
      fontSize: "48px",
      color: "white",
      backgroundColor: "#207BBA",
      width: "48px",
      height: "48px",
      borderRadius: "48px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <PlayArrowIcon />
  </Box>
);
