import React, { SyntheticEvent } from "react";

export default class SvgUri extends React.Component<{
  [key: string]: any;
  uri: string;
  height: string | number;
  width: string | number;
}> {
  public render(): React.ReactElement {
    const { props } = this.props;
    const pressQuirk: {
      web: { onClick?: (ev: SyntheticEvent) => void };
    } = {
      web: {},
    };
    return (
      <img
        {...pressQuirk.web}
        alt={""}
        src={props.uri}
        height={props.height}
        width={props.width}
      />
    );
  }
}
