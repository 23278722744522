import React from "react";
import { Record } from "../../State/Records";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import SwitchButton from "../../Layout/Components/SwitchButton";

export default class GridField extends Field {
  protected selectedAssociationWithChildren: string = null;

  public display(signal: FieldSignal, setData: Function): React.ReactNode {
    const associations: { [key: string]: Record } = {};
    const associationsWithChildren: { [key: string]: string[] } = {};
    const switchButtonRefs: { [key: string]: SwitchButton } = {};
    this.context.meta.associations.forEach((association: Record) => {
      associations[association.uuid] = association;
      if (association._gridParent) {
        if (!associationsWithChildren[association._gridParent])
          associationsWithChildren[association._gridParent] = [];
        associationsWithChildren[association._gridParent].push(
          association.uuid
        );
      }
    });

    const associationsWithChildrenKeys = Object.keys(associationsWithChildren);
    return Object.entries(associations).map(
      ([uuid, association]) =>
        associationsWithChildrenKeys.indexOf(association.uuid) === -1 && (
          <React.Fragment key={uuid}>
            <div
              style={{
                alignItems: "center",
                display: "flex",
                flexDirection: "row",
                fontWeight: "bold",
                marginBottom: 16,
                marginTop: 16,
                paddingLeft: "8%",
                paddingRight: "8%",
              }}
            >
              <SwitchButton
                onValueChange={(value: boolean) => {
                  if (!value) {
                    this.change("");
                    return;
                  }
                  Object.entries(switchButtonRefs).forEach(([uuid_, ref]) => {
                    if (uuid === uuid_ || !ref) return;
                    ref.setState({ value: false });
                  });
                  this.change(uuid);
                }}
                ref={(ref) => ref && (switchButtonRefs[association.uuid] = ref)}
              />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {association._gridParent &&
                `${associations[association._gridParent].name} - `}
              &nbsp;
              {association.name}
            </div>
          </React.Fragment>
        )
    );
  }
}
