import React from "react";
import { connect } from "react-redux";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { StateType } from "../../../../../../../State/Store";
import Records, {
  Record,
  RecordObject,
} from "../../../../../../../State/Records";
import HttpClient from "../../../../../../../State/HttpClient";
import { pushRoute } from "../../../../../../../State/Router";
import { trans } from "../../../../../../../Tools/LocaleHandler";
import Label from "../../../../../../Util/Label";
import { Styles } from "../../../../../../Layout/BodyOpenedWeb";
import Button from "../../../../../../../Layout/Components/Button";

const perPage = 10;

let tmr: any = null;

export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
    records: Records.mapRefsToRecords(state.lists["AssociationMembership:all"]),
  };
})((props: { authObject: RecordObject; records: Record[] }) => {
  props.records || Records.list("AssociationMembership", "all");

  const [state, setState] = React.useState({
    page: 1,
    filters: {
      associationName: null,
      firstName: null,
      lastName: null,
      organisationName: null,
      state: null,
      hasTariffArea: null,
    },
  });

  if (!props.records) {
    return null;
  }

  const styles = {
    td: {
      padding: 6,
      fontFamily: "Lato",
      fontSize: 14,
      color: "#0A3B60",
      borderBottom: "solid 3px #fff",
    },
    th: {
      borderTop: "solid 1px #ccc",
      borderBottom: "solid 1px #ccc",
      padding: 12,
      width: "16.6%",
      fontFamily: "Lato",
      fontSize: 14,
      fontWeight: "bold" as const,
      color: "#0A3B60",
    },
    widget: {
      borderRadius: 4,
      cursor: "pointer",
      fontSize: 44,
      marginLeft: 4,
      marginRight: 4,
      fill: "#fff",
    },
    actionButton: {
      cursor: "pointer",
      width: 30,
      margin: 2,
      borderRadius: 3,
    },
  };

  const records = props.records.filter((record) => {
    if (state.filters.associationName) {
      if (
        record.association.name
          .toLowerCase()
          .indexOf(state.filters.associationName.toLowerCase()) === -1
      ) {
        return false;
      }
    }
    if (state.filters.firstName) {
      if (
        record.user.firstName
          .toLowerCase()
          .indexOf(state.filters.firstName.toLowerCase()) === -1
      ) {
        return false;
      }
    }
    if (state.filters.lastName) {
      if (
        record.user.lastName
          .toLowerCase()
          .indexOf(state.filters.lastName.toLowerCase()) === -1
      ) {
        return false;
      }
    }
    if (state.filters.organisationName) {
      if (
        record.user.organisationName
          .toLowerCase()
          .indexOf(state.filters.organisationName.toLowerCase()) === -1
      ) {
        return false;
      }
    }
    if (state.filters.state) {
      if (record.state !== state.filters.state) {
        return false;
      }
    }

    if (state.filters.hasTariffArea) {
      if (record.hasTariffArea !== state.filters.hasTariffArea) {
        return false;
      }
    }
    return true;
  });
  const pageCount = Math.ceil(records.length / perPage);
  return (
    <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <div
        style={{
          fontFamily: "Lato",
          color: "#0A3B60",
          display: "flex",
          flexDirection: "row",
          fontSize: 16,
          fontWeight: "bold",
          justifyContent: "space-between",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {trans(Label.ADMIN__NAV_ASSOCIATION_MEMBERSHIP)}
      </div>
      <div style={{ backgroundColor: "#fff", padding: 15, borderRadius: 10 }}>
        <div>
          <table
            cellPadding={0}
            cellSpacing={0}
            style={{ margin: 24, width: "calc(100% - 48px)" }}
          >
            <thead>
              <tr>
                <td style={styles.th}>{trans(Label.UI__ASSOCIATION)}</td>
                <td style={styles.th}>{trans(Label.UI__TIMESTAMP)}</td>
                <td style={styles.th}>{trans(Label.UI__FIRST_NAME)}</td>
                <td style={styles.th}>{trans(Label.UI__LAST_NAME)}</td>
                <td style={{ ...styles.th, width: "10.6%" }}>
                  {trans(Label.UI__COMPANY)}
                </td>
                <td style={{ ...styles.th, width: "10%" }}>
                  {trans(Label.UI__STATUS)}
                </td>
                <td style={{ ...styles.th, width: "10%" }}>
                  {trans(Label.UI__REQUESTED_TARIFF_AREA_ACCESS)}
                </td>
                <td style={{ ...styles.th, width: "29.2%" }}>&nbsp;</td>
              </tr>
              <tr>
                <td style={styles.th}>
                  <input
                    type={"text"}
                    style={Styles.searchInput}
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || null;
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          page: 1,
                          filters: {
                            ...state.filters,
                            associationName: value,
                          },
                        });
                      }, 250);
                    }}
                  />
                </td>
                <td style={styles.th}>&nbsp;</td>
                <td style={styles.th}>
                  <input
                    type={"text"}
                    style={Styles.searchInput}
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || null;
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          page: 1,
                          filters: {
                            ...state.filters,
                            firstName: value,
                          },
                        });
                      }, 250);
                    }}
                  />
                </td>
                <td style={styles.th}>
                  <input
                    type={"text"}
                    style={Styles.searchInput}
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || null;
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          page: 1,
                          filters: {
                            ...state.filters,
                            lastName: value,
                          },
                        });
                      }, 250);
                    }}
                  />
                </td>
                <td style={styles.th}>
                  <input
                    type={"text"}
                    style={Styles.searchInput}
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || null;
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          page: 1,
                          filters: {
                            ...state.filters,
                            organisationName: value,
                          },
                        });
                      }, 250);
                    }}
                  />
                </td>
                <td style={styles.th}>
                  <Select
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || null;
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          page: 1,
                          filters: {
                            ...state.filters,
                            state: value,
                          },
                        });
                      }, 250);
                    }}
                    style={{ width: 120 }}
                    value={state.filters.state}
                  >
                    {[
                      { label: trans(Label.UI__ALL), value: null },
                      {
                        label: trans(Label.ASSOCIATION_MEMBERSHIP__PENDING),
                        value: "pending",
                      },
                      {
                        label: trans(Label.ASSOCIATION_MEMBERSHIP__APPROVED),
                        value: "approved",
                      },
                      {
                        label: trans(Label.ASSOCIATION_MEMBERSHIP__REJECTED),
                        value: "rejected",
                      },
                    ].map((item) => (
                      <MenuItem
                        key={`${item.value}:${item.label}`}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td style={styles.th}>
                  <Select
                    onChange={(ev) => {
                      clearTimeout(tmr);
                      const value = ev.target.value || null;
                      tmr = setTimeout(() => {
                        setState({
                          ...state,
                          page: 1,
                          filters: {
                            ...state.filters,
                            hasTariffArea: value,
                          },
                        });
                      }, 250);
                    }}
                    style={{ width: 120 }}
                    value={state.filters.hasTariffArea}
                  >
                    {[
                      { label: trans(Label.UI__ALL), value: null },
                      {
                        label: trans(
                          Label.ASSOCIATION_MEMBERSHIP__TARIFF_AREA_YES
                        ),
                        value: 1,
                      },
                      {
                        label: trans(
                          Label.ASSOCIATION_MEMBERSHIP__TARIFF_AREA_NO
                        ),
                        value: 0,
                      },
                    ].map((item) => (
                      <MenuItem
                        key={`${item.value}:${item.label}`}
                        value={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                </td>
                <td style={styles.th}>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {records.length > 0 &&
                records
                  .slice(
                    (state.page - 1) * perPage,
                    (state.page - 1) * perPage + perPage
                  )
                  .map((record) => {
                    return (
                      <tr
                        key={record.uuid}
                        style={{
                          backgroundColor:
                            record.state === "approved"
                              ? "#d2faf1"
                              : record.state === "rejected"
                              ? "#fad2d5"
                              : "#F5F7F8",
                        }}
                      >
                        <td style={styles.td}>{record.association.name}</td>
                        <td style={styles.td}>{record.created_at}</td>
                        <td style={styles.td}>{record.user.firstName}</td>
                        <td style={styles.td}>{record.user.lastName}</td>
                        <td style={styles.td}>
                          {record.user.organisationName}
                        </td>
                        <td style={styles.td}>
                          {(() => {
                            switch (record.state) {
                              case "approved":
                                return trans(
                                  Label.ASSOCIATION_MEMBERSHIP__APPROVED
                                );
                              case "rejected":
                                return trans(
                                  Label.ASSOCIATION_MEMBERSHIP__REJECTED
                                );
                              default:
                                return trans(
                                  Label.ASSOCIATION_MEMBERSHIP__PENDING
                                );
                            }
                          })()}
                        </td>
                        {/* <td style={styles.td}>{record.hasTariffArea == true ? 'Yes' : 'No'}</td> */}

                        <td style={styles.td}>
                          {(() => {
                            switch (record.hasTariffArea) {
                              case 0:
                                return trans(
                                  Label.ASSOCIATION_MEMBERSHIP__TARIFF_AREA_NO
                                );
                              // case 0:
                              //     return trans(Label.ASSOCIATION_MEMBERSHIP__TARIFF_AREA_NO);
                              case 1:
                                return trans(
                                  Label.ASSOCIATION_MEMBERSHIP__TARIFF_AREA_YES
                                );
                              default:
                                return trans(
                                  Label.ASSOCIATION_MEMBERSHIP__TARIFF_AREA_YES
                                );
                            }
                          })()}
                        </td>

                        <td style={styles.td}>
                          {record.state === "pending" ? (
                            <div
                              id={`widgets-${record.uuid}`}
                              style={{ display: "inline-block" }}
                            >
                              <img
                                alt={"Accept"}
                                onClick={() => {
                                  document.getElementById(
                                    `widgets-${record.uuid}`
                                  ).innerHTML = "Loading...";
                                  HttpClient.action(
                                    "approve",
                                    "AssociationMembership",
                                    { data: { uuid: record.uuid } }
                                  ).then((recordBlueprint: Record) => {
                                    if (!recordBlueprint) return;
                                    Records.edit(
                                      "AssociationMembership",
                                      record.uuid,
                                      recordBlueprint,
                                      true
                                    );
                                  });
                                }}
                                src={"/icons/check_box.svg"}
                                style={styles.actionButton}
                              />
                              <img
                                alt={"Cancel"}
                                onClick={() => {
                                  document.getElementById(
                                    `widgets-${record.uuid}`
                                  ).innerHTML = "Loading...";
                                  HttpClient.action(
                                    "reject",
                                    "AssociationMembership",
                                    { data: { uuid: record.uuid } }
                                  ).then((recordBlueprint: Record) => {
                                    if (!recordBlueprint) return;
                                    Records.edit(
                                      "AssociationMembership",
                                      record.uuid,
                                      recordBlueprint,
                                      true
                                    );
                                  });
                                }}
                                src={"/icons/cancel_box.svg"}
                                style={styles.actionButton}
                              />
                              <img
                                alt={"Details"}
                                onClick={() =>
                                  pushRoute(
                                    `/Admin/LandAssociationMembership/${record.uuid}`
                                  )
                                }
                                src={"/icons/details_box.svg"}
                                style={styles.actionButton}
                              />
                            </div>
                          ) : (
                            <Button
                              style={{
                                textTransform: "initial",
                                border: "solid 1px #6eccff",
                              }}
                              color={"#c1e4f7"}
                              textColor={"#0A3B60"}
                              title={trans(Label.UI__VIEW_DETAILS)}
                              onPress={() =>
                                pushRoute(
                                  `/Admin/LandAssociationMembership/${record.uuid}`
                                )
                              }
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
          {records.length > perPage ? (
            <Pagination
              count={pageCount}
              page={state.page}
              shape="rounded"
              color="primary"
              defaultPage={1}
              boundaryCount={2}
              hideNextButton
              hidePrevButton
              sx={{ display: "flex", justifyContent: "center" }}
              onChange={(ev, page) => setState({ ...state, page })}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
});
