import PreviewBrief from "./PreviewBrief";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { useState } from "react";
import PreviewMore from "./PreviewMore";
import "./styles.css";

const ContentPreview: React.FC<{ form: FormState }> = ({ form }) => {
  const [view, setView] = useState("brief");
  const setBrief = () => setView("brief");
  const setMore = () => setView("more");
  return (
    <div className="preview-wrapper">
      {view === "brief" && <PreviewBrief form={form} switchView={setMore} />}
      {view === "more" && <PreviewMore form={form} switchView={setBrief} />}
    </div>
  );
};
export default ContentPreview;
