import Close from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemText,
  List,
  ListItem,
  IconButton,
  Backdrop,
} from "@mui/material";
import React from "react";
import Toast from "../../../../../Layout/Common/Toast";
import Button from "../../../../../Layout/Components/Button";
import HttpClient from "../../../../../State/HttpClient";
import { Record } from "../../../../../State/Records";
import Field from "../../../../../Tools/FormBuilder/Field";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import { trans } from "../../../../../Tools/LocaleHandler";
import HiddenField from "../../../../FormFields/HiddenField";
import InputField from "../../../../FormFields/InputField";
import Label from "../../../../Util/Label";
import PushFormLayout from "./PushFormLayout";

export const PushButton = (props: { item: Record }) => {
  const [open, setOpen] = React.useState(false);
  const onClose = () => setOpen(false);
  const [pushes, setPushes] = React.useState(props.item.pushes);

  const h2Style = {
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
    color: "#12689e",
  };

  class Push {
    title: string;
    body: string;
    sentAt: string;
  }

  return (
    <div style={{ display: "inline" }}>
      <Button
        title="Pushes"
        color={"#d2e5f5"}
        textColor={"#12689e"}
        style={{
          padding: "2px 8px",
          marginTop: "-27px",
          marginRight: "8px",
          border: "1.5px solid #90c1ec",
        }}
        onPress={() => {
          setOpen(true);
        }}
      ></Button>
      <Dialog
        style={{ maxHeight: "820px" }}
        open={open}
        onClose={() => onClose()}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle
          color={"#12689e"}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>Neue Push-Nachricht</span>
          <IconButton onClick={onClose} sx={{ padding: "0px" }}>
            <Close sx={{ color: "#0A3B60", fontSize: "30px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <FormBuilder
            key={"form-itemArgument"}
            action={(blueprint, done) => {
              HttpClient.action("resend-push", "Item", { blueprint })
                .then((r) => {
                  let push = {
                    title: blueprint.fields.title,
                    body: blueprint.fields.body,
                    sentAt: (new Date).toLocaleString('sv')
                  };

                  setPushes((oldPushes: any) => [...oldPushes, push]);

                  Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
                })
                .catch((ex) => {
                  console.warn(ex);
                })
                .finally(() => done());
            }}
            fields={{
              uuid: new HiddenField(
                "form-item-uuid-id",
                Field.FLAG_REQUIRED,
                props.item.uuid
              ),
              title: new InputField(
                "push-title-" + props.item.uuid,
                Field.FLAG_REQUIRED,
                "",
                {
                  label: trans(Label.FORM__TITLE),
                  validations: ["maxChar60"],
                }
              ),
              body: new InputField(
                "push-text-" + props.item.uuid,
                Field.FLAG_REQUIRED,
                "",
                {
                  label: trans(Label.FORM__TEXT),
                  validations: ["maxChar120"],
                }
              ),
            }}
            layout={PushFormLayout}
          ></FormBuilder>

          <h2 style={h2Style}>Letzte Push-Nachrichten</h2>

          <List
            style={{
              borderRadius: "8px",
              boxShadow: "0px 2px 8px rgba(0, 0, 0, .2)",
              border: "1px solid rgba(0, 0, 0, .1)",
              maxHeight: "300px",
              overflowY: "auto",
            }}
          >
            {pushes.length ? (
              ""
            ) : (
              <ListItem key="label-no-pushes">
                <ListItemText
                  primaryTypographyProps={{ color: "#12689e" }}
                  primary={"No pushes was sent"}
                ></ListItemText>
              </ListItem>
            )}

            {pushes.map((push: Push, index: number) => (
              <ListItem key={props.item.uuid + "-push-" + index}>
                <div style={{ width: "80%" }}>
                  <ListItemText
                    secondaryTypographyProps={{ color: "#90c1ec" }}
                    primaryTypographyProps={{ color: "#12689e" }}
                    style={{ paddingRight: "20px" }}
                    primary={push.title}
                    secondary={push.body}
                  ></ListItemText>
                </div>
                <ListItemText
                  secondaryTypographyProps={{ color: "#90c1ec" }}
                  primaryTypographyProps={{ color: "#12689e" }}
                  primary={push.sentAt.split(" ")[0]}
                  secondary={push.sentAt.split(" ")[1]}
                ></ListItemText>
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};
