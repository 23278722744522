import React from "react";
import { connect } from "react-redux";
import FormBuilder, {
  Blueprint,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import { auth } from "../../../../Root";
import { getClientType } from "../../../../Util/Helper";
import { getHomeUrl, pushRoute } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Toast from "../../../../../Layout/Common/Toast";
import AuthFormLayout from "../GateForms/AuthFormLayout";
import EmailField from "../../../../FormFields/EmailField";
import Field from "../../../../../Tools/FormBuilder/Field";
import Label from "../../../../Util/Label";
import PasswordField from "../../../../FormFields/PasswordField";
import { RecordObject } from "../../../../../State/Records";
import { StateType } from "../../../../../State/Store";
// import {getDefaultItemTypeTariff} from '../../../../Layout/BodyOpenedMobile';

export default connect((state: StateType) => ({
  authObject: state.app.authObject,
}))((props: { authObject: RecordObject }): React.ReactElement => {
  if (props.authObject) {
    const homeUrl = getHomeUrl();
    pushRoute(homeUrl);
    return null;
  }

  return (
    <FormBuilder
      key={"form-auth"}
      action={(blueprint: Blueprint, done) => {
        HttpClient.action("auth", "User", { blueprint })
          .then((authObject: RecordObject) => {
            if (!authObject) {
              return;
            }
            auth(authObject, false, () => {
              pushRoute(getHomeUrl());
            });
          })
          .catch((ex) => {
            console.warn(ex);
            Toast.show({ type: "warning", text: trans(ex.text || ex) });
          })
          .finally(() => done());
      }}
      layout={AuthFormLayout}
      fields={{
        email: new EmailField("form-auth-email-id", Field.FLAG_REQUIRED, null, {
          focus: getClientType() !== "ctmobile",
          label: trans(Label.FORM__EMAIL),
          next: "form-auth-password-id",
          validations: ["email"],
        }),
        password: new PasswordField(
          "form-auth-password-id",
          Field.FLAG_REQUIRED,
          null,
          {
            label: trans(Label.FORM__PASSWORD),
          }
        ),
      }}
    />
  );
});
