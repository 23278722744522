import React from "react";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { asset } from "../../../../Util/Helper";
import View from "../../../../../Layout/View";
import Button from "../../../../../Layout/Components/Button";
import Form from "../../../../../Layout/Components/Form";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import Text from "../../../../../Layout/Components/Text";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import SvgUri from "../../../../../Layout/Components/SvgUri";
import { Record } from "../../../../../State/Records";
import BackButton from "../../../../Layout/Common/BackButton";
import Env from "../../../../../env.json";

const RegisterFormLayout = (form: FormState): React.ReactNode => {
  const associations: { [key: string]: Record } = {};
  form.data.associations.map(
    (association: Record) => (associations[association.uuid] = association)
  );
  const ScrollView: any = View; //getClientType() === "ctmobile" ? RnScrollView : View;

  return (
    <ScrollView
      // {...scrollProps}
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      {/* first step */}
      {form.step.current === 0 && (
        <React.Fragment>
          <View
            style={{
              alignItems: "center",
              marginBottom: 24,
              paddingTop: 8,
            }}
          >
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                marginBottom: 24,
                width: "84%",
              }}
            >
              <BackButton to={"/Gate/Auth/User"} />
              <View
                style={{
                  backgroundColor: "#2e77ba",
                  borderBottomLeftRadius: 16,
                  borderTopLeftRadius: 16,
                  flex: 4,
                  height: 8,
                }}
              >
                <Text>&nbsp;</Text>
              </View>
              <View
                style={{
                  backgroundColor: "#d0dce7",
                  borderBottomRightRadius: 16,
                  borderTopRightRadius: 16,
                  flex: 8,
                  height: 8,
                }}
              >
                <Text>&nbsp;</Text>
              </View>
            </View>
            <View>
              {(() => {
                const message = trans(Label.USER__WIZARD_STEP_FOR_TARIFF_AREA);
                if (!message) return;
                const messageSplit = message.split(" ");
                if (messageSplit.length === 1)
                  return (
                    <Text style={{ fontSize: 22, textAlign: "center" }}>
                      {message}
                    </Text>
                  );
                const messageP2 = messageSplit.pop();
                return (
                  <React.Fragment>
                    <Text style={{ fontSize: 22, textAlign: "center" }}>
                      {messageSplit.join(" ")}
                    </Text>
                    <Text
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {messageP2}
                    </Text>
                  </React.Fragment>
                );
              })()}
            </View>
          </View>

          <View style={{ paddingLeft: "8%", paddingRight: "8%" }}>
            {form.fields.hasTariffArea}

            <View style={{ margin: 4 }}>&nbsp;</View>

            <Button
              color={"#207bba"}
              large={true}
              onPress={() => form.buttons.next.action()}
              title={trans(Label.UI__NEXT)}
            />
          </View>
        </React.Fragment>
      )}
      {/* second step */}
      {form.step.current === 1 && (
        <React.Fragment>
          <View
            style={{
              alignItems: "center",
              marginBottom: 24,
              paddingTop: 8,
            }}
          >
            <View
              style={{
                alignItems: "center",
                flexDirection: "row",
                marginBottom: 24,
                width: "84%",
              }}
            >
              <BackButton onPress={() => form.buttons.prev.action()} />

              <View
                style={{
                  backgroundColor: "#2e77ba",
                  borderBottomLeftRadius: 16,
                  borderTopLeftRadius: 16,
                  flex: 4,
                  height: 8,
                }}
              >
                <Text>&nbsp;</Text>
              </View>
              <View
                style={{
                  backgroundColor: "#d0dce7",
                  borderBottomRightRadius: 16,
                  borderTopRightRadius: 16,
                  flex: 8,
                  height: 8,
                }}
              >
                <Text>&nbsp;</Text>
              </View>
            </View>
            <View>
              {(() => {
                const message = trans(Label.USER__WIZARD_STEP_0_TITLE);
                if (!message) return;
                const messageSplit = message.split(" ");
                if (messageSplit.length === 1)
                  return (
                    <Text style={{ fontSize: 22, textAlign: "center" }}>
                      {message}
                    </Text>
                  );
                const messageP2 = messageSplit.pop();
                return (
                  <React.Fragment>
                    <Text style={{ fontSize: 22, textAlign: "center" }}>
                      {messageSplit.join(" ")}
                    </Text>
                    <Text
                      style={{
                        fontSize: 22,
                        fontWeight: "bold",
                        textAlign: "center",
                      }}
                    >
                      {messageP2}
                    </Text>
                  </React.Fragment>
                );
              })()}
            </View>
          </View>
          {form.getFieldValue("hasTariffArea")
            ? form.metas.associationWithTariffArea
            : form.metas.association}

          <View style={{ paddingLeft: "8%", paddingRight: "8%" }}>
            <View style={{ margin: 4 }}>&nbsp;</View>

            <Button
              color={"#207bba"}
              disabled={
                !(
                  form.getMetaValue("association") &&
                  !form.getFieldValue("hasTariffArea")
                ) &&
                !(
                  form.getMetaValue("associationWithTariffArea") &&
                  form.getFieldValue("hasTariffArea")
                )
              }
              large={true}
              onPress={() => form.buttons.next.action()}
              title={trans(Label.UI__NEXT)}
            />
          </View>
        </React.Fragment>
      )}

      {/* last / third step */}
      {form.step.current === 2 && (
        <Form
          onSubmit={(ev) => {
            ev.preventDefault();
            form.buttons.submit.action();
            return false;
          }}
          style={{ paddingLeft: "8%", paddingRight: "8%" }}
        >
          {/* header */}
          <View
            style={{
              alignItems: "center",
              flexDirection: "row",
              marginTop: 8,
              marginBottom: 24,
            }}
          >
            <BackButton onPress={() => form.buttons.prev.action()} />
            <View
              style={{
                alignSelf: "center",
                backgroundColor: "#2e77ba",
                borderBottomLeftRadius: 16,
                borderTopLeftRadius: 16,
                flex: 8,
                height: 8,
              }}
            >
              <Text>&nbsp;</Text>
            </View>
            <View
              style={{
                alignSelf: "center",
                backgroundColor: "#d0dce7",
                borderBottomRightRadius: 16,
                borderTopRightRadius: 16,
                flex: 4,
                height: 8,
              }}
            >
              <Text>&nbsp;</Text>
            </View>
          </View>
          {(() => {
            let associationKey = form.getFieldValue("hasTariffArea")
              ? form.getMetaValue("associationWithTariffArea")
              : form.getMetaValue("association");
            let logo: string = null;
            const association = associations[associationKey];

            if (association._gridParent) {
              logo = associations[association._gridParent].logo;
            } else if (association.logo) {
              logo = association.logo;
            }
            if (!logo) return null;
            return (
              <View style={{ height: 100, marginBottom: 50 }}>
                <SvgUri height={"100%"} width={"100%"} uri={asset(logo)} />
              </View>
            );
          })()}

          <View
            style={{
              alignItems: "center",
              flex: 1,
              marginBottom: 24,
            }}
          >
            <Text
              style={{
                color: "#003e60",
                fontSize: 20,
                fontWeight: "bold",
                marginBottom: 12,
              }}
            >
              {trans(Label.USER__WIZARD_STEP_1_TITLE)}
            </Text>
            <Text style={{ color: "#003e60" }}>
              {trans(Label.USER__WIZARD_STEP_1_TEXT_1)}
            </Text>
            <Text style={{ color: "#003e60" }}>
              {trans(Label.USER__WIZARD_STEP_1_TEXT_2)}
            </Text>
          </View>

          {/* fields */}

          <Text
            style={{
              color: "#003e60",
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 32,
              marginTop: 32,
            }}
          >
            {trans(Label.SECTION__LOGIN_INFO)}
          </Text>

          <ErrorMsg error={form.getError("form-register-email-id", true)} />
          {form.fields.email}
          <View style={{ margin: 4 }}>&nbsp;</View>

          <ErrorMsg error={form.getError("form-register-password-id", true)} />
          {form.fields.password}

          <View style={{ margin: 4 }}>&nbsp;</View>

          <ErrorMsg
            error={form.getError("form-register-passwordConfirm-id", true)}
          />
          {form.metas.passwordConfirm}

          <Text
            style={{
              color: "#003e60",
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 32,
              marginTop: 48,
            }}
          >
            {trans(Label.SECTION__PERSONAL_INFO)}
          </Text>

          <ErrorMsg error={form.getError("form-register-firstName-id", true)} />
          {form.fields.firstName}

          <View style={{ margin: 4 }}>&nbsp;</View>

          <ErrorMsg error={form.getError("form-register-lastName-id", true)} />
          {form.fields.lastName}

          <View style={{ margin: 4 }}>&nbsp;</View>

          <ErrorMsg
            error={form.getError("form-register-phoneNumber-id", true)}
          />
          {form.fields.phoneNumber}

          <Text
            style={{
              color: "#003e60",
              fontSize: 20,
              fontWeight: "bold",
              marginBottom: 32,
              marginTop: 48,
            }}
          >
            {trans(Label.SECTION__PROFESSIONAL_INFO)}
          </Text>

          <ErrorMsg
            error={form.getError("form-register-organisationName-id", true)}
          />
          {form.fields.organisationName}

          <View style={{ margin: 4 }}>&nbsp;</View>

          <ErrorMsg
            error={form.getError("form-register-organisationPosition-id", true)}
          />
          {form.fields.organisationPosition}

          <View
            style={{
              alignItems: "center",
              alignSelf: "flex-start",
              flexDirection: "row",
              marginTop: 24,
            }}
          >
            {form.fields.agreement}

            {(() => {
              return (
                <span
                  style={{
                    color: "#708da0",
                    flex: 1,
                    flexWrap: "wrap",
                    fontSize: 13,
                    marginLeft: 12,
                    textAlign: "justify",
                  }}
                >
                  {trans(Label.FORM__DATA_PRIVACY_AGREEMENT_TEXT)}
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href={Env.privacyPolicyUrl}
                    style={{ fontWeight: "bold" }}
                  >
                    {trans(Label.FORM__DATA_PRIVACY_AGREEMENT_LINK_TEXT)}
                  </a>
                </span>
              );
            })()}
          </View>

          <View style={{ margin: 4 }}>&nbsp;</View>

          <Button
            color={"#207bba"}
            disabled={form.buttons.submit.disabled && form.touched}
            large={true}
            onPress={() => form.buttons.submit.action()}
            title={trans(Label.FORM__REGISTER)}
          />
        </Form>
      )}

      <View style={{ marginTop: 140 }}>&nbsp;</View>
    </ScrollView>
  );
};
export default RegisterFormLayout;
