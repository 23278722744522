type LinkProps = {
  label: string;
};
export const LinkExternal: React.FC<LinkProps> = ({ label }) => {
  return (
    <div className="link-external">
      <span>{label}</span>
    </div>
  );
};
