import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import { FC, ReactNode } from "react";
type NavButtonProps = {
  icon?: string;
  buttonText: string;
  action: () => void;
  // position: string;
  selected: boolean;
  children?: ReactNode;
};
const NavButton: FC<NavButtonProps> = (props) => {
  const { buttonText, icon, action, selected } = props;
  return (
    <ListItemButton
      selected={selected}
      onClick={() => {
        action();
      }}
    >
      {icon && (
        <ListItemIcon>
          <img alt={""} src={icon} style={{ width: 15, height: 15 }} />
        </ListItemIcon>
      )}
      <ListItemText>{buttonText}</ListItemText>
      {props.children}
    </ListItemButton>
  );
};
export default NavButton;
