import React from "react";
// import { StyleSheet } from "react-native";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import View from "../../../../../Layout/View";
import Button from "../../../../../Layout/Components/Button";
import Text from "../../../../../Layout/Components/Text";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";

const QuestionFormLayout = (form: FormState): React.ReactElement => {
  const styles =
    //   StyleSheet.create(
    {
      mainContainer: {
        backgroundColor: "#cddde7",
        borderRadius: 15,
        marginBottom: 30,
        padding: 25,
        paddingBottom: 35,
      },
      text: {
        color: "#00365c",
        marginBottom: 25,
      },
    };
  //   );

  return (
    <View style={{ padding: 35, paddingLeft: 20, paddingRight: 20 }}>
      <View style={styles.mainContainer}>
        <Text style={{ ...styles.text, fontSize: 20, marginBottom: 20 }}>
          {trans(Label.QUESTIONS__PAGE_TITLE)}
        </Text>

        <Text style={styles.text}>{trans(Label.QUESTIONS__PAGE_TEXT)}</Text>

        {form.fields.question}
      </View>

      <View style={{ marginLeft: 20, marginRight: 20 }}>
        <Button
          color={"#8cb9d6"}
          disabled={form.buttons.submit.disabled && form.touched}
          onPress={() => form.buttons.submit.action()}
          title={trans(Label.FORM__SUBMIT)}
          textColor={"#014b79"}
        />
      </View>

      <View style={{ margin: 10 }}>&nbsp;</View>
    </View>
  );
};
export default QuestionFormLayout;
