import React from "react";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import QuestionsFormLayout from "../QuestionForms/QuestionsFormLayout";
import TextField from "../../../../FormFields/TextField";
import Field from "../../../../../Tools/FormBuilder/Field";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import Toast from "../../../../../Layout/Common/Toast";
import { getHomeUrl, pushRoute } from "../../../../../State/Router";
import HttpClient from "../../../../../State/HttpClient";

const CreateQuestion = (): React.ReactElement => {
  return (
    <FormBuilder
      key={"form-questions"}
      action={(blueprint, done) => {
        HttpClient.action("createQuestion", "User", {
          blueprint,
        })
          .then(() => {
            Toast.show({ type: "info", text: trans(Label.UI__SUCCESS) });
            pushRoute(getHomeUrl());
          })
          .finally(() => {
            done();
          });
      }}
      fields={{
        question: new TextField(
          "form-questions-question-id",
          Field.FLAG_REQUIRED,
          null,
          {
            bgColor: "#e7eff4",
            color: "#748c97",
            height: 240,
            label: trans(Label.FORM__QUESTION),
          }
        ),
      }}
      layout={QuestionsFormLayout}
    />
  );
};
export default CreateQuestion;
