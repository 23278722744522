export type ClientType = "ctweb" | "ctmobile";

const b64 = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

let assetDomain: string = "";

const asset = (path: string | string[] = null, flag: boolean = null): any => {
  if (typeof path === "string" && path.slice(0, 4) === "http") {
    return path;
  }

  if (path === null) {
    return null;
  }

  if (Array.isArray(path)) {
    return path.map((path) => asset(path, flag));
  }

  path = path.charAt(0) === "/" ? path.substr(1) : path;

  if (flag) {
    return `${assetDomain}/asset/${btoa(path)}`;
  }

  return `${assetDomain}/${path}`;
};

const btoa = (string: string): string => {
  let bitmap,
    a,
    b,
    c,
    result = "",
    i = 0,
    rest = string.length % 3; // To determine the final padding
  for (; i < string.length; ) {
    if (
      (a = string.charCodeAt(i++)) > 255 ||
      (b = string.charCodeAt(i++)) > 255 ||
      (c = string.charCodeAt(i++)) > 255
    ) {
      throw new TypeError(
        'Failed to execute "btoa" on "Window": The string to be encoded contains characters outside of the Latin1 range.'
      );
    }
    bitmap = (a << 16) | (b << 8) | c;
    result +=
      b64.charAt((bitmap >> 18) & 63) +
      b64.charAt((bitmap >> 12) & 63) +
      b64.charAt((bitmap >> 6) & 63) +
      b64.charAt(bitmap & 63);
  }
  // If there's need of padding, replace the last 'A's with equal signs
  return rest ? result.slice(0, rest - 3) + "===".substring(rest) : result;
};

const getClientType = (): ClientType => {
  // switch (true) {
  // case typeof document !== 'undefined':
  return "ctweb";
  // case typeof navigator !== 'undefined' && navigator.product === 'ReactNative':
  //     return 'ctmobile';
  // default:
  //     return null;
  // }
};

const getFilename = (text: string): string => {
  const lastIndexOfSlash = text.lastIndexOf("/");
  return lastIndexOfSlash === -1 ? text : text.substr(lastIndexOfSlash + 1);
};

const setAssetDomain = (domain: string): void => {
  domain =
    domain.charAt(domain.length - 1) === "/"
      ? domain.substr(0, domain.length - 1)
      : domain;
  assetDomain = domain;
};

const transformDate = (date: string): string => {
  let [dateTime, hrMinTime] = date.split(" ");
  const split = dateTime.split("-");
  dateTime = [split[2], split[1], split[0]].join("-");
  return dateTime + " " + hrMinTime;
};

export {
  asset,
  btoa,
  getClientType,
  getFilename,
  setAssetDomain,
  transformDate,
};
