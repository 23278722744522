import React, { SyntheticEvent } from "react";
import { connect } from "react-redux";
import { StateType } from "../../../../../State/Store";
import Records, { Record, RecordObject } from "../../../../../State/Records";
import { pushRoute, RouterState } from "../../../../../State/Router";
import View from "../../../../../Layout/View";
import { trans } from "../../../../../Tools/LocaleHandler";
import Close from "@mui/icons-material/Close";
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { CloudDownload as ExportCsv } from "@mui/icons-material/";

import Label from "../../../../Util/Label";
import { asset } from "../../../../Util/Helper";
import Divider from "@mui/material/Divider";
import Toast from "../../../../../Layout/Common/Toast";
import HttpClient from "../../../../../State/HttpClient";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import HiddenField from "../../../../FormFields/HiddenField";
import InputField from "../../../../FormFields/InputField";
import SubItemFormLayout from "../ItemCollections/SubItem/SubItemForms/SubItemFormLayout";
import Field from "../../../../../Tools/FormBuilder/Field";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Storage from "../../../../../State/Storage";
import Button from "../../../../../Layout/Components/Button";
import WYSIWYGTextField from "../../../../FormFields/WYSIWYGTextField";

const styles = {
  t: {
    borderBottom: "solid 1px #ccc",
    padding: 12,
  },
};

const ExportButton = (props: { uuid: string }) => {
  const apiUrl = HttpClient.getApiUrl();
  const authorization = Storage.get("authorization");
  return (
    <a
      href={`${apiUrl}/action/${authorization}/Item/csvExportOne?uuid=${props.uuid}`}
      style={{ margin: 25, marginBottom: 0 }}
      target={"_blank"}
      rel="noreferrer"
    >
      <ExportCsv style={{ cursor: "pointer", marginRight: 20 }} />
    </a>
  );
};

type RouteParams = { uuid: string };
export default connect((state: StateType, props: { rstate: RouterState }) => {
  const params = props.rstate.params;
  const subItemsSignature = ["Item", "SubItem", "item", params.uuid].join(":");
  const eventAttendanceSignature = [
    "Event",
    "EventAttendance",
    "event",
    params.uuid,
  ].join(":");

  return {
    authObject: state.app.authObject,
    collectionSet: {
      subItems: Records.mapRefsToRecords(state.collections[subItemsSignature]),
      eventAttendance: Records.mapRefsToRecords(
        state.collections[eventAttendanceSignature]
      ),
    },
    params,
    record: Records.getRecord("Item", params.uuid),
    subItemsSignature,
  };
})(
  (props: {
    authObject: RecordObject;
    collectionSet: { [key: string]: Record[] };
    record: Record;
    params: RouteParams;
    subItemsSignature: string;
  }) => {
    props.record || Records.retrieve("Item", props.params.uuid);
    props.collectionSet.subItems ||
      Records.collect("Item", "SubItem", "item", props.params.uuid);
    props.collectionSet.eventAttendance ||
      Records.collect("Event", "EventAttendance", "event", props.params.uuid);

    if (!props.record) {
      return null;
    }

    const [editItem, setEditItem] = React.useState<string>(null);

    const downloadHandler = (ev: SyntheticEvent, documentAsset: string) => {
      ev.preventDefault();
      HttpClient.action("getDownloadToken", "User").then((downloadToken) => {
        window.open(
          `${documentAsset}?downloadToken=${downloadToken}`,
          "_blank"
        );
      });
      return false;
    };

    return (
      <div style={{ paddingLeft: "4%", paddingRight: "4%" }}>
        <View
          style={{
            flexDirection: "row",
            fontSize: 22,
            fontWeight: "bold",
            justifyContent: "space-between",
            marginBottom: 25,
            marginTop: 25,
          }}
        >
          {trans(Label.ADMIN__ITEM_VIEW)}
          <div>
            {["event", "poll"].indexOf(props.record.type) !== -1 && (
              <ExportButton uuid={props.record.uuid} />
            )}
            <Edit
              onClick={() => pushRoute(`/Admin/EditItem/${props.record.uuid}`)}
              style={{ cursor: "pointer" }}
            />
          </div>
        </View>

        <View
          style={{
            backgroundColor: "#fff",
            padding: 48,
            paddingLeft: 72,
            paddingRight: 72,
          }}
        >
          <div style={{ fontSize: 20, marginBottom: 25 }}>
            <span style={{ color: "#aaa", fontSize: 18 }}>
              {trans(Label.FORM__DISPLAY_AREA)}
            </span>
            &nbsp;&nbsp;&nbsp;
            {(() => {
              switch (props.record.displayArea) {
                case "memberArea":
                  return trans(Label.ITEM__DISPLAY_MEMBER_AREA);
                case "tariffArea":
                  return trans(Label.ITEM__DISPLAY_TARIFF_AREA);
                default:
                  return props.record.displayArea;
              }
            })()}
          </div>

          {!!props.record.exclusive && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__EXCLUSIVE)}
              </span>
              &nbsp;&nbsp;&nbsp;Yes
            </div>
          )}

          {props.record.publishState === "published" && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__PUBLISH_DATE)}
              </span>
              &nbsp;&nbsp;&nbsp;{props.record.publishDate}
            </div>
          )}

          {/*<div style={{fontSize: 20, marginBottom: 25}}>*/}
          {/*    <span style={{color: '#aaa', fontSize: 18}}>{trans(Label.ADMIN__ITEM_BELONGS_TO_ASSOCIATION)}</span>&nbsp;&nbsp;&nbsp;{props.record.association.name}*/}
          {/*</div>*/}

          <Divider style={{ marginBottom: 25 }} />

          {!!props.record.cover && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <img
                alt={""}
                src={asset(`${props.record.cover}?nocache=${Math.random()}`)}
                style={{
                  border: "solid 1px #aaa",
                  borderRadius: 15,
                  maxHeight: 400,
                  maxWidth: "100%",
                }}
              />
            </div>
          )}

          {!!props.record.video && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <video
                controls={true}
                style={{ maxHeight: 400, maxWidth: "100%" }}
              >
                {/* chrome handles video caches very poorly. reimplement app version and invalidate caches per version maybe? */}
                <source
                  src={asset(props.record.video) + `?rand=${Math.random()}`}
                />
              </video>
            </div>
          )}

          {!!props.record.title && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__TITLE)}
              </span>
              &nbsp;&nbsp;&nbsp;{props.record.title}
            </div>
          )}

          {!!props.record.link && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__LINK)}
              </span>
              &nbsp;&nbsp;&nbsp;{props.record.link}
            </div>
          )}

          {!!props.record.desc && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__DESC)}
              </span>
              &nbsp;&nbsp;&nbsp;{props.record.desc}
            </div>
          )}

          {!!props.record.text && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__TEXT)}
              </span>
              <div
                dangerouslySetInnerHTML={{ __html: props.record.text }}
              ></div>
            </div>
          )}

          {!!props.record.startTime && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__START_TIME)}
              </span>
              <br />
              <br />
              {props.record.startTime}
            </div>
          )}

          {!!props.record.endTime && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__END_TIME)}
              </span>
              <br />
              <br />
              {props.record.endTime}
            </div>
          )}

          {!!props.record.address && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__ADDRESS)}
              </span>
              <br />
              <br />
              {props.record.address}
            </div>
          )}

          {!!props.record.documents && (
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              <span style={{ color: "#aaa", fontSize: 18 }}>
                {trans(Label.FORM__DOCUMENT)}
              </span>
              &nbsp;&nbsp;&nbsp;
              {asset(props.record.documents).map((documentAsset: string) => (
                <div
                  key={documentAsset}
                  style={{ fontSize: 20, marginBottom: 25 }}
                >
                  <a
                    onAuxClick={(ev) => downloadHandler(ev, documentAsset)}
                    onTouchStart={(ev) => downloadHandler(ev, documentAsset)}
                    onClick={(ev) => downloadHandler(ev, documentAsset)}
                    href={documentAsset}
                    target={"_blank"}
                    rel="noreferrer"
                    style={{ cursor: "pointer" }}
                  >
                    {documentAsset}
                  </a>
                </div>
              ))}
            </div>
          )}

          {!!props.record.answers && (
            <React.Fragment>
              <div
                style={{
                  color: "#aaa",
                  fontSize: 18,
                  marginBottom: 20,
                  marginTop: 10,
                }}
              >
                {trans(Label.FORM__ANSWERS)}
              </div>
              {props.record.answers.map((answer: string, index: number) => (
                <div key={index} style={{ fontSize: 20, marginBottom: 25 }}>
                  <span style={{ color: "#aaa", fontSize: 18 }}>
                    {index + 1}.&nbsp;&nbsp;&nbsp;
                  </span>
                  {answer}
                </div>
              ))}
            </React.Fragment>
          )}

          {[
            "argument",
            "currentStatus",
            "conclusion",
            "economy",
            "restructuring",
            "training",
            "securingEmployment",
            "workingHours",
            "pensions",
            "other",
            "industry",
            "corona",
            "wagePolicy",
            "digitalization",
            "employment",
            "socialPolicy",
            "education",
            "skilledEmployment",
            "laborPolicy",
            "collectiveAgreement",
            "participation",
            "legal",
            "international",
            "elections",
          ].indexOf(props.record.type) !== -1 &&
            props.collectionSet.subItems && (
              <React.Fragment>
                <Divider style={{ marginBottom: 25 }} />

                <div style={{ fontSize: 25 }}>
                  {trans(Label.ADMIN__SUB_ITEM)}
                </div>

                <FormBuilder
                  key={"form-subItem"}
                  action={(blueprint, done) => {
                    HttpClient.action("create", "SubItem", { blueprint })
                      .then((subItem) => {
                        const signature = [
                          "Item",
                          "SubItem",
                          "item",
                          props.record.uuid,
                        ].join(":");
                        Records.addToCollection(signature, "SubItem", subItem);
                        done(true);
                      })
                      .catch((ex) => {
                        Toast.show({
                          type: "warning",
                          text: trans(ex.text || ex),
                        });
                        done();
                      });
                  }}
                  fields={{
                    item: new HiddenField(
                      "form-subItem-item-id",
                      Field.FLAG_REQUIRED,
                      props.record.uuid
                    ),
                    title: new InputField(
                      "form-subItem-title-id",
                      Field.FLAG_OPTIONAL,
                      null,
                      { label: trans(Label.FORM__TITLE) }
                    ),
                    // icon: new ImageField('form-subItem-icon-id', Field.FLAG_OPTIONAL, null, {label: trans(Label.FORM__ICON)}),
                    text: new WYSIWYGTextField(
                      "form-subItem-text-id",
                      Field.FLAG_OPTIONAL,
                      null,
                      { label: trans(Label.FORM__TEXT) }
                    ),

                    // text: new TextField('form-subItem-text-id', Field.FLAG_REQUIRED, null, {label: trans(Label.FORM__TEXT)}),
                  }}
                  layout={SubItemFormLayout}
                />

                <DragDropContext
                  onDragEnd={(result, provided) => {
                    const from = result.source.index;
                    const to = result.destination.index;
                    if (from === to) return;
                    let newList = [...props.collectionSet.subItems];

                    const record = props.collectionSet.subItems[from];
                    newList.splice(from, 1);
                    newList.splice(to, 0, record);

                    HttpClient.action("reorder", "SubItem", {
                      data: {
                        itemUuid: props.record.uuid,
                        subItemUuids: newList.map((subItem) => subItem.uuid),
                      },
                    });

                    Records.setCollection(
                      props.subItemsSignature,
                      newList.map((record) => record.toRef("SubItem"))
                    );
                  }}
                >
                  <Droppable droppableId={"droppable"}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef}>
                        {props.collectionSet.subItems &&
                          props.collectionSet.subItems.map((record, index) => (
                            <Draggable
                              key={record.uuid}
                              draggableId={record.uuid}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <div
                                    style={{
                                      backgroundColor: "#fafafa",
                                      border: "solid 1px #ccc",
                                      borderRadius: 15,
                                      marginBottom: 25,
                                      padding: 25,
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        marginBottom: 25,
                                      }}
                                    >
                                      {editItem !== record.uuid ? (
                                        <Edit
                                          onClick={() => {
                                            setEditItem(record.uuid);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <Close
                                          onClick={() => {
                                            setEditItem(null);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      )}
                                      {props.collectionSet.subItems.length >
                                        1 && editItem !== record.uuid ? (
                                        <Delete
                                          onClick={() => {
                                            Records.remove(
                                              record.toRef("SubItem")
                                            )
                                              .then(() => {
                                                Toast.show({
                                                  type: "info",
                                                  text: trans(
                                                    Label.UI__SUCCESS
                                                  ),
                                                });
                                              })
                                              .catch((ex) => {
                                                Toast.show({
                                                  type: "warning",
                                                  text: trans(ex.text || ex),
                                                });
                                              });
                                          }}
                                          style={{ cursor: "pointer" }}
                                        />
                                      ) : (
                                        <span>&nbsp;</span>
                                      )}
                                    </div>

                                    {editItem !== record.uuid ? (
                                      <React.Fragment>
                                        <div
                                          style={{
                                            fontSize: 20,
                                            marginBottom: 25,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "#aaa",
                                              fontSize: 18,
                                            }}
                                          >
                                            {trans(Label.FORM__TITLE)}
                                          </span>
                                          &nbsp;&nbsp;&nbsp;{record.title}
                                        </div>

                                        <div style={{ fontSize: 20 }}>
                                          <span
                                            style={{
                                              color: "#aaa",
                                              fontSize: 18,
                                            }}
                                          >
                                            {trans(Label.FORM__TEXT)}
                                          </span>
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: record.text,
                                            }}
                                          ></p>
                                        </div>
                                      </React.Fragment>
                                    ) : (
                                      <FormBuilder
                                        key={"form-subItem-inline"}
                                        action={(blueprint, done) => {
                                          setEditItem(null);
                                          Records.edit(
                                            "SubItem",
                                            record.uuid,
                                            blueprint
                                          )
                                            .then((subItem) => {
                                              Toast.show({
                                                type: "info",
                                                text: trans(Label.UI__SUCCESS),
                                              });
                                            })
                                            .catch((ex) => {
                                              Toast.show({
                                                type: "warning",
                                                text: trans(ex.text || ex),
                                              });
                                            })
                                            .finally(() => done());
                                        }}
                                        data={{ inline: true }}
                                        fields={{
                                          uuid: new HiddenField(
                                            "form-subItem-inline-uuid-id",
                                            Field.FLAG_REQUIRED,
                                            record.uuid
                                          ),
                                          title: new InputField(
                                            "form-subItem-inline-title-id",
                                            Field.FLAG_REQUIRED,
                                            record.title,
                                            { label: trans(Label.FORM__TITLE) }
                                          ),
                                          // icon: new ImageField('form-subItem-inline-icon-id', Field.FLAG_OPTIONAL, record.icon, {label: trans(Label.FORM__ICON)}),
                                          text: new WYSIWYGTextField(
                                            "form-subItem-inline-text-id",
                                            Field.FLAG_OPTIONAL,
                                            record.text,
                                            { label: trans(Label.FORM__TEXT) }
                                          ),
                                        }}
                                        layout={SubItemFormLayout}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>

                <div style={{ margin: 50 }}></div>
              </React.Fragment>
            )}
        </View>

        <br />
        <br />

        {!!props.record.answers && (
          <View
            style={{
              backgroundColor: "#fff",
              padding: 48,
              paddingLeft: 72,
              paddingRight: 72,
            }}
          >
            <div style={{ fontSize: 20, marginBottom: 25, marginLeft: 10 }}>
              {trans(Label.FORM__STATS)}
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                style={{ display: "flex", flexDirection: "column", flex: 4 }}
              >
                <div
                  style={{ display: "flex", flexDirection: "row", margin: 10 }}
                >
                  <div style={{ flex: 1, marginRight: 10 }}>
                    {trans(Label.FORM__STARTED)}
                  </div>
                  <div style={{ flex: 1 }}>{props.record.created_at}</div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", margin: 10 }}
                >
                  <div style={{ flex: 1, marginRight: 10 }}>
                    {trans(Label.FORM__EXPIRY)}
                  </div>
                  <div style={{ flex: 1 }}>{props.record.expiry}</div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", margin: 10 }}
                >
                  <div style={{ flex: 1, marginRight: 10 }}>
                    {trans(Label.FORM__PARTICIPANTS)}
                  </div>
                  <div style={{ flex: 1 }}>
                    {props.record.voteDetails.total}
                  </div>
                </div>
                <div
                  style={{ display: "flex", flexDirection: "row", margin: 10 }}
                >
                  <div style={{ flex: 1, marginRight: 10 }}>
                    {trans(Label.FORM__SHARE_OF_TOTAL)}
                  </div>
                  <div style={{ flex: 1 }}>
                    {props.record.voteDetails.shareOfTotal}
                  </div>
                </div>
              </div>
              <div style={{ flex: 5 }}>
                <table
                  cellSpacing={0}
                  cellPadding={15}
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 15px",
                    marginTop: -65,
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <td style={{ fontWeight: "bold" }}>
                        {trans(Label.FORM__OPTION)}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {trans(Label.FORM__VOTES)}
                      </td>
                      <td style={{ fontWeight: "bold" }}>
                        {trans(Label.FORM__PERCENT)}
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {props.record.answers.map(
                      (answer: string, index: number) => (
                        <tr key={index}>
                          <td
                            style={{ border: "solid 1px #ccc", borderRight: 0 }}
                          >
                            {answer}
                          </td>
                          <td
                            style={{
                              borderBottom: "solid 1px #ccc",
                              borderTop: "solid 1px #ccc",
                            }}
                          >
                            {props.record.voteResultsCount[index]}
                          </td>
                          <td
                            style={{ border: "solid 1px #ccc", borderLeft: 0 }}
                          >
                            {props.record.voteResults[index]}
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </View>
        )}

        {props.record.type === "event" && (
          <View
            style={{
              backgroundColor: "#fff",
              padding: 48,
              paddingLeft: 72,
              paddingRight: 72,
            }}
          >
            <div style={{ fontSize: 20, marginBottom: 25 }}>
              {trans(Label.EVENT_ATTENDANCE__TITLE)}

              {(() => {
                if (
                  !props.collectionSet.eventAttendance ||
                  !props.collectionSet.eventAttendance.length
                ) {
                  return;
                }
                const apiUrl = HttpClient.getApiUrl();
                const authorization = Storage.get("authorization");
                return (
                  <a
                    href={`${apiUrl}/action/${authorization}/Item/csvExportRsvp?uuid=${props.record.uuid}`}
                    style={{ margin: 25, marginBottom: 0 }}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    <Button
                      color={"#0083c4"}
                      title={trans(Label.UI__CSV_EXPORT)}
                    />
                  </a>
                );
              })()}
            </div>

            {(() => {
              if (
                !props.collectionSet.eventAttendance ||
                !props.collectionSet.eventAttendance.length
              ) {
                return <div>{trans("trans.ui.emptyList")}</div>;
              }

              return (
                <React.Fragment>
                  <table
                    cellPadding={0}
                    cellSpacing={0}
                    style={{ width: "calc(100% - 48px)" }}
                  >
                    <thead>
                      <tr>
                        <td style={styles.t}>
                          {trans(Label.FORM__FIRST_NAME)}
                        </td>
                        <td style={styles.t}>{trans(Label.FORM__LAST_NAME)}</td>
                        <td style={styles.t}>{trans(Label.FORM__EMAIL)}</td>
                        <td style={styles.t}>
                          {trans(Label.FORM__CONTACT_PHONE)}
                        </td>
                        <td style={styles.t}>&nbsp;</td>
                      </tr>
                    </thead>
                    <tbody>
                      {props.collectionSet.eventAttendance.map(
                        (eventAttendee) => (
                          <tr key={eventAttendee.uuid}>
                            <td style={styles.t}>
                              {eventAttendee.user.firstName}
                            </td>
                            <td style={styles.t}>
                              {eventAttendee.user.lastName}
                            </td>
                            <td style={styles.t}>{eventAttendee.user.email}</td>
                            <td style={styles.t}>
                              {eventAttendee.user.phoneNumber}
                            </td>
                            <td style={styles.t}>{eventAttendee.user.state}</td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </table>
                </React.Fragment>
              );
            })()}
          </View>
        )}
      </div>
    );
  }
);
