import React from 'react';
import Text from '../Components/Text';
import View from '../View';
import {getClientType} from '../LayoutHelper';

type ToastType = {type: 'info' | 'warning' | 'error', text?: string, errors?: Array<string>};
export default class Toast extends React.Component<{}> {
    protected static ref: Toast = null;
    protected static forceUpdate: Function = (): void => null;
    protected static toasts: ToastType[] = [];

    public static show(toast: ToastType): void {
        if (!toast.text && !toast.errors) return;

        if (Toast.toasts.indexOf(toast)) {
            Toast.toasts.splice(Toast.toasts.indexOf(toast));
        }
        Toast.toasts.push(toast);
        Toast.forceUpdate();
        setTimeout(() => Toast.toasts.indexOf(toast) !== -1 ? (Toast.toasts.splice(Toast.toasts.indexOf(toast), 1) && Toast.forceUpdate()) : null, 3600);
    }

    public render(): React.ReactElement {
        Toast.forceUpdate = () => this.forceUpdate();
        Toast.ref = this;

        if (!Toast.toasts.length) {
            return null;
        }
        return <View style={{
            backgroudColor: 'transparent',
            flexDirection: 'row',
            justifyContent: 'center',
            pointerEvents: 'none',
            position: getClientType() === 'ctweb' ? 'fixed' : 'absolute',
            width: getClientType() === 'ctweb' ? 'calc(100% - 40px)' : '100%',
            zIndex: 3,
        }}>
            <View style={{margin: 50, maxWidth: 800}}>
                {Toast.toasts.map(toast => {
                    let bgColor: string;
                    let bgColorAccent: string;
                    let textColor: string;
                    switch (toast.type) {
                    case 'info':
                        bgColor = '#5f5f5f';
                        bgColorAccent = '#5f5f5f';
                        textColor = 'white';
                        break;
                    case 'warning':
                        bgColor = '#fff2db';
                        bgColorAccent = '#feb64f';
                        textColor = '#2b1e1a';
                        break;
                    case 'error':
                        bgColor = '#ffa3a3';
                        bgColorAccent = '#c60000';
                        textColor = '#2b1e1a';
                        break;
                    default:
                        throw new Error();
                    }

                    return <View
                        key={Toast.toasts.indexOf(toast)}
                        onPress={() => Toast.toasts.splice(Toast.toasts.indexOf(toast), 1) && Toast.forceUpdate()}
                        style={{alignItems: 'stretch', cursor: 'pointer', flexDirection: 'row', pointerEvents: 'auto'}}
                    >
                        <View style={{
                            backgroundColor: bgColorAccent,
                            borderBottomColor: '#bebebf',
                            borderBottomLeftRadius: 6,
                            borderBottomWidth: 2,
                            borderTopColor: '#dedebf',
                            borderTopLeftRadius: 6,
                            borderTopWidth: 1,
                            marginBottom: 12,
                            opacity: 0.98,
                            width: 6,
                        }}>{null}</View>
                        <View style={{
                            backgroundColor: bgColor,
                            borderBottomColor: '#bebebf',
                            borderBottomRightRadius: 6,
                            borderBottomWidth: 2,
                            borderRightColor: '#bebebf',
                            borderRightWidth: 2,
                            borderTopColor: '#dedebf',
                            borderTopRightRadius: 6,
                            borderTopWidth: 1,
                            marginBottom: 12,
                            opacity: 0.98,
                            padding: 20,
                            paddingLeft: 28,
                            paddingRight: 28,
                        }}>
                            <Text style={{color: textColor, fontSize: 16}}>{ toast.text }</Text>
                            {toast.errors && toast.errors.length && (
                                <ul>
                                    {toast.errors.map(e => <li key={e}>{e}</li>)}
                                </ul>
                            )}
                        </View>
                    </View>;
                })}
            </View>
        </View>;
    }
}