import Box from "@mui/system/Box/Box";
import { ReactNode } from "react";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import styled from "styled-components";

export const FieldLabelStyled = styled.div`
  font-size: 18px;
`;

type ItemFieldWrapperProps = {
  error?: string;
  field: ReactNode;
  children?: ReactNode;
};
const ItemFieldWrapper: React.FC<ItemFieldWrapperProps> = ({
  error,
  field,
  children,
}) => (
  <Box
    sx={{
      margin: "0px 0px 24px",
    }}
  >
    {children}
    {error ? <ErrorMsg error={error} /> : null}
    {field}
  </Box>
);
export default ItemFieldWrapper;
