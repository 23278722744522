import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import { BluePlayButton } from "./BluePlayButton";

type VideoProps = {
  videoUrl: string;
};

export const Video: React.FC<VideoProps> = ({ videoUrl }) => {
  const [state, setState] = useState(() => {
    const offscreenVideo = document.createElement("video");

    offscreenVideo.autoplay = true;
    offscreenVideo.muted = true;

    return {
      thumbUrl: null,
      offscreenCanvas: document.createElement("canvas"),
      offscreenVideo,
    };
  });

  const updateThumbnail = (_: Event) => {
    state.offscreenCanvas.width = state.offscreenVideo.videoWidth;
    state.offscreenCanvas.height = state.offscreenVideo.videoHeight;

    const context = state.offscreenCanvas.getContext("2d");

    context.drawImage(
      state.offscreenVideo,
      0,
      0,
      state.offscreenCanvas.width,
      state.offscreenCanvas.height
    );

    state.offscreenVideo.pause();

    setState({
      ...state,
      thumbUrl: state.offscreenCanvas.toDataURL("image/jpeg"),
    });
  };

  useEffect(() => {
    state.offscreenVideo.addEventListener("loadeddata", updateThumbnail, {
      capture: false,
      passive: true,
    });

    if (videoUrl) {
      state.offscreenVideo.src = videoUrl;
    }

    return () =>
      state.offscreenVideo.removeEventListener("loadeddata", updateThumbnail);
  }, [videoUrl]);

  const playerRef = useRef<ReactPlayer>();

  return (
    <Box sx={{ height: "275px" }} className="media-preview">
      <ReactPlayer
        url={videoUrl}
        light={state.thumbUrl}
        muted={true}
        height="100%"
        width="100%"
        playing={true}
        controls={true}
        playIcon={BluePlayButton}
        config={{
          file: {
            forceVideo: true,
            attributes: {},
          },
        }}
        ref={playerRef}
      />
    </Box>
  );
};
