import React from "react";
import Env from "../../../../../env.json";

const KnowledgeBaseLanding = () => {


    const url = Env.kbBaseUrl + '?84cec86d72df4fba7b78d2b9eb414084=' + Math.round(new Date().getTime()/1000);

    // const fd = new FormData();

    // fd.append('username', Env.kbUser);
    // fd.append('password', Env.kbPass);

//     if (!state.cookie) {
//       fetch(new URL("/api/auth/generate_auth_cookie", Env.kbBaseUrl).toString(), {
//         method: "post",
//         body: fd
//       })
//         .then((res) => res.json())
//         .then((d) => {
//           const url = `${Env.kbBaseUrl}?cookie=` + encodeURI(d.cookie) + '&cookie_name=' + encodeURI(d.cookie_name);

//           setState({
//             ...state,
//             cookie: d.cookie,
//             url
//           });

//           window.open(url, 'blank');
//         })
//         .catch((ex) => {
//           console.warn(ex);
//         });
//   }

  return <div style={{ overflow: 'hidden' }}>

        <iframe
          title="Knowledge base"
          style={{ overflow: 'hidden', border: 'none' }}
          src={url}
          width={window.innerWidth - 300}
          height={window.innerHeight - 5}></iframe>
  </div>;
};

export const kb = (url: string) => {
    const siteUrl = Env.kbBaseUrl + '?'+ Env.kbVar +'=' + Math.round(new Date().getTime()/1000) + '&url=' + encodeURI(url)
    window.open(siteUrl, 'blank')
}

export default KnowledgeBaseLanding;
