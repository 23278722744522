import Toast from "../../../../../Layout/Common/Toast";

const serverValidation = (result: any) => {
  const { errors } = result || null;

  if (errors) {
    let allErrs: Array<string> = [];
    Object.entries(errors).forEach((v: any) => {
      const msg = v[1].join(" ");
      allErrs.push(msg);
    });

    Toast.show({ type: "error", text: null, errors: allErrs });
  }
};

export default serverValidation;
