import { Box } from "@mui/material";

type AddToCalendarProps = {
  text: string;
};

const color = "#0A3B60";
const calendarIcon = 'url("/icons/calendar_plus.svg")';

export const AddToCalendar: React.FC<AddToCalendarProps> = ({ text }) => {
  return (
    <>
      <Box
        className="calendar-icon"
        sx={{
          WebkitMaskImage: calendarIcon,
          maskImage: calendarIcon,
          backgroundColor: color,
          width: 18,
          height: 20,
        }}
      />
      <span>{text}</span>
    </>
  );
};
