import HttpClient, { Context } from "./HttpClient";
import {
  mergeState,
  removeCollection,
  removeList,
  replaceState,
  resetState,
  setCollection,
  setList,
  setRecord,
  setRecordError,
  store,
} from "./Store";

export default new (class Records {
  protected recordStatus: { [key: string]: boolean } = {};
  public listStatus: { [key: string]: boolean } = {};
  protected collectStatus: { [key: string]: boolean } = {};

  // list {
  public list(repository: string, listOrContext: string | Context): void {
    if (!repository || !listOrContext) {
      return;
    }

    const context =
      typeof listOrContext === "string"
        ? { list: { type: listOrContext } }
        : listOrContext;
    const signature =
      typeof listOrContext === "string"
        ? [repository, listOrContext].join(":")
        : [repository, JSON.stringify(context)].join(":");

    if (this.listStatus[signature]) {
      return;
    }

    this.listStatus[signature] = true;
    HttpClient.action("list", repository, context).then(
      (recordResults: { uuid: string }[]) => {
        const list: RecordRef[] = [];
        const srepository = this.getRepository(repository) || {};
        recordResults.forEach((recordResult) => {
          srepository[recordResult.uuid] = this.transformRecord(recordResult);
          list.push(new RecordRef(repository, recordResult.uuid));
        });

        mergeState({
          records: {
            [repository]: srepository,
          },
          lists: {
            [signature]: list,
          },
        });
      }
    );
  }
  // }

  // edit {
  public edit(
    repository: string,
    uuid: string,
    blueprint: any,
    noRequest: boolean = false,
    errCallback? : CallableFunction
  ): Promise<Record> {
    if (!repository || !uuid || !blueprint) {
      return null;
    }

    return new Promise((resolve, reject) => {
      if (noRequest) {
        const record = this.transformRecord(blueprint);
        setRecord(repository, uuid, record);
        resolve(record);
        return;
      }

      HttpClient.action("edit", repository, { blueprint }, false, errCallback)
        .then((recordResult) => {
          const record = this.transformRecord(recordResult);
          setRecord(repository, uuid, record);
          resolve(record);
        })
        .catch((ex) => reject(ex));
    });
  }
  // }

  // remove {
  public remove(recordRef: RecordRef, flag: string = null, action: string = 'remove'): Promise<void> {
    if (!recordRef) {
      return null;
    }

    return new Promise((resolve, reject) => {
      flag = flag || recordRef.flag;
      HttpClient.action(action, recordRef.repository, {
        remove: { flag, uuid: recordRef.uuid },
      })
        .then(() => {
          const lists = this.getLists();
          Object.entries(lists).forEach(([signature, list]) => {
            const splices: number[] = [];
            for (let [index, ref] of Object.entries(list)) {
              if (
                ref.repository === recordRef.repository &&
                ref.uuid === recordRef.uuid
              ) {
                splices.unshift(parseInt(index));
              }
            }
            splices.map((index) => lists[signature].splice(index, 1));
          });
          const collections = this.getCollections();
          Object.entries(collections).forEach(([signature, list]) => {
            const splices: number[] = [];
            for (let [index, ref] of Object.entries(list)) {
              if (
                ref.repository === recordRef.repository &&
                ref.uuid === recordRef.uuid
              ) {
                splices.unshift(parseInt(index));
              }
            }
            splices.map((index) => collections[signature].splice(index, 1));
          });

          const records = this.getRecords();
          delete records[recordRef.repository][recordRef.uuid];

          replaceState({
            records,
            lists,
            collections,
          });

          resolve(null);
        })
        .catch((ex) => reject(ex));
    });
  }
  // }

  // retrieve {
  public retrieve(repository: string, uuid: string, flag: string = null): void {
    if (!repository || !uuid) {
      return;
    }

    const signature = `${repository}:${uuid}`;

    if (this.recordStatus[signature]) {
      return;
    }

    this.recordStatus[signature] = true;

    HttpClient.action("retrieve", repository, { retrieve: { uuid, flag } })
      .then((record) => {
        setRecord(repository, uuid, this.transformRecord(record));
      })
      .catch((ex) => {
        if (ex.status === 404) {
          setRecordError(repository, uuid, 404);
        }
        // setRecord(repository, uuid, null);
        console.warn(
          `record from repository <<${repository}>> with uuid <<${uuid}>> not found. ex: ${ex}`
        );
      });
  }
  // }

  // collect {
  public collect(
    foreignRepository: string,
    repository: string,
    foreignKey: string,
    uuid: string
  ): void {
    if (!foreignRepository || !repository || !foreignKey || !uuid) {
      return;
    }

    const signature = [foreignRepository, repository, foreignKey, uuid].join(
      ":"
    );

    if (this.collectStatus[signature]) {
      return;
    }

    this.collectStatus[signature] = true;

    const context = { collect: { foreignRepository, foreignKey, uuid } };

    HttpClient.action("collect", repository, context).then(
      (recordResults: { uuid: string }[]) => {
        const collection: RecordRef[] = [];
        const srepository = this.getRepository(repository) || {};
        recordResults.forEach((recordResult) => {
          srepository[recordResult.uuid] = this.transformRecord(recordResult);
          collection.push(new RecordRef(repository, recordResult.uuid));
        });

        mergeState({
          records: {
            [repository]: srepository,
          },
          collections: {
            [signature]: collection,
          },
        });
      }
    );
  }
  // }

  // handle lists and collections on new records {
  public addToRecords(
    repository: string,
    recordResult: { [key: string]: any }
  ): void {
    if (!repository || !recordResult) {
      return;
    }

    setRecord(
      repository,
      recordResult.uuid,
      this.transformRecord(recordResult)
    );
  }

  public addToList(
    signature: string,
    repository: string,
    recordResult: { [key: string]: any },
    simple: boolean = false
  ): void {
    if (!signature || !repository || !recordResult) {
      return;
    }

    const list = this.getList(signature);
    if (!list) {
      return;
    }
    list.unshift(new RecordRef(repository, recordResult.uuid));

    if (simple) {
      mergeState({
        lists: {
          [signature]: list,
        },
      });
      return;
    }

    mergeState({
      records: {
        [repository]: {
          [recordResult.uuid]: this.transformRecord(recordResult),
        },
      },
      lists: {
        [signature]: list,
      },
    });
  }
  public removeFromList(signature: string, recordRef: RecordRef): void {
    if (!signature || !recordRef) {
      return;
    }

    const list = this.getList(signature);
    if (!list) {
      return;
    }
    const splices: number[] = [];
    for (let [index, ref] of Object.entries(list)) {
      if (
        ref.repository === recordRef.repository &&
        ref.uuid === recordRef.uuid
      ) {
        splices.unshift(parseInt(index));
      }
    }
    splices.map((index) => list.splice(index, 1));
    setList(signature, list);
  }
  public setList(signature: string, list: RecordRef[]): void {
    if (!signature || !list) {
      return;
    }
    this.listStatus[signature] = true;
    setList(signature, list);
  }
  public removeListFromCache(signature: string): void {
    if (!signature) {
      return;
    }

    this.listStatus[signature] = false;
    removeList(signature);
  }

  public addToCollection(
    signature: string,
    repository: string,
    recordResult: { [key: string]: any }
  ): void {
    if (!signature || !repository || !recordResult) {
      return;
    }

    const collection = this.getCollection(signature);
    if (!collection) {
      return;
    }
    const recordRef = new RecordRef(repository, recordResult.uuid);
    collection.unshift(recordRef);

    mergeState({
      records: {
        [repository]: {
          [recordRef.uuid]: this.transformRecord(recordResult),
        },
      },
      collections: {
        [signature]: collection,
      },
    });
  }
  public removeFromCollection(signature: string, recordRef: RecordRef): void {
    if (!signature || !recordRef) {
      return;
    }

    const collection = this.getCollection(signature);
    if (!collection) {
      return;
    }
    const splices: number[] = [];
    for (let [index, ref] of Object.entries(collection)) {
      if (
        ref.repository === recordRef.repository &&
        ref.uuid === recordRef.uuid
      ) {
        splices.unshift(parseInt(index));
      }
    }
    splices.map((index) => collection.splice(index, 1));
    setCollection(signature, collection);
  }
  public setCollection(signature: string, list: RecordRef[]): void {
    if (!signature || !list) {
      return;
    }

    this.collectStatus[signature] = true;
    setCollection(signature, list);
  }
  public removeCollectionFromCache(signature: string): void {
    if (!signature) {
      return;
    }

    this.collectStatus[signature] = false;
    removeCollection(signature);
  }
  // }

  public getRecord(repository: string, uuid: string): Record {
    try {
      return store.getState().records[repository][uuid] || null;
    } catch (ex) {
      return null;
    }
  }

  public mapRefsToRecords(refList: RecordRef[]): Record[] {
    if (typeof refList === "undefined") {
      return null;
    }
    if (!refList.length) {
      return [];
    }
    const records = this.getRecords();
    return refList.map((ref) => records[ref.repository][ref.uuid]);
  }

  public reset(): void {
    this.recordStatus = {};
    this.collectStatus = {};
    this.listStatus = {};
    resetState();
  }

  //

  protected getRecords(): { [key: string]: { [key: string]: Record } } {
    return store.getState().records;
  }

  protected getRepository(repository: string): { [key: string]: Record } {
    return store.getState().records[repository];
  }

  public getLists(): { [key: string]: RecordRef[] } {
    return store.getState().lists;
  }

  protected getList(signature: string): RecordRef[] {
    return store.getState().lists[signature];
  }

  protected getCollections(): { [key: string]: RecordRef[] } {
    return store.getState().collections;
  }

  protected getCollection(signature: string): RecordRef[] {
    return store.getState().collections[signature];
  }

  //

  protected transformRecord(questionEmails: { [key: string]: any }): Record {
    return new Record(questionEmails);
  }
})();

class Record {
  [key: string]: any;
  publishDate: any;
  publishState: string;
  uuid: string;
  created_at: string;
  updated_at: string;

  public constructor(record: { [key: string]: any }) {
    // todo: ???????????? what do we do here?

    // Object.keys(record).map(prop => {
    //     this[prop] = record[prop];
    // });

    Object.keys(record).forEach((prop) => {
      if (
        record[prop] &&
        typeof record[prop] === "object" &&
        record[prop].repository &&
        record[prop].uuid
      ) {
        this[prop] = new RecordRef(record[prop].repository, record[prop].uuid);
      } else {
        this[prop] = record[prop];
      }
    });
  }

  public toRef(repository: string): RecordRef {
    return new RecordRef(repository, this.uuid);
  }
}

class RecordObject {
  public ref: RecordRef;
  public record: Record;

  constructor(ref: RecordRef, record: Record) {
    this.ref = ref;
    this.record = record;
  }
}

class RecordRef {
  public repository: string;
  public uuid: string;
  public flag: string;
  public token: string;
  public active: boolean;

  constructor(
    repository: string,
    uuid: string,
    flag: string = null,
    token: string = null,
    active: boolean = null
  ) {
    this.repository = repository;
    this.uuid = uuid;
    this.flag = flag;
    this.token = token;
    this.active = active;
  }
}

export { Record, RecordObject, RecordRef };
