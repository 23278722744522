import React, { CSSProperties, ReactNode } from "react";
// import { getClientType } from "../LayoutHelper";
// import { Text as RnText, TextStyle } from "react-native";

export type TextProps = {
  style?: // TextStyle |
  CSSProperties;
  children: ReactNode;
};
export default class Text extends React.Component<TextProps> {
  protected text: string;
  protected style: { [key: string]: any };

  constructor(props: TextProps) {
    super(props);
    this.text = null;
    this.style = props.style ? props.style : {};
  }

  public render(): ReactNode {
    const text =
      this.text && typeof this.text === "string"
        ? this.text
        : this.props.children;
    const content = text && typeof text === "string" ? text : "";
    const style = this.style ? this.style : {};

    // switch (getClientType()) {
    // case 'ctweb':
    return <span style={style}>{content}</span>;
    // case 'ctmobile':
    //     return <RnText style={style}>{content}</RnText>;
    // default:
    //     return null;
    // }
  }
}
