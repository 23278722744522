import React from "react";
import Field, { FieldContext, FieldFlagType, FieldSignal } from "../../Tools/FormBuilder/Field";
import Box from "@mui/material/Box";
import { InfoBubble } from "../../Layout/Components/InfoBubble";


interface UrlFieldProps {
  onChange?: (value: string) => void;
}

export default class UrlField extends Field {
  private onChange: UrlFieldProps['onChange'];

  constructor(id: string, flag?: FieldFlagType, value?: any, context?: FieldContext, onChange?: UrlFieldProps['onChange']) {
    super(id, flag, value, context);
    this.onChange = onChange || (() => { });
  }
  public display(signal: FieldSignal): React.ReactNode {
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
        ? "#ff3333"
        : "transparent";
    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <input
          autoFocus={this.context.focus}
          defaultValue={this.value}
          id={this.id}
          onBlur={() => {
            signal.blur();
          }}
          onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
            this.change(ev.target.value);
            this.onChange(ev.target.value);
          }}
          onFocus={() => {
            signal.focus();
          }}
          onKeyPress={(ev) => {
            if (
              ev.key === "Enter" &&
              typeof this.context.onReturn === "function"
            ) {
              ev.preventDefault();
              this.context.onReturn();
              return false;
            }
          }}
          placeholder={this.context.label}
          ref={(ref) => (Field.fieldRefs[this.id] = ref)}
          style={{
            borderColor: colorForError,
            boxShadow: `0px 0px 5px ${colorForError}`,
            borderWidth: 1,
            //
            backgroundColor: !this.context.bgColor
              ? "#f2f5f7"
              : this.context.bgColor,
            borderRadius: 16,
            color: !this.context.color ? "#093b60" : this.context.color,
            fontSize: 18,
            outline: "none",
            padding: 18,
            paddingLeft: 32,
            paddingRight: 32,
            width: "100%",
          }}
          type={"url"}
        />
        <Box sx={{ alignSelf: "center", position: "absolute", right: ".8rem" }}>
          {!this.value ? <InfoBubble fieldId={this.id} /> : null}
        </Box>
      </Box>
    );
  }
}
