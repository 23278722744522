import { Fragment } from "react";
import styled from "styled-components";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { Title } from "./components/Title";
import { LinkDocuments } from "./components/LinkDocuments";
import { LinkExternal } from "./components/LinkExternal";
import { Text } from "./components/Text";
import { Address } from "./components/Address";
import { AddToCalendar } from "./components/AddToCalendar";
import { Answer } from "./components/Answer";
import { EventDateTime } from "./components/EventDateTime";
import { FormState } from "../../../../../Tools/FormBuilder/FormBuilder";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import { Video } from "./components/Video";
import { ViewTitle } from "./components/ViewTitle";

type SubItem = {
  fields: { title: string; text: string };
  metas: {};
};

const DivWithMobileImageRight = styled.div`
  background-image: url("/icons/mobile.right.part.png");
  background-repeat: no-repeat;
  background-position: center top;
  margin-right: 35px;
  padding: 70px 30px;
`;

const PreviewMore: React.FC<{ form: FormState; switchView: () => void }> = ({
  form,
  switchView,
}) => {
  const title = form.getFieldValue("title");
  const coverField = form.getField("cover");
  const text = form.getFieldValue("text");
  const documents = form.getFieldValue("documents")
    ? Object.values(form.getFieldValue("documents"))
    : null;
  const link = form.getFieldValue("link");
  const video = form.getField("video")
    ? form.getField("video")["videoSrc"]
    : null;

  const answer1 = form.getMetaValue("answer1")
    ? form.getMetaValue("answer1")
    : null;
  const answer2 = form.getMetaValue("answer2")
    ? form.getMetaValue("answer2")
    : null;
  const answer3 = form.getMetaValue("answer3")
    ? form.getMetaValue("answer3")
    : null;
  const poll = answer1 || answer2 || answer3;
  const address = form.getFieldValue("address")
    ? form.getFieldValue("address")
    : null;

  const subItems = form.getMetaValue("subItems");
  const startTime = form.getFieldValue("startTime");
  const endTime = form.getFieldValue("endTime");
  const canJoin = form.getFieldValue("canJoin");
  const event = address || startTime || endTime || canJoin;

  return (
    <>
      <ViewTitle titleText={trans("trans.ui.detailView")} />
      <Box sx={{ display: "flex" }}>
        <DivWithMobileImageRight>
          <div
            style={{
              width: "33px",
              height: "356px",
              transform: "rotate(0deg)",
            }}
          >
            <div
              style={{
                padding: "15px",
                color: "#053C60",
                fontSize: "48px",
                position: "absolute",
                top: "190px",
                left: "-45px",
                cursor: "pointer",
              }}
              onClick={switchView}
            >
              <ArrowBackIcon fontSize="inherit" color="inherit" />
            </div>
          </div>
        </DivWithMobileImageRight>
        <Box
          sx={{
            backgroundImage: 'url("/icons/mobile.png")',
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center top",
            backgroundSize: "contain",
            backgroundOrigin: "border-box",
            padding: "70px 30px",
            marginRight: "35px",
          }}
        >
          <Card
            sx={{
              height: "567px",
              overflowY: "auto",
              width: "299px",
              transform: "rotate(0deg)",
              padding: "0.8rem 5px",
            }}
          >
            <Title title={title} color="#0A3B60" />
            {coverField && (
              <CardMedia
                component="img"
                src={coverField["imgSrc"]}
                sx={{ borderRadius: "16px", margin: "20px 2px 10px" }}
              />
            )}
            <CardContent sx={{ padding: 0 }}>
              {text && !poll ? <Text text={text} /> : null}
              {video ? (
                <>
                  <Divider />
                  <Box
                    sx={{
                      height: "275px",
                      marginTop: "30px",
                      marginBottom: "15px",
                      borderRadius: "16px",
                    }}
                  >
                    <Video videoUrl={video} />
                  </Box>
                </>
              ) : null}
              {event && (
                <>
                  <Divider />
                  <Box sx={{ marginTop: "30px" }}>
                    <EventDateTime start={startTime} end={endTime} />
                    <Address address={address} />
                    <CardMedia
                      component="img"
                      src="/icons/map.png"
                      sx={{ borderRadius: "16px", marginBottom: "10px" }}
                    />
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box
                        sx={{
                          color: "#0A3B60",
                          fontSize: "14px",
                          fontWeight: "bold",
                          display: "flex",
                          marginBottom: "auto",
                        }}
                      >
                        <AddToCalendar text={trans("trans.ui.addToCalendar")} />
                      </Box>
                      <Box
                        sx={{
                          color: "#157DBA",
                          fontSize: "14px",
                          fontWeight: "bold",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {documents &&
                          documents.map((file: any) => (
                            <LinkDocuments fileName="Datei" key={file.name} />
                          ))}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}

              {documents && !event && (
                <Box
                  sx={{
                    color: "#157DBA",
                    fontSize: "14px",
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    marginBottom: "15px",
                  }}
                >
                  {documents.map((file: any) => (
                    <LinkDocuments fileName="Datei" key={file.name} />
                  ))}
                </Box>
              )}

              {subItems
                ? subItems.map((subItem: SubItem, index: number) => {
                    const { title, text } = subItem.fields;
                    return (
                      <Fragment key={index}>
                        <Divider />
                        <CardContent sx={{ padding: "15px 0px 0px" }}>
                          <Title title={title} color="#157DBA" />
                          <Text text={text} />
                        </CardContent>
                      </Fragment>
                    );
                  })
                : null}
              {canJoin ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "30px",
                  }}
                >
                  <Typography
                    component="span"
                    sx={{
                      color: "#207BBA",
                      fontWeight: "bold",
                      fontSize: "0.9rem",
                    }}
                  >
                    {trans("trans.eventAttendance.buttonJoin")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#B9CCDB",
                      fontSize: "0.85rem",
                      fontWeight: "bold",
                    }}
                    component="span"
                  >
                    {trans("trans.eventAttendance.buttonInterested")}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#B9CCDB",
                      fontSize: "0.85rem",
                      fontWeight: "bold",
                    }}
                    component="span"
                  >
                    {trans("trans.eventAttendance.buttonNotInterested")}
                  </Typography>
                </Box>
              ) : null}
              {poll ? (
                <Box sx={{ marginTop: "20px" }}>
                  {answer1 && <Answer answerText={answer1} answerNumber={1} />}
                  {answer2 && <Answer answerText={answer2} answerNumber={2} />}
                  {answer3 && <Answer answerText={answer3} answerNumber={3} />}
                </Box>
              ) : null}
              {link && (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <LinkExternal label={trans(Label.UI__GO_TO_LINK)} />
                </Box>
              )}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};
export default PreviewMore;
