export default class Label {
  public static readonly ADMIN__CONTENT_WIZARD_TITLE =
    "trans:admin:contentWizardTitle";
  public static readonly ADMIN__CONTENT_WIZARD_ITEM_TYPE =
    "trans:admin:contentWizardItemType";
  public static readonly ADMIN__CONTENT_WIZARD_PUBLISH_STATE =
    "trans:admin:contentWizardPublishState";
  public static readonly ADMIN__CONTENT_WIZARD_STEP_1_TITLE =
    "trans:admin:contentWizardStep1Title";
  public static readonly ADMIN__CONTENT_WIZARD_STEP_2_TITLE =
    "trans:admin:contentWizardStep2Title";
  public static readonly ADMIN__CONTENT_WIZARD_STEP_3_TITLE =
    "trans:admin:contentWizardStep3Title";
  public static readonly ADMIN__DISPLAY_ASSOCIATION_MEMBERSHIP_TITLE =
    "trans:admin:displayAssociationMembershipTitle";
  public static readonly ADMIN__NAV_ASSOCIATION = "trans:admin:navAssociation";
  public static readonly ADMIN__NAV_MY_ASSOCIATION =
    "trans:admin:navMyAssociation";
  public static readonly ADMIN__NAV_ASSOCIATION_MEMBERSHIP =
    "trans:admin:navAssociationMembership";
  public static readonly ADMIN__NAV_QUESTIONS = "trans:admin:navQuestions";
  public static readonly ADMIN__NAV_CONTENT_WIZARD =
    "trans:admin:navContentWizard";
  public static readonly ADMIN__NAV_NEWS_ITEMS = "trans:admin:navNewsItems";
  public static readonly ADMIN__NAV_NEGOTIATION_ITEMS =
    "trans:admin:navNegotiationItems";
  public static readonly ADMIN__NAV_RELEASE_CENTER =
    "trans:admin:navReleaseCenter";
  public static readonly ADMIN__NAV_RELEASE_CENTER_MANUAL =
    "trans:admin:navReleaseCenterManual";
  public static readonly ADMIN__NAV_RELEASE_CENTER_SUBSCRIBE =
    "trans:admin:navReleaseCenterSubscribe";
  public static readonly ADMIN__NAV_RELEASE_CENTER_AUTO_ADOPT =
    "trans:admin:navReleaseCenterAutoAdopt";
  public static readonly ADMIN__NAV_TOPIC_ITEMS = "trans:admin:navTopicItems";
  public static readonly ADMIN__NAV_USER = "trans:admin:navUser";
  public static readonly ADMIN__NAV_USERS_MEMBERS =
    "trans:admin:navUsersMembers";
  public static readonly ADMIN__NAV_SETTINGS = "trans:admin:navSettings";
  public static readonly ADMIN__RELEASE_CENTER_MANUAL_REVIEW =
    "trans:admin:navReleaseCenterManualReview";
  public static readonly ADMIN__RELEASE_CENTER_REJECT =
    "trans:admin:navReleaseCenterManualReject";
  public static readonly ADMIN__RELEASE_CENTER_REMOVE =
    "trans:admin:navReleaseCenterManualRemove";
  public static readonly ADMIN__RELEASE_CENTER_TITLE =
    "trans:admin:navReleaseCenterTitle";
  public static readonly ADMIN__RELEASE_CENTER_SUBTITLE =
    "trans:admin:navReleaseCenterSubtitle";
  public static readonly ADMIN__RELEASE_CENTER_REJECTED_LIST =
    "trans:admin:navReleaseCenterRejectedList";
  public static readonly ADMIN__RELEASE_CENTER_LIST =
    "trans:admin:navReleaseCenterList";
  public static readonly ADMIN__SUB_ITEM = "trans:admin:subItem";
  //
  public static readonly ADMIN__ASSOCIATION_CREATE =
    "trans:admin:associationCreate";
  public static readonly ADMIN__ASSOCIATION_EDIT =
    "trans:admin:associationEdit";
  public static readonly ADMIN__ASSOCIATION_VIEW =
    "trans:admin:associationView";
  public static readonly ADMIN__ASSOCIATION_MEMBERSHIP_APPROVE =
    "trans:admin:associationMembershipApprove";
  public static readonly ADMIN__ASSOCIATION_MEMBERSHIP_REJECT =
    "trans:admin:associationMembershipReject";
  public static readonly ADMIN__ASSOCIATION_MEMBERSHIP_VIEW =
    "trans:admin:associationMembershipView";
  public static readonly ADMIN__ASSOCIATION_MEMBERSHIP_VIEW_TITLE =
    "trans:admin:associationMembershipViewTitle";
  public static readonly ADMIN__ITEM_BELONGS_TO_ASSOCIATION =
    "trans:admin:itemBelongsToAssociation";
  public static readonly ADMIN__ITEM_EDIT = "trans:admin:itemEdit";
  public static readonly ADMIN__ITEM_VIEW = "trans:admin:itemView";
  public static readonly ADMIN__QUESTION_VIEW = "trans:admin:questionView";
  public static readonly ADMIN__QUESTION_VIEW_TITLE =
    "trans:admin:questionViewTitle";
  public static readonly ADMIN__USER_EDIT = "trans:admin:userEdit";
  public static readonly ADMIN__USER_VIEW = "trans:admin:userView";
  //
  public static readonly ASSOCIATION__JOIN = "trans:association:join";
  public static readonly ASSOCIATION__JOIN_SUBMIT =
    "trans:association:joinSubmit";
  public static readonly ASSOCIATION__PARENT = "trans:association:parent";
  //
  public static readonly ASSOCIATION_MEMBERSHIP__TARIFF_AREA_YES =
    "trans:associationMembership:tariffAreaYes";
  public static readonly ASSOCIATION_MEMBERSHIP__TARIFF_AREA_NO =
    "trans:associationMembership:tariffAreaNo";
  //
  public static readonly ASSOCIATION_MEMBERSHIP__APPROVED =
    "trans:associationMembership:approved";
  public static readonly ASSOCIATION_MEMBERSHIP__PENDING =
    "trans:associationMembership:pending";
  public static readonly ASSOCIATION_MEMBERSHIP__REJECTED =
    "trans:associationMembership:rejected";
  //
  public static readonly ERROR__API_ENDPOINT_NOT_FOUND =
    "trans:error:apiEndpointNotFound";
  public static readonly ERROR__BAD_CREDENTIALS = "trans:error:badCredentials";
  public static readonly ERROR__BAD_PASSWORD_RESET =
    "trans:error:badPasswordReset";
  public static readonly ERROR__BAD_PERMISSIONS = "trans:error:badPermissions";
  public static readonly ERROR__BAD_REQUEST = "trans:error:badRequest";
  public static readonly ERROR__EMAIL_EXISTS = "trans:error:emailExists";
  public static readonly ERROR__EMAIL_NOT_EXISTS = "trans:error:emailNotExists";
  public static readonly ERROR__RECORD_NOT_FOUND = "trans:error:recordNotFound";
  public static readonly ERROR__RESOURCE_NOT_FOUND =
    "trans:error:resourceNotFound";
  //
  public static readonly FORM__FACEBOOK_ACCOUNT_TOKEN =
    "trans:form:facebookAccountToken";
  public static readonly FORM__HAS_TARIFF_AREA = "trans:form:hasTariffArea";
  public static readonly FORM__HAS_EXCLUSIVE_TARIFF_AREA =
    "trans:form:hasExclusiveTariffArea";
  public static readonly FORM__FACEBOOK_ACCOUNT = "trans:form:facebookAccount";
  public static readonly FORM__TWITTER_ACCOUNT = "trans:form:twitterAccount";
  public static readonly FORM__ADD_EMAIL = "trans:form:addEmail";
  public static readonly FORM__ADDRESS = "trans:form:address";
  public static readonly FORM__AGREEMENT = "trans:form:agreement";
  public static readonly FORM__ANSWER = "trans:form:answer";
  public static readonly FORM__ANSWERS = "trans:form:answers";
  public static readonly FORM__ASSOCIATION_NAME = "trans:form:associationName";
  public static readonly FORM__AUTH = "trans:form:auth";
  public static readonly FORM__CATEGORY = "trans:form:category";
  public static readonly FORM__CONTACT_ADDRESS = "trans:form:contactAddress";
  public static readonly FORM__CONTACT_EMAIL = "trans:form:contactEmail";
  public static readonly FORM__CONTACT_PHONE = "trans:form:contactPhone";
  public static readonly FORM__CONTENT_TYPE_QUESTION =
    "trans:form:contentTypeQuestion";
  public static readonly FORM__COVER = "trans:form:cover";
  public static readonly FORM__CREATE = "trans:form:create";
  public static readonly FORM__CREATED_AT = "trans:form:createdAt";
  public static readonly FORM__DATA_PRIVACY_AGREEMENT_TEXT =
    "trans:form:dataPrivacyAgreementText";
  public static readonly FORM__DATA_PRIVACY_AGREEMENT_LINK_TEXT =
    "trans:form:dataPrivacyAgreementLinkText";
  public static readonly FORM__DESC = "trans:form:desc";
  public static readonly FORM__DOCUMENT = "trans:form:document";
  public static readonly FORM__DISPLAY_AREA = "trans:form:displayArea";
  public static readonly FORM__DISPLAY_AREA_QUESTION =
    "trans:form:displayAreaQuestion";
  public static readonly FORM__EMAIL = "trans:form:email";
  public static readonly FORM__EMAIL_CONFIRMED = "trans:form:emailConfirmed";
  public static readonly FORM__END_TIME = "trans:form:endTime";
  public static readonly FORM__EVENT_CAN_JOIN_FLAG =
    "trans:form:eventCanJoinFlag";
  public static readonly FORM__EXCLUSIVE = "trans:form:exclusive";
  public static readonly FORM__EXPIRY = "trans:form:expiry";
  public static readonly FORM__FIRST_NAME = "trans:form:firstName";
  public static readonly FORM__FORGOT_PASSWORD = "trans:form:forgotPassword";
  public static readonly FORM__HIDDEN = "trans:form:hidden";
  public static readonly FORM__ICON = "trans:form:icon";
  public static readonly FORM__ID = "trans:form:id";
  public static readonly FORM__IS_ADMIN = "trans:form:isAdmin";
  public static readonly FORM__LAST_NAME = "trans:form:lastName";
  public static readonly FORM__LINK = "trans:form:link";
  public static readonly FORM__LOGO = "trans:form:logo";
  public static readonly FORM__MAKE_ADMIN = "trans:form:makeAdmin";
  public static readonly FORM__NAME = "trans:form:name";
  public static readonly FORM__NOW = "trans:form:now";
  public static readonly FORM__OPTION = "trans:form:option";
  public static readonly FORM__ORGANISATION_NAME =
    "trans:form:organisationName";
  public static readonly FORM__ORGANISATION_POSITION =
    "trans:form:organisationPosition";
  public static readonly FORM__PARTICIPANTS = "trans:form:participants";
  public static readonly FORM__PASSWORD = "trans:form:password";
  public static readonly FORM__PASSWORD_CHANGE = "trans:form:passwordChange";
  public static readonly FORM__PASSWORD_CONFIRM = "trans:form:passwordConfirm";
  public static readonly FORM__PHONE_NUMBER = "trans:form:phoneNumber";
  public static readonly FORM__PUBLISH = "trans:form:publish";
  public static readonly FORM__SAVE_DRAFT = "trans:form:saveDraft";
  public static readonly FORM__PUBLISH_STATE = "trans:form:publishState";
  public static readonly FORM__PUBLISH_STATE_LABEL =
    "trans.form.publishStateLabel";
  public static readonly FORM__PUBLISH_DATE = "trans:form:publishDate";
  public static readonly FORM__REJECTED_DATE = "trans:form:rejectedDate";
  public static readonly FORM__PUBLISHER = "trans:form:publisher";
  public static readonly FORM__PUBLISHER_LABEL = "trans:form:publisherLabel";
  public static readonly FORM__PUSH_NOTIFICATION =
    "trans:form:pushNotification";
  public static readonly FORM__QUESTION = "trans:form:question";
  public static readonly FORM__QUESTIONS_EMAIL = "trans:form:questionsEmail";
  public static readonly FORM__MEMBERSHIP_REQUEST_EMAIL =
    "trans:form:membershipRequestEmail";
  public static readonly FORM__CONTENT_ADDED_EMAIL =
    "trans:form:contentAddedEmail";
  public static readonly FORM__PERCENT = "trans:form:percent";
  public static readonly FORM__REGISTER = "trans:form:register";
  public static readonly FORM__REMOVE_ADMIN_STATUS =
    "trans:form:removeAdminStatus";
  public static readonly FORM__RESET_PASSWORD_EMAIL_LABEL =
    "trans:form:resetPasswordEmailLabel";
  public static readonly FORM__RESET_PASSWORD_EXECUTE =
    "trans:form:resetPasswordExecute";
  public static readonly FORM__RESET_PASSWORD_LABEL =
    "trans:form:resetPasswordLabel";
  public static readonly FORM__RESET_PASSWORD_REQUEST =
    "trans:form:resetPasswordRequest";
  public static readonly FORM__SELECT_ASSOCIATION =
    "trans:form:selectAssociation";
  public static readonly FORM__SELECT_ASSOCIATION_SUB =
    "trans:form:selectAssociationSub";
  public static readonly FORM__SHARE_OF_TOTAL = "trans:form:shareOfTotal";
  public static readonly FORM__START_TIME = "trans:form:startTime";
  public static readonly FORM__STARTED = "trans:form:started";
  public static readonly FORM__STATE = "trans:form:state";
  public static readonly FORM__STATS = "trans:form:stats";
  public static readonly FORM__SUBCATEGORY = "trans:form:subcategory";
  public static readonly FORM__SUBMIT = "trans:form:submit";
  public static readonly FORM__VOTES = "trans:form:votes";
  public static readonly FORM__TEXT = "trans:form:text";
  public static readonly FORM__TITLE = "trans:form:title";
  public static readonly FORM__UPLOAD = "trans:form:upload";
  public static readonly FORM__USER_TYPE = "trans:form:userType";
  public static readonly FORM__VIDEO = "trans:form:video";
  //
  public static readonly GATE__CONTENT = "trans:gate:content";
  public static readonly GATE__TITLE = "trans:gate:title";
  // items {
  // display area
  public static readonly ITEM__DISPLAY_TARIFF_AREA =
    "trans:item:displayTariffArea";
  public static readonly ITEM__DISPLAY_MEMBER_AREA =
    "trans:item:displayMemberArea";
  // type all
  public static readonly ITEM__TYPE_ALL = "trans:item:typeAll";
  // news {
  public static readonly ITEM__TYPE_EXCLUSIVE = "trans:item:typeExclusive";
  // message
  public static readonly ITEM__TAG_MESSAGE = "trans:item:tagMessage";
  public static readonly ITEM__TAG_LINK = "trans:item:tagLink";
  public static readonly ITEM__TAG_GRAPHIC = "trans:item:tagGraphic";
  public static readonly ITEM__TAG_VIDEO = "trans:item:tagVideo";
  public static readonly ITEM__TAG_QUOTE = "trans:item:tagQuote";
  public static readonly ITEM__TYPE_NEWS = "trans:item:typeNews";
  // socialMedia
  public static readonly ITEM__TAG_FACEBOOK = "trans:item:tagFacebook";
  public static readonly ITEM__TAG_TWITTER = "trans:item:tagTwitter";
  public static readonly ITEM__TAG_INSTAGRAM = "trans:item:tagInstagram";
  public static readonly ITEM__TAG_LINKEDIN = "trans.item.tagLinkedin";
  public static readonly ITEM__TYPE_SOCIAL_MEDIA = "trans:item:typeSocialMedia";
  // mediaReport
  public static readonly ITEM__TAG_MEDIA_REPORT = "trans:item:tagMediaReport";
  public static readonly ITEM__TYPE_MEDIA_REPORT = "trans:item:typeMediaReport";
  // }
  // negotiations
  public static readonly ITEM__TAG_ARGUMENT = "trans:item:tagArgument";
  public static readonly ITEM__TYPE_ARGUMENT = "trans:item:typeArgument";
  public static readonly ITEM__TAG_CURRENT_STATUS =
    "trans:item:tagCurrentStatus";
  public static readonly ITEM__TYPE_CURRENT_STATUS =
    "trans:item:typeCurrentStatus";
  public static readonly ITEM__TAG_DOCUMENT = "trans:item:tagDocument";
  public static readonly ITEM__PUBLISHED = "trans:item:published";
  public static readonly ITEM__DRAFT = "trans:item:draft";
  public static readonly ITEM__TYPE_DOCUMENT = "trans:item:typeDocument";
  public static readonly ITEM__TAG_CONCLUSION = "trans:item:tagConclusion";
  public static readonly ITEM__TYPE_CONCLUSION = "trans:item:typeConclusion";
  public static readonly ITEM__TAG_FIXTURE = "trans:item:tagFixture";
  public static readonly ITEM__TYPE_FIXTURE = "trans:item:typeFixture";
  // tarifrunde topics
  public static readonly ITEM__TAG_ECONOMY = "trans:item:tagEconomy";
  public static readonly ITEM__TYPE_ECONOMY = "trans:item:typeEconomy";
  public static readonly ITEM__TAG_RESTRUCTURING =
    "trans:item:tagRestructuring";
  public static readonly ITEM__TYPE_RESTRUCTURING =
    "trans:item:typeRestructuring";
  public static readonly ITEM__TAG_TRAINING = "trans:item:tagTraining";
  public static readonly ITEM__TYPE_TRAINING = "trans:item:typeTraining";
  public static readonly ITEM__TAG_SECURING_EMPLOYMENT =
    "trans:item:tagSecuringEmployment";
  public static readonly ITEM__TYPE_SECURING_EMPLOYMENT =
    "trans:item:typeSecuringEmployment";
  public static readonly ITEM__TAG_WORKING_HOURS = "trans:item:tagWorkingHours";
  public static readonly ITEM__TYPE_WORKING_HOURS =
    "trans:item:typeWorkingHours";
  public static readonly ITEM__TAG_PENSIONS = "trans:item:tagPensions";
  public static readonly ITEM__TYPE_PENSIONS = "trans:item:typePensions";
  public static readonly ITEM__TAG_OTHER = "trans:item:tagOther";
  public static readonly ITEM__TYPE_OTHER = "trans:item:typeOther";
  public static readonly ITEM__TAG_COLLECTIVE_AGREEMENT =
    "trans:item:tagCollectiveAgreement";
  public static readonly ITEM__TYPE_COLLECTIVE_AGREEMENT =
    "trans:item:typeCollectiveAgreement";
  public static readonly ITEM__TAG_PARTICIPATION =
    "trans:item:tagParticipation";
  public static readonly ITEM__TYPE_PARTICIPATION =
    "trans:item:typeParticipation";
  // member area topics
  public static readonly ITEM__TAG_INDUSTRY = "trans:item:tagIndustry";
  public static readonly ITEM__TYPE_INDUSTRY = "trans:item:typeIndustry";
  public static readonly ITEM__TAG_CORONA = "trans:item:tagCorona";
  public static readonly ITEM__TYPE_CORONA = "trans:item:typeCorona";
  public static readonly ITEM__TAG_WAGE_POLICY = "trans:item:tagWagePolicy";
  public static readonly ITEM__TYPE_WAGE_POLICY = "trans:item:typeWagePolicy";
  public static readonly ITEM__TAG_DIGITALIZATION =
    "trans:item:tagDigitalization";
  public static readonly ITEM__TYPE_DIGITALIZATION =
    "trans:item:typeDigitalization";
  public static readonly ITEM__TAG_EMPLOYMENT = "trans:item:tagEmployment";
  public static readonly ITEM__TYPE_EMPLOYMENT = "trans:item:typeEmployment";
  public static readonly ITEM__TAG_SOCIAL_POLICY = "trans:item:tagSocialPolicy";
  public static readonly ITEM__TYPE_SOCIAL_POLICY =
    "trans:item:typeSocialPolicy";
  public static readonly ITEM__TAG_EDUCATION = "trans:item:tagEducation";
  public static readonly ITEM__TYPE_EDUCATION = "trans:item:typeEducation";
  public static readonly ITEM__TAG_SKILLED_EMPLOYMENT =
    "trans:item:tagSkilledEmployment";
  public static readonly ITEM__TYPE_SKILLED_EMPLOYMENT =
    "trans:item:typeSkilledEmployment";
  public static readonly ITEM__TAG_LABOR_POLICY = "trans:item:tagLaborPolicy";
  public static readonly ITEM__TYPE_LABOR_POLICY = "trans:item:typeLaborPolicy";
  public static readonly ITEM__TAG_LEGAL = "trans:item:tagLegal";
  public static readonly ITEM__TYPE_LEGAL = "trans:item:typeLegal";
  public static readonly ITEM__TAG_INTERNATIONAL =
    "trans:item:tagInternational";
  public static readonly ITEM__TYPE_INTERNATIONAL =
    "trans:item:typeInternational";
  public static readonly ITEM__TAG_ELECTIONS = "trans:item:tagElections";
  public static readonly ITEM__TYPE_ELECTIONS = "trans:item:typeElections";
  // member area news
  public static readonly ITEM__TYPE_NEWS_MEMBER_AREA =
    "trans:item:typeNewsMemberArea";
  public static readonly ITEM__TYPE_FACEBOOK_MEMBER_AREA =
    "trans:item:typeFacebookMemberArea";
  public static readonly ITEM__TYPE_TWITTER_MEMBER_AREA =
    "trans:item:typeTwitterMemberArea";
  public static readonly ITEM__TYPE_INSTAGRAM_MEMBER_AREA =
    "trans:item:typeInstagramMemberArea";
  public static readonly ITEM__TAG_POLL = "trans:item:tagPoll";
  public static readonly ITEM__TYPE_POLL = "trans:item:typePoll";
  // events
  public static readonly ITEM__TAG_EVENT = "trans:item:tagEvent";
  public static readonly ITEM__TYPE_EVENT = "trans:item:typeEvent";
  public static readonly ITEM__TYPE_EVENT_CURRENT =
    "trans:item:typeEventCurrent";
  public static readonly ITEM__TYPE_EVENT_INTERESTED =
    "trans:item:typeEventInterested";
  public static readonly ITEM__TYPE_EVENT_JOINED = "trans:item:typeEventJoined";
  public static readonly ITEM__TYPE_EVENT_PAST = "trans:item:typeEventPast";
  //
  public static readonly QUESTIONS__NAV_BUTTON = "trans:questions:navButton";
  public static readonly QUESTIONS__UI_TITLE = "trans:questions:uiTitle";
  public static readonly QUESTIONS__PAGE_TITLE = "trans:questions:pageTitle";
  public static readonly QUESTIONS__PAGE_TEXT = "trans:questions:pageText";
  //
  public static readonly SECTION__LOGIN_INFO = "trans:section:loginInfo";
  public static readonly SECTION__PERSONAL_INFO = "trans:section:personalInfo";
  public static readonly SECTION__PROFESSIONAL_INFO =
    "trans:section:professionalInfo";
  //
  public static readonly UI__ACTIVATED = "trans:ui:activated";
  public static readonly UI__ADD_TO_CALENDAR = "trans:ui:addToCalendar";
  public static readonly UI__ALL = "trans:ui:all";
  public static readonly UI__ANY = "trans:ui:any";
  public static readonly UI__BACK = "trans:ui:back";
  public static readonly UI__CLOSE = "trans:ui:close";
  public static readonly UI__CONFIRM = "trans:ui:confirm";
  public static readonly UI__CONFIRM_ABO_REMOVE = "trans:ui:confirmABORemove";
  public static readonly UI__CSV_EXPORT = "trans:ui:csvExport";
  public static readonly UI__DEACTIVATED = "trans:ui:deactivated";
  public static readonly UI__DEAUTH = "trans:ui:deauth";
  public static readonly UI__GO_TO_LINK = "trans:ui:goToLink";
  public static readonly UI__GO_TO_FB_LINK = "trans:ui:goToFbLink";
  public static readonly UI__GO_TO_TWITTER_LINK = "trans:ui:goToTwitterLink";
  public static readonly UI__GO_TO_INSTAGRAM_LINK =
    "trans:ui:goToInstagramLink";
  public static readonly UI__MORE = "trans:ui:more";
  public static readonly UI__NAV_NEWS = "trans:ui:navNews";
  public static readonly UI__NAV_NEGOTIATIONS = "trans:ui:navNegotiations";
  public static readonly UI__NAV_TOPICS = "trans:ui:navTopics";
  public static readonly UI__NAV_NEWS_MEMBER_AREA =
    "trans:ui:navNewsMemberArea";
  public static readonly UI__NAV_TOPICS_MEMBER_AREA =
    "trans:ui:navTopicsMemberArea";
  public static readonly UI__NAV_EVENTS_MEMBER_AREA =
    "trans:ui:navEventsMemberArea";
  public static readonly UI__NEXT = "trans:ui:next";
  public static readonly UI__NO = "trans:ui:no";
  public static readonly UI__NOTHING_FOUND = "trans:ui:nothingFound";
  public static readonly UI__PASSWORD_RESET_REQUEST_SUCCESS =
    "trans:ui:passwordResetRequestSuccess";
  public static readonly UI__PASSWORD_RESET_SUCCESS =
    "trans:ui:passwordResetSuccess";
  public static readonly UI__PREV = "trans:ui:prev";
  public static readonly UI__PRIVACY_POLICY = "trans:ui:privacyPolicy";
  public static readonly UI__PROFILE = "trans:ui:profile";
  public static readonly UI__SETTINGS = "trans:ui:settings";
  public static readonly UI__TITLE = "trans:ui:title";
  public static readonly UI__TITLE_MEMBER_AREA = "trans:ui:titleMemberArea";
  public static readonly UI__SUCCESS = "trans:ui:success";
  public static readonly UI__TERMS_OF_USE = "trans:ui:termsOfUse";
  public static readonly UI__YES = "trans:ui:yes";
  public static readonly UI__EXISTING_TOPICS = "trans:ui:existingTopics";
  public static readonly UI__VIEW_DETAILS = "trans:ui:viewDetails";
  public static readonly UI__TIMESTAMP = "trans:ui:timestamp";
  public static readonly UI__ASSOCIATION = "trans:ui:association";
  public static readonly UI__FIRST_NAME = "trans:ui:firstName";
  public static readonly UI__LAST_NAME = "trans:ui:lastName";
  public static readonly UI__COMPANY = "trans:ui:company";
  public static readonly UI__STATUS = "trans:ui:status";
  public static readonly UI__REQUESTED_TARIFF_AREA_ACCESS =
    "trans:ui:requestedTariffAreaAccess";
  public static readonly UI__UPLOADING_CONTENT_PLEASE_WAIT =
    "trans:ui:uploadingContentPleaseWait";
  //
  public static readonly USER__WIZARD_STEP_FOR_TARIFF_AREA =
    "trans:user:wizardStepForTariffArea";
  public static readonly USER__WIZARD_STEP_0_TITLE =
    "trans:user:wizardStep0Title";
  public static readonly USER__WIZARD_STEP_1_TITLE =
    "trans:user:wizardStep1Title";
  public static readonly USER__WIZARD_STEP_1_TEXT_1 =
    "trans:user:wizardStep1Text1";
  public static readonly USER__WIZARD_STEP_1_TEXT_2 =
    "trans:user:wizardStep1Text2";

  // event attendance states
  public static readonly EVENT_ATTENDANCE__BUTTON_JOIN =
    "trans:eventAttendance:buttonJoin";
  public static readonly EVENT_ATTENDANCE__BUTTON_INTERESTED =
    "trans:eventAttendance:buttonInterested";
  public static readonly EVENT_ATTENDANCE__BUTTON_DECLINE =
    "trans:eventAttendance:buttonDecline";
  public static readonly EVENT_ATTENDANCE__STATE_JOINED =
    "trans:eventAttendance:stateJoined";
  public static readonly EVENT_ATTENDANCE__STATE_INTERESTED =
    "trans:eventAttendance:stateInterested";
  public static readonly EVENT_ATTENDANCE__STATE_DECLINED =
    "trans:eventAttendance:stateDeclined";
  public static readonly EVENT_ATTENDANCE__TITLE =
    "trans:eventAttendance:title";
  // settings
  public static readonly SETTINGS__MODE_TARIFF = "trans:settings:modeTariff";
  public static readonly SETTINGS__MODE_MEMBER = "trans:settings:modeMember";
  public static readonly SETTINGS__ENABLE_ONBOARDING =
    "trans:settings:enableOnboarding";
  public static readonly SETTINGS__RESET_ONBOARDING_FOR_ALL_USERS =
    "trans:settings:resetOnboardingForAllUsers";
  public static readonly SETTINGS__ONBOARDING_PICTURE_MODE =
    "trans:settings:onboardingPictureMode";
  public static readonly SETTINGS__ONBOARDING_PICTURE_MODE_COVER =
    "trans:settings:onboardingPictureModeCover";
  public static readonly SETTINGS__ONBOARDING_PICTURE_MODE_CONTAIN =
    "trans:settings:onboardingPictureModeContain";
  public static readonly SETTINGS__ONBOARDING_PICTURE_MODE_STRETCH =
    "trans:settings:onboardingPictureModeStretch";
  public static readonly SETTINGS__ONBOARDING_PICTURE_MODE_CENTER =
    "trans:settings:onboardingPictureModeCenter";
  public static readonly SETTINGS__ONBOARDING_SKIP =
    "trans:settings:onboardingSkip";
  public static readonly SETTINGS__ONBOARDING_NEXT =
    "trans:settings:onboardingNext";
  public static readonly SETTINGS__ONBOARDING_FINISH =
    "trans:settings:onboardingFinish";
}
export function getLabelField(searchString: string) {
  const itemTags = Object.getOwnPropertyNames(Label).filter(
    (item) => item.indexOf("ITEM__TAG") !== -1
  );
  const terms = Object.fromEntries(
    itemTags.map((item) => {
      const parts = item.split("_");
      const term =
        parts[3] + (parts[4] ? parts[4] : "") + (parts[5] ? parts[5] : "");
      return [term, item];
    })
  );
  return Object.getOwnPropertyDescriptor(
    Label,
    terms[searchString.toLocaleUpperCase()]
  ).value;
}
