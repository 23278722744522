import React from "react";
import { connect } from "react-redux";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import { pushRoute } from "../../../../../State/Router";
import ButtonsField from "../../../../FormFields/ButtonsField";
import { trans } from "../../../../../Tools/LocaleHandler";
import Label from "../../../../Util/Label";
import ContentWizardFormLayout from "../ItemForms/ContentWizardFormLayout";
import { StateType } from "../../../../../State/Store";
import Field from "../../../../../Tools/FormBuilder/Field";
import { RecordObject } from "../../../../../State/Records";

export default connect((state: StateType) => {
  return {
    authObject: state.app.authObject,
  };
})((props: { authObject: RecordObject }) => {
  function setFieldValueToNull(currentField: string, form: any): void {
    let otherFields = [
      "itemTypeNewsForTariffArea",
      "itemTypeNegotiationsForTariffArea",
      "itemTypeNegotiationsGroupForTariffArea",
      "itemTypeTopicsForTariffArea",
      "itemTypeNewsForMemberArea",
      "itemTypeEventsForMemberArea",
      "itemTypeTopicsForMemberArea",
    ];
    for (var key in otherFields) {
      if (otherFields[key] !== currentField) {
        form.setFieldValue(otherFields[key], null);
      }
    }
  }
  return (
    <FormBuilder
      key={"form-contentWizard"}
      action={(blueprint, done) => {
        const displayArea = blueprint.fields.displayArea;
        let itemType =
          blueprint.fields.itemTypeNewsForTariffArea ??
          blueprint.fields.itemTypeNegotiationsForTariffArea ??
          blueprint.fields.itemTypeNegotiationsGroupForTariffArea ??
          blueprint.fields.itemTypeTopicsForTariffArea ??
          blueprint.fields.itemTypeNewsForMemberArea ??
          blueprint.fields.itemTypeEventsForMemberArea ??
          blueprint.fields.itemTypeTopicsForMemberArea;

        if (
          blueprint.fields.itemTypeTopicsForMemberArea ||
          blueprint.fields.itemTypeTopicsForTariffArea
        ) {
          pushRoute(`/Admin/CreateItemTopic/${displayArea}/${itemType}`);
          return;
        }

        if (blueprint.fields.itemTypeNegotiationsGroupForTariffArea) {
          pushRoute(
            `/Admin/CreateItemNegotationGroup/${displayArea}/${itemType}`
          );
          return;
        }

        switch (itemType) {
          // negotiations
          case "document":
            pushRoute(`/Admin/CreateItemDocument/${displayArea}`);
            break;
          case "fixture":
            pushRoute(`/Admin/CreateItemFixture/${displayArea}`);
            break;
          // member topics
          case "industry":
            pushRoute(`/Admin/CreateItemIndustry/${displayArea}`);
            break;
          case "instagram":
            pushRoute(`/Admin/CreateItemInstagram/memberArea`);
            break;
          // news
          case "message":
            pushRoute(`/Admin/CreateItemMessage/${displayArea}`);
            break;
          case "link":
            pushRoute(`/Admin/CreateItemLink/${displayArea}`);
            break;
          case "graphic":
            pushRoute(`/Admin/CreateItemGraphic/${displayArea}`);
            break;
          case "video":
            pushRoute(`/Admin/CreateItemVideo/${displayArea}`);
            break;
          case "quote":
            pushRoute(`/Admin/CreateItemQuote/${displayArea}`);
            break;
          case "facebook":
            pushRoute(`/Admin/CreateItemFacebook/${displayArea}`);
            break;
          case "twitter":
            pushRoute(`/Admin/CreateItemTwitter/${displayArea}`);
            break;
          case "mediaReport":
            pushRoute(`/Admin/CreateItemMediaReport/${displayArea}`);
            break;
          // poll and event
          case "poll":
            pushRoute(`/Admin/CreateItemPoll/${displayArea}`);
            break;
          case "event":
            pushRoute(`/Admin/CreateItemEvent/memberArea`);
            break;
        }
        done();
      }}
      fields={{
        displayArea: new ButtonsField(
          "form-cw-displayArea-id",
          Field.FLAG_REQUIRED,
          null,
          {
            options: [
              {
                label: trans(Label.ITEM__DISPLAY_MEMBER_AREA),
                value: "memberArea",
                onSelect: (form) => {
                  if (
                    [
                      "argument",
                      "facebook",
                      "twitter",
                      "currentStatus",
                      "document",
                      "conclusion",
                      "fixture",
                      "economy",
                      "restructuring",
                      "training",
                      "securingEmployment",
                      "workingHours",
                      "pensions",
                      "collectiveAgreement",
                      "participation",
                    ].indexOf(
                      form.getFieldValue("itemTypeNewsForTariffArea")
                    ) !== -1
                  ) {
                    form.setFieldValue("itemTypeNewsForTariffArea", null);
                  }
                },
              },
              {
                label: trans(Label.ITEM__DISPLAY_TARIFF_AREA),
                value: "tariffArea",
                onSelect: (form) => {
                  if (
                    [
                      "events",
                      "instagram",
                      "corona",
                      "wagePolicy",
                      "digitalization",
                      "employment",
                      "socialPolicy",
                      "education",
                      "skilledEmployment",
                      "laborPolicy",
                      "legal",
                      "international",
                      "elections",
                      "industry",
                    ].indexOf(
                      form.getFieldValue("itemTypeNewsForTariffArea")
                    ) !== -1
                  ) {
                    form.setFieldValue("itemTypeNewsForTariffArea", null);
                  }
                },
              },
            ],
          }
        ),
        itemTypeNewsForTariffArea: new ButtonsField(
          "form-cw-itemTypeNewsForTariffArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              // news
              {
                value: "message",
                label: trans(Label.ITEM__TAG_MESSAGE),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
              {
                value: "link",
                label: trans(Label.ITEM__TAG_LINK),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
              {
                value: "graphic",
                label: trans(Label.ITEM__TAG_GRAPHIC),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
              {
                value: "video",
                label: trans(Label.ITEM__TAG_VIDEO),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
              {
                value: "quote",
                label: trans(Label.ITEM__TAG_QUOTE),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
              {
                value: "facebook",
                label: trans(Label.ITEM__TYPE_SOCIAL_MEDIA),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeNewsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "mediaReport",
                label: trans(Label.ITEM__TAG_MEDIA_REPORT),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
              //
              {
                value: "poll",
                label: trans(Label.ITEM__TAG_POLL),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForTariffArea", form),
              },
            ],
          }
        ),
        itemTypeNegotiationsGroupForTariffArea: new ButtonsField(
          "form-cw-itemTypeNegotiationsForTariffArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              // negotiations
              {
                value: "argument",
                label: trans(Label.ITEM__TAG_ARGUMENT),
                group: "negotation-tarif-group",
                onSelect: (form) => {
                  setFieldValueToNull(
                    "itemTypeNegotiationsGroupForTariffArea",
                    form
                  );
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "currentStatus",
                label: trans(Label.ITEM__TAG_CURRENT_STATUS),
                group: "negotation-tarif-group",
                onSelect: (form) => {
                  setFieldValueToNull(
                    "itemTypeNegotiationsGroupForTariffArea",
                    form
                  );
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "conclusion",
                label: trans(Label.ITEM__TAG_CONCLUSION),
                group: "negotation-tarif-group",
                onSelect: (form) => {
                  setFieldValueToNull(
                    "itemTypeNegotiationsGroupForTariffArea",
                    form
                  );
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
            ],
          },
          true
        ),
        itemTypeNegotiationsForTariffArea: new ButtonsField(
          "form-cw-itemTypeNegotiationsForTariffArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              {
                value: "document",
                label: trans(Label.ITEM__TAG_DOCUMENT),
                onSelect: (form) => {
                  setFieldValueToNull(
                    "itemTypeNegotiationsForTariffArea",
                    form
                  );
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "fixture",
                label: trans(Label.ITEM__TAG_FIXTURE),
                onSelect: (form) => {
                  setFieldValueToNull(
                    "itemTypeNegotiationsForTariffArea",
                    form
                  );
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
            ],
          }
        ),

        itemTypeTopicsForTariffArea: new ButtonsField(
          "form-cw-itemTypeTopicsForTariffArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              //tariff topics
              {
                value: "economy",
                label: trans(Label.ITEM__TAG_ECONOMY),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "restructuring",
                label: trans(Label.ITEM__TAG_RESTRUCTURING),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "training",
                label: trans(Label.ITEM__TAG_TRAINING),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "securingEmployment",
                label: trans(Label.ITEM__TAG_SECURING_EMPLOYMENT),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "workingHours",
                label: trans(Label.ITEM__TAG_WORKING_HOURS),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "pensions",
                label: trans(Label.ITEM__TAG_PENSIONS),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },

              {
                value: "collectiveAgreement",
                label: trans(Label.ITEM__TAG_COLLECTIVE_AGREEMENT),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },

              {
                value: "participation",
                label: trans(Label.ITEM__TAG_PARTICIPATION),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
              {
                value: "other",
                label: trans(Label.ITEM__TAG_OTHER),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForTariffArea", form);
                  form.setFieldValue("displayArea", "tariffArea");
                },
              },
            ],
          },
          true
        ),
        itemTypeNewsForMemberArea: new ButtonsField(
          "form-cw-itemTypeNewsForMemberArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              // news
              {
                value: "message",
                label: trans(Label.ITEM__TAG_MESSAGE),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
              {
                value: "link",
                label: trans(Label.ITEM__TAG_LINK),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
              {
                value: "graphic",
                label: trans(Label.ITEM__TAG_GRAPHIC),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
              {
                value: "video",
                label: trans(Label.ITEM__TAG_VIDEO),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
              {
                value: "quote",
                label: trans(Label.ITEM__TAG_QUOTE),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
              {
                value: "facebook",
                label: trans(Label.ITEM__TYPE_SOCIAL_MEDIA),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeNewsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },

              {
                value: "mediaReport",
                label: trans(Label.ITEM__TAG_MEDIA_REPORT),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
              //
              {
                value: "poll",
                label: trans(Label.ITEM__TAG_POLL),
                onSelect: (form) =>
                  setFieldValueToNull("itemTypeNewsForMemberArea", form),
              },
            ],
          }
        ),
        itemTypeEventsForMemberArea: new ButtonsField(
          "form-cw-itemTypeEventsForMemberArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              //events
              {
                value: "event",
                label: trans(Label.ITEM__TAG_EVENT),
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeEventsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
            ],
          }
        ),

        itemTypeTopicsForMemberArea: new ButtonsField(
          "form-cw-itemTypeTopicsForMemberArea-id",
          Field.FLAG_OPTIONAL,
          null,
          {
            label: trans(Label.ADMIN__CONTENT_WIZARD_ITEM_TYPE),
            options: [
              //topics
              {
                value: "industry",
                label: trans(Label.ITEM__TAG_INDUSTRY),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "corona",
                label: trans(Label.ITEM__TAG_CORONA),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "wagePolicy",
                label: trans(Label.ITEM__TAG_WAGE_POLICY),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "digitalization",
                label: trans(Label.ITEM__TAG_DIGITALIZATION),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "employment",
                label: trans(Label.ITEM__TAG_EMPLOYMENT),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "socialPolicy",
                label: trans(Label.ITEM__TAG_SOCIAL_POLICY),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },

              {
                value: "education",
                label: trans(Label.ITEM__TAG_EDUCATION),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "skilledEmployment",
                label: trans(Label.ITEM__TAG_SKILLED_EMPLOYMENT),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "laborPolicy",
                label: trans(Label.ITEM__TAG_LABOR_POLICY),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "legal",
                label: trans(Label.ITEM__TAG_LEGAL),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "international",
                label: trans(Label.ITEM__TAG_INTERNATIONAL),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "elections",
                label: trans(Label.ITEM__TAG_ELECTIONS),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
              {
                value: "other",
                label: trans(Label.ITEM__TAG_OTHER),
                group: "member-topics",
                onSelect: (form) => {
                  setFieldValueToNull("itemTypeTopicsForMemberArea", form);
                  form.setFieldValue("displayArea", "memberArea");
                },
              },
            ],
          },
          true
        ),
      }}
      layout={ContentWizardFormLayout}
    />
  );
});
