import React from "react";
import Add from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import FormBuilder, {
  FormState,
} from "../../../../../Tools/FormBuilder/FormBuilder";
import View from "../../../../../Layout/View";
import Form from "../../../../../Layout/Components/Form";
import { trans } from "../../../../../Tools/LocaleHandler";
import ErrorMsg from "../../../../../Layout/Components/ErrorMsg";
import Button from "../../../../../Layout/Components/Button";
import Label from "../../../../Util/Label";
import EmailField from "../../../../FormFields/EmailField";
import Field from "../../../../../Tools/FormBuilder/Field";
import HiddenField from "../../../../FormFields/HiddenField";
import HttpClient from "../../../../../State/HttpClient";
import Toast from "../../../../../Layout/Common/Toast";
import Records, { Record, RecordRef } from "../../../../../State/Records";

const styles = {
  segment: {
    fontSize: 20,
    marginBottom: 25,
  },
  span: {
    color: "#aaa",
    fontSize: 18,
  },
};

let submit: () => void = () => null;

const AssociationFormLayout = (form: FormState): React.ReactNode => {
  return (
    <Form form={form} style={{ paddingLeft: "4%", paddingRight: "4%" }}>
      <View
        style={{
          fontSize: 22,
          fontWeight: "bold",
          marginBottom: 24,
          marginTop: 24,
        }}
      >
        {form.data.record
          ? trans(Label.ADMIN__ASSOCIATION_EDIT)
          : trans(Label.ADMIN__ASSOCIATION_CREATE)}
      </View>

      <View
        style={{
          backgroundColor: "#fff",
          padding: 50,
          paddingLeft: 70,
          paddingRight: 70,
        }}
      >
        {form.data.record && (
          <div style={{ fontSize: 20, marginBottom: 40 }}>
            <span style={styles.span}>{trans(Label.FORM__ID)}</span>
            <br />
            <br />
            {form.data.record.id} : {form.data.record.uuid}
          </div>
        )}

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__NAME)}</span>
        </div>
        <ErrorMsg error={form.getError("form-association-name-id", true)} />
        {form.fields.name}
        <View space={50} />

        <ErrorMsg error={form.getError("form-association-logo-id", true)} />
        {form.fields.logo}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__CONTACT_EMAIL)}</span>
        </div>
        <ErrorMsg
          error={form.getError("form-association-contactEmail-id", true)}
        />
        {form.fields.contactEmail}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__CONTACT_ADDRESS)}</span>
        </div>
        <ErrorMsg
          error={form.getError("form-association-contactAddress-id", true)}
        />
        {form.fields.contactAddress}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__CONTACT_PHONE)}</span>
        </div>
        <ErrorMsg
          error={form.getError("form-association-contactPhone-id", true)}
        />
        {form.fields.contactPhone}
        <View space={50} />

        {/* association question emails { */}

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__QUESTIONS_EMAIL)}</span>
        </div>

        <FormBuilder
          action={(blueprint, done) => {
            const data = blueprint.fields;
            if (!form.data.record) {
              form.data.questionEmails.push(data);
              form.setDataValue("questionEmails", form.data.questionEmails);
              done(true);
              return;
            }
            HttpClient.action("addQuestionEmail", "Association", { data })
              .then((recordResult) => {
                const signature = [
                  "Association",
                  "AssociationQuestionEmails",
                  "association",
                  form.data.record.uuid,
                ].join(":");
                Records.addToCollection(
                  signature,
                  "AssociationQuestionEmails",
                  recordResult
                );
                done(true);
              })
              .catch((ex) => {
                console.warn(ex);
                Toast.show({ type: "warning", text: trans(ex.text || ex) });
                done();
              });
          }}
          fields={{
            ...(form.data.record
              ? {
                associationUuid: new HiddenField(
                  "form-addAssociationQuestionEmail-associationUuid-id",
                  Field.FLAG_REQUIRED,
                  form.data.record.uuid
                ),
              }
              : {}),
            ...{
              email: new EmailField(
                "form-addAssociationQuestionEmail-email-id",
                Field.FLAG_OPTIONAL,
                null,
                {
                  label: trans(Label.FORM__ADD_EMAIL),
                  onReturn: () => {
                    submit();
                  },
                  validations: ["email"],
                }
              ),
            },
          }}
          layout={(form: FormState) => {
            submit = () => form.buttons.submit.action();
            return (
              <div>
                <ErrorMsg
                  error={form.getError(
                    "form-addAssociationQuestionEmail-email-id",
                    true
                  )}
                  style={{ alignSelf: "flex-start" }}
                />
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  {form.fields.email}
                  <div
                    onClick={() => {
                      form.buttons.submit.action();
                    }}
                  >
                    <Add style={{ fontSize: 30, marginLeft: 10 }} />
                  </div>
                </div>
              </div>
            );
          }}
        />
        <View space={20} />

        {(form.data.questionEmails || []).map(
          (record: Record, index: number) => (
            <div key={record.uuid || index}>
              <div
                style={{
                  alignItems: "center",
                  color: "#777",
                  display: "flex",
                  fontSize: 16,
                  margin: 10,
                }}
              >
                &bull; {record.email}
                &nbsp;&nbsp;&nbsp;
                <div
                  onClick={() => {
                    if (!form.data.record) {
                      form.data.questionEmails.splice(index, 1);
                      form.setDataValue(
                        "questionEmails",
                        form.data.questionEmails
                      );
                      return;
                    }
                    Records.remove(
                      new RecordRef("AssociationQuestionEmails", record.uuid)
                    )
                      .then(() => {
                        Toast.show({
                          type: "info",
                          text: trans(Label.UI__SUCCESS),
                        });
                      })
                      .catch((ex) => {
                        console.warn(ex);
                        Toast.show({
                          type: "warning",
                          text: trans(ex.text || ex),
                        });
                      });
                  }}
                >
                  <Delete />
                </div>
              </div>
            </div>
          )
        )}
        <View space={50} />

        {/* association content emails { */}

        <div style={styles.segment}>
          <span style={styles.span}>
            {trans(Label.FORM__CONTENT_ADDED_EMAIL)}
          </span>
        </div>

        <FormBuilder
          action={(blueprint, done) => {
            const data = blueprint.fields;
            if (!form.data.record) {
              form.data.contentEmails.push(data);
              form.setDataValue("contentEmails", form.data.contentEmails);
              done(true);
              return;
            }
            HttpClient.action("addContentEmail", "Association", { data })
              .then((recordResult) => {
                const signature = [
                  "Association",
                  "AssociationContentEmails",
                  "association",
                  form.data.record.uuid,
                ].join(":");
                Records.addToCollection(
                  signature,
                  "AssociationContentEmails",
                  recordResult
                );
                done(true);
              })
              .catch((ex) => {
                console.warn(ex);
                Toast.show({ type: "warning", text: trans(ex.text || ex) });
                done();
              });
          }}
          fields={{
            ...(form.data.record
              ? {
                associationUuid: new HiddenField(
                  "form-addAssociationContentEmail-associationUuid-id",
                  Field.FLAG_REQUIRED,
                  form.data.record.uuid
                ),
              }
              : {}),
            ...{
              email: new EmailField(
                "form-addAssociationContentEmail-email-id",
                Field.FLAG_OPTIONAL,
                null,
                {
                  label: trans(Label.FORM__ADD_EMAIL),
                  onReturn: () => {
                    submit();
                  },
                  validations: ["email"],
                }
              ),
            },
          }}
          layout={(form: FormState) => {
            submit = () => form.buttons.submit.action();
            return (
              <div>
                <ErrorMsg
                  error={form.getError(
                    "form-addAssociationContentEmail-email-id",
                    true
                  )}
                  style={{ alignSelf: "flex-start" }}
                />
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  {form.fields.email}
                  <div
                    onClick={() => {
                      form.buttons.submit.action();
                    }}
                  >
                    <Add style={{ fontSize: 30, marginLeft: 10 }} />
                  </div>
                </div>
              </div>
            );
          }}
        />
        <View space={20} />

        {(form.data.contentEmails || []).map(
          (record: Record, index: number) => (
            <div key={record.uuid || index}>
              <div
                style={{
                  alignItems: "center",
                  color: "#777",
                  display: "flex",
                  fontSize: 16,
                  margin: 10,
                }}
              >
                &bull; {record.email}
                &nbsp;&nbsp;&nbsp;
                <div
                  onClick={() => {
                    if (!form.data.record) {
                      form.data.contentEmails.splice(index, 1);
                      form.setDataValue(
                        "contentEmails",
                        form.data.contentEmails
                      );
                      return;
                    }
                    Records.remove(
                      new RecordRef("AssociationContentEmails", record.uuid)
                    )
                      .then(() => {
                        Toast.show({
                          type: "info",
                          text: trans(Label.UI__SUCCESS),
                        });
                      })
                      .catch((ex) => {
                        console.warn(ex);
                        Toast.show({
                          type: "warning",
                          text: trans(ex.text || ex),
                        });
                      });
                  }}
                >
                  <Delete />
                </div>
              </div>
            </div>
          )
        )}
        <View space={50} />

        {/* association membership request emails { */}

        <div style={styles.segment}>
          <span style={styles.span}>
            {trans(Label.FORM__MEMBERSHIP_REQUEST_EMAIL)}
          </span>
        </div>

        <FormBuilder
          action={(blueprint, done) => {
            const data = blueprint.fields;
            if (!form.data.record) {
              form.data.membershipRequestEmails.push(data);
              form.setDataValue(
                "membershipRequestEmails",
                form.data.membershipRequestEmails
              );
              done(true);
              return;
            }
            HttpClient.action("addMembershipRequestEmail", "Association", {
              data,
            })
              .then((recordResult) => {
                const signature = [
                  "Association",
                  "AssociationMembershipRequestEmails",
                  "association",
                  form.data.record.uuid,
                ].join(":");
                Records.addToCollection(
                  signature,
                  "AssociationMembershipRequestEmails",
                  recordResult
                );
                done(true);
              })
              .catch((ex) => {
                console.warn(ex);
                Toast.show({ type: "warning", text: trans(ex.text || ex) });
                done();
              });
          }}
          fields={{
            ...(form.data.record
              ? {
                associationUuid: new HiddenField(
                  "form-addAssociationMembershipRequestEmail-associationUuid-id",
                  Field.FLAG_REQUIRED,
                  form.data.record.uuid
                ),
              }
              : {}),
            ...{
              email: new EmailField(
                "form-addAssociationMembershipRequestEmail-email-id",
                Field.FLAG_OPTIONAL,
                null,
                {
                  label: trans(Label.FORM__ADD_EMAIL),
                  onReturn: () => {
                    submit();
                  },
                  validations: ["email"],
                }
              ),
            },
          }}
          layout={(form: FormState) => {
            submit = () => form.buttons.submit.action();
            return (
              <div>
                <ErrorMsg
                  error={form.getError(
                    "form-addAssociationMembershipRequestEmail-email-id",
                    true
                  )}
                  style={{ alignSelf: "flex-start" }}
                />
                <div
                  style={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                  }}
                >
                  {form.fields.email}
                  <div
                    onClick={() => {
                      form.buttons.submit.action();
                    }}
                  >
                    <Add style={{ fontSize: 30, marginLeft: 10 }} />
                  </div>
                </div>
              </div>
            );
          }}
        />
        <View space={20} />

        {(form.data.membershipRequestEmails || []).map(
          (record: Record, index: number) => (
            <div key={record.uuid || index}>
              <div
                style={{
                  alignItems: "center",
                  color: "#777",
                  display: "flex",
                  fontSize: 16,
                  margin: 10,
                }}
              >
                &bull; {record.email}
                &nbsp;&nbsp;&nbsp;
                <div
                  onClick={() => {
                    if (!form.data.record) {
                      form.data.membershipRequestEmails.splice(index, 1);
                      form.setDataValue(
                        "membershipRequestEmails",
                        form.data.membershipRequestEmails
                      );
                      return;
                    }
                    Records.remove(
                      new RecordRef(
                        "AssociationMembershipRequestEmails",
                        record.uuid
                      )
                    )
                      .then(() => {
                        Toast.show({
                          type: "info",
                          text: trans(Label.UI__SUCCESS),
                        });
                      })
                      .catch((ex) => {
                        console.warn(ex);
                        Toast.show({
                          type: "warning",
                          text: trans(ex.text || ex),
                        });
                      });
                  }}
                >
                  <Delete />
                </div>
              </div>
            </div>
          )
        )}
        <View space={50} />

        {/* } */}

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__PUBLISHER)}</span>
        </div>
        <ErrorMsg
          error={form.getError("form-association-publisher-id", true)}
        />
        {form.fields.publisher}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__HIDDEN)}</span>
        </div>
        <ErrorMsg error={form.getError("form-association-hidden-id", true)} />
        {form.fields.hidden}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__HAS_TARIFF_AREA)}</span>
        </div>
        <ErrorMsg
          error={form.getError("form-association-hasTariffArea-id", true)}
        />
        {form.fields.hasTariffArea}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>
            {trans(Label.FORM__HAS_EXCLUSIVE_TARIFF_AREA)}
          </span>
        </div>
        <ErrorMsg
          error={form.getError(
            "form-association-hasExclusiveTariffArea-id",
            true
          )}
        />
        {form.fields.exclusiveForTariffArea}
        {/* <View space={50} /> */}

        {/* <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__TWITTER_ACCOUNT)}</span>
        </div>
        <ErrorMsg error={form.getError("form-association-twitter-id", true)} />
        {form.fields.twitterAccount}
        <View space={50} /> */}

        {/* <div style={styles.segment}>
          <span style={styles.span}>{trans(Label.FORM__FACEBOOK_ACCOUNT)}</span>
        </div>
        <ErrorMsg error={form.getError("form-association-facebook-id", true)} />
        {form.fields.facebookAccount}
        <View space={50} />

        <div style={styles.segment}>
          <span style={styles.span}>
            {trans(Label.FORM__FACEBOOK_ACCOUNT_TOKEN)}
          </span>
        </div>
        <ErrorMsg
          error={form.getError("form-association-facebook-token-id", true)}
        />
        {form.fields.facebookToken}
        <View space={50} /> */}
      </View>

      <View style={{ margin: 25 }}></View>

      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Button
          color={"#4daff0"}
          disabled={form.buttons.submit.disabled && form.touched}
          large={true}
          onPress={() => {
            form.buttons.submit.action();
          }}
          title={trans(Label.FORM__SUBMIT)}
        />
      </View>

      <View style={{ margin: 25 }}></View>
    </Form>
  );
};
export default AssociationFormLayout;
