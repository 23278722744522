import React, { FC, ReactNode, useEffect } from "react";
import View from "../../Layout/View";
import Text from "../../Layout/Components/Text";
import Field, {
  FieldContext,
  FieldFlagType,
  FieldSignal,
} from "../../Tools/FormBuilder/Field";
import SwitchButton from "../../Layout/Components/SwitchButton";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormBuilder, { FormState } from "../../Tools/FormBuilder/FormBuilder";

interface LayoutProps {
  id: string;
  context: FieldContext;
  value: any;
  onChange: any;
  formBuilder: FormBuilder;
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children, id, context, value, onChange, formBuilder }) => {
  useEffect(() => {
    if (!onChange) {
      return;
    }

    onChange(value, formBuilder.getFormState())
  }, [])

  return (
    <View key={`${id}:${context?.label}`}>
      <Text>{context?.label || ""}</Text>
      {children}
    </View>
  )
}

export default class OptionsField extends Field {
  private onChange: Function;
  public constructor(
    id: string,
    flag: FieldFlagType = Field.FLAG_OPTIONAL,
    value: any = null,
    context: FieldContext = null,
    onChange: Function = (value: any, form: FormState): void => null
  ) {
    super(id, flag, value, context);
    this.onChange = onChange;
  }

  public valueTransformer(value: any): any {
    if (!this.data || !this.data.length) {
      return !!value;
    } else {
      return typeof value === "string" ? value : "";
    }
  }

  public display(signal: FieldSignal): React.ReactNode {
    if (!this.data || !this.data.length) {
      return (
        <Layout
          id={this.id}
          context={this.context}
          value={this.value}
          onChange={this.onChange}
          formBuilder={this.formBuilder}
        >
          <SwitchButton
            onValueChange={(value: boolean) => {
              this.onChange(value, this.formBuilder.getFormState());
              this.change(value);
            }}
            ref={(ref) => ref && (Field.fieldRefs[this.id] = ref)}
            value={!!this.value}
          />
        </Layout>
      );
    }

    return (
      <Select
        onChange={(ev) => {
          this.change(ev.target.value.toString());
        }}
        ref={(ref) => ref && (Field.fieldRefs[this.id] = ref)}
        value={this.value}
      >
        {this.data.map((item) => (
          <MenuItem key={`${this.id}:${item.label}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
    );
  }
}
