import { ReactElement, ReactNode } from "react";
import FormBuilder, { Blueprint, FormState } from "./FormBuilder";

// type ChangeFn = (value: any, forceInvalidate?: boolean, errorMessage?: string) => void;
type ChangeFn = (value: any, forceInvalidate?: boolean) => void;
export type FieldContext = {
  bgColor?: string;
  collectionFormBuilder?: (
    formIndex: number,
    refFn: (ref: FormBuilder) => void
  ) => ReactElement<FormBuilder>;
  color?: string;
  focus?: boolean;
  height?: number;
  index?: string;
  label?: string;
  matches?: string;
  meta?: any;
  multiple?: boolean;
  next?: string;
  onReturn?: () => void;
  options?: Option[];
  theme?: { [key: string]: any };
  validations?: string[];
  variant?: string;
  minDate?: Date;
};
export type FieldFlagType = "fieldFlagOptional" | "fieldFlagRequired";
export type FieldSignal = { blur: Function; focus: Function };
export type Option = {
  label: string;
  value: string;
  onSelect?: (form: FormState) => void;
  group?: string | null;
  selected?: boolean;
};
type TransFn = (label: string) => string;

abstract class Field {
  public static VALIDATION_ERROR__REQUIRED = "trans:validationError:required";
  public static VALIDATION_ERROR__MATCH = "trans:validationError:match";
  public static VALIDATION_ERROR__IMAGE = "trans:validationError:image";
  public static VALIDATION_ERROR__SVG = "trans:validationError:svg";
  public static VALIDATION_ERROR__VIDEO = "trans:validationError:video";

  public static readonly FLAG_OPTIONAL = "fieldFlagOptional";
  public static readonly FLAG_REQUIRED = "fieldFlagRequired";

  public static activeElem: string = null;
  public static fieldRefs: { [key: string]: any } = {};
  public static fieldValues: { [key: string]: any } = {};

  private static transFn: TransFn = (label: string) => label;
  private static validationErrorTransFn: TransFn = (label: string) => label;

  //

  public id: string;
  public name: string;
  public type: string;
  public flag: string;
  public context: FieldContext;

  // for options
  public data: Option[] = null;

  public isRequired: boolean = false;
  public isValid: boolean = true;
  public touched: boolean = false;
  public value: any;
  public initialValue: any;
  public skipFieldIfValueIsInitialFlag: boolean = false;

  public isOptionsField: boolean = false;
  public formBuilder: FormBuilder = null;

  protected change: ChangeFn;
  protected changeNotify: Function = (): void => null;
  protected error: string = "";

  protected static validatorFn: (method: string, value: string) => boolean; // <== validatorFn

  private collectionIndex: number;
  private readonly collectionForms: { [key: string]: ReactNode };
  private readonly collectionFormBuilderRefs: { [key: string]: FormBuilder };

  abstract display(
    signal: FieldSignal,
    setData: (key: string, value: any, rerender?: boolean) => void
  ): ReactNode;

  public constructor(
    id: string,
    flag: FieldFlagType = Field.FLAG_OPTIONAL,
    value: any = null,
    context: FieldContext = null
  ) {
    if (context && context.options) {
      this.data = Array.isArray(context.options) ? context.options : [];
      this.isOptionsField = true;
    }

    this.value =
      typeof value === "string"
        ? this.valueTransformer(value.trim())
        : this.valueTransformer(value);
    this.initialValue = this.value;

    this.id = id;
    this.flag = flag;
    this.context = context || {};
    this.name = id.split("-")[2];

    this.flag === Field.FLAG_REQUIRED && (this.isRequired = true);

    // collections
    this.collectionForms = this.context.collectionFormBuilder ? {} : null;
    this.collectionIndex = this.context.collectionFormBuilder ? 0 : null;
    this.collectionFormBuilderRefs = this.context.collectionFormBuilder
      ? {}
      : null;
    this.collectionForms && this.addCollectionItem();

    this.change = (value: any, forceInvalidateFlag: boolean = false) => {
      this.touched = true;

      this.value =
        typeof value === "string"
          ? this.valueTransformer(value.trim())
          : this.valueTransformer(value);

      if (!forceInvalidateFlag) {
        this.validate(this.value);
      } else {
        this.isValid = false;
      }

      Field.fieldValues[this.id] = this.value;

      // onSelect
      if (this.data) {
        let option: Option;
        for (option of this.data) {
          if (option.value === value) {
            typeof option.onSelect === "function" &&
              option.onSelect(this.formBuilder.getFormState());
            break;
          }
        }
      }

      // this has to be async in case the field child of the form child of the collection field of the parent form will
      // trigger a rerender inside the child form that will trigger a rerender to the parent form
      setTimeout(() => this.changeNotify(), 1);
    };
    this.validate(this.value);
  }

  public setFormBuilder(formBuilder: FormBuilder): void {
    this.formBuilder = formBuilder;
  }

  public updateValue(newValue: any) {
    if (this.value !== newValue) {
      this.value = newValue;
      this.change(this.value);
    }
  }

  public addCollectionItem(): void {
    const formIndex = this.collectionIndex;
    const FormBuilder = this.context.collectionFormBuilder(
      formIndex,
      (ref: FormBuilder) => {
        if (!ref) return;
        ref.setFormIndex(formIndex);
        // form notifies changes to the collection field it is displayed in, that subsequently notifies the form it belongs to (and runs validations altogether)
        ref.setParentFormChangeNotify(() => this.getCollections());
        // set remove form (from collections)
        ref.setRemove(() => {
          delete this.collectionForms[formIndex];
          delete this.collectionFormBuilderRefs[formIndex];
          this.getCollections();
        });
        this.collectionFormBuilderRefs[formIndex] = ref;
        this.getCollections();
      }
    );
    this.collectionIndex++;
    this.collectionForms[formIndex.toString()] = FormBuilder;
    this.changeNotify();
  }
  private getCollections(): void {
    let valid = true;
    let value: any = "";
    const collectionBlueprints: Blueprint[] = [];
    Object.values(this.collectionFormBuilderRefs).forEach((ref) => {
      if (!ref.isValid) {
        valid = false;
      } else {
        collectionBlueprints.push(ref.getBlueprint());
      }
      ref.setState({
        canRemoveFlag:
          Object.values(this.collectionForms).length > 1 ||
          this.flag === Field.FLAG_OPTIONAL,
      });
    });
    if (valid && collectionBlueprints.length) value = collectionBlueprints;
    const forceInvalidateFlag = !valid;
    this.change(value, forceInvalidateFlag);
  }

  public setChangeNotify(changeNotify: Function): void {
    this.changeNotify = changeNotify;
  }

  public setError(error: string): void {
    this.error = this.touched ? error : "";
  }
  public getError(): string {
    return this.error;
  }

  public setValue(value: any): void {
    this.value = value;
    this.validate(this.value);
  }

  public setRequired(required: boolean): void {
    this.isRequired = required;
    this.validate(this.value);
  }

  public focusNext(): void {
    if (
      this.context.next &&
      Field.fieldRefs[this.context.next] &&
      typeof Field.fieldRefs[this.context.next].focus === "function"
    )
      Field.fieldRefs[this.context.next].focus();
  }

  public getCollectionForms(): { index: string; node: ReactNode }[] {
    if (!this.collectionForms) return [];
    const map: { index: string; node: ReactNode }[] = [];
    Object.entries(this.collectionForms).forEach(([index, node]) => {
      map.push({ index, node });
    });
    return map;
  }

  public reset(): void {
    this.value = this.initialValue;
    this.touched = false;
    this.validate(this.value);
    if (Field.fieldRefs[this.id]) {
      typeof Field.fieldRefs[this.id].value !== "undefined" &&
        (Field.fieldRefs[this.id].value = this.initialValue);
      if (
        Field.fieldRefs[this.id] &&
        typeof Field.fieldRefs[this.id].setState === "function" &&
        Field.fieldRefs[this.id].state &&
        typeof Field.fieldRefs[this.id].state.value !== "undefined"
      ) {
        Field.fieldRefs[this.id].setState({ value: this.initialValue });
      }
    }
    this.changeNotify(true /*do not touch form*/);
  }

  public skipFieldIfValueIsInitial(flag: boolean = true): void {
    this.skipFieldIfValueIsInitialFlag = flag;
  }

  public trans(label: string): string {
    return Field.transFn(label);
  }

  public validate(value: any): boolean {
    switch (true) {
      case this.isRequired:
        if ((typeof value === "string" && !value.length) || !value) {
          this.setError(this.trans(Field.VALIDATION_ERROR__REQUIRED));
          this.isValid = false;
          return false;
        }
        break;
      case (typeof value === "string" && !value.length) || !value:
        this.isValid = true;
        return true;
    }

    // matches some other field validation
    if (
      typeof this.context.matches !== "undefined" &&
      value !== Field.fieldValues[this.context.matches]
    ) {
      this.isValid = false;
      this.setError(this.trans(Field.VALIDATION_ERROR__MATCH));
      return false;
    }

    let okFlag: boolean = true;

    const validations: string[] = this.context.validations
      ? this.context.validations
      : [];

    if (!validations) {
      this.isValid = true;
      return true;
    }

    for (let validation of validations) {
      okFlag = Field.validatorFn
        ? Field.validatorFn(validation, value)
        : (() => {
            console.warn("Validator not set.");
            return false;
          })();
      if (!okFlag) {
        this.setError(Field.validationErrorTransFn(validation));
        break;
      }
    }

    this.isValid = okFlag;

    if (okFlag) {
      this.setError("");
      return okFlag;
    }

    return okFlag;
  }

  public valueTransformer(value: any): any {
    if (this.id !== "form-item-address-id") return value;
    if (value !== "") return value;
    return null;
  }

  public static setValidatorFn(
    validatorFn: (method: string, value: any) => boolean
  ): void {
    this.validatorFn = validatorFn;
  }

  public static setTransFn(transFn: TransFn): void {
    Field.transFn = transFn;
  }

  public static setValidationErrorTransFn(
    validationErrorTransFn: TransFn
  ): void {
    Field.validationErrorTransFn = validationErrorTransFn;
  }
}

export default Field;

// export { FieldFlagType, FieldSignal, Option };
