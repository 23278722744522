import React from "react";
import { connect } from "react-redux";
import FormBuilder from "../../../../../Tools/FormBuilder/FormBuilder";
import HttpClient from "../../../../../State/HttpClient";
import { getHomeUrl, pushRoute } from "../../../../../State/Router";
import { trans } from "../../../../../Tools/LocaleHandler";
import Toast from "../../../../../Layout/Common/Toast";
import EmailField from "../../../../FormFields/EmailField";
import Field from "../../../../../Tools/FormBuilder/Field";
import Label from "../../../../Util/Label";
import PasswordField from "../../../../FormFields/PasswordField";
import { auth } from "../../../../Root";
import PageNotFound from "../../../../Layout/Views/PageNotFound";
import InputField from "../../../../FormFields/InputField";
import OptionsField from "../../../../FormFields/OptionsField";
import GridField from "../../../../FormFields/GridField";
import { asset, getClientType } from "../../../../Util/Helper";
import RegisterFormLayout from "../GateForms/RegisterFormLayout";
import Records, { Record } from "../../../../../State/Records";
import { RecordObject } from "../../../../../State/Records";
import { StateType } from "../../../../../State/Store";
import PhoneField from "../../../../FormFields/PhoneField";

export default connect((state: StateType) => ({
  associations: Records.mapRefsToRecords(state.lists["Association:all"]),
  authObject: state.app.authObject,
}))(
  (props: {
    associations: Record[];
    authObject: RecordObject;
  }): React.ReactElement => {
    if (props.authObject) {
      if (getClientType() === "ctmobile") {
        pushRoute(getHomeUrl());
        return null;
      }
      return <PageNotFound />;
    }

    props.associations || Records.list("Association", "all");

    if (props.associations === null) {
      return null; // aka loading
    }

    return (
      <FormBuilder
        key={"form-register"}
        action={(blueprint, done) => {
          HttpClient.action("create", "User", { blueprint })
            .then((authObject: RecordObject) => {
              auth(authObject, false, () => {
                pushRoute(`/Gate/Auth/Opening`);
              });
            })
            .catch((ex) => {
              console.warn(ex);
              ex.text &&
                Toast.show({ type: "warning", text: trans(ex.text || ex) });
            })
            .finally(() => done());
        }}
        data={{
          associations: props.associations,
        }}
        fields={{
          email: new EmailField(
            "form-register-email-id",
            Field.FLAG_REQUIRED,
            null,
            {
              focus: true,
              label: trans(Label.FORM__EMAIL),
              next: "form-register-password-id",
              validations: ["email"],
            }
          ),
          password: new PasswordField(
            "form-register-password-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__PASSWORD),
              next: "form-register-passwordConfirm-id",
              validations: [],
            }
          ),
          firstName: new InputField(
            "form-register-firstName-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__FIRST_NAME),
              next: "form-register-lastName-id",
              validations: [],
            }
          ),
          lastName: new InputField(
            "form-register-lastName-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__LAST_NAME),
              next: "form-register-phoneNumber-id",
              validations: ["phoneNumber"],
            }
          ),
          phoneNumber: new PhoneField(
            "form-register-phoneNumber-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__PHONE_NUMBER),
              next: "form-register-organisationName-id",
              validations: [],
            }
          ),
          organisationName: new InputField(
            "form-register-organisationName-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__ORGANISATION_NAME),
              next: "form-register-organisationPosition-id",
              validations: [],
            }
          ),
          organisationPosition: new InputField(
            "form-register-organisationPosition-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__ORGANISATION_POSITION),
              validations: [],
            }
          ),
          hasTariffArea: new OptionsField(
            "form-register-tariff-area-id",
            Field.FLAG_OPTIONAL,
            null,
            {
              label: trans(Label.FORM__HAS_TARIFF_AREA),
              validations: [],
            }
          ),
          agreement: new OptionsField(
            "form-register-agreement-id",
            Field.FLAG_REQUIRED,
            null
          ),
        }}
        metas={{
          association: new GridField(
            "form-register-associations-id",
            Field.FLAG_OPTIONAL,
            null,
            {
              label: trans(Label.FORM__SELECT_ASSOCIATION),
              meta: {
                associations: props.associations.filter(
                  (association) => association.exclusiveForTariffArea === 0
                ),
                gridImg: { uri: asset(`/Assets/Layout/MapGermany.png`) },
                gridSize: 6,
                labelAssociationSub: trans(Label.FORM__SELECT_ASSOCIATION_SUB),
              },
            }
          ),
          associationWithTariffArea: new GridField(
            "form-register-associations-id",
            Field.FLAG_OPTIONAL,
            null,
            {
              label: trans(Label.FORM__SELECT_ASSOCIATION),
              meta: {
                associations: props.associations.filter(
                  (association) => association.hasTariffArea === 1
                ),
                gridImg: { uri: asset(`/Assets/Layout/MapGermany.png`) },
                gridSize: 6,
                labelAssociationSub: trans(Label.FORM__SELECT_ASSOCIATION_SUB),
              },
            }
          ),
          passwordConfirm: new PasswordField(
            "form-register-passwordConfirm-id",
            Field.FLAG_REQUIRED,
            null,
            {
              label: trans(Label.FORM__PASSWORD_CONFIRM),
              matches: "form-register-password-id",
              next: "form-register-firstName-id",
            }
          ),
        }}
        layout={RegisterFormLayout}
        steps={[
          ["form-register-tariff-area-id"],
          ["form-register-associations-id"],
          [
            "form-register-email-id",
            "form-register-password-id",
            "form-register-passwordConfirm-id",
            "form-register-firstName-id",
            "form-register-lastName-id",
            "form-register-phoneNUmber-id",
            "form-register-organisationName-id",
            "form-register-organisationPosition-id",
            "form-register-agreement-id",
          ],
        ]}
      />
    );
  }
);
