import React, { SyntheticEvent } from "react";
import Box from "@mui/material/Box/Box";
import { InfoBubble } from "../../Layout/Components/InfoBubble";
import Field, { FieldSignal } from "../../Tools/FormBuilder/Field";
import HttpClient from "../../State/HttpClient";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import RestoreIcon from "@mui/icons-material/Restore";

const maxFileSize = 209715200;

export default class DocumentField extends Field {
  public defaultValue: string[] = null;
  public emptyFiles: FileList = null;
  public files: FileList = null;
  public signal: FieldSignal = null;

  public reset(): void {
    this.resetDocument();
    super.reset();
  }

  public display(signal: FieldSignal): React.ReactNode {
    this.signal = signal;
    this.skipFieldIfValueIsInitial();
    if (!this.defaultValue && this.value && Array.isArray(this.value)) {
      this.defaultValue = this.value;
    }
    const colorForError = this.error
      ? "#ff3333"
      : this.isRequired && !this.isValid
      ? "#ff3333"
      : "transparent";
    return (
      <Box sx={{ display: "flex", position: "relative" }}>
        <div
          style={{
            borderColor: colorForError,
            boxShadow: `0px 0px 5px ${colorForError}`,
            borderWidth: 1,
            borderStyle: "solid",
            //
            backgroundColor: !this.context.bgColor
              ? "#f2f5f7"
              : this.context.bgColor,
            borderRadius: 16,
            display: "flex",
            flex: 1,
            flexDirection: "column",
            padding: 18,
            paddingLeft: 32,
            paddingRight: 32,
          }}
        >
          <input
            id={this.id}
            name={this.context.label}
            multiple={true}
            type={"file"}
            accept="application/pdf, audio/mpeg,audio/wav, video/mp4"
            onBlur={() => signal.blur()}
            onChange={(ev) => {
              this.signal.focus();
              this.isValid = true;
              if (!ev.target.files.length) {
                this.setNullDocument();
                return;
              }
              const files = ev.target.files;
              Object.values(files).forEach((file) => {
                if (file.size > maxFileSize) {
                  this.setNullDocument();
                  this.setError(
                    `Datei (${file.name}). ${this.trans(
                      "trans.validationError.maxSize"
                    )}`
                  );
                  this.isValid = false; //!this.isRequired || false;
                  return;
                }
              });
              if (this.isValid) this.setDocument(files);
            }}
            ref={(ref) => {
              if (!ref) return;
              Field.fieldRefs[this.id] = ref;
              if (!this.emptyFiles) this.emptyFiles = ref.files; // hold ref empty files to clear on reset / remove
              if (!this.files) return;
              ref.files = this.files;
            }}
            style={{ display: "none" }}
          />

          <div style={{ display: "flex" }}>
            <button type={"button"}>
              <label htmlFor={this.id}>{this.context.label}</label>
            </button>
            {this.defaultValue && (
              <IconButton
                color="primary"
                onClick={() => this.resetDocument()}
                sx={{ padding: 0 }}
              >
                <RestoreIcon />
              </IconButton>
            )}
            {this.value && (
              <IconButton
                color="error"
                onClick={() => this.setNullDocument()}
                sx={{ padding: 0 }}
              >
                <CancelIcon />
              </IconButton>
            )}
          </div>

          {Object.values(
            this.files || Array.isArray(this.value) ? this.value : []
          ).map((file: any, index) => (
            <div
              key={`${index}:${file.name}`}
              style={{
                marginTop: 10,
                display: "block",
                maxWidth: 180,
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                textTransform: "lowercase",
              }}
            >
              &bull;{" "}
              {typeof file === "string" ? (
                <a
                  onAuxClick={(ev) => this.downloadHandler(ev, file)}
                  onClick={(ev) => this.downloadHandler(ev, file)}
                  href={file}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {file}
                </a>
              ) : (
                file.name
              )}
            </div>
          ))}
        </div>
        <Box sx={{ paddingTop: "10px", position: "absolute", right: ".8rem" }}>
          <InfoBubble fieldId={this.id} />
        </Box>
      </Box>
    );
  }

  protected resetDocument(): void {
    if (!this.defaultValue) {
      this.setNullDocument();
      return;
    }

    this.signal.focus();
    this.files = null;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    this.change(this.defaultValue);
  }

  protected setNullDocument(): void {
    this.signal.focus();
    this.files = null;
    Field.fieldRefs[this.id].files = this.emptyFiles;
    this.change(null);
  }

  protected setDocument(filelist: FileList): void {
    this.signal.focus();
    this.files = filelist;
    this.change(filelist);
  }

  protected downloadHandler(ev: SyntheticEvent, documentAsset: string) {
    ev.preventDefault();
    HttpClient.action("getDownloadToken", "User").then((downloadToken) => {
      window.open(`${documentAsset}?downloadToken=${downloadToken}`, "_blank");
    });
    return false;
  }
}
