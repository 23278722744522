import Typography from "@mui/material/Typography";
type TitleProps = {
  title: string;
  color: string;
};
export const Title: React.FC<TitleProps> = ({ title, color }) => {
  return (
    <Typography variant="h5" sx={{ color: color, fontWeight: "bold" }}>
      {title}
    </Typography>
  );
};
