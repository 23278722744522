import styled from "styled-components";

type ErrorMsgProps = {
  error: string;
  style?: { [key: string]: string | number };
};

const StyledErrorMessage = styled.div<ErrorMsgProps>`
  position: relative;
  .main-box {
    align-items: center;
    align-self: center;
    position: absolute;
    bottom: 0px;
    flex-direction: column;
    display: ${(props) => (props.error ? "flex" : "none")};
    ${(props) => (props.style ? { ...props.style } : null)}};
    .message-body{
      background-color: #ed6161;
      border-radius: 8px;
      color: #fff;
      padding: 12px;
      padding-left: 32px;
      padding-right: 32px;
    }
    .marker{
      background-сolor: transparent;
      border-bottom-width: 0px;
      border-left-width: 8px;
      border-right-width: 8px;
      border-top-width: 8px;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: #ed6161;
      border-style: solid;
      height: 0px;
      width: 0px;
    }
  }
`;

const ErrorMsg: React.FC<ErrorMsgProps> = ({
  error,
  style,
}): React.ReactElement<ErrorMsgProps> => {
  return (
    <StyledErrorMessage error={error} style={style}>
      <div className="main-box">
        <div className="message-body">{error}</div>
        <div className="marker" />
      </div>
    </StyledErrorMessage>
  );
};
export default ErrorMsg;
