import React from 'react';
import Field, { FieldSignal, FieldFlagType, FieldContext } from '../../Tools/FormBuilder/Field';

export default class HiddenField extends Field {
    constructor(id: string, flag?: FieldFlagType, value?: any, context?: FieldContext) {
        super(id, flag, value, context);
    }

    public display(signal: FieldSignal): React.ReactNode {
        return null;
    }
}
