// import AsyncStorage from '@react-native-community/async-storage';
// import {getClientType} from './StateHelper';

export default new class Storage {
    protected storage: {[key: string]: any} = {};

    public start(): Promise<void> {
        return new Promise(resolve => {
            let storage: string;
            // switch (getClientType()) {
            // case 'ctweb':
                storage = localStorage.getItem('storage');
                this.storage = storage ? JSON.parse(storage) : {};
                resolve();
            //     break;
            // case 'ctmobile':
            //     AsyncStorage.getItem('storage').then(storage => {
            //         this.storage = storage ? JSON.parse(storage) : {};
            //         resolve();
            //     });
            //     return;
            // default:
            //     resolve();
            // }
        });
    }

    //

    public get<Item>(prop: string, defaultItem: Item = null): Item {
        const item: Item = this.storage[prop];
        return item ? item : defaultItem;
    }

    public set<Item>(prop: string, item: Item) {
        this.storage[prop] = JSON.parse(JSON.stringify(item));

        // switch (getClientType()) {
        // case 'ctweb':
            localStorage.setItem('storage', JSON.stringify(this.storage));
        //     break;
        // case 'ctmobile':
        //     // todo potential bug: this is async. not waiting for promise resolve, execution order could be bad
        //     AsyncStorage.setItem('storage', JSON.stringify(this.storage));
        //     break;
        // }
    }
}();