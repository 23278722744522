import { createStore } from "redux";
import { Record, RecordObject, RecordRef } from "./Records";

export type ErrorType = 404 | 410;

export type StateType = {
  app: {
    authObject: RecordObject;
  };
  collections: { [key: string]: RecordRef[] };
  errors: {
    records: { [key: string]: ErrorType };
  };
  lists: { [key: string]: RecordRef[] };
  records: { [key: string]: { [key: string]: Record } };
  ui: { [key: string]: any };
};

type StateUpdate = {
  app?: {
    authObject?: RecordObject;
  };
  collections?: { [key: string]: RecordRef[] };
  errors?: {
    records: {};
  };
  lists?: { [key: string]: RecordRef[] };
  records?: { [key: string]: { [key: string]: Record } };
  ui?: any;
};

const getDefaultState = (): StateType => ({
  app: {
    authObject: null,
  },
  collections: {},
  errors: {
    records: {},
  },
  lists: {},
  records: {},
  ui: {},
});

const reducer = (
  state: StateType = getDefaultState(),
  action: {
    type: string;
    value: { domain: string; data: { [key: string]: any } };
  }
) => {
  if (!action || !action.value) {
    return state;
  }

  switch (action.type) {
    case "mergeDomain":
      state[action.value.domain] = {
        ...state[action.value.domain],
        ...action.value.data,
      };
      return { ...state };

    case "replaceDomain":
      state[action.value.domain] = action.value.data;
      return { ...state };

    case "mergeState":
      if (action.value.data.app) {
        state.app = { ...state.app, ...action.value.data.app };
      }

      Object.entries<RecordRef[]>(action.value.data.collections || {}).forEach(
        ([signature, recordRefs]) => {
          if (!state.collections[signature]) {
            state.collections[signature] = [];
          }
          state.collections[signature] = recordRefs;
        }
      );

      Object.entries<RecordRef[]>(action.value.data.lists || {}).forEach(
        ([signature, recordRefs]) => {
          if (!state.lists[signature]) {
            state.lists[signature] = [];
          }
          state.lists[signature] = recordRefs;
        }
      );

      Object.entries<Record>(action.value.data.records || {}).forEach(
        ([repository, recordSet]) => {
          if (!state.records[repository]) {
            state.records[repository] = {};
          }
          state.records[repository] = {
            ...state.records[repository],
            ...recordSet,
          };
        }
      );

      if (action.value.data.ui) {
        state.ui = { ...state.ui, ...action.value.data.ui };
      }

      return { ...state };

    case "replaceState":
      if (action.value.data.app) {
        state.app = action.value.data.app;
      }

      if (action.value.data.collections) {
        state.collections = action.value.data.collections;
      }

      if (action.value.data.lists) {
        state.lists = action.value.data.lists;
      }

      if (action.value.data.records) {
        state.records = action.value.data.records;
      }

      if (action.value.data.ui) {
        state.ui = action.value.data.ui;
      }

      return { ...state };

    case "resetState":
      return { ...getDefaultState() };

    case "setRecord":
      if (!state.records[action.value.data.repository]) {
        state.records[action.value.data.repository] = {
          [action.value.data.uuid]: action.value.data.record,
        };
        return { ...state };
      }
      state.records[action.value.data.repository][action.value.data.uuid] =
        action.value.data.record;
      return { ...state };

    case "setRepository":
      state.records[action.value.data.repository] = action.value.data.recordSet;
      return { ...state };

    case "setList":
      state.lists[action.value.data.signature] = action.value.data.recordRefs;
      return { ...state };

    case "removeList":
      if (!state.lists[action.value.data.signature]) {
        return state;
      }
      delete state.lists[action.value.data.signature];
      return { ...state };

    case "setCollection":
      state.collections[action.value.data.signature] =
        action.value.data.recordRefs;
      return { ...state };

    case "removeCollection":
      if (!state.collections[action.value.data.signature]) {
        return state;
      }
      delete state.collections[action.value.data.signature];
      return { ...state };

    case "setError":
      if (!state.errors[action.value.domain]) {
        return state;
      }
      if (!state.errors[action.value.domain][action.value.data.repository]) {
        state.errors[action.value.domain][action.value.data.repository] = {
          [action.value.data.uuid]: action.value.data.error,
        };
        return { ...state };
      }
      state.errors[action.value.domain][action.value.data.repository][
        action.value.data.uuid
      ] = action.value.data.error;
      return { ...state };

    default:
      return state;
  }
};

const store = createStore(reducer);

const mergeDomain = (domain: string, data: any) => {
  store.dispatch({ type: "mergeDomain", value: { domain, data } });
};

const replaceDomain = (domain: string, data: any) => {
  store.dispatch({ type: "replaceDomain", value: { domain, data } });
};

const mergeState = (state: StateUpdate) => {
  store.dispatch({
    type: "mergeState",
    value: { domain: null, data: { ...state } },
  });
};

const replaceState = (state: StateUpdate) => {
  store.dispatch({
    type: "replaceState",
    value: { domain: null, data: { ...state } },
  });
};

const resetState = () => {
  store.dispatch({ type: "resetState", value: { domain: null, data: null } });
};

const setRecord = (repository: string, uuid: string, record: Record) => {
  store.dispatch({
    type: "setRecord",
    value: {
      domain: "records",
      data: { repository, uuid, record },
    },
  });
};

const setRepository = (
  repository: string,
  recordSet: { [key: string]: Record }
) => {
  store.dispatch({
    type: "setRepository",
    value: {
      domain: "records",
      data: { repository, recordSet },
    },
  });
};

const setList = (signature: string, recordRefs: RecordRef[]) => {
  store.dispatch({
    type: "setList",
    value: {
      domain: "lists",
      data: { signature, recordRefs },
    },
  });
};

const removeList = (signature: string) => {
  store.dispatch({
    type: "removeList",
    value: {
      domain: "lists",
      data: { signature },
    },
  });
};

const setCollection = (signature: string, recordRefs: RecordRef[]) => {
  store.dispatch({
    type: "setCollection",
    value: {
      domain: "collections",
      data: { signature, recordRefs },
    },
  });
};

const removeCollection = (signature: string) => {
  store.dispatch({
    type: "removeCollection",
    value: {
      domain: "collections",
      data: { signature },
    },
  });
};

const setRecordError = (repository: string, uuid: string, error: ErrorType) => {
  store.dispatch({
    type: "setError",
    value: {
      domain: "records",
      data: { repository, uuid, error },
    },
  });
};

export {
  mergeDomain,
  replaceDomain,
  mergeState,
  replaceState,
  resetState,
  setRecord,
  setRepository,
  setList,
  removeList,
  setCollection,
  removeCollection,
  setRecordError,
  //   StateType,
  store,
  //   ErrorType,
};
